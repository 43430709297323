import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import {default as styles} from "../Styles"
import action from "../../../images/Action.jpg"


export default function IrrigationAction(irrigation, farmDetails){
  const decodeHTMLEntities = (text) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  }
  function renderActionWithImages(action, images) {
    // Define a regex pattern for the image markers
    const pattern = /irrigationAction[^\s]+/g;
   
    // Split the commentary into segments based on the pattern
    let segments = [];
    let lastIndex = 0;
    let match;
    while ((match = pattern.exec(action)) !== null) {
         console.log(match);
        // Push the text segment before the image marker
        segments.push({ type: 'text', content: action.slice(lastIndex, match.index) });
        
        // Push the image segment
        segments.push({ type: 'image', key: match[0] });  // Change here: use match[0]
        
        lastIndex = match.index + match[0].length;
    }
    
    // Push any remaining text after the last image marker
    if (lastIndex < action.length) {
        segments.push({ type: 'text', content: action.slice(lastIndex) });
    }
    
    console.log("Action", segments)
    // Render each segment as either text or image
    return segments.map((segment, index) => {
      if (segment.type === 'text') {
           console.log(segment.action); 
           return <Text key={index} style={{ ...styles.commentaryAction}}>{decodeHTMLEntities(segment.content)}</Text>;
      } else {
          const imageData = images[segment.key];
          if (imageData) {
              return (
                <>
                  <div style={[styles.commentaryImageWrapper]}> 
                    <Image key={index} src={imageData} style={{ width: "35%", height: "auto", marginBottom: 10 }} />
                    <Text key={`spacer-${index}`} style={{ height: 20, display:"block" }}>&nbsp;</Text>
                  </div>
                </>
              );
          }
        }
      });
        // return (
    // <Text style={styles.commentary}>
    // {generalFarm.commentary}
    // </Text>
    // )
  }
    return(
      <Page>
      {/* header */}
      <div fixed style = {styles.actionHeaderImageBox} >
        <Image 
          src = {action}
          style={{position: 'relative',
          display: 'flex',
          paddingBottom: 0,
          marginBottom: 0}} />
      
        <div style={{position: 'absolute'}}>
          <Text style={styles.title}>The Action</Text>
          <Text style={styles.secondSubtitle}>Irrigation Management</Text>  
        </div>
      </div>

      <View fixed style = {{height: 15}}></View>
      <div style={styles.wrapper}>
      {renderActionWithImages(irrigation.action, irrigation.images)} 
      </div>
      <View fixed style = {{marginTop: "30%"}}></View> 
      <Text style={styles.farmName} fixed>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
      </Page>
    )
}