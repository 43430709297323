import React, { useState, createContext } from 'react';

// Define default states
const defaultFarmDetails = {
    supplier_no: "",
    farm_owner: "",
    plan_owner: "",
    farmAddress: "",
    farmRegion: "",
    farmPic: "",
    farmAddressLat: 0,
    farmAddressLong: 0,
    date_edited: "",
    currentYear: "",
    futureYear: "",
    financialYear:"",
    farmSurveyId: "",
    revisionNumber: "",
    surveyData: [],
    modules: [],
    hasFarmPicChanged: true,
    mapRequired: ""
};

const defaultGeneralFarm = {
    questions: [
        "Is a farm map available that identifies environmental characteristics on your farm?",
        "Are practices in place to minimise soil structure problems and erosion on farm?",
        "Is soil testing conducted at least every 2 years and used in conjunction with a whole-farm nutrient budget?",
        "Is fertiliser applied using the (4 Rs) right rate, right place, right time, right source approach?",
        "Do you believe your effluent management practices are current?",
        "Is biodiversity considered when making practice change on farm?",
        "Is efficient use of energy important to your farm?",
        "Do you believe water use for the dairy shed and stock water is efficient?",
        "Are controls in place for weeds and pests on farm?",
        "Are silage wrap and animal carcasses disposed of in an environmentally friendly way?",
        "Do you have shade and shelter belt plantings on farm?",
        "Are stock fenced out of riparian zones?"
    ],
    scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    practices: ["", "", "", "", "", "", "", "", "", "", "", ""],
    focusArea: ["", "", "", "", "", "", "", "", "", "", "", ""],
    goodColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    improvementColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    innovativeColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    focusColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
    },
    commentary: "•\n\n•\n\n•",
    action: "*Not applicable for General Farm Module*",
    focus: "",
    focusAdditional: "",
    approach: "*Not applicable for General Farm Module*",
    farmMap: "",
    mapTitle: "",
    mapDescription: "",
    mapFocus:"",
    generalQuestions: new Map(),
    generalPage: 0,
    images: []
}

const defaultNitrogen = {
    questions: [
        "Is your fertiliser spreader calibrated correctly and checked regularly?",
        "Do you assess the current weather and soil conditions before applying Nitrogen fertiliser?",
        "Are other limiting growth factors considered into your Nitrogen fertiliser decision?",
        "Do you assess pasture and feed requirements before applying Nitrogen?",
        "Do you adjust your Nitrogen fertilisation rates based on seasonal conditions?",
        "Is your grazing round adjusted throughout the season to optimise the Nitrogen response?",
        "Do you feed test for CP and adjust your diet accordingly?",
        "Do you adjust your Nitrogen formulation for minimising Nitrogen losses to the environment?",
        "Do you manage and monitor the legume content of your pasture?",
        "Do you grow alternative pasture and forage to limit Nitrogen losses to the environment?",
    ],
    scoreArray:  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    practices: ["", "", "", "", "", "", "", "", "", ""],
    focusArea: ["", "", "", "", "", "", "", "", "", ""],    
    goodColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    improvementColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    innovativeColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    focusColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
    },
    nitrogenFertiliser: "",
    purchasedFeed: "",
    milkingCows: "",
    milkingHectares: "",
    milkSolids: "",
    milkingFat: "",
    milkProtein: "",
    homegrownFeed: "",
    importedFeed: "",
    exportedPro: "",

    surplus: "",
    benchmarkcommentary: "",
    commentary: "•\n\n•\n\n•",
    action: "•\n\n•\n\n•",
    focus: "",
    focusAdditional: "",
    approach: "•\n\n•\n\n•",
    nitrogenQuestions: new Map(),
    nitrogenPage: 0,
    graph: "",
    images: []
}

const defaultCarbon = {
    questions:[
        "As a cow eats more and produces more milk, the energy needed for maintenance is diluted lowering emission intensity (EI). Are your cows achieving >90% of their liveweight in kg of MS?",
        "Increasing reproductive performance will decrease your emissions as there is less DMI requirement for young stock and less methane emissions. Have you been able to improve your reproductive performance?",
        "Direct leaching and nitrous oxide emissions can be minimised by avoiding applying fertiliser to waterlogged soils. Do you have an improved drainage program for your farm?",
        "Balancing the cow’s diets for nitrogen helps minimise nitrogen loss to the environment. Do you use low nitrogen feed or grow low nitrogen forages?",
        "Using effluent as a fertiliser source and minimising build-up of manures helps limit nitrous oxide emissions. Do you have an active effluent management plan?",
        "Sequestering carbon in the soil can help offset GHG emissions. Are you aiming to increase soil carbon levels on your farm through practices such as reduced tillage, use of riparian buffers, deeper rooted species selection, improved grazing etc.?",
        "Planting trees can help ‘offset’ emissions from your farm business without impacting on production. Do you strategically plant trees on farm?",
        "On average energy and fuel account for 16% of the emissions on farm. Do you conduct any energy audits and/or use renewable energy sources?", 
        "Research is accelerating the development of feed additives that can mitigate methane. Have you considered adopting low emission feed additives?",
    ],
    scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    practices: ["", "", "", "", "", "", "", "", ""],
    focusArea: ["", "", "", "", "", "", "", "", ""],
    goodColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    improvementColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    innovativeColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    focusColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
    },

    commentary: "•\n\n•\n\n•",
    action: "•\n\n•\n\n•",
    focus: "",
    focusAdditional: "",
    approach: "•\n•\n•",
    netGHG: "",
    vehicleEquivalent: "",
    GHGEmissionsIntensity: "",
    carbonQuestions: new Map(),
    graph: "",
    carbonPage: 0,
    images: []
}

const defaultEffluent = {
    questions: [
        "Have you calculated the water usage in the dairy and facilities and identified measures to reduce water usage?",
        "Do you have a system to separate solids and debris from the effluent stream?",
        "Is proper management in place to ensure adequate storage of effluent in your ponds during wet months or when application is not suitable?",
        "Is there proper management in place to prevent effluent from polluting groundwater, surface waters, or surrounding land in the case of roads, underpasses, laneways, and bridge crossings?", 
        "How do you manage the reuse and application of the effluent to ensure no offsite discharge?", 
        "How do you manage the reuse and application of the effluent to gain maximum productivity benefits from the nutrients, organic matter and water?",
        "Is a farm map used to plan irrigation from the effluent system (which incorporates soil nutrient and paddock elevation details)?",
        "How do you ensure to provide a safe work environment for staff ,visitors and service providers  and do you provide adequate training to staff on risks related to Effluent system?",
        "How do you manage effluent to prevent and minimize the potential infection and spread of diseases within the herd?",
        "Do you have a contingency plan to prevent sudden shock loading of effluent in the pond, such as when you need to dispose of milk that is unacceptable for supply or cannot be collected by Fonterra and cannot be stored on-farm for animal feeding?", 
        "Have you used a ‘Self Assessment tool’ to proactively identify the risks your business may pose to human health and the environment (General Environmental Duty)?",
        "Does your effluent management plan reflect the existing effluent system? Have changes been made on farm which would require the plan to be altered?", 
    ],
    scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    practices: ["", "", "", "", "", "", "", "", "", "", "", ""],
    focusArea: ["", "", "", "", "", "", "", "", "", "", "", ""],
    goodColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    improvementColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    innovativeColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    focusColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
    },
    haApplied:0,
    commentary: "•\n\n•\n\n•",
    action: "•\n\n•\n\n•",
    focus: "",
    focusAdditional: "",
    approach: "•\n•\n•",
    effluentQuestions: new Map(),
    effluentPage: 0,
    images: []
}

const defaultHealthyCalves = {
    colostrum: {
        currentYear:"",
        futureYear:"",
        questions:[
            "Are teats washed, disinfected and dried prior to colostrum harvesting? Are calves collected and cows milked soon as possible after calving to manage colostrum quality and mastitis risk?",
            "Is colostrum quality tested, classified and either used immediately or stored appropriately?",
            "Are calves are actively fed an adequate volume of warm supplementary colostrum as soon as possible?",
            "Has everyone on the farm team received training in how to correctly collect, test and store colostrum; and stomach tube a calf?",
            "Do you regularly assess and review the adequacy of our colostrum management program?", 
        ],
        scoreArray:  [0, 0, 0, 0, 0],
        practices: ["", "", "", "", ""],
        focusArea: ["", "", "", "", ""],        
        goodColumn: [0, 0, 0, 0, 0],
        improvementColumn: [0, 0, 0, 0, 0],
        innovativeColumn: [0, 0, 0, 0, 0],
        focusColumn: [0, 0, 0, 0, 0],
        calvesQuestions: new Map(),
        scores: {
            good: 0,
            innovative: 0,
            improvement: 0,
            focusArea: 0,
        },
        commentary: "•\n\n•\n\n•",
        focus: "",
        focusAdditional: "",
        actionTable: [{ id: '1', goals: 'Eg: To ensure whole farm team is harvesting colostrum to the best possible standard.', action: 'Eg: Agree upon standard of care for colostrum collection, develop a clear system and protocols, communicate to whole team and display in dairy', priority_who:'', priority_when:''},
        { id: '2', goals: 'Eg.: To give our calves the best chance to be the healthiest, most productive calves they possibly can.', action: 'Eg: Conduct colostrum management review each season with team and regularly review performance using calf blood tests', priority_who:'', priority_when:''},
        { id: '3', goals: 'Eg: Harvest as much high quality colostrum as quickly as we can.', action: 'Eg: Commit to twice daily collection of calves from calving area, and Brix testing colostrum', priority_who:'', priority_when:''},
        { id: '4', goals: '', action: '', priority_who:'', priority_when:''},
        { id: '5', goals: '', action: '', priority_who:'', priority_when:''}],
        graph1: "",
        graph2: "",
        graph3: "",
        graph4: "",
        images: []
    },
    calfPathways: {
        currentYear:"",
        futureYear:"",
        questions: [            
            "Do all calves that you rear receive the same standard of care, whether they are replacement heifers or surplus/non-replacements?",
            "Does everybody in your farm team understand the legal requirements for transporting bobby calves (5-30 days old), and have a clear system for communicating which calves are fit for transport and sale and when they were last fed?",
            "Do you use sexed semen to breed replacement females from the highest genetic merit dams in the herd, determined using a combination of production data and genomic testing?",
            "Are beef sires used for cross breeding selected strategically for target markets?",
            "Is the proportions and numbers of calves reared reviewed annually for profitability?",
        ],
        scoreArray:  [0, 0, 0, 0, 0],
        practices: ["", "", "", "", ""],
        focusArea: ["", "", "", "", ""],
        goodColumn: [0, 0, 0, 0, 0],
        improvementColumn: [0, 0, 0, 0, 0],
        innovativeColumn: [0, 0, 0, 0, 0],
        focusColumn: [0, 0, 0, 0, 0],
        calvesQuestions: new Map(),
        scores: {
            good: 0,
            innovative: 0,
            improvement: 0,
            focusArea: 0,
        },
        commentary: "•\n\n•\n\n•",
        focus: "",
        focusAdditional: "",
        actionTable: [{ id: '1', goals: 'Eg: Feel confident that the numbers and proportions of calves we are rearing is right for our system ', action: 'Eg: Calculate the rearing costs and true cost of heifer replacements for our herd, and schedule regular reviews', priority_who:'', priority_when:''},
        { id: '2', goals: 'Eg: Know that everybody in our farm team understands the legal requirements surrounding bobby calves ', action: 'Eg: Develop an agreement for the team to read and sign. Implement communications, record keeping and signage for bobby calves', priority_who:'', priority_when:''},
        { id: '3', goals: 'Eg: Our breeding decisions are considered and strategic, and reviewed each season', action: 'Eg: Use the Good Bulls Guide and DataGene website to get the latest information on ABVs when making decisions.', priority_who:'', priority_when:''},
        { id: '4', goals: '', action: '', priority_who:'', priority_when:''},
        { id: '5', goals: '', action: '', priority_who:'', priority_when:''}],
        graph1: "",
        graph2: "",
        images: []
    },
    trainingGrid: [
        { id: '1', training_complete: '', who: '', when:''},
        { id: '2', training_complete: '', who: '', when:''},
        { id: '3', training_complete: '', who: '', when:''},
        { id: '4', training_complete: '', who: '', when:''},
        { id: '5', training_complete: '', who: '', when:''},
        { id: '6', training_complete: '', who: '', when:''},
        { id: '7', training_complete: '', who: '', when:''},
        { id: '8', training_complete: '', who: '', when:''},
        { id: '9', training_complete: '', who: '', when:''},
        { id: '10', training_complete: '', who: '', when:''}
    ],
    achievingTransfer: "",
    failureTransfer: "",
    less12hours: "",
    more12hours: "",
    noColostrum: "",
    goalachievingTransfer: "",
    goalfailureTransfer: "",
    goalless12hours: "",
    goalmore12hours: "",
    goalnoColostrum: "",
    replacement1: "",
    beef1: "",
    export1: "",
    rearing1: "",
    euthanised1:"",
    bobby1:"",
    replacement2: "",
    beef2: "",
    export2: "",
    rearing2: "",
    euthanised2:"",
    bobby2:"",
    healthyCalvesPage: 0
}

const defaultIrrigation = {
    questions:[
        "What type of irrigation system/s does your farm have?",
        "What factors do you consider when deciding on irrigation start-up? Are all staff trained in the current irrigation practices on farm?",
        "How are irrigation intervals determined? Readily Available Water (RAW) and evapotranspiration are critical to determination of required irrigation interval.",
        "What automation processes are used during the irrigation application?", 
        "Does the irrigation system design allow for efficient use of water and energy?", 
        "What monitoring equipment is used to determine efficient use of irrigation?", 
        "How are soil related risk factor such as salinity, water logging, erosion and compaction managed?", 
        "Precision flood irrigation is most efficient when water is on and off the paddock in 8 hours. Do your paddocks meet this benchmark?", 
        "Do you consider the 48 hour weather forecast prior to irrigation?",  
        "Do you test and monitor water quality in bores regularly?", 
        "Do you utilise the correct shandy rate when irrigating wastewater (effluent), bore water etc.?", 
        "Do you have a water market strategy?", 
        "Do you calibrate spray irrigators prior to the season starting?", 
        "Is there an ongoing maintenance schedule developed for irrigation equipment?", 
        "Is variable rate (VRI) irrigation applications used if the tecnology is installed.", 
    ],
    scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0],
    practices: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
    focusArea: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
    goodColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0],
    improvementColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0],
    innovativeColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0],
    focusColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0],
    scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
    },
    benchmarkcommentary: "",
    commentary: "•\n\n•\n\n•",
    action: "•\n\n•\n\n•",
    focus: "",
    focusAdditional: "",
    approach: "•\n•\n•",
    waterApplied:"",
    rainfall:"",
    yourGPWUI: "",
    pastureGrown: 0,
    irrigationQuestions: new Map(),
    irrigationPage: 0,
    graph:"",
    images: [],
    selectedIrrigationType:""
}

const defaultBioSecurity = {
    questions:[
        "UNDERSTANDING: Do you have a documented biosecurity plan for your farm that includes and addresses the key risks for your individual system, and is clearly communicated both verbally and visually to all people entering our property and facilities?",
        "PREVENTING and MANAGING: Do you know disease status of your herd, and existing farm weeds and pests? Do you employ screening and preventative programs to suit your individual farm biosecurity requirements?",
        "CONTROLLING and PROTECTING: Do you understand the biosecurity risks posed by farm inputs, pest species, neighbours, equipment, vehicles, and people, and have appropriate strategies and controls in place to manage these risks?", 
        "CONTAINING and SEGREGATING: Are sick and dead animals, effluent, and waste materials isolated for management in a way that prevents spread of disease?", 
        "MONITORING: Do you identify stock and record movements appropriately and regularly assess and review the adequacy and efficacy of your biosecurity management plan?", 
    ],
    actionTable: [{ id: '1', goals: 'Eg: To ensure farm team are mitigating our biosecurity risks to the best possible standard.', action: 'Eg: Document biosecurity management plan, develop a clear set of decontamination protocols, provide required equipment, and communicate to team and display in dairy.', priority_who:'', priority_when:''},
        { id: '2', goals: 'Eg.: Manage our biosecurity risks when introducing purchased livestock.', action: ' Eg: Purchase low-risk animals and conduct screening tests prior to purchasing livestock, and segregate and quarantine upon arrival (and plan to purchase animals with enough lead time to allow for this, ie bulls intended for upcoming use). ', priority_who:'', priority_when:''},
        { id: '3', goals: 'Eg: Explore options for managing sick lactating cows as a separate herd or to milk last. ', action: 'Eg: Create a separate hospital area for sick calves away from healthy animals with little to no risk of cross-contamination.', priority_who:'', priority_when:''},
        { id: '4', goals: '', action: '', priority_who:'', priority_when:''},
        { id: '5', goals: '', action: '', priority_who:'', priority_when:''}],

    scoreArray: [0, 0, 0, 0, 0],
    practices: ["", "", "", "", ""],
    focusArea: ["", "", "", "", ""],    
    goodColumn: [0, 0, 0, 0, 0],
    improvementColumn: [0, 0, 0, 0, 0],
    innovativeColumn: [0, 0, 0, 0, 0],
    focusColumn: [0, 0, 0, 0, 0],
    scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
    },
    commentary: "•\n\n•\n\n•",
    focus: "",
    focusAdditional: "", 
    riskAssessment:"",
    managementPlan: "",
    visitorRegister:"",
    propertySignage:"",
    planReview:"",
    biosecurityQuestions: new Map(),
    biosecurityPage: 0,
    images: []
}

const defaultModules = [];
const defaultModulesDisplay = [];
const defaultTempModule = "";
const defaultTempArr = [];
const defaultReviewStatus = "";
const defaultOperation = "";
const defaultHighestRevisionNumber = "";
const defaultReviewName = "";
const defaultModuleCheckbox = new Map();
const defaultG_User = false
const defaultG_UserRoles = []
const defaultActiveStep = 1;
const defaultSteps = [
  {
    label: 'Home Page',
    link: '/'
  },
  {
    label: 'Farm Details',
    link: '/FarmDetails'
  },
    
  {
    label: 'Module Page',
    link: '/ModulePage'
  },
  {
    label: 'Status',
    link: '/Review'
  },
  

]
const defaultPayload = {
    schemaVersionNum: "",
    surveyVersionNumber: "",
    region: "",
    farmId: "",
    dateCreated: "",
    dateReviewed: "",
    dateEdited: "",
    creatorName: "",
    reviewerName: "",
    financialYear:"",
    farmSurveyId: "",
    revisionNumber: "",
    status: "",
    id: "",
    modules: [],
    surveyData: []
}

//These are global variables
export const AppRunTimeContext = createContext({
    tempModule: defaultTempModule,
    tempArr: defaultTempArr,
    modules: defaultModules,
    modulesDisplay: defaultModulesDisplay,
    moduleCheckbox: defaultModuleCheckbox, 
    farmDetails: defaultFarmDetails,
    generalFarm:defaultGeneralFarm,
    nitrogen: defaultNitrogen,
    carbon: defaultCarbon,
    effluent: defaultEffluent,
    healthyCalves: defaultHealthyCalves,
    biosecurity: defaultBioSecurity,
    irrigation: defaultIrrigation,
    activeStep: defaultActiveStep,
    steps: defaultSteps,
    reviewStatus: defaultReviewStatus,
    reviewName: defaultReviewName,
    g_userRoles: defaultG_UserRoles,
    g_user: defaultG_User,
    payload: defaultPayload,
    operation: defaultOperation,
    highestRevisionNumber: defaultHighestRevisionNumber
});

export const ContextProvider = (props) => {

    const [farmDetails, setFarmDetails] = useState(defaultFarmDetails);

    const [modules, set_modules] = useState(defaultModules);
    const [modulesDisplay, set_modulesDisplay] = useState(defaultModules);
    const [tempModule, setTempModule] = useState(defaultTempModule)
    const [tempArr, setTempArr] = useState(defaultTempArr)
    const [reviewStatus, setReviewStatus] = useState(defaultReviewStatus)
    const [activeStep, setActiveStep] = useState(defaultActiveStep)
    const [steps, setSteps] = useState(defaultSteps)
    const [operation, setOperation] = useState(defaultOperation)    
    const [highestRevisionNumber, setHighestRevisionNumber] = useState(defaultHighestRevisionNumber)    
    const [reviewName, setReviewName] = useState(defaultReviewName)

    const [moduleCheckbox, setModuleCheckbox] = useState(defaultModuleCheckbox)

    const [innovativeScore] = useState(8)

    const [improvementScore] = useState(2)

    const [generalFarm, setGeneralFarm] = useState(defaultGeneralFarm)

    const [nitrogen, setNitrogen] = useState (defaultNitrogen)

    const [carbon, setCarbon] = useState(defaultCarbon)

    const [effluent, setEffluent] = useState(defaultEffluent)

    const [healthyCalves, setHealthyCalves] = useState(defaultHealthyCalves)

    const [irrigation, setIrrigation] = useState(defaultIrrigation)

    const [biosecurity, setBiosecurity] = useState(defaultBioSecurity)
    const [g_userRoles, g_setUserRoles] = useState(defaultG_UserRoles); //Contains Auth Roles for logged in user
    const [g_user, g_setUser] = useState(defaultG_User); //Flag to tell if user details are fetched from API
    const [payload, setPayload] = useState(defaultPayload);
 

    const clearContext = () => {
        // setFarmDetails(defaultFarmDetails);
        set_modules(defaultModules);
        set_modulesDisplay(defaultModulesDisplay);
        setTempModule(defaultTempModule);
        setTempArr(defaultTempArr);
        setActiveStep(defaultActiveStep);
        setSteps(defaultSteps);
        setReviewStatus(defaultReviewStatus);
        setReviewName(defaultReviewName);
        setModuleCheckbox(defaultModuleCheckbox);
        // setGeneralFarm(defaultGeneralFarm);
        // setNitrogen(defaultNitrogen);
        // setCarbon(defaultCarbon);
        // setEffluent(defaultEffluent);
        // setHealthyCalves(defaultHealthyCalves);
        // setIrrigation(defaultIrrigation);
        // setBiosecurity(defaultBioSecurity);
        // g_setUserRoles(defaultG_UserRoles);
        // g_setUser(defaultG_User);
        setPayload(defaultPayload);
        setOperation(defaultOperation);
        setHighestRevisionNumber(defaultHighestRevisionNumber)
        console.log('context cleared...')
    };
    

    return (
        <AppRunTimeContext.Provider
            value={{                                  
                farmDetails: farmDetails,
                setFarmDetails: setFarmDetails,
                
                modules: modules, 
                set_modules: set_modules,
                
                modulesDisplay: modulesDisplay, 
                set_modulesDisplay: set_modulesDisplay,

                tempModule: tempModule,
                setTempModule:setTempModule,
                tempArr: tempArr,
                setTempArr: setTempArr,

                moduleCheckbox: moduleCheckbox,
                setModuleCheckbox: setModuleCheckbox,

                activeStep: activeStep,
                setActiveStep:setActiveStep,

                steps: steps,
                setSteps: setSteps,

                innovativeScore: innovativeScore,
                improvementScore: improvementScore,

                generalFarm: generalFarm,
                setGeneralFarm: setGeneralFarm,
                nitrogen: nitrogen,
                setNitrogen: setNitrogen,
                carbon: carbon,
                setCarbon: setCarbon,
                effluent: effluent,
                setEffluent: setEffluent,
                healthyCalves: healthyCalves,
                setHealthyCalves: setHealthyCalves,
                irrigation: irrigation,
                setIrrigation: setIrrigation,
                biosecurity: biosecurity,
                setBiosecurity: setBiosecurity,

                reviewStatus:reviewStatus,
                setReviewStatus: setReviewStatus,
                reviewName: reviewName,
                setReviewName: setReviewName,


                g_userRoles: g_userRoles,
                g_user: g_user,
                g_setUserRoles: g_setUserRoles,
                g_setUser: g_setUser,

                payload: payload,
                setPayload: setPayload,

                operation: operation,
                setOperation: setOperation,

                highestRevisionNumber: highestRevisionNumber,
                setHighestRevisionNumber: setHighestRevisionNumber,

                clearContext: clearContext
                }}
        >
            {props.children}
        </AppRunTimeContext.Provider>
    );
};