import React, {useContext, useEffect, useState} from 'react';
import { Document, Font, PDFViewer } from '@react-pdf/renderer';
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { AppRunTimeContext } from '../Context/index'; 
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// Importing fonts
import recoletaFont from "../fonts/RecoletaAlt-Bold.ttf"
import ArialFont from "../fonts/ArialMT.ttf"
import recoletaMediumFont from "../fonts/RecoletaAlt-Medium.ttf"
import ArialBoldFont from "../fonts/Arial-Bold.ttf"
import Calibri from "../fonts/Calibri.ttf"
import ArialItalicFont from "../fonts/Arial-Italic.ttf"

// Importing report pages
import pdfCover from './pdf/pdfCover';
import pdfIntro from './pdf/pdfIntro';
import pdfTableContent from './pdf/pdfTableContent';
import pdfFarmDetails from './pdf/pdfFarmDetails';
import pdfEnding from './pdf/pdfEnding'
import jsPDF from 'jspdf';

import GfCommentary from './pdf/GF/GfCommentary';
import GfHeader from './pdf/GF/GfHeader';
import GfTable from './pdf/GF/GfTable';
import GfAreaOfFocus from './pdf/GF/GfAreaOfFocus';
import GfAction from './pdf/GF/GfAction';

import NitrogenHeader from './pdf/Nitrogen/NitrogenHeader';
import NitrogenBenchmark from './pdf/Nitrogen/NitrogenBenchmark';
import NitrogenCommentary from './pdf/Nitrogen/NitrogenCommentary';
import NitrogenTable from './pdf/Nitrogen/NitrogenTable';
import NitrogenAreaOfFocus from './pdf/Nitrogen/NitrogenAreaOfFocus';
import NitrogenAction from './pdf/Nitrogen/NitrogenAction';
import NitrogenApproach from './pdf/Nitrogen/NitrogenApproach';

import EffluentHeader from './pdf/Effluent/EffluentHeader';
import EffluentCommentary from './pdf/Effluent/EffluentCommentary';
import EffluentTable from './pdf/Effluent/EffluentTable';
import EffluentAreaOfFocus from './pdf/Effluent/EffluentAreaOfFocus';
import EffluentAction from './pdf/Effluent/EffluentAction';
import EffluentApproach from './pdf/Effluent/EffluentApproach';

import CarbonHeader from './pdf/Carbon/CarbonHeader';
import CarbonGraph from './pdf/Carbon/CarbonBenchMark';
import CarbonAreaOfFocus from './pdf/Carbon/CarbonAreaOfFocus';
import CarbonCommentary from './pdf/Carbon/CarbonCommentary';
import CarbonTable from './pdf/Carbon/CarbonTable';
import CarbonAction from './pdf/Carbon/CarbonAction';
import CarbonApproach from './pdf/Carbon/CarbonApproach';

import IrrigationApproach from './pdf/Irrigation/IrrigationApproach';
import IrrigationAction from './pdf/Irrigation/IrrigationAction';
import IrrigationAreaOfFocus from './pdf/Irrigation/IrrigationAreaOfFocus';
import IrrigationBenchMark from './pdf/Irrigation/IrrigationBenchMark';
import IrrigationCommentary from './pdf/Irrigation/IrrigationCommentary';
import IrrigationHeader from './pdf/Irrigation/IrrigationHeader';
import IrrigationTable from './pdf/Irrigation/IrrigationTable';

import HealthyCalvesCover from './pdf/HealthyCalves/HealthyCalvesCover';
import ColostrumAction from'./pdf/HealthyCalves/ColostrumAction';
import ColostrumAreaOfFocus from'./pdf/HealthyCalves/ColostrumAreaOfFocus';
import ColostrumBenchmark from'./pdf/HealthyCalves/ColostrumBenchmark';
import ColostrumCommentary from'./pdf/HealthyCalves/ColostrumCommentary';
import ColostrumHeader from'./pdf/HealthyCalves/ColostrumHeader';
import ColostrumTable from'./pdf/HealthyCalves/ColostrumTable';

import CalfPathwayAction from './pdf/HealthyCalves/CalfPathwayAction';
import CalfPathwayAreaOfFocus from './pdf/HealthyCalves/CalfPathwayAreaOfFocus';
import CalfPathwayBenchmark from './pdf/HealthyCalves/CalfPathwayBenchmark';
import CalfPathwayCommentary from './pdf/HealthyCalves/CalfPathwayCommentary';
import CalfPathwayHeader from './pdf/HealthyCalves/CalfPathwayHeader';
import CalfPathwayTable from './pdf/HealthyCalves/CalfPathwayTable';
import HealthyCalvesTeam from './pdf/HealthyCalves/HealthyCalvesTeam';
import BiosecurityHeader from './pdf/Biosecurity/BiosecurityHeader';
import BiosecurityCommentary from './pdf/Biosecurity/BiosecurityCommentary';
import BiosecurityTable from './pdf/Biosecurity/BiosecurityTable';
import BiosecurityAreaOfFocus from './pdf/Biosecurity/BiosecurityAreaOfFocus';
import BiosecurityAction from './pdf/Biosecurity/BiosecurityAction';
import 'jspdf-autotable';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';


export default function ReportTemplate() {
  const {modules, farmDetails, generalFarm, nitrogen, effluent, carbon, healthyCalves, irrigation,biosecurity,
    setGeneralFarm, setNitrogen, setEffluent, setCarbon, setHealthyCalves, setIrrigation, setBiosecurity} = useContext(AppRunTimeContext);
  
    const [loading, setLoading] = useState(true);    
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
 
  useEffect(() => {
    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
        window.location.href = '/'; // Redirect to home page
    }

    // Order modules
    const moduleOrder = ['/GeneralFarm', '/Nitrogen', '/Effluent', '/Carbon', '/HealthyCalves', '/Irrigation', '/Biosecurity'];
    modules.sort((a, b) => moduleOrder.indexOf(a) - moduleOrder.indexOf(b));
  }, []);


  // Function that adds pages in report depending on choice of modules chosen by user
  function ChoiceModule(){
    return(
      modules.map((moduleName,i) => (
        DisplayChosenModule((moduleName))
    )))
  }

  function DisplayChosenModule(module){
    console.log(modules)
    if (module === "/GeneralFarm"){
      return(
        <>
          {GfHeader(generalFarm, farmDetails, setGeneralFarm)}
          {GfCommentary(generalFarm, farmDetails)}
          {GfTable(generalFarm, farmDetails)}
          {GfAreaOfFocus(generalFarm, farmDetails)}
          {GfAction(generalFarm, farmDetails)}
        </>
      )
    }
    else if (module === "/Nitrogen"){
      return (
        <>
          {NitrogenHeader(nitrogen, farmDetails, setNitrogen)}
          {NitrogenBenchmark(nitrogen, farmDetails)}
          {NitrogenCommentary(nitrogen, farmDetails)}
          {NitrogenTable(nitrogen, farmDetails)}
          {NitrogenAreaOfFocus(nitrogen, farmDetails)}
          {NitrogenAction(nitrogen, farmDetails)}
          {NitrogenApproach(nitrogen, farmDetails)}
        </>
      )   
    }
    else if (module === "/Effluent"){
      return (
        <>
          {EffluentHeader(effluent, farmDetails, setEffluent)}
          {EffluentCommentary(effluent, farmDetails)}
          {EffluentTable(effluent, farmDetails)}
          {EffluentAreaOfFocus(effluent, farmDetails)}
          {EffluentAction(effluent, farmDetails)}
          {EffluentApproach(effluent, farmDetails)}
        </>
      )  
    }
    else if (module === "/Carbon"){
      return (
        <>
          {CarbonHeader(carbon, farmDetails, setCarbon)}
          {CarbonGraph(carbon, farmDetails)}
          {CarbonCommentary(carbon, farmDetails)}
          {CarbonTable(carbon, farmDetails)}
          {CarbonAreaOfFocus(carbon, farmDetails)}
          {CarbonAction(carbon, farmDetails)}
          {CarbonApproach(carbon, farmDetails)}
        </>
      )  
    }
    else if (module === "/HealthyCalves"){
      return (
        <>
          {HealthyCalvesCover(healthyCalves, farmDetails, setHealthyCalves)}
          {HealthyCalvesTeam(healthyCalves, farmDetails, setHealthyCalves)}
          {ColostrumHeader(healthyCalves, farmDetails)}
          {ColostrumBenchmark(healthyCalves, farmDetails)}
          {ColostrumCommentary(healthyCalves, farmDetails)}
          {ColostrumTable(healthyCalves, farmDetails)}
          {ColostrumAreaOfFocus(healthyCalves, farmDetails)}
          {ColostrumAction(healthyCalves, farmDetails)}
          {CalfPathwayHeader(healthyCalves, farmDetails)}
          {CalfPathwayBenchmark(healthyCalves, farmDetails)}
          {CalfPathwayCommentary(healthyCalves, farmDetails)}
          {CalfPathwayTable(healthyCalves, farmDetails)}
          {CalfPathwayAreaOfFocus(healthyCalves, farmDetails)}
          {CalfPathwayAction(healthyCalves, farmDetails)}
        </>
      ) 
    }
    else if(module === "/Irrigation"){
      return (
        <>
          {IrrigationHeader(irrigation, farmDetails, setIrrigation)}
          {IrrigationBenchMark(irrigation, farmDetails)}
          {IrrigationCommentary(irrigation, farmDetails)}
          {IrrigationTable(irrigation, farmDetails)}
          {IrrigationAreaOfFocus(irrigation, farmDetails)}
          {IrrigationAction(irrigation, farmDetails)}
          {IrrigationApproach(irrigation, farmDetails)}
        </>
      ) 
    }
      else if (module === "/Biosecurity"){
      return (
        <>
          {BiosecurityHeader(biosecurity, farmDetails, setBiosecurity)}
          {BiosecurityCommentary(biosecurity, farmDetails)}
          {BiosecurityTable(biosecurity, farmDetails)}
          {BiosecurityAreaOfFocus(biosecurity, farmDetails)}
          {BiosecurityAction(biosecurity, farmDetails)}
        </>
      )  
    }
  }
    
  Font.register({
    family: 'Recoleta',
    src: recoletaFont
  });
  Font.register({
    family: 'Arial',
    src: ArialFont
  });
  Font.register({
    family: 'RecoletaMedium',
    src: recoletaMediumFont
  });
  Font.register({
    family: 'ArialBold',
    src: ArialBoldFont
  });
  Font.register({
    family: 'Calibri',
    src: Calibri
  });
  Font.register({
    family: 'ArialItalic',
    src: ArialItalicFont
  })

  Font.registerHyphenationCallback(word => {
  // Return entire word as unique part
  return [word];
      });

      const MyDocument = () => (
        <Document onRender={() => setLoading(false)}  className="pdf-container" renderMode="canvas">
        {/* Page 1-4: cover, introduction, table of contents, farm details */}
        {pdfCover(farmDetails)}
        {pdfIntro(farmDetails)}
        {pdfTableContent(farmDetails, modules, generalFarm, nitrogen, carbon, effluent, healthyCalves, irrigation, biosecurity)}
        {pdfFarmDetails(farmDetails)}
        <ChoiceModule />
        {/* Last Page */}
        {pdfEnding()}
      </Document>
      );

      const saveFile = () => {      
        const formattedDateTime = currentDateTime.toLocaleString().replace(/[\s,\/:]/g, '_');                 
        const fileName = farmDetails.supplier_no + `_${formattedDateTime}.pdf`;   
        //const fileName = `${formattedDateTime}.pdf`;          
        pdf(<MyDocument />).toBlob().then((blob) => saveAs(blob, fileName));
      };

  return (
    <div>
      {/* Loading UI */}
      {loading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress size={80} />
          <Typography variant="h6" style={{ marginTop: 20 }}>
            Loading PDF...
          </Typography>
        </div>
      )}
  
  <div className="ReportTemplate">
        {console.log(farmDetails)}
        <PDFViewer style={{ height: '90vh', width: '100%' }} className="pdf-iframe" showToolbar={true}> 
          <MyDocument />
        </PDFViewer>
  
        {/* Conditionally render the buttons based on loading state */}
        {!loading && (
          <>
            <Link to="/Review">
              <Button id="back" style={{ backgroundColor: '#002D4E' }} variant="contained">
                Back
              </Button>
            </Link>

            <Button
              id="saveFile"
              style={{ backgroundColor: '#002D4E' }}
              variant="contained"
               onClick={saveFile}>Save PDF</Button>
            <Button
              id="next"
              style={{ backgroundColor: '#002D4E' }}
              variant="contained"
              onClick={() => {
                window.location.href = '/';
              }}
            >
              Home
            </Button>
          </>
        )}
      </div>
    </div>
  );
  
  }
