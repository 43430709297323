import React from 'react';
import { Page, Text, Image, StyleSheet } from '@react-pdf/renderer';
import HealthyCalvesCoverPage from "../../../images/HealthyCalvesCover.jpg"
import {default as styles} from "../Styles"


const CoverStyle = StyleSheet.create({
  title: {
    fontSize: 45,
    textAlign: 'left',
    paddingLeft: "5%",
    color: 'white',
    fontFamily: 'Recoleta'
  },
  farmName :{
    paddingTop: "80%",
    fontSize: 12, 
    bottom: 30, 
    left: 0, 
    right: 0, 
    textAlign: 'left', 
    marginLeft: 30, 
    color:  'white', 
    fontFamily: 'Recoleta'
  },
  pageNo: {
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'right',
    marginRight: 30,
    color:  'white',
    fontFamily: 'Recoleta'
  }

})

function getPageNumber(pageNumber, healthyCalves, setHealthyCalves){
  if (pageNumber !== healthyCalves.healthyCalvesPage) {
    setHealthyCalves(prevDetails => ({
          ...prevDetails,
          healthyCalvesPage: pageNumber
      }));
  }
}

export default function HealthyCalvesCover(healthyCalves, farmDetails, setHealthyCalves) {
    return(
      <Page>
        < div style ={{width: "100%", height: "100%"}}>
          <Image 
          src = {HealthyCalvesCoverPage}
          style={styles.pageBackground} />
        </div>
        <div style={{position: 'absolute',marginTop: "25%", height: "80%", width: "100%"}}>
          <Text style={CoverStyle.title}> Animal Health and   </Text>
          <Text style={CoverStyle.title}> Welfare - Healthy</Text>
          <Text style={CoverStyle.title}>  Calves  </Text>
          
          <Text style={CoverStyle.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
          
          <Text style={CoverStyle.pageNo} render={({ pageNumber }) => (
            `${pageNumber}` && getPageNumber(pageNumber, healthyCalves, setHealthyCalves)
            )} fixed /> 
          <Text style={CoverStyle.pageNo} render={({ pageNumber }) => (
          `${pageNumber}`
          )} fixed />
        </div>
      </Page>
    )
}
