import React from 'react';
import { Page, Text, View, Image, StyleSheet, Polyline, Svg, Line} from '@react-pdf/renderer';
import nitrogenBenchmark from "../../../images/nitrogenBenchmark.jpg"
import carbonGraphIcon from "../../../images/carbonGraphIcon.png"
import carbonGraphPlaceholder from "../../../images/carbonGraphPlaceholder.png"
import {default as styles} from "../Styles"


const CarbonStyle = StyleSheet.create({
  graph_middle_section:{
    height: "15%",
    backgroundColor: "#DBEBC7"
  },
  graph_boxes:{
    flexDirection: 'row',
    position: "absolute",
    marginLeft: "5%",
    marginTop: "5%",
  },
  first_column:{
    width: "70%",
    color:'#002D4E',
    alignContent: 'center',
    textAlign: 'left',},
    first_column_title:{
    fontFamily: 'RecoletaMedium',
    fontSize: 26,
    paddingBottom: 5
  },
  first_column_subtitle:{
    fontFamily: 'Arial',
    fontSize: 16
  },
  second_column:{
    width: "30%",
    paddingTop: 20,
    alignContent: 'flex-end',
    alignItems: 'left',},
    first_column2:{
    width: "16%",
    alignContent: 'left',
    paddingTop: 25
  },
  second_column2:{
    width: "84%",
    paddingTop: 20,
    alignContent:  'left',
  },
  graph_box:{
    height:"50%",
    marginVertical: "5%",
    backgroundColor: "#FFFFFF",   
    marginRight: "22%",
    overflow:"hidden"
  },
  data_benchmark:{
    color: "#76B82A",
    fontFamily: 'Arial',
    fontSize: "16px"
  }
})


export default function CarbonGraph(carbon, farmDetails){
  return(
    <Page>
      <div >
        <Image 
          src = {nitrogenBenchmark}
          style={styles.headerImage} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Benchmark</Text>
        <Text style={styles.secondSubtitle}>Greenhouse Gas Emissions</Text> 
      </div>

      <div style = {[CarbonStyle.graph_middle_section,{display: "flex"}]}>
        <View style = {CarbonStyle.graph_boxes} >
          <div style = {CarbonStyle.first_column}>
            <Text  style = {CarbonStyle.first_column_title}>Emission Intensity</Text>
            <Text style = {CarbonStyle.first_column_subtitle}>kg CO
                        <Text style = {{fontSize: 11}}>2 </Text> 
            per kg Fat Protein Corrected Milk </Text>
          </div>
          <div style = {CarbonStyle.second_column}>
            <Image 
              src={carbonGraphIcon} 
              style = {{width: "75%", height: "auto"}}/>
          </div> 
        </View>
      </div>

      <div>
        <View style = {CarbonStyle.graph_boxes}>
          <div style = {CarbonStyle.first_column2}>
              <Svg  width="100" height="500">
              <Polyline
                points = "80 400, 40 400,40 0, 80 0" 
                strokeWidth={3}
                stroke="#76B82A"/>
              <Line
                x1="40"
                y1="80"
                x2="80"
                y2="80"
                strokeWidth={3}
                stroke="#76B82A"/>

            </Svg>
          </div>

          <div style = {CarbonStyle.second_column2}>
            <Text style = {{fontSize:"16px"}}> Your EI - 
              <Text style = {CarbonStyle.data_benchmark}> {carbon.GHGEmissionsIntensity} kg CO
                <Text style = {{fontSize:"16px"}}>2 </Text> 
              </Text> 
               per kg FPCM 
            </Text>

            <Text style = {{fontSize:"16px",paddingTop: "12%"}}> Australia average EI - 
              <Text style = {CarbonStyle.data_benchmark}> 1.03 kg CO
                <Text style = {{fontSize:"16px"}}>2 </Text> 
              </Text> 
               per kg FPCM 
            </Text>

            
            {/* placeholder for graph pic */}
            <div style = {CarbonStyle.graph_box}>  
              <Image
                  id='carbonGraph'
                  src={(carbon.graph === ""  || !carbon.graph) ? carbonGraphPlaceholder : carbon.graph}
                  alt = "Carbon emission benchmark plot"
                  style={{                    
                    width: "402",
                    height: "260",  
                    marginLeft:"-25",        
                    marginTop:"-10",                   
                    backgroundColor:"#fff"
                  }}/>              
            </div>

            <Text style = {{fontSize:"16px"}}> World average EI - 
              <Text style = {CarbonStyle.data_benchmark}> 2.5 kg CO
                <Text style = {{fontSize: "16px"}}>2 </Text> 
              </Text> 
               per kg FPCM 
            </Text>
          </div>
        </View>
      </div>


      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
}
