import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Input } from 'react-spreadsheet-grid';
import { AppRunTimeContext } from '../Context/index'; 
import { useState, useContext } from 'react';



//Training Table used in Healthy calves module made using react-spreadsheet-grid
//https://www.npmjs.com/package/react-spreadsheet-grid

export default function TrainingTable() {
  const {healthyCalves, farmDetails} = useContext(AppRunTimeContext);

  const Training = () => {
    var trainingTable = healthyCalves.trainingGrid //making it global using contextprovider
    const [rows, setRows] = useState(trainingTable);
  
      // A callback called every time a value changed.
      // Every time it save a new value to the state.
      const onFieldChange = (rowId, field) => (value) => {
          // Find the row that is being changed
          const row = rows.find(({id}) => id === rowId);
          
          // Change a value of a field
          row[field] = value;
          setRows([].concat(rows))
          healthyCalves.trainingGrid = rows
          farmDetails.surveyData['HealthyCalves'].trainingGrid = rows
      }

    const initColumns = () => [
      {
        title: () => 'Training Complete',
        value: (row, { focus }) => {
          // You can use the built-in Input.
        return (
          <Input style = {{background: 'rgb(0, 45, 78)'}}
            value={row.training_complete}
            focus={focus}
            onChange={onFieldChange(row.id, 'training_complete')}
          />);
        }
      }, 
      {
        title: () => 'Who',
        value: (row, { focus }) => {
          // You can use the built-in Input.
          return (
            <Input style = {{background: 'rgb(0, 45, 78)'}}
              value={row.who}
              focus={focus}
              onChange={onFieldChange(row.id, 'who')}
            />
          );
        } 
      }, 
      {
        title: () => 'When',
        value: (row, { focus }) => {
        // You can use the built-in Input.
        return (
          <Input  style = {{background: 'rgb(0, 45, 78)'}}
            value={row.when}
            focus={focus}
            onChange={onFieldChange(row.id, 'when')}
          />);
        }
      }, 
    ]
        
    return (
      <Grid style = {{background: 'rgb(235, 244, 249)'}}
          columns={initColumns()}
          rows={rows}
          isColumnsResizable
          //onColumnResize={onColumnResize}
          getRowKey={row => row.id}
      />
  )}
  
  return (
    <div style={{width: '100%'}}>
              <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>Training Table - (Optional)</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Training/>
        </AccordionDetails>
      </Accordion>
 
    </div>
  );
}
    