import useModuleLink from '../hooks/moduleLink';
import Next from "./Next";
import Back from "./Back";
import React from 'react';
import { AppRunTimeContext } from '../Context/index'; 
import { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useNavigate, Link } from 'react-router-dom';
import ProgressBar from "./ProgressBar";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

export default function ModulePage() {
  const { farmDetails, modules, set_modules, moduleCheckbox, tempModule, setTempModule, tempArr, setTempArr, steps, setSteps } = useContext(AppRunTimeContext);
  const { deleteLink, updateLink, getLinks} = useModuleLink();
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const [initialModules, setInitialModules] = useState([]);

  var arr;

  const moduleLabels = {
          '/GeneralFarm': 'General Farm Management',
          '/Nitrogen': 'Nutrient Management (Nitrogen)',
          '/Effluent': 'Effluent Management',
          '/Carbon': 'Greenhouse Gas Emissions',
          '/HealthyCalves': 'Animal Health and Welfare (Healthy Calves)',
          '/Irrigation': 'Irrigation Management',
          '/Biosecurity': 'Farm Biosecurity'
   };
  
  useEffect(() => {

    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
        window.location.href = '/'; // Redirect to home page
    }

    // Your code here will run after the component has mounted
    //setTempModule(farmDetails.modules.map(item => "/" + item)[0])
    setTempArr(farmDetails.modules.map(item => "/" + item))
    set_modules(farmDetails.modules.map(item => "/" + item))
    setTempModule(farmDetails.modules.map(item => "/" + item)[0]);
    setInitialModules([...farmDetails.modules]); // Store the initial modules
    farmDetails.modules.forEach((module) => {
      console.log(module)
      console.log('--------------------------')
      updateLink('/'+module)
   // let newStep = {
    //             label: moduleLabels[('/'+module)] || ('/'+module), // Use label from moduleLabels if available
    //            link: ('/'+module),
    //};
   
     //setSteps(steps.concat(newStep));
      //console.log(steps);

    });
    
    // console.log(tempModule)
    // console.log(farmDetails)
  }, []);  // Empty dependency array means this useEffect runs once after initial render
  
  // const newData = {...data};
  // newData.modules.push(moduleName);
  // newData.modules = newData.modules.filter(module => module !== moduleName);



  function handleChange(e) {
    if (e.target.checked) {
      console.log(e.target.value)
      let newStep = {
      label: moduleLabels[e.target.value] || e.target.value, // Use label from moduleLabels if available
      link: e.target.value,
    };
     setSteps(steps.concat(newStep));//for progress bar

      updateLink(e.target.value);
      console.log(getLinks())
      tempArr.push(e.target.value);
      setTempArr(tempArr);
      moduleCheckbox.set(e.target.attributes.value.nodeValue, true)
      set_modules(modules.concat(e.target.value))
      farmDetails.modules.push(e.target.value.substring(1));
      //set_modules(farmDetails.modules.map(item => "/" + item))
    }
    else{
      deleteLink(e.target.value);
      arr = removeItemOnce(tempArr, e.target.value)
      setTempArr(arr);
      moduleCheckbox.delete(e.target.attributes.value.nodeValue)
      set_modules(removeItemOnce(modules, e.target.value))
     let newSteps = steps.filter(step => step.link !== e.target.value.substring());//for progress bar
     setSteps(newSteps);
      farmDetails.modules = farmDetails.modules.filter(module => module !== e.target.value.substring(1));
      //set_modules(farmDetails.modules.map(item => "/" + item))
    }
    setTempModule(tempArr[0]);
    //setTempModule(farmDetails.modules.map(item => "/" + item)[0]);
    
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleNextClick = () => {
    if (tempArr.length === 0) { // Replace with your condition for no checkboxes selected
      setOpenDialog(true);
    }
    navigate(tempModule);
    // Do nothing here; navigation is handled by the Link in the Next component
  };

  // Remove module from list when unticked
  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  const getChecked = (key) => {
    // if (moduleCheckbox.get(key)) 
    //   return true;
    console.log(key);
    if (farmDetails.modules.includes(key))
      return true;
    else
      return false; 
  }



  return (
    <div className="module-page">
      {/* {console.log(farmDetails)}
      {console.log(tempModule)}
      {console.log(tempArr)} */}
      {console.log(farmDetails)}
      {console.log(getLinks())}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
            <Grid item xs={4} style={{ backgroundColor: 'white' }}>
           <div className="progressbar">
                    <ProgressBar currentStep={2}/>
       </div> 
       </Grid>
        <Grid item xs={8}>
      <header className="module-header">
  
        <h1 className="module-h1">Module Page</h1>
        <div className="modules">
         <div className="checkbox-container">
          <p>
            <input
              type="checkbox"
              id="GeneralFarmManagement"
              value="/GeneralFarm"
              checked = {getChecked("GeneralFarm")}
              onChange={handleChange}
              // disabled={initialModules.includes("GeneralFarm")}
              />
            <label htmlFor="GeneralFarmManagement">
              {" "}General Farm Management{" "}
            </label>
          </p>

          <p>
            <input 
              type="checkbox" 
              id="NutrientManagement(Nitrogen)" 
              value="/Nitrogen"
              onChange={handleChange}
              checked = {getChecked("Nitrogen")}
              // disabled={initialModules.includes("Nitrogen")}
            />
            <label htmlFor="NutrientManagement(Nitrogen)">
              {" "}
              Nutrient Management (Nitrogen){" "}
            </label>
          </p>

          <p>
            <input  
              type="checkbox" 
              id="EffluentManagement" 
              value="/Effluent"
              onChange={handleChange} 
              checked = {getChecked("Effluent")}
              // disabled={initialModules.includes("Effluent")}
            />
            <label htmlFor="EffluentManagement">{" "}Effluent Management{" "} </label>
          </p>

          <p>
            <input 
              type="checkbox" 
              id="GreenhouseGasEmissions" 
              value="/Carbon"
              onChange={handleChange} 
              checked = {getChecked("Carbon")}
              // disabled={initialModules.includes("Carbon")}
              />
            <label htmlFor="GreenhouseGasEmissions">
              {" "}Greenhouse Gas Emissions{" "}
            </label>
          </p>

          <p>
            <input 
              type="checkbox" 
              id="HealthyCalves" 
              value="/HealthyCalves"
              onChange={handleChange} 
              checked = {getChecked("HealthyCalves")}
              // disabled={initialModules.includes("HealthyCalves")}
              />
            <label htmlFor="HealthyCalves">
              {" "}Animal Health and Welfare (Healthy Calves){" "}
            </label>
          </p>

          <p>
            <input 
              type="checkbox" 
              id="Irrigation" 
              value="/Irrigation"
              onChange={handleChange} 
              checked = {getChecked("Irrigation")}
              // disabled={initialModules.includes("Irrigation")}
              />
            <label htmlFor="Irrigation">{" "}Irrigation Management{" "}</label>
          </p>

         <p>
            <input 
              type="checkbox" 
              id="Biosecurity" 
              value="/Biosecurity"
              onChange={handleChange} 
              checked = {getChecked("Biosecurity")}
              // disabled={initialModules.includes("Biosecurity")}
              />
            <label htmlFor="Biosecurity">{" "}Farm Biosecurity{" "}</label>
          </p>
          </div>
        </div>
        
        {/* <div className="back-next-button">
        <Back back = {'/FarmDetails'}/>
        <Button 
          variant="contained"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </div>

      <div className="back-next-button">
         
         <Back back = {'/FarmDetails'}/>
         <Next next = {tempModule}/>
       </div> */}
                       <div className="back-next-button">
                    <div className="btn-text-left">
                     <div className="btn-text-left">
                     <Link to = "/FarmDetails" > 
                
                            <Button
                                style = {{backgroundColor:"#002D4E"}}
                                id="Back"
                                variant="contained">
                                Back
                            </Button>
                    </Link>
                       </div>
                    </div>

                    <div className="btn-text-right">
                                <Next 
                              next={tempModule} 
                              onClick={handleNextClick} 
                              disabled={tempArr.length === 0}
                            />
                    </div>
                            <div className="btn-text-left">
                <div className="btn-text-left">
 
                {/* <Button
                  id = "save"
                  style={{ backgroundColor:"#002D4E", marginLeft: "-3px"}}
                  variant="contained"
                  className="btn-save"
                  onClick={() => { window.location.href = '/'; }}
              >
                  Home
              </Button>               */}
              </div>
              </div>
                    </div>  

      </header>
       </Grid>
        </Grid>
          </Box>

        {/* Dialog Component */}
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Incomplete Selection"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please select at least one module before proceeding.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}

