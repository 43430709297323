import React from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import introductionHeader from "../../images/Introduction.jpg"
import {default as styles} from "./Styles.jsx"
import { ListModules } from '../../components/Utilities';


export default function pdfTableContent(farmDetails, modules, generalFarm, nitrogen, carbon, effluent, healthyCalves, irrigation, biosecurity){
    return(
<     Page>
        <div >
          <Image 
          src = {introductionHeader}
          style={styles.headerImage} />
        </div>

        <div style={{position: 'absolute'}}>
          <Text style={styles.title}>Contents</Text>
          <Text style={styles.tableOfContents}>
            Farm Details ................................................................................................ 4
          </Text>
          {ListModules(modules, generalFarm, nitrogen, carbon, effluent, healthyCalves, irrigation, biosecurity)}

        </div>
        <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
        )} fixed />
      </Page>
    )
}
