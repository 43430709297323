import React from 'react';
import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import {default as styles} from "../Styles"
import action from "../../../images/Action.jpg"

const EffluentStyles = StyleSheet.create({
  text_action:{
    color: '#002D4E',
    font: 'Arial',
    fontSize: 12,
    paddingHorizontal: 10,
    marginBottom: 10,
    marginHorizontal: "5%",
    lineHeight:1.4
  },
})

export default function EffluentApproach(effluent, farmDetails){

  return(
    <Page>
      {/* header */}
      <div style = {styles.approachHeaderImageBox} >
        <Image 
          src = {action}
          style={{position: 'relative',
          display: 'flex',
          paddingBottom: 0,
          marginBottom: 0}} />
      
        <div style={{position: 'absolute'}}>
          <Text style={styles.title}>The Action</Text>
          <Text style={styles.secondSubtitle}>Effluent Management</Text>  
        </div>
      </div>

      <View fixed style = {{height: 15}}></View>

      {effluent.approach !== '' && (
          <div style = {styles.approach}>
        <Text style={styles.approach_text}>Approach</Text>
        <Text style = {EffluentStyles.text_action}>{effluent.approach} </Text>
      </div>
        )}
      <View fixed style = {{marginTop: "30%"}}></View> 
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
}