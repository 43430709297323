import React  from 'react';
import { Page, Text, Image, StyleSheet, View } from '@react-pdf/renderer';
import focus from "../../../images/Focus.jpg"
import {default as styles} from "../Styles"
import Icon from "../../../images/dairy_australia_icon.png";

const ColostrumStyle = StyleSheet.create({
  secondSubtitle: {
      fontSize: 14,
      marginBottom: 60,
      textAlign: 'left',
      color: '#002D4E',
      marginLeft: 30,
      marginTop: -80,
      fontFamily: 'RecoletaMedium',
  },
  headerImageBox: {
    height: "22%",
  },
  tableContainer: {
    height: 100, 
    backgroundColor:"#EBF4F9", 
    borderBottomWidth: 1, 
    marginLeft: "5%", 
    marginRight: "5%"
    },
  headerContainer: {
    height: 25, 
    backgroundColor:'#002D4E', 
    borderBottomWidth: 1, 
    marginLeft: "5%", 
    marginRight: "5%"
  },
  headerText: {
    fontFamily: "ArialBold",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff"
  },
  headerGoal: {
    borderLeftWidth: 1, 
    borderRightWidth: 1, 
    height: 25, 
    width: "35%", 
    padding: 5
  },
  headerAction:{
    borderRightWidth: 1, 
    height: 25, 
    width: "35%", 
    padding: 5
  },
  headerPriority:{
    borderRightWidth: 1, 
    height: 25, 
    width: "30%", 
    padding: 5
  },
  rowText:{
    fontFamily: "ArialItalic",
    fontSize: 12,
    textAlign: "justify",
    color: "#002D4E"
  },
  goal: {
    borderLeftWidth: 1, 
    borderRightWidth: 1, 
    height: 100, 
    width: "35%", 
    padding: 10
  },
  action:{
    borderRightWidth: 1, 
    height: 100, 
    width: "35%", 
    padding: 10
  },
  priority:{
    borderRightWidth: 1, 
    height: 100, 
    width: "30%", 
    padding: 10
  },
})

export default function ColostrumAction(healthyCalves, farmDetails){
  return(
    <Page size="A4" style={styles.page}>
        <div style = {styles.headerImageBox} >
            <Image 
            src = {focus}
            style={styles.headerImage} />

        <div style = {{flexDirection: "row", position: 'absolute'}}>
            <div style={{width: "80%"}}>
                <Text style={styles.title}>The Action</Text>
                <Text style={styles.secondSubtitle}>Animal Health and Welfare - Healthy Calves</Text> 
                <Text style={ColostrumStyle.secondSubtitle}>(Colostrum) </Text>   
            </div>
            <div style = {{width: "20%"}}>
                <Image 
                    src = {Icon} />
            </div>
          </div>
        </div>

        <div style = {[ColostrumStyle.headerText,ColostrumStyle.headerContainer]}>
          <View style = {{flexDirection: "row",height: 25}}>
            <Text style = {ColostrumStyle.headerGoal} >Our Goals</Text>
            <Text style = {ColostrumStyle.headerAction} >Our Actions</Text>
            <Text style = {ColostrumStyle.headerPriority} >Priority</Text>
          </View>
        </div>

        {healthyCalves.colostrum.actionTable.map((item) =>
          <div style = {ColostrumStyle.tableContainer}>
            <View style = {{flexDirection: "row",height: 100}}>
              <div style = {ColostrumStyle.goal} >
                <Text style ={ColostrumStyle.rowText} > {item.goals}</Text>
              </div>
              <div style = {ColostrumStyle.action} >
                <Text style ={ColostrumStyle.rowText} > {item.action}</Text>
              </div>
              <div style = {ColostrumStyle.priority} >
                <Text style ={ColostrumStyle.rowText} > <Text style = {{fontFamily: "Arial"}}> By who?</Text> {item.priority_who}</Text>
                <Text style ={ColostrumStyle.rowText} > <Text style = {{fontFamily: "Arial"}}> By when?</Text> {item.priority_when}</Text>
              </div>
            </View>
          </div>
        )}

      <Text style={styles.farmName} fixed>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
} 



