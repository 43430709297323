// LoadModules.js
function LoadModules(farmDetails) {
    // Clone the farmDetails object to avoid direct mutation
    let updatedFarmDetails = JSON.parse(JSON.stringify(farmDetails));
  
    // Check if the modules array exists and has items
    if (Array.isArray(updatedFarmDetails.modules) && updatedFarmDetails.modules.length) {
      // Loop over the modules array and perform checks
      updatedFarmDetails.modules.forEach(moduleName => {
        // Perform checks based on the module name
        if (moduleName === 'Biosecurity' && !('Biosecurity' in updatedFarmDetails.surveyData)) {
          updatedFarmDetails.surveyData['Biosecurity'] = {
            module: "Biosecurity",
            commentary: "",
            focus: "",
            focusAdditional: "",
            riskAssessment: "",
            managementPlan: "",
            visitorRegister: "",
            propertySignage: "",
            planReview: "",
            biosecurityPage: 0,
            actionTable: [{
                    id: '1',
                    goals: 'Eg: To ensure farm team are mitigating our biosecurity risks to the best possible standard.',
                    action: 'Eg: Document biosecurity management plan, develop a clear set of decontamination protocols, provide required equipment, and communicate to team and display in dairy.',
                    priority_who: '',
                    priority_when: ''
                },
                {
                    id: '2',
                    goals: 'Eg.: Manage our biosecurity risks when introducing purchased livestock.',
                    action: ' Eg: Purchase low-risk animals and conduct screening tests prior to purchasing livestock, and segregate and quarantine upon arrival (and plan to purchase animals with enough lead time to allow for this, ie bulls intended for upcoming use). ',
                    priority_who: '',
                    priority_when: ''
                },
                {
                    id: '3',
                    goals: 'Eg: Explore options for managing sick lactating cows as a separate herd or to milk last. ',
                    action: 'Eg: Create a separate hospital area for sick calves away from healthy animals with little to no risk of cross-contamination.',
                    priority_who: '',
                    priority_when: ''
                },
                {
                    id: '4',
                    goals: '',
                    action: '',
                    priority_who: '',
                    priority_when: ''
                },
                {
                    id: '5',
                    goals: '',
                    action: '',
                    priority_who: '',
                    priority_when: ''
                }
            ],
            QuestionMapping: [
              {
                Q1_7: "UNDERSTANDING: We have a documented biosecurity plan for our farm that includes and addresses the key risks for our individual system, and is clearly communicated both verbally and visually to all people entering our property and facilities.",
                Q2_7: "PREVENTING and MANAGING: We know the disease status of our herd, and existing farm weeds and pests. We employ screening and preventative programs to suit our individual farm biosecurity requirements.",
                Q3_7: "CONTROLLING and PROTECTING: We understand the biosecurity risks posed by farm inputs, pest species, neighbours, equipment, vehicles, and people, and have appropriate strategies and controls in place to manage these risks.",
                Q4_7: "CONTAINING and SEGREGATING: Sick and dead animals, effluent, and waste materials are isolated for management in a way that prevents spread of disease.",
                Q5_7: "MONITORING: We identify stock and record movements appropriately and regularly assess and review the adequacy and efficacy of our biosecurity management plan."
              }
            ],
            questions: [
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              }
            ]
          };
        } else if (moduleName === 'Carbon' && !('Carbon' in updatedFarmDetails.surveyData)) {
          updatedFarmDetails.surveyData['Carbon'] = {
            module: "Carbon",
            commentary: "",
            action: "",
            approach: "",
            focus: "",
            focusAdditional: "",
            netGHG: "",
            vehicleEquivalent: "",
            GHGEmissionsIntensity: "",
            carbonPage: 0,
            graph: "",
            QuestionMapping: [
              {
                Q1_3: "As a cow eats more and produces more milk, the energy needed for maintenance is diluted lowering emission intensity (EI). Are your cows achieving >90% of their liveweight in kg of MS?",
                Q2_3: "Increasing reproductive performance will decrease your emissions as there is less DMI requirement for young stock and less methane emissions. Have you been able to improve your reproductive performance?",
                Q3_3: "Direct leaching and nitrous oxide emissions can be minimised by avoiding applying fertiliser to waterlogged soils. Do you have an improved drainage program for your farm?",
                Q4_3: "Balancing the cow’s diets for nitrogen helps minimise nitrogen loss to the environment. Do you use low nitrogen feed or grow low nitrogen forages?",
                Q5_3: "Using effluent as a fertiliser source and minimising build-up of manures helps limit nitrous oxide emissions. Do you have an active effluent management plan?",
                Q6_3: "Sequestering carbon in the soil can help offset GHG emissions. Are you aiming to increase soil carbon levels on your farm through practices such as reduced tillage, use of riparian buffers, deeper rooted species selection, improved grazing etc.?",
                Q7_3: "Planting trees can help ‘offset’ emissions from your farm business without impacting on production. Do you strategically plant trees on the farm?",
                Q8_3: "On average energy and fuel account for 16% of the emissions on farm. Do you conduct any energy audits and/or use renewable energy sources?",
                Q9_3: "Research is accelerating the development of feed additives that can mitigate methane. Have you considered adopting low emission feed additives?"
              }
            ],
            questions: [
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
            {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            }
          ]
          };
        }
  
        // Add other modules checks and defaults here if needed
        else if (moduleName === 'Effluent' && !('Effluent' in updatedFarmDetails.surveyData)) {
          updatedFarmDetails.surveyData['Effluent'] = {
            module: "Effluent",
            commentary: "",
            action: "",
            approach: "",
            focus: "",
            focusAdditional: "",
            effluentPage: 0,
            QuestionMapping: [
              {
                Q1_4: "Have you calculated the water usage in the dairy and facilities and identified measures to reduce water usage?",
                Q2_4: "Do you have a system to separate solids and debris from the effluent stream?",
                Q3_4: "Is proper management in place to ensure adequate storage of effluent in your ponds during wet months or when application is not suitable?",
                Q4_4: "Is there proper management in place to prevent effluent from polluting groundwater, surface waters, or surrounding land in the case of roads, underpasses, laneways, and bridge crossings?",
                Q5_4: "How do you manage the reuse and application of the effluent to ensure no offsite discharge?",
                Q6_4: "How do you manage the reuse and application of the effluent to gain maximum productivity benefits from the nutrients, organic matter, and water?",
                Q7_4: "Is a farm map used to plan irrigation from the effluent system (which incorporates soil nutrient and paddock elevation details)?",

                Q8_4: "How do you ensure to provide a safe work environment for staff, visitors and service providers and do you provide adequate training to staff on risks related to effluent system?",
                Q9_4: "How do you manage effluent to prevent and minimize the potential infection and spread of diseases within the herd?",
                Q10_4: "Do you have a contingency plan to prevent sudden shock loading of effluent in the pond, such as when you need to dispose of milk that is unacceptable for supply or cannot be collected by Fonterra and cannot be stored on-farm for animal feeding?",
                Q11_4: "Have you used a ‘Self Assessment tool’ to proactively identify the risks your business may pose to human health and the environment (General Environmental Duty)?",
                Q12_4: "Does your effluent management plan reflect the existing effluent system? Have changes been made on farm which would require the plan to be altered?"
              }
            ],
            questions: [
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
            {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
              {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
              subQuestions: [
                  [
                    false,
                    false,
                    false
                  ]
              ],
              practice: "",
              focusCheck: "",
              notApplicable: false
          },
            {
              subQuestions: [
                  [
                    false,
                    false,
                    false
                  ]
              ],
              practice: "",
              focusCheck: "",
              notApplicable: false
          }
          ]
          };
        }
        else if (moduleName === 'GeneralFarm' && !('GeneralFarm' in updatedFarmDetails.surveyData)) {
          updatedFarmDetails.surveyData['GeneralFarm'] = {
            module: "GeneralFarm",
            commentary: "",
            focus: "",
            focusAdditional: "",
            farmMap: "",
            mapDescription: "",
            mapFocus: "",
            generalPage: 0,
            QuestionMapping: [
              {
                Q1_1: "Is a farm map available that identifies environmental characteristics on your farm?",
                Q2_1: "Are practices in place to minimize soil structure problems and erosion on-farm?",
                Q3_1: "Is soil testing conducted at least every 2 years and used in conjunction with a whole farm nutrient budget?",
                Q4_1: "Is fertiliser applied using the (4 Rs) Right rate, Right place, Right time, Right source approach?",
                Q5_1: "Do you believe your effluent management practices are current?",
                Q6_1: "Is biodiversity considered when making any practice change on farm?",
                Q7_1: "Is efficient use of energy important to your farm?",
                Q8_1: "Do you believe water use for the dairy shed and stock water is efficient?",
                Q9_1: "Are controls in place for weeds and pests on farm?",
                Q10_1: "Are silage wrap and animal carcasses disposed of in an environmentally friendly way?",
                Q11_1: "Do you have shade and shelter belt plantings on farm?",
                Q12_1: "Are stock fenced out of riparian zones?"
              }
            ],
            questions: [
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              }
            ]
          };
        }
        else if (moduleName === 'HealthyCalves' && !('HealthyCalves' in updatedFarmDetails.surveyData)) {
          updatedFarmDetails.surveyData['HealthyCalves'] = {
            module: "HealthyCalves",
            currentYear: " ",
            futureYear:" ",
            trainingGrid: [
              { id: '1', training_complete: '', who: '', when:''},
              { id: '2', training_complete: '', who: '', when:''},
              { id: '3', training_complete: '', who: '', when:''},
              { id: '4', training_complete: '', who: '', when:''},
              { id: '5', training_complete: '', who: '', when:''},
              { id: '6', training_complete: '', who: '', when:''},
              { id: '7', training_complete: '', who: '', when:''},
              { id: '8', training_complete: '', who: '', when:''},
              { id: '9', training_complete: '', who: '', when:''},
              { id: '10', training_complete: '', who: '', when:''}
          ],
            achievingTransfer: "",
            failureTransfer: "",
            less12hours: "",
            more12hours: "",
            noColostrum: "",
            goalachievingTransfer: "",
            goalfailureTransfer: "",
            goalless12hours: "",
            goalmore12hours: "",
            goalnoColostrum: "",
            replacement1: "",
            beef1: "",
            export1: "",
            rearing1: "",
            euthanised1:"",
            bobby1:"",
            replacement2: "",
            beef2: "",
            export2: "",
            rearing2: "",
            euthanised2:"",
            bobby2:"",
            healthyCalvesPage: 0,
            colostrum: {
              commentary: "",
              focus: "",
              focusAdditional: "",
              graph1: "",
              graph2: "",
              actionTable: [{ id: '1', goals: '', priority_who:'', priority_when:''},
              { id: '2', goals: '', priority_who:'', priority_when:''},
              { id: '3', goals: '', action: '', priority_who:'', priority_when:''},
              { id: '4', goals: '', action: '', priority_who:'', priority_when:''},
              { id: '5', goals: '', action: '', priority_who:'', priority_when:''}],
              QuestionMapping: [
                {
                  Q1_5: "Teats are washed, disinfected and dried prior to colostrum harvesting. Calves are collected and cows are milked as soon as possible after calving to manage colostrum quality and mastitis risk.",
                  Q2_5: "Colostrum quality is tested, classified and either used immediately or stored appropriately.",
                  Q3_5: "Calves are actively fed an adequate volume of warm supplementary colostrum (no microwave) as soon as possible.",
                  Q4_5: "Everyone on the farm team has received training in how to correctly collect, test and store colostrum; and stomach tube a calf. ",
                  Q5_5: "We regularly assess and review the adequacy of our colostrum management program."
                }
              ],
              questions: [
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                }
              ]
            },
            calfPathways: {
              commentary: "",
              focus: "",
              focusAdditional: "",
              graph1: "",
              graph2: "",
              actionTable: [{ id: '1', goals: '', priority_who:'', priority_when:''},
              { id: '2', goals: '', priority_who:'', priority_when:''},
              { id: '3', goals: '', action: '', priority_who:'', priority_when:''},
              { id: '4', goals: '', action: '', priority_who:'', priority_when:''},
              { id: '5', goals: '', action: '', priority_who:'', priority_when:''}],
              QuestionMapping: [
                {
                  Q1_5: "All calves that we rear receive the same standard of care, whether they are replacement heifers or surplus/non-replacements.",
                  Q2_5: "Everybody in our farm team understands the legal requirements for transporting bobby calves (5-30 days old) and we have a clear system for communicating which calves are fit for transport and sale and when they were last fed.",
                  Q3_5: "We use sexed semen to breed replacement females from the highest genetic merit dams in the herd, determined using a combination of production data and genomic testing.",
                  Q4_5: "Beef sires used for cross breeding are selected strategically for target markets.",
                  Q5_5: "The proportions and numbers of calves reared is reviewed annually for profitability."
                }
              ],
              questions: [
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                }
              ]
            }
          };
        }
        else if (moduleName === 'Irrigation' && !('Irrigation' in updatedFarmDetails.surveyData)) {
          updatedFarmDetails.surveyData['Irrigation'] = {
            module: "Irrigation",
            commentary: "",
            action: "",
            approach: "",
            focus: "",
            focusAdditional: "",
            waterApplied: "",
            rainfall: "",
            yourGPWUI: "",
            pastureGrown: "",
            irrigationPage: 0,
            graph: "",
            benchmarkcommentary: "",
            selectedIrrigationType: "",
            QuestionMapping: [
              {
                Q1_6: "What type of irrigation system/s does your farm have?",
                Q2_6: "What factors do you consider when deciding on irrigation start-up? Are all staff trained in the current irrigation practices on farm?",
                Q3_6: "How are irrigation intervals determined? Readily Available Water (RAW) and evapotranspiration are critical to determination of required irrigation interval.",
                Q4_6: "What automation processes are used during the irrigation application?",
                Q5_6: "Does the irrigation system design allow for efficient use of water and energy?",
                Q6_6: "What monitoring equipment is used to determine efficient use of irrigation?",
                Q7_6: "How are soil related risk factor such as salinity, water logging, erosion and compaction managed?",
                Q8_6: "Precision flood irrigation is most efficient when water is on and off the paddock in 8 hours, Do your paddocks meet this benchmark?",
                Q9_6: "Do you consider the 48 hour weather forecast prior to irrigation?",
                Q10_6: "Do you test and monitor water quality in bores regularly?",
                Q11_6: "Do you utilise the correct shandy rate when irrigating wastewater (effluent), bore water etc.?",
                Q12_6: "Do you have a water market strategy?",
                Q13_6: "Do you calibrate spray irrigators prior to the season starting?",
                Q14_6: "Is there an ongoing maintenance schedule for irrigation equipment?",
                Q15_6: "Are Variable Rate Irrigation (VRI) applications used if the technology is installed?"
              }
            ],
            questions: [
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
            {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ],
                  [
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
            },
            {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },               
             {
              subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false
                  ]
              ],
              practice: "",
              focusCheck: "",
              notApplicable: false
            },
            {
            subQuestions: [
                [
                  false,
                  false,
                  false
                ]
            ],
            practice: "",
            focusCheck: "",
            notApplicable: false
          },
          {
            subQuestions: [
                [
                  false,
                  false,
                  false
                ]
            ],
            practice: "",
            focusCheck: "",
            notApplicable: false
          },
          {
            subQuestions: [
                [
                  false,
                  false,
                  false
                ]
            ],
            practice: "",
            focusCheck: "",
            notApplicable: false
          },
          {
            subQuestions: [
                [
                  false,
                  false,
                  false
                ]
            ],
            practice: "",
            focusCheck: "",
            notApplicable: false
          }
          ]
          };
        }
        else if (moduleName === 'Nitrogen' && !('Nitrogen' in updatedFarmDetails.surveyData)) {
          updatedFarmDetails.surveyData['Nitrogen'] = {
            module: "Nitrogen",
            commentary: "",
            action: "",
            approach: "",
            focus: "",
            focusAdditional: "",
            nitrogenFertiliser: "",
            purchasedFeed: "",
            milkingCows: "",
            nitrogenPage: 0,
            milkingHectares: "",
            milkSolids: "",
            milkingFat: "",
            milkProtein: "",
            homegrownFeed: "",
            importedFeed: "",
            exportedPro: "",
            surplus: "",
            graph: "",
            benchmarkcommentary: "",
            QuestionMapping: [
              {
                Q1_2: "Is your fertiliser spreader calibrated correctly and checked regularly?",
                Q2_2: "Do you assess the current weather and soil conditions before applying Nitrogen fertiliser?",
                Q3_2: "Are other limiting growth factors considered into your Nitrogen fertiliser decision?",
                Q4_2: "Do you assess pasture and feed requirements before applying Nitrogen?",
                Q5_2: "Do you adjust your Nitrogen fertilisation rates based on seasonal conditions?",
                Q6_2: "Is your grazing round adjusted throughout the season to optimise the Nitrogen response?",
                Q7_2: "Do you feed test for CP and adjust your diet accordingly?",
                Q8_2: "Do you adjust your Nitrogen formulation for minimising Nitrogen losses to the environment?",
                Q9_2: "Do you manage and monitor the legume content of your pasture?",
                Q10_2: "Do you grow alternative pasture and forage to limit Nitrogen losses to the environment?"
              }
            ],
            questions: [
              {
                subQuestions: [
                  [
                    false,
                    false,
                    false,
                    false
                  ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                  subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              },
              {
                  subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              },
              {
                  subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              },
              {
                  subQuestions: [
                      [
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              },
              {
                  subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              },
              {
                  subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              },
              {
                  subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              },
              {
                  subQuestions: [
                      [
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              },
              {
                  subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              }
            ]
          };
        }
      });
    }
  
    // Return the updated farmDetails object
    return updatedFarmDetails;
  }
  
  export default LoadModules;
  