import React  from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import focus from "../../../images/Focus.jpg"
import {default as styles} from "../Styles"
import { Table, TableHeader,TableFooter  } from '../../../components/Utilities'
import { CalculateScores, CalculateFocusArea , IconPicture} from '../../Utilities'; 


export default function CarbonTable(carbon, farmDetails){
  const borderHeight = [55,55,45,45,45,65,45,45,45];
  CalculateScores(carbon, carbon.practices);
  CalculateFocusArea(carbon, carbon.focusColumn);
  IconPicture(carbon.practices,carbon.goodColumn, carbon.improvementColumn, carbon.innovativeColumn)
  const data = {
      module: "Greenhouse Gas Emissions",
      total:
      [carbon.scores.innovative, carbon.scores.good, carbon.scores.improvement, carbon.scores.focusArea],
      items: []}
      for (var i = 0; i < carbon.questions.length; i += 1){
        data.items.push({
          sr: 1,
          question: carbon.questions[i] || "",
          innovative: carbon.innovativeColumn[i] || 0,
          good: carbon.goodColumn[i] || 0,
          areaOfImprovement: carbon.improvementColumn[i] || 0,
          focusArea: carbon.focusColumn[i] || "",
        },)
      }
      
  return(
    <Page size="A4" style={styles.page}>
      <div >
        <Image 
        src = {focus}
        style={styles.headerImage} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Focus</Text>
        <Text style={styles.secondSubtitle}>Greenhouse Gas Emissions</Text>  
        {TableHeader(data)}
        {Table(data.items,borderHeight, 9)}
        {TableFooter(data.total, 30)}
      </div>
      
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
} 