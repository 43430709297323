import React  from 'react';
import { Page, Text, StyleSheet, Image } from '@react-pdf/renderer';
import Carbon from "../../../images/Carbon.jpg"
import carbonIcons from "../../../images/carbonIcons.png"
import {default as styles} from "../Styles"


const CarbonStyle = StyleSheet.create({
    header_image: {
        marginTop:0,
        marginVertical: 0,
        marginHorizontal: 0,
        padding: 0,
        height: "45%"
    },
    header_middle_section: {
        height: "35%",
        backgroundColor: "#DBEBC7"
    },
    header_middle_section_text: {
        paddingHorizontal: "10%",
        font: 'Arial',
        fontSize: 26,
        color: '#002D4E',
        textAlign: 'center',
    },
    header_bottom_section:{
        height: "10%"
    },
    header_bottom_section_text:{
        paddingHorizontal: "10%",
        paddingVertical: "10%",
        font: 'Arial',
        fontSize: 12,
        color: '#002D4E',
        textAlign: 'center',
    },
})

function getPageNumber(pageNumber, carbon, setCarbon){
    if (pageNumber !== carbon.carbonPage) {
        setCarbon(prevDetails => ({
            ...prevDetails,
            carbonPage: pageNumber
        }));
    }
  }

export default function CarbonHeader(carbon, farmDetails, setCarbon){

    const formatnetGHG = carbon.netGHG.toLocaleString('en-AU');
    const formatvehicleEquivalent = carbon.vehicleEquivalent.toLocaleString('en-AU');
    return(
        <Page>
            <div style={CarbonStyle.header_image} >
                <Image 
                    src = {Carbon}/>
                <div style={{position: 'absolute'}}>
                    <Text style={styles.title}>The Headline</Text>
                    <Text style={styles.subtitle}>Greenhouse Gas Emissions </Text>         
                </div>
            </div>
            
            <div style = {CarbonStyle.header_middle_section}>
                <Text style = {[CarbonStyle.header_middle_section_text,{paddingTop: "5%",paddingRight: "5%"}]}>Your Farm Yearly Emissions of
                <Text style = {{fontFamily: 'ArialBold'}}> {Number(formatnetGHG).toLocaleString()} t CO</Text>
                <Text style={{ fontSize: 14 }}>2</Text></Text>
                <Text style = {CarbonStyle.header_middle_section_text}> is equivalent to 
                <Text style = {{fontFamily: 'ArialBold'}}> {formatvehicleEquivalent} vehicles*</Text>
                </Text>
                <Image 
                    src = {carbonIcons} />
            </div>

            <div style = {CarbonStyle.header_bottom_section}>
                <Text style = {CarbonStyle.header_bottom_section_text}>
                    * Based on the average emissions intensity for passenger vehicles in Australia of 149.5g/km and travelling an average 13,300 km per year.
                </Text>
            </div>
        
            <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
            <Text style={styles.pageNumber} render={({ pageNumber }) => (
            `${pageNumber}` && getPageNumber(pageNumber, carbon, setCarbon)
            )} fixed />
            <Text style={styles.pageNumber} render={({ pageNumber }) => (
            `${pageNumber}`
            )} fixed />
        </Page>
    )
} 
