import {atom, useRecoilState} from 'recoil';
import { AppRunTimeContext } from '../Context/index'; 
import { useContext } from 'react';


const modLink = atom({
  key: 'ModLink',
  default: []
})

const Next = atom({
  key: 'Next',
  default: '/Review'
})

const Prev = atom({
  key: 'Prev',
  default: '/ModulePage'
})


export default function useModuleLink() {
  const { modules } = useContext(AppRunTimeContext);

  const [links, setModLink] = useRecoilState(modLink);
  const [prev, setprev] =  useRecoilState(Prev);
  const [next, setnext] =  useRecoilState(Next);

  const clearLinks = () => {
    setModLink([]);  // Clearing the links array
  }
  

  const updateLink = (element) => {
    setModLink((currentState) => {
      if (!currentState.includes(element)) {
        return [...currentState, element];
      }
      return currentState;  // return the current state if element is already present
    });
  
    console.log(element);
  };
  

  const deleteLink = (element) => {
    setModLink((currentState) => currentState.filter(row => element!==row))
  }

  const getLinks = () => {
    return links;
  }

  const setNextPrev = (value) => {
    if (modules.length > 1) {
      console.log(modules)
      modules.forEach(function (item, index) {        
      if (item === value && index !== (modules.length - 1)  && index !== 0) {
        setnext(modules[index + 1]);
        console.log('a')
        setprev(modules[index - 1]);
      }
      else if (item === value && index === 0) {
        setprev('/ModulePage');
        console.log('b)')
        console.log(item)
        console.log(value)
        console.log(index)
        console.log(links)
        setnext(links[index + 1]);
      }
      else if (item === value && index === (modules.length - 1)) {
        setnext('/Review');
        console.log('c')
        setprev(modules[index - 1]);
      }
      else if (value === '/Review'){
        console.log('e')
        console.log(item)
        console.log(index)
        console.log(value)
        console.log(links)
        console.log(modules[index])
        const previous = modules[index]
        // setprev(modules[index]); // Review page case..
        // setprev(previous)
      }
    });
    }
    else {
      console.log('d')
      setprev('/ModulePage');
      setnext('/Review');
    }
  }

  const getNext = () => {
    return next;
  }

  const getPrev = () => {
    return prev;
  }

  return {deleteLink, setNextPrev, getLinks, updateLink, getNext, getPrev, clearLinks}
}

