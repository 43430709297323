import { TextField } from "@mui/material";
import React, { useCallback, useState, useRef, useEffect } from "react";
import * as XLSX from 'xlsx';
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { AppRunTimeContext } from '../Context/index';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import useModuleLink from '../hooks/moduleLink';
import Next from "./Next";
import Back from "./Back";
import acquireAccessToken from "../accessToken"
import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import ProgressBar from "./ProgressBar";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { List, ListItem, ListItemText } from '@mui/material';
import LoadModules from './LoadModules';
import './Autosearch.css';

export default function Review() {
  const {farmDetails, setFarmDetails, payload, setPayload, operation, reviewStatus,setReviewStatus, modules, set_modules, highestRevisionNumber, setHighestRevisionNumber} = useContext(AppRunTimeContext);
  const [status, setStatus] = React.useState('');
  const [name, setName] = React.useState('');
  const [next, setNext] = React.useState('/FarmDetails');
  const [prev, setPrev] = React.useState(modules[modules.length-1]);
  const [submitted, setSubmitted] = React.useState(false);

  const { instance, accounts } = useMsal();
  const [fetchedData, setFetchedData] = React.useState([]);
  const [isCancelled, setIsCancelled] = React.useState(false);  // Add this line
  const { generalFarm, setGeneralFarm, modify, setOperation } = useContext(AppRunTimeContext);
  const { carbon, setCarbon, irrigation, setIrrigation, healthyCalves, setHealthyCalves } = useContext(AppRunTimeContext);
  const { nitrogen, setNitrogen } = useContext(AppRunTimeContext);
  const { effluent, setEffluent, biosecurity, setBiosecurity } = useContext(AppRunTimeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [modulesToReview, setModulesToReview] = useState([]);
  const [openIncompleteDialog, setOpenIncompleteDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [reviewOption, setReviewOption] = useState(false);
  const [sendMailBtn, setSendMailBtn] = useState(false);

  // const { setNextPrev, getNext, getPrev} = useModuleLink();
  // console.log(prev)
  // console.log(modules)
  // console.log(location)
  // setNextPrev('/Review')
  // let prev = getPrev();

  const checkSurveyData = () => {
    const modulesWithIncompleteQuestions = [];
    const moduleNameMapping = {
      GeneralFarm: "General Farm Management",
      Nitrogen: "Nutrient Management (Nitrogen)",
      Effluent: "Effluent Management",
      Carbon: "Greenhouse Gas Emissions",
      HealthyCalves: "Animal Health and Welfare (Healthy Calves)",
      Irrigation: "Irrigation Management",
      Biosecurity: "Farm Biosecurity"
    };

    // Assuming farmDetails.modules is an array of module keys
    const activeModules = farmDetails.modules; // This should be the list of module keys that are active

    Object.keys(farmDetails.surveyData).forEach((moduleKey) => {
      if (activeModules.includes(moduleKey) && moduleKey !== 'details') {
        const module = farmDetails.surveyData[moduleKey];

        const checkQuestions = (questions, moduleName) => {
          if (questions && questions.length > 0) {
            questions.forEach((question) => {
              const notApplicable = question.notApplicable || false;
              const focusCheck = question.focusCheck || '';
              const practice = question.practice || '';
              if (!notApplicable && (!focusCheck || !practice)) {
                if (!modulesWithIncompleteQuestions.includes(moduleName)) {
                  modulesWithIncompleteQuestions.push(moduleName);
                }
              }
            });
          }
        };

        if (moduleKey === 'HealthyCalves') {
          checkQuestions(module.colostrum.questions, moduleNameMapping[moduleKey]);
          checkQuestions(module.calfPathways.questions, moduleNameMapping[moduleKey]);
        } else {
          checkQuestions(module.questions, moduleNameMapping[moduleKey]);
        }
      }
    });

    setModulesToReview(modulesWithIncompleteQuestions);    

    return modulesWithIncompleteQuestions.length > 0;
  };

  const handleNextClick = () => {
    if (isSaving) {
      console.log("Saving in progress. Please wait.");
      return;
    }


    if (status === "COMPLETE" || status === "REVIEW") {
      // Invoke the check function and get the result
      const hasIncompleteModules = checkSurveyData();
      if (hasIncompleteModules) {
        setOpenIncompleteDialog(true);
        return
      }
      else {
        setIsSaving(true); // Indicate the start of the saving process
        handleSave();
        return
      }
    }

    navigate(next);

  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    assignNext(e);
    setOpenDialog(true);  // Open the dialog
  };

  useEffect(() => {
    if (openDialog) {
      const timer = setTimeout(() => {
        setOpenDialog(false);
      }, 1000);

      return () => clearTimeout(timer); // Clean up the timer to prevent memory leaks
    }
  }, [openDialog]);


  React.useEffect(() => {

    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
      window.location.href = '/'; // Redirect to home page
    }

    const MODULES_MAP = {
      Carbon: { target: carbon, propName: "graph", name: "carbonGraph" },
      Nitrogen: { target: nitrogen, propName: "graph", name: "nitrogenGraph" },
      Irrigation: { target: irrigation, propName: "graph", name: "irrigationGraph" },
      HealthyCalves: [
        { target: healthyCalves.colostrum, propName: "graph1", name: "healthyCalvesColostrumGraph1" },
        { target: healthyCalves.colostrum, propName: "graph2", name: "healthyCalvesColostrumGraph2" },
        { target: healthyCalves.colostrum, propName: "graph3", name: "healthyCalvesColostrumGraph3" },
        { target: healthyCalves.colostrum, propName: "graph4", name: "healthyCalvesColostrumGraph4" },
        { target: healthyCalves.calfPathways, propName: "graph1", name: "healthyCalvesCalfPathwaysGraph1" },
        { target: healthyCalves.calfPathways, propName: "graph2", name: "healthyCalvesCalfPathwaysGraph2" }
      ],
      GeneralFarm: { target: generalFarm, propName: "farmMap", name: "farmMap" },
    };

    const moduleCommentaryMap = {
      GeneralFarm: { prefix: "gfCommentary", target: generalFarm },
      Nitrogen: { prefix: "nitrogenCommentary", target: nitrogen },
      Effluent: { prefix: "effluentCommentary", target: effluent },
      Carbon: { prefix: "carbonCommentary", target: carbon },
      Irrigation: { prefix: "irrigationCommentary", target: irrigation },
      Biosecurity: { prefix: "biosecurityCommentary", target: biosecurity },
      HealthyCalvesColostrum: { prefix: "colostrumCommentary", target: healthyCalves.colostrum },
      HealthyCalvesCalfPathways: { prefix: "calfPathwaysCommentary", target: healthyCalves.calfPathways }
    };

    const moduleActionMap = {

      Nitrogen: { prefix: "nitrogenAction", target: nitrogen },
      Effluent: { prefix: "effluentAction", target: effluent },
      Carbon: { prefix: "carbonAction", target: carbon },
      Irrigation: { prefix: "irrigationAction", target: irrigation },

    };
    async function fetchData() {


      if (operation === 'modify' && modules.length === 0) {
        setPrev('/')


        //const token = localStorage.getItem('accessToken');
        const token = await acquireAccessToken(instance, accounts[0])
        localStorage.setItem("accessToken", token);


        const url = farmDetails.revisionNumber
          ? `${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/getFarmSurveyData/${farmDetails.farmSurveyId}$${farmDetails.revisionNumber}`
          : `${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/getFarmSurveyData/${farmDetails.farmSurveyId}`;
        try {
          const response = await axios.get(url, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });

          setFetchedData(response.data);
          //   // Update farmDetails without mutating
          //   setFarmDetails(prevDetails => ({
          //     ...prevDetails,
          //     farmRegion: response.data[0].region,
          //     supplier_no: response.data[0].farmId,
          //     farm_owner: response.data[0].surveyData['details'].farmOwner,
          //     plan_owner: response.data[0].surveyData['details'].planOwner,
          //     date_edited: response.data[0].dateEdited,  
          //     surveyData: response.data[0].surveyData,
          //     modules: response.data[0].modules,
          //     farmAddress: response.data[0].surveyData['details'].farmAddress ?? "",
          //     farmAddressLat: response.data[0].surveyData['details'].farmAddressLat,
          //     farmAddressLong: response.data[0].surveyData['details'].farmAddressLong
          // }));

          // Directly assign to farmDetails
          farmDetails.farmRegion = response.data[0].region;
          farmDetails.supplier_no = response.data[0].farmId;
          farmDetails.financialYear = response.data[0].financialYear;
          farmDetails.farm_owner = response.data[0].surveyData['details'].farmOwner;
          farmDetails.plan_owner = response.data[0].surveyData['details'].planOwner;
          farmDetails.date_edited = response.data[0].dateEdited;
          farmDetails.surveyData = response.data[0].surveyData;
          farmDetails.modules = response.data[0].modules;
          farmDetails.mapRequired = response.data[0].mapRequired;
          farmDetails.farmAddress = response.data[0].surveyData['details'].farmAddress ?? "";
          farmDetails.farmAddressLat = response.data[0].surveyData['details'].farmAddressLat;
          farmDetails.farmAddressLong = response.data[0].surveyData['details'].farmAddressLong;
          if (!farmDetails.revisionNumber || farmDetails.revisionNumber.trim() === '') {
            farmDetails.revisionNumber = '0001';
          }
          setPayload(response.data[0])

          set_modules(response.data[0].modules.map(item => "/" + item))

          // console.log(response.data[0])

          // console.log(modules)

          // setPrev(operation === 'modify' ? '/' : response.data[0].modules.map(item => "/" + item)[response.data[0].modules.length-1])

          if ('GeneralFarm' in response.data[0].surveyData) {
            response.data[0].surveyData['GeneralFarm'].questions.forEach((question, index) => {
              // Update the generalFarm.practices array based on the practice in each question
              generalFarm.practices[index] = question.practice;
              generalFarm.focusArea[index] = question.focusCheck;
            });
            generalFarm.commentary = response.data[0].surveyData['GeneralFarm'].commentary
            generalFarm.focus = response.data[0].surveyData['GeneralFarm'].focus
            generalFarm.focusAdditional = response.data[0].surveyData['GeneralFarm'].focusAdditional
            generalFarm.mapTitle = response.data[0].surveyData['GeneralFarm'].mapTitle
            generalFarm.mapDescription = response.data[0].surveyData['GeneralFarm'].mapDescription
            generalFarm.mapFocus = response.data[0].surveyData['GeneralFarm'].mapFocus
            generalFarm.generalPage = response.data[0].surveyData['GeneralFarm'].generalPage
            if (response.data[0].surveyData['GeneralFarm'].scoreArray) {
              generalFarm.scoreArray = response.data[0].surveyData['GeneralFarm'].scoreArray
            }
           
            // Extract image names from the commentary
            const imageNames = (response.data[0].surveyData['GeneralFarm'].commentary.match(/gfCommentary[^\s]+/g) || []);
          }
          if ('Carbon' in response.data[0].surveyData) {
            response.data[0].surveyData['Carbon'].questions.forEach((question, index) => {
              // Update the generalFarm.practices array based on the practice in each question
              carbon.practices[index] = question.practice;
              carbon.focusArea[index] = question.focusCheck;
            });

            carbon.GHGEmissionsIntensity = response.data[0].surveyData['Carbon'].GHGEmissionsIntensity
            carbon.action = response.data[0].surveyData['Carbon'].action
            carbon.approach = response.data[0].surveyData['Carbon'].approach
            carbon.carbonPage = response.data[0].surveyData['Carbon'].carbonPage
            carbon.commentary = response.data[0].surveyData['Carbon'].commentary
            carbon.focus = response.data[0].surveyData['Carbon'].focus
            carbon.focusAdditional = response.data[0].surveyData['Carbon'].focusAdditional
            carbon.netGHG = response.data[0].surveyData['Carbon'].netGHG
            carbon.vehicleEquivalent = response.data[0].surveyData['Carbon'].vehicleEquivalent
            if (response.data[0].surveyData['Carbon'].scoreArray) {
              carbon.scoreArray = response.data[0].surveyData['Carbon'].scoreArray
            }

          }
          if ('Nitrogen' in response.data[0].surveyData) {
            response.data[0].surveyData['Nitrogen'].questions.forEach((question, index) => {
              // Update the generalFarm.practices array based on the practice in each question
              nitrogen.practices[index] = question.practice;
              nitrogen.focusArea[index] = question.focusCheck;

            });
            // {console.log(nitrogen)}
            // {console.log(payload)}
            nitrogen.milkProtein = response.data[0].surveyData['Nitrogen'].milkProtein
            nitrogen.milkSolids = response.data[0].surveyData['Nitrogen'].milkSolids
            nitrogen.milkingCows = response.data[0].surveyData['Nitrogen'].milkingCows
            nitrogen.milkingFat = response.data[0].surveyData['Nitrogen'].milkingFat
            nitrogen.milkingHectares = response.data[0].surveyData['Nitrogen'].milkingHectares
            nitrogen.nitrogenFertiliser = response.data[0].surveyData['Nitrogen'].nitrogenFertiliser
            nitrogen.purchasedFeed = response.data[0].surveyData['Nitrogen'].purchasedFeed
            nitrogen.importedFeed = response.data[0].surveyData['Nitrogen'].importedFeed
            nitrogen.homegrownFeed = response.data[0].surveyData['Nitrogen'].homegrownFeed
            nitrogen.surplus = response.data[0].surveyData['Nitrogen'].surplus
            nitrogen.exportedPro = response.data[0].surveyData['Nitrogen'].exportedPro
            nitrogen.commentary = response.data[0].surveyData['Nitrogen'].commentary
            nitrogen.action = response.data[0].surveyData['Nitrogen'].action
            nitrogen.approach = response.data[0].surveyData['Nitrogen'].approach
            nitrogen.nitrogenPage = response.data[0].surveyData['Nitrogen'].nitrogenPage
            nitrogen.focus = response.data[0].surveyData['Nitrogen'].focus
            nitrogen.focusAdditional = response.data[0].surveyData['Nitrogen'].focusAdditional
            nitrogen.benchmarkcommentary = response.data[0].surveyData['Nitrogen'].benchmarkcommentary
          
            if (response.data[0].surveyData['Nitrogen'].scoreArray) {
              nitrogen.scoreArray = response.data[0].surveyData['Nitrogen'].scoreArray
            }

            if (operation === 'modify') {
              // nitrogen.graph = response.data[0].surveyData['Nitrogen'].graph  -- not yet saving graph
            }
          }

          if ('Effluent' in response.data[0].surveyData) {
            response.data[0].surveyData['Effluent'].questions.forEach((question, index) => {
              // Update the generalFarm.practices array based on the practice in each question
              effluent.practices[index] = question.practice;
              effluent.focusArea[index] = question.focusCheck;
            });
            effluent.commentary = response.data[0].surveyData['Effluent'].commentary
            effluent.focus = response.data[0].surveyData['Effluent'].focus
            effluent.focusAdditional = response.data[0].surveyData['Effluent'].focusAdditional
            effluent.haApplied = response.data[0].surveyData['Effluent'].haApplied
            effluent.effluentPage = response.data[0].surveyData['Effluent'].effluentPage
            effluent.action = response.data[0].surveyData['Effluent'].action
            effluent.approach = response.data[0].surveyData['Effluent'].approach
            if (response.data[0].surveyData['Effluent'].scoreArray) {
              effluent.scoreArray = response.data[0].surveyData['Effluent'].scoreArray
            }
          }

          if ('Irrigation' in response.data[0].surveyData) {
            response.data[0].surveyData['Irrigation'].questions.forEach((question, index) => {
              // Update the generalFarm.practices array based on the practice in each question
              irrigation.practices[index] = question.practice;
              irrigation.focusArea[index] = question.focusCheck;
            });


            irrigation.action = response.data[0].surveyData['Irrigation'].action
            irrigation.approach = response.data[0].surveyData['Irrigation'].approach
            irrigation.irrigationPage = response.data[0].surveyData['Irrigation'].irrigationPage
            irrigation.benchmarkcommentary = response.data[0].surveyData['Irrigation'].benchmarkcommentary
            irrigation.commentary = response.data[0].surveyData['Irrigation'].commentary
            irrigation.focus = response.data[0].surveyData['Irrigation'].focus
            irrigation.focusAdditional = response.data[0].surveyData['Irrigation'].focusAdditional
            irrigation.waterApplied = response.data[0].surveyData['Irrigation'].waterApplied
            irrigation.rainfall = response.data[0].surveyData['Irrigation'].rainfall
            irrigation.yourGPWUI = response.data[0].surveyData['Irrigation'].yourGPWUI
            irrigation.pastureGrown = response.data[0].surveyData['Irrigation'].pastureGrown
            irrigation.selectedIrrigationType = response.data[0].surveyData['Irrigation'].selectedIrrigationType || ""
            if (response.data[0].surveyData['Irrigation'].scoreArray) {
              irrigation.scoreArray = response.data[0].surveyData['Irrigation'].scoreArray
            }
          }

          if ('HealthyCalves' in response.data[0].surveyData) {
            response.data[0].surveyData['HealthyCalves'].colostrum.questions.forEach((question, index) => {
              // Update the generalFarm.practices array based on the practice in each question
              healthyCalves.colostrum.practices[index] = question.practice;
              healthyCalves.colostrum.focusArea[index] = question.focusCheck;
            });
            response.data[0].surveyData['HealthyCalves'].calfPathways.questions.forEach((question, index) => {
              // Update the generalFarm.practices array based on the practice in each question
              healthyCalves.calfPathways.practices[index] = question.practice;
              healthyCalves.calfPathways.focusArea[index] = question.focusCheck;
            });

            if (response.data[0].surveyData['HealthyCalves'].colostrum.scoreArray) {
              healthyCalves.colostrum.scoreArray = response.data[0].surveyData['HealthyCalves'].colostrum.scoreArray
            }
            if (response.data[0].surveyData['HealthyCalves'].calfPathways.scoreArray) {
              healthyCalves.calfPathways.scoreArray = response.data[0].surveyData['HealthyCalves'].calfPathways.scoreArray
            }
            healthyCalves.colostrum.commentary = response.data[0].surveyData['HealthyCalves'].colostrum.commentary
            healthyCalves.colostrum.focus = response.data[0].surveyData['HealthyCalves'].colostrum.focus
            healthyCalves.colostrum.focusAdditional = response.data[0].surveyData['HealthyCalves'].colostrum.focusAdditional
            healthyCalves.colostrum.actionTable = response.data[0].surveyData['HealthyCalves'].colostrum.actionTable
            healthyCalves.colostrum.currentYear = response.data[0].surveyData['HealthyCalves'].colostrum.currentYear
            healthyCalves.colostrum.futureYear = response.data[0].surveyData['HealthyCalves'].colostrum.futureYear


            healthyCalves.calfPathways.commentary = response.data[0].surveyData['HealthyCalves'].calfPathways.commentary
            healthyCalves.calfPathways.focus = response.data[0].surveyData['HealthyCalves'].calfPathways.focus
            healthyCalves.calfPathways.focusAdditional = response.data[0].surveyData['HealthyCalves'].calfPathways.focusAdditional
            healthyCalves.calfPathways.actionTable = response.data[0].surveyData['HealthyCalves'].calfPathways.actionTable
            healthyCalves.calfPathways.currentYear = response.data[0].surveyData['HealthyCalves'].calfPathways.currentYear
            healthyCalves.calfPathways.futureYear = response.data[0].surveyData['HealthyCalves'].calfPathways.futureYear




            healthyCalves.trainingGrid = response.data[0].surveyData['HealthyCalves'].trainingGrid
            healthyCalves.achievingTransfer = response.data[0].surveyData['HealthyCalves'].achievingTransfer;
            healthyCalves.failureTransfer = response.data[0].surveyData['HealthyCalves'].failureTransfer;
            healthyCalves.less12hours = response.data[0].surveyData['HealthyCalves'].less12hours;
            healthyCalves.more12hours = response.data[0].surveyData['HealthyCalves'].more12hours;
            healthyCalves.noColostrum = response.data[0].surveyData['HealthyCalves'].noColostrum;

            healthyCalves.goalachievingTransfer = response.data[0].surveyData['HealthyCalves'].goalachievingTransfer;
            healthyCalves.goalfailureTransfer = response.data[0].surveyData['HealthyCalves'].goalfailureTransfer;
            healthyCalves.goalless12hours = response.data[0].surveyData['HealthyCalves'].goalless12hours;
            healthyCalves.goalmore12hours = response.data[0].surveyData['HealthyCalves'].goalmore12hours;
            healthyCalves.goalnoColostrum = response.data[0].surveyData['HealthyCalves'].goalnoColostrum;

            healthyCalves.replacement1 = response.data[0].surveyData['HealthyCalves'].replacement1;
            healthyCalves.beef1 = response.data[0].surveyData['HealthyCalves'].beef1;
            healthyCalves.export1 = response.data[0].surveyData['HealthyCalves'].export1;
            healthyCalves.rearing1 = response.data[0].surveyData['HealthyCalves'].rearing1;
            healthyCalves.bobby1 = response.data[0].surveyData['HealthyCalves'].bobby1;

            healthyCalves.replacement2 = response.data[0].surveyData['HealthyCalves'].replacement2;
            healthyCalves.beef2 = response.data[0].surveyData['HealthyCalves'].beef2;
            healthyCalves.export2 = response.data[0].surveyData['HealthyCalves'].export2;
            healthyCalves.rearing2 = response.data[0].surveyData['HealthyCalves'].rearing2;
            healthyCalves.bobby2 = response.data[0].surveyData['HealthyCalves'].bobby2;

            healthyCalves.healthyCalvesPage = response.data[0].surveyData['HealthyCalves'].healthyCalvesPage;

          }

          if ('Biosecurity' in response.data[0].surveyData) {
            response.data[0].surveyData['Biosecurity'].questions.forEach((question, index) => {
              // Update the generalFarm.practices array based on the practice in each question
              biosecurity.practices[index] = question.practice;
              biosecurity.focusArea[index] = question.focusCheck;
            });
            biosecurity.commentary = response.data[0].surveyData['Biosecurity'].commentary
            biosecurity.focus = response.data[0].surveyData['Biosecurity'].focus
            biosecurity.focusAdditional = response.data[0].surveyData['Biosecurity'].focusAdditional
            biosecurity.biosecurityPage = response.data[0].surveyData['Biosecurity'].biosecurityPage
            biosecurity.riskAssessment = response.data[0].surveyData['Biosecurity'].riskAssessment
            biosecurity.managementPlan = response.data[0].surveyData['Biosecurity'].managementPlan
            biosecurity.visitorRegister = response.data[0].surveyData['Biosecurity'].visitorRegister
            biosecurity.propertySignage = response.data[0].surveyData['Biosecurity'].propertySignage
            biosecurity.planReview = response.data[0].surveyData['Biosecurity'].planReview
            biosecurity.actionTable = response.data[0].surveyData['Biosecurity'].actionTable
            if (response.data[0].surveyData['Biosecurity'].scoreArray) {
              biosecurity.scoreArray = response.data[0].surveyData['Biosecurity'].scoreArray
            }
          }

          return response.data;  // <-- Return the fetched data


        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }


    async function fetchImage(imageType, target, propName = "graph") {
      try {
        const token = await acquireAccessToken(instance, accounts[0]);
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/getImage/${imageType}_${farmDetails.farmSurveyId}_${farmDetails.revisionNumber}`;
        // Iterate over the moduleCommentaryMap to check for matching prefix
        for (const moduleName in moduleCommentaryMap) {
          if (imageType.startsWith(moduleCommentaryMap[moduleName].prefix)) {
            url = `${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/getImage/${imageType}`;
            break;
          }
        }

        for (const moduleName in moduleActionMap) {
          if (imageType.startsWith(moduleActionMap[moduleName].prefix)) {
            url = `${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/getImage/${imageType}`;
            break;
          }
        }


        const response = await axios.get(url, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        if (response.data) {
          if (propName === "images") {
            target[propName] = target[propName] || {};  // Ensure it's an object
            target[propName][imageType] = response.data;  // Set the image data with the imageType as the key
          } else {
            target[propName] = response.data;
          }
          const defaultModuleData = LoadModules(farmDetails);
          setFarmDetails(defaultModuleData);
        }
      } catch (error) {
        console.error(`Error fetching ${imageType}:`, error);
      }
    }

    if (operation === 'modify' && modules.length === 0) {
      fetchData().then(data => {
        if (data && data[0]) {
          data[0].modules.forEach(moduleName => {
            // Check if the module is in the fetched data
            if (moduleName in data[0].surveyData) {
              const moduleData = moduleCommentaryMap[moduleName];
              const moduleData1 = moduleActionMap[moduleName];

              // Check if commentary exists and then process it
              if (data[0].surveyData[moduleName].commentary) {
                // Regex to match commentary based on module prefix
                const regex = new RegExp(`${moduleData.prefix}\\S+`, "g");

                // Extract image names from the commentary
                const imageNames = (data[0].surveyData[moduleName].commentary.match(regex) || []);
                console.log(imageNames)
                // Fetch each commentary image
                imageNames.forEach(name => fetchImage(name, moduleData.target, "images"));
              }

              if (data[0].surveyData[moduleName].action) {
                // Regex to match commentary based on module prefix
                const regex = new RegExp(`${moduleData1.prefix}\\S+`, "g");

                // Extract image names from the action
                const imageNames = (data[0].surveyData[moduleName].action.match(regex) || []);
                console.log(imageNames)
                // Fetch each action image
                imageNames.forEach(name => fetchImage(name, moduleData1.target, "images"));
              }

              // Special handling for HealthyCalves sub-modules
              if (moduleName === 'HealthyCalves') {
                // Process HealthyCalves.colostrum commentary
                let subModuleData = moduleCommentaryMap['HealthyCalvesColostrum'];
                if (subModuleData && data[0].surveyData.HealthyCalves.colostrum.commentary) {
                  const regex = new RegExp(`${subModuleData.prefix}\\S+`, "g");
                  const imageNames = (data[0].surveyData.HealthyCalves.colostrum.commentary.match(regex) || []);
                  console.log(imageNames);
                  imageNames.forEach(name => fetchImage(name, subModuleData.target, "images"));
                }
                // Process HealthyCalves.calfPathways commentary
                subModuleData = moduleCommentaryMap['HealthyCalvesCalfPathways'];
                if (subModuleData && data[0].surveyData.HealthyCalves.calfPathways.commentary) {
                  const regex = new RegExp(`${subModuleData.prefix}\\S+`, "g");
                  const imageNames = (data[0].surveyData.HealthyCalves.calfPathways.commentary.match(regex) || []);
                  console.log(imageNames);
                  imageNames.forEach(name => fetchImage(name, subModuleData.target, "images"));
                }
              }
            }
          });


          if (data && data[0]) {
            for (const module of Object.keys(MODULES_MAP)) {
              if (data[0].modules.includes(module)) {
                const graphs = Array.isArray(MODULES_MAP[module]) ? MODULES_MAP[module] : [MODULES_MAP[module]];
                for (const graph of graphs) {
                  fetchImage(graph.name, graph.target, graph.propName);
                }
              }
            }

            // // Extract image names from the commentary
            // const imageNames = (data[0].surveyData['GeneralFarm'].commentary.match(/gfCommentary[^\s]+/g) || []);
            // console.log(imageNames)
            // // Fetch each commentary image
            // imageNames.forEach(name => fetchImage(name, generalFarm, "images"));

            fetchImage("farmPic", farmDetails, "farmPic");  // Special case for farmPic
          }

        }
      });
    }
    else {
      const defaultModuleData = LoadModules(farmDetails);
      setFarmDetails(defaultModuleData);
    }



  }, [farmDetails.farmSurveyId, accounts, instance]);

  const isAEDT = (date) => {
    const year = date.getFullYear();
    const startDST = new Date(Date.UTC(year, 9, 1)); // October 1st
    const endDST = new Date(Date.UTC(year, 3, 1)); // April 1st

    // Find the first Sunday in October
    startDST.setUTCDate(1 + (7 - startDST.getUTCDay()) % 7);

    // Find the first Sunday in April
    endDST.setUTCDate(1 + (7 - endDST.getUTCDay()) % 7);

    return (date >= startDST && date < endDST);
  };


  const getCurrentDateTime = () => {
    const nowUTC = new Date(Date.now());

    const offset = isAEDT(nowUTC) ? 12 : 11; // UTC+13 if Daylight Saving Time, otherwise UTC+12

    const aet = new Date(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(),
      nowUTC.getUTCHours() + offset, nowUTC.getUTCMinutes(), nowUTC.getUTCSeconds());

    const year = aet.getFullYear();
    const month = String(aet.getMonth() + 1).padStart(2, '0');
    const day = String(aet.getDate()).padStart(2, '0');
    const hour = String(aet.getHours()).padStart(2, '0');
    const minute = String(aet.getMinutes()).padStart(2, '0');
    const second = String(aet.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}${hour}${minute}${second}`;
  };

  // Helper function to prepare payload
  const preparePayload = (prevDetails, specificFields = {}) => {
    const { surveyData } = farmDetails;

    const surveyDataWithoutGraphs = { ...farmDetails.surveyData };

    if (surveyData.Carbon) {
      const { graph: carbonGraph, ...rest } = surveyData.Carbon;
      surveyDataWithoutGraphs.Carbon = rest;
    }

    if (surveyData.Nitrogen) {
      const { graph: nitrogenGraph, ...rest } = surveyData.Nitrogen;
      surveyDataWithoutGraphs.Nitrogen = rest;
    }

    if (surveyData.Irrigation) {
      const { graph: irrigationGraph, ...rest } = surveyData.Irrigation;
      surveyDataWithoutGraphs.Irrigation = rest;
    }

    if (surveyData.HealthyCalves) {
      const { colostrum, calfPathways, ...rest } = surveyData.HealthyCalves;

      // Remove graph1 and graph2 from colostrum
      const { graph1: colostrumGraph1, graph2: colostrumGraph2,graph3: colostrumGraph3, graph4: colostrumGraph4, ...colostrumRest } = colostrum;
    
      // Remove graph1 and graph2 from calfPathways
      const { graph1: calfPathwaysGraph1, graph2: calfPathwaysGraph2, ...calfPathwaysRest } = calfPathways;

      surveyDataWithoutGraphs.HealthyCalves = {
        ...rest,
        colostrum: colostrumRest,
        calfPathways: calfPathwaysRest
      };
    }

    if (surveyData.GeneralFarm) {
      const { farmMap, ...restGeneralFarm } = surveyData.GeneralFarm;
      surveyDataWithoutGraphs.GeneralFarm = restGeneralFarm;
    }

    return {
      ...prevDetails,
      dateEdited: getCurrentDateTime(),
      region: farmDetails.farmRegion,
      financialYear: farmDetails.financialYear,
      farmId: farmDetails.supplier_no,
      surveyData: surveyDataWithoutGraphs,
      modules: farmDetails.modules,
      ...specificFields
    };

  };



  // Helper function to make Axios call
  const makeAxiosCall = async (urlSuffix, payload) => {
    try {
      const token = await acquireAccessToken(instance, accounts[0]);
      localStorage.setItem("accessToken", token);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/${urlSuffix}`, payload, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Response:', response.data);
      console.log(payload);
      return response;
    } catch (error) {
      console.error('An error occurred while performing Axios POST:', error);
    }
  };

  // Helper function to update payload based on status
  const updatePayloadForStatus = (payload, status, name) => {
    let updatedPayload = { ...payload, dateEdited: getCurrentDateTime() };
    switch (status) {
      case "REVIEW":
        updatedPayload.status = "REVIEW";
        updatedPayload.reviewerName = name;
        break;
      case "CANCELLED":
        updatedPayload.status = "CANCELLED";
        break;
      case "COMPLETE":
        updatedPayload.status = "COMPLETE";
        break;
      default:
        break;
    }
    return updatedPayload;
  };


  const handleSave = () => {    
    const urlSuffix = operation === "modify" ? "insertFarmSurveyData" : "insertFarmSurveyData";
    setOpenDialog(true);
    if (operation !== "modify") {
      farmDetails.farmSurveyId = farmDetails.supplier_no + getCurrentDateTime();
    }
    // Check if highestRevisionNumber is defined and is a string of 4 digits
    let validRevisionNumber = highestRevisionNumber && /^\d{4}$/.test(highestRevisionNumber);
    let newRevisionNumber;

    if (validRevisionNumber) {
      // Increment the revision number if it's valid
      newRevisionNumber = (parseInt(highestRevisionNumber, 10) + 1).toString().padStart(4, '0');
    } else {
      // Default to '0001' if highestRevisionNumber is undefined or invalid
      newRevisionNumber = '0001';
    }
    // Define a helper function to make the image upload call
    const uploadGraph = async (imageName, graphData) => {
      if (imageName.startsWith('farmPic_')) {
        if (!farmDetails.hasFarmPicChanged) {
          return true
        }
        else {
          setFarmDetails(prevDetails => ({
            ...prevDetails,
            hasFarmPicChanged: false
          }));
        }
      }
      const imagePayload = {
        "imageName": imageName,
        "imageDetails": graphData
      };
      console.log("Image Payload:", imagePayload);
      return makeAxiosCall("storeImage", imagePayload);
    };
    setPayload(prevDetails => {
      const specificFields = operation === "modify" ? {
        revisionNumber: newRevisionNumber,
        id: farmDetails.supplier_no + `_${newRevisionNumber}`,
        editedBy: accounts[0].name
      } : {
        creatorName: accounts[0].name,
        dateCreated: getCurrentDateTime(),
        id: farmDetails.supplier_no + `_${newRevisionNumber}`,
        schemaVersionNum: "001",
        surveyVersionNumber: "1",
        status: "DRAFT",
        farmSurveyId: farmDetails.farmSurveyId,
        revisionNumber: newRevisionNumber,
        editedBy: accounts[0].name
      };

      let updatedPayload = preparePayload(prevDetails, specificFields);

      // Update payload based on status
      updatedPayload = updatePayloadForStatus(updatedPayload, status, name);
      // setIsSaving(true);  // Set saving state to true at the beginning

      (async () => {
        try {
          const response = await makeAxiosCall(urlSuffix, updatedPayload);

          if (response) {
            setHighestRevisionNumber(newRevisionNumber);
            if (operation !== "modify") {
              farmDetails.farmSurveyId = updatedPayload.farmSurveyId;
              farmDetails.date_edited = updatedPayload.dateEdited;
              setOperation('modify');
            }

            const graphPaths = [
              { path: "HealthyCalves.colostrum.graph1", namePrefix: "healthyCalvesColostrumGraph1_" },
              { path: "HealthyCalves.colostrum.graph2", namePrefix: "healthyCalvesColostrumGraph2_" },
              { path: "HealthyCalves.colostrum.graph3", namePrefix: "healthyCalvesColostrumGraph3_" },
              { path: "HealthyCalves.colostrum.graph4", namePrefix: "healthyCalvesColostrumGraph4_" },
              { path: "HealthyCalves.calfPathways.graph1", namePrefix: "healthyCalvesCalfPathwaysGraph1_" },
              { path: "HealthyCalves.calfPathways.graph2", namePrefix: "healthyCalvesCalfPathwaysGraph2_" },
              { path: "Nitrogen.graph", namePrefix: "nitrogenGraph_" },
              { path: "Carbon.graph", namePrefix: "carbonGraph_" },
              { path: "Irrigation.graph", namePrefix: "irrigationGraph_" },
              { path: "farmPic", namePrefix: "farmPic_" },
              { path: "GeneralFarm.farmMap", namePrefix: "farmMap_" }
            ];

            const graphPromises = graphPaths.map(graph => {
              const parts = graph.path.split('.');
              let graphValue;

              switch (parts.length) {
                case 1:
                  graphValue = farmDetails[parts[0]];
                  break;
                case 2:
                  if (farmDetails.surveyData && farmDetails.surveyData[parts[0]]) {
                    graphValue = farmDetails.surveyData[parts[0]][parts[1]];
                  }
                  break;
                case 3:
                  if (farmDetails.surveyData && farmDetails.surveyData[parts[0]] && farmDetails.surveyData[parts[0]][parts[1]]) {
                    graphValue = farmDetails.surveyData[parts[0]][parts[1]][parts[2]];
                  }
                  break;
                default:
                  break;
              }

              if (graphValue && graphValue !== "") {
                return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_${newRevisionNumber}`, graphValue);
              }
              return Promise.resolve(true);
            });

            const graphResponses = await Promise.all(graphPromises);

            if (graphResponses.every(res => res)) {
              setOpenDialog(false);   // Close the dialog
              if (["CANCELLED", "REVIEW"].includes(status)) {
                setTimeout(() => {
                  window.location.href = '/';  // Redirect to homepage after a delay
                }, 1000);
              }
            } else {
              setOpenDialog(false);
              // Handle the situation where not all graphResponses are successful
            }
          } else {
            setOpenDialog(false);
          }
        } catch (error) {
          console.error("An error occurred during saving:", error);
          setOpenDialog(false);
          // Handle the error appropriately, possibly show an error message to the user
        } finally {
          setIsSaving(false);  // Reset saving state at the end of the save logic
        }

        // Check status and navigate if needed
        if (status === "COMPLETE" && !isSaving) {
          navigate('/ReportTemplate');
        }

      })();

      return updatedPayload;
    });
  };

  useEffect(() => {
    if (!isSaving) {
      setOpenDialog(false);
    }
  }, [isSaving]);



  function assignNext (e)  {

    if (status === "DRAFT") {
      setNext('/FarmDetails');
    }
    else if (status === "COMPLETE") {
      setNext('/ReportTemplate');
    }
    else if (status === "CANCELLED") {
      // setTimeout(() => {
      //               window.location.href = "/"; // Redirect to homepage after 5 seconds
      //           }, 3000); 
    }

  }

  useEffect(() => {
    assignNext(status);
  }, [status]);

  const farmStatus = [
    {
      value: 'DRAFT',
      label: 'EDIT',
    },
    {
      value: 'REVIEW',
      label: 'REVIEW',
    },
    {
      value: 'COMPLETE',
      label: 'COMPLETE',
    },
    {
      value: 'CANCELLED',
      label: 'CANCEL',
    },

  ];

  
  const [reviewers, setReviewers] = useState([]);
  const [filterReviewers, setFilterReviewers] = useState([]);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [selectedReviewer, setSelectedReviewer] = useState('');
  const dropdownRef = useRef(null);

  const [email, setEmail] = useState('');
  const [sendTo, setSendTo] = useState('');
  const [message, setMessage] = useState('');

  /*------------------------ Read .xlxs file -------------------------------*/

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('../../email_details.xlsx'); // Adjust the path accordingly
        const data = await response.arrayBuffer();
        // Parse the Excel data
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];        
        const jsonResult = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Assuming the first row contains headers
        const headers = jsonResult[0];
        const jsonData = jsonResult.slice(1).map((row) =>
          headers.reduce((acc, header, index) => {
            acc[header] = row[index];
            return acc;
          }, {})
        );

        const filteredData = jsonData.filter(value => JSON.stringify(value) !== '{}');

        setReviewers(filteredData);
      } catch (error) {
        console.error('Error fetching or parsing data', error);
      }
    };

    fetchData();
  }, []);
  /*------------------------ /Read .xlxs file -------------------------------*/
    useEffect(() => {
    if (searchTerm === '') {
      setFilterReviewers(reviewers);
    }
    if (searchTerm !== '') {
      const filtered = reviewers.filter(reviewer => {
        const searchValue = reviewer.user_name.toLowerCase()
        return searchValue.indexOf(searchTerm) > -1
      })
      setFilterReviewers(filtered);
    }
  }, [searchTerm])

    useEffect(() => {  
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

  const handleSearch = (event) => {
    const searchWord = event.target.value.toLowerCase();
    const searchEmailId = document.getElementById("user_email").value;
    setReviewOption(true)
    setSearchTerm(null);
    setName(event.target.value)    
    setSearchTerm(searchWord);

    if(searchWord.length > 0 && searchEmailId.length > 0){
      setSendMailBtn(true);
    } else {
      setSendMailBtn(false);
    }
  };

  const handleEmailSearch = (event) => {
    const searchEmailText = event.target.value.toLowerCase();
    if(searchEmailText.length > 0){
      setSendMailBtn(true);
    } else {
      setSendMailBtn(false);
    }
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setReviewOption(false)
    }
  };

  const handleItemClick = (item) => {
    const selectedValue = item.user_name;
    setSelectedReviewer(selectedValue);
    setReviewOption(false)
    const selectedReviewerData = reviewers.find((reviewer) => reviewer.user_name === selectedValue);
    if (selectedReviewerData) {
      setName(selectedValue)   
      setSearchTerm(selectedValue);
      setEmail(selectedReviewerData.user_email);
      setSendTo(selectedValue);
      setSendMailBtn(true);
    } else {
      setSearchTerm('');
      setEmail(''); // Clear the email if no corresponding reviewer is found
    }
    console.log('Selected item:', item.user_email);
  };


  const handleReviewerChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedReviewer(selectedValue);
    // Find the corresponding email based on the selected reviewer
    const selectedReviewerData = reviewers.find((reviewer) => reviewer.user_name === selectedValue);
    if (selectedReviewerData) {
      setEmail(selectedReviewerData.user_email);
    } else {
      setEmail(''); 
    }
  };

  const handleNoRecordFound = () => {    
      setReviewOption(false)    
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (status === "COMPLETE" || status === "REVIEW") {
      // Invoke the check function and get the result
      const hasIncompleteModules = checkSurveyData();
      if (hasIncompleteModules) {
        setOpenIncompleteDialog(true);
        return
      }
      else {    
          setOpenEmailDialog(true);
          const emailContent = '<p>Dear '+searchTerm+',<br><br>You are receiving this email as part of FEP review process.<br>Please review the below FEP.<br><br>FEP NO: <b>'+farmDetails.supplier_no+'</b><br><br>Please go to the FEP Web Portal <a href="https://farmenvironmentplanau.apps.fonterra.com/" target="_blank" rel="noopener noreferrer">here</a> to find '+farmDetails.supplier_no+', you can do this by searching the farm number.<br><br>Thanks</p>';
          setTimeout(async () => {            
            try {   
              const response = await axios.post(`${process.env.REACT_APP_LOGIC_APP_URL}`,{ 
              toemail: email,    
              subject: 'Do not reply - ACTION - '+farmDetails.supplier_no +' has been submitted for your review',              
              body: emailContent
              });
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setOpenEmailDialog(false);    
            console.log('Email sent:', data.message);
          } catch (error) {
            setOpenEmailDialog(false);     
            saveData();
            setSendMailBtn(false);
            console.error('Error sending email:', error.message);
          } finally {
            setOpenEmailDialog(false);
            saveData();
            setSendMailBtn(false);
          }
        }, 1500);
        return
      }
    }
};

const saveData =()=>{
  if (status === "COMPLETE" || status === "REVIEW") {
    // Invoke the check function and get the result
    const hasIncompleteModules = checkSurveyData();
    if (!hasIncompleteModules) {
      handleSave();
      return
    }
  }
  navigate(next);
}

  return (
    <div className="farmDetails-page">
      {console.log(farmDetails)}
      {console.log(generalFarm)}
      {console.log(healthyCalves)}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
          <div className="progressbar">
                 <ProgressBar currentStep={3}/>
           </div>
          </Grid>
          <Grid item xs={9}>
            <header className="farmDetails-header">
              <h1 className="farmDetails-h1">Status Change</h1>
              <div className="farmDetails-questions">
                <h4>FEP Actions and Guidelines</h4>
                <div>
                  <ul>
                    <li>
                      <strong>Edit:</strong> You can make changes to an existing FEP, including adding or changing benchmarking data and text/content of the FEP.
                    </li>
                    <li>
                      <strong>Review:</strong> Assign an existing FEP to another person (team member or staff at Richmond) to review the FEP before printing.
                    </li>
                    <li>
                      <strong>Complete:</strong> Mark the FEP as complete only when it's ready for printing. This action will send the FEP for printing. Note that if the FEP needs to be reviewed by someone else, choose the "Review" option.
                    </li>
                    <li>
                      <strong>Cancel:</strong> If you no longer want to complete the FEP (e.g., supplier changed their mind), mark the FEP as cancelled. This action won't delete the FEP; it will remain in the system in case you decide to complete it later.
                    </li>
                  </ul>
                </div>
                <h5 style = {{marginLeft: '1%'}}>Click on the drop-down below to change status</h5>
            <div className="benchmark"></div>
            <p>
            <TextField
              id="select-status"
              select
              defaultValue='DRAFT'
              sx={{ m: 1, width: '11ch'}}
              onChange={handleStatusChange}
            >
              {farmStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>

                </p>
                { status === "COMPLETE" && (
              <>
                <p>Click next to save PDF</p>
              </>
            )}
            { status === "CANCELLED" && (
                <>
                    { isCancelled && <p>Your Farm Environment Plan has been cancelled.</p> } {/* Update this line */}
    
                    <div className="back-next-button">
                      <Button variant="contained" href="#contained-buttons"  className="btn-save" style={{ backgroundColor:"#002D4E", marginTop:10, marginBottom:20}}  sx={{ ml: 1}} onClick={handleSave}>CANCEL</Button>
                    </div>



</>
                )}
                 { status === "REVIEW" && (
                  <>
                    <form onSubmit={sendEmail}>                     
                      <h5>Assign Reviewer<sup>*</sup> </h5>
                      <p>                       
                        <div className="menu-container" ref={dropdownRef}>
                          <TextField
                            required
                            type="text"
                            placeholder="Please Search Reviewer"
                            value={searchTerm}
                            onChange={handleSearch}
                            id="user_name"
                            name="user_name"
                            sx={{ m: 1, width: '36ch' }}
                            InputProps={{ style: { fontSize: 20 } }}
                            InputLabelProps={{ style: { fontSize: 20 } }}
                          />
                          {reviewOption && (
                            <div className="menu-list">
                              {filterReviewers.length > 0 ? (filterReviewers.map((item, index) => (
                                <MenuItem key={item.id} 
                                value={item.user_name} 
                                onClick={() => handleItemClick(item)} 
                                className="menu-item" 
                                sx={{ m: 1, width: '36ch' }}
                                  InputProps={{ style: { fontSize: 20 } }}
                                  InputLabelProps={{ style: { fontSize: 20 } }}>
                                  {item.user_name}
                                </MenuItem>
                              ))):<MenuItem
                              className="menu-item" 
                              onClick={handleNoRecordFound}
                              sx={{ m: 1, width: '36ch' }}
                                InputProps={{ style: { fontSize: 20 } }}
                                InputLabelProps={{ style: { fontSize: 20 } }}>
                                  No record found
                              </MenuItem>}
                            </div >
                          )}
                        </div>
                      </p>
                      <h5> Reviewer Email<sup>*</sup> </h5>
                      <p>
                      <TextField
                            
                            type="text"                            
                            value={email}                           
                            id="user_email"
                            name="user_email"
                            sx={{ m: 1, width: '36ch' }}
                            InputProps={{ style: { fontSize: 20 } }}
                            InputLabelProps={{ style: { fontSize: 20 } }}
                        />                       
                          </p>
                      {submitted && <p>Your Farm Environment Plan has been assigned for review.</p>}

                     
                        <div className="back-next-button">                           
                          {!sendMailBtn && <Button variant="contained" disabled href="#contained-buttons" className="btn-save" style={{ backgroundColor: "#d3d3d3",color: "#555", cursor: "not-allowed", marginTop: 10, marginBottom: 20 }} sx={{ ml: 1 }}>Send Mail</Button>}
                          {sendMailBtn && <Button variant="contained" href="#contained-buttons" className="btn-save" style={{ backgroundColor: "#002D4E", marginTop: 10, marginBottom: 20 }} sx={{ ml: 1 }} onClick={sendEmail}>Send Mail</Button>}
                        </div>
                    </form> 
                  </>
                )}
              </div>
              {!["REVIEW", "CANCELLED"].includes(status) && (
                <div className="back-next-button">
                <Back back = {prev} onClick={() => navigate(prev)}/>
                <Next next={next} onClick={handleNextClick} />
                  <div className="btn-text-left">

                  </div>
                </div>
              )}

            </header>
          </Grid>
        </Grid>
      </Box>

      {/* Set Status Dialog */}
      <Dialog
        open={openDialog}
        aria-labelledby="saving-dialog-title"
        aria-describedby="saving-dialog-description"
      >
        <DialogTitle id="saving-dialog-title">{"Changing status..."}</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
            Please wait while we change the status.
          </DialogContentText>
          {/* Centered spinner with space above */}
          <CircularProgress size={40} style={{ marginTop: '10px' }} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openEmailDialog}
        aria-labelledby="saving-dialog-title"
        aria-describedby="saving-dialog-description"
      >
        <DialogTitle id="saving-dialog-title">{"Email"}</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
          Sending Email ...
          </DialogContentText>
          {/* Centered spinner with space above */}
          <CircularProgress size={40} style={{ marginTop: '10px' }} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openIncompleteDialog}
        onClose={() => setOpenIncompleteDialog(false)}
        aria-labelledby="incomplete-modules-dialog-title"
        aria-describedby="incomplete-modules-dialog-description"
      >
        <DialogTitle id="incomplete-modules-dialog-title">{"Incomplete Modules"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="incomplete-modules-dialog-description">
            The following module(s) have questions that need to be completed:
          </DialogContentText>
          <List>
            {modulesToReview.map((module, index) => (
              <ListItem key={index}>
                <ListItemText primary={module} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenIncompleteDialog(false)}>Close</Button>
        </DialogActions>
        </Dialog>
    
    </div>
  );

}