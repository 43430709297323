import React, { useLayoutEffect,useCallback, useRef,useState,useEffect } from 'react';
import Button from "@mui/material/Button";
import Papa from "papaparse";
import { useDropzone } from 'react-dropzone';
import Plot from 'react-plotly.js';
import * as  htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { AppRunTimeContext } from '../Context/index'; 
import { useContext } from 'react';
// import Plotly from 'plotly.js';
//Package for uploading csv file: https://www.npmjs.com/package/react-dropzone

//Package for plot: https://plotly.com/javascript/line-charts/


export const BellCarbon = ({dataimport, onImageGenerated, runCalculations}) => {
  const [parsedCsvData, setParsedCsvData] = useState([]); //set processed data from csv file
  const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length; //calculate average
  const [Mean, setmean] =  useState(1); //store mean
  const [isshow, setisshow] = useState(false); //show plot
  const [triggerImageGeneration, setTriggerImageGeneration] = useState(false);

  useEffect(() => {
    if (isshow) {
        new Promise(resolve => setTimeout(() => resolve(htmlToImage.toPng(graphRef.current)), 1000))
            .then((dataUrl) => {
                onImageGenerated(dataUrl);  // Send the data URL to the parent component
            })
            .catch((error) => {
                console.error('Error converting to image:', error);
            });
    }
  }, [triggerImageGeneration, isshow]);
  const [Std, setstd] =  useState(1); //store standard deviation
  const [bellXValues, setBellXValues] = useState([]); //store x values for graph
  const [bellYValues, setBellYValues] = useState([]); //store y values for graph
 
  const graphRef = useRef(null);
  const [imageDataUrl, setImageDataUrl] = useState('');
  const {setCarbon, carbon} = useContext(AppRunTimeContext)


  //function to get csv file
  const load = function(){

        // First, run the Calculations function
        runCalculations();

        // Toggle the triggerImageGeneration state
        setTriggerImageGeneration(prev => !prev)

        fetch( './EIDATA.csv' )
            .then( response => response.text() )
            .then( responseText => {
                setisshow(true);
                parseFile( responseText );
            });
    };



  //function to  get csv file data 
  const parseFile = file => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: results => {
        setParsedCsvData(results.data)
        processData();
      },
    });
  };


                                                                                                                                                                                       

  

  //calculating x  values
  useEffect(() => { 
    processData();
    return () => {}}, [parsedCsvData])
    useEffect(() => {
      // defining chart limits between which the graph will be plotted
      let lcl = Mean - Std * 6;
      let ucl = Mean + Std * 6;
    
      let ticks = [lcl];
      let steps = 100; // steps corresponds to the size of the output array
      let stepSize = Math.round(((ucl - lcl) / steps) * 10000) / 10000;
      let tickVal = lcl;
      for (let i = 0; i <= steps; i++) {
        ticks.push(Math.round(tickVal * 10000) / 10000); // rounding off to 4 decimal places
        tickVal = tickVal + stepSize;
      }
      setBellXValues(ticks); //array for X values
    }, [Mean, Std]);

  //calculating y  values
  useEffect(() => {
    // Using PDF function from vega-statistics instead of importing the whole library
    const densityNormal = (value, mean, stdev) => {
    const SQRT2PI = Math.sqrt(2 * Math.PI);
    stdev = (stdev == null) ? 1 : stdev;
    const z = (value - (mean || 0)) / stdev;
    return Math.exp(-0.5 * z * z) / (stdev * SQRT2PI);
  };
  
  let YValues = bellXValues.map((item) => {
    if (Mean === null || Std === undefined) {
      return null;
    } else {
      const pdfValue = densityNormal(item, Mean, Std);
      return pdfValue === Infinity ? null : pdfValue;
    }
  });
  setBellYValues(YValues); // array for Y values
  }, [bellXValues]);
    


  //function to process data to separate columns
  function processData() {
    var temp = Object.values(parsedCsvData);
    var temparr = [];

    temp.map((parsedData, index) => (
        temparr.push(parsedData['EI ranked'])
    ))
    var mean = average(temparr);//calculate mean
    setmean(mean);
    var arr = temparr.map((k)=>{
      return (k - mean) ** 2
    })
    // Calculating the sum of updated array
    let sum = arr.reduce((acc, curr)=> acc + curr, 0);
    
    // Calculating the variance
    let variance = sum / arr.length;
    let std = Math.sqrt(variance)
    setstd(std) //calculate standard deviation
  }

  return (
    <><>
    <div>
      <h4>Carbon Benchmark Plot</h4>
    </div>
    <div className="btn-save">
    <Button variant="contained"  style={{ backgroundColor:"#002D4E"}} onClick={ load }>Calculate</Button>
    </div>
    
    </>
    {/*Plot */}
    { isshow &&  
    <div ref={graphRef}>
    <Plot 
      divId = "carbonGraph"
      data = {[{ name: '"Emissions intensity (Kg CO<sup>2</sup>/kg FPCM)"', marker: {color: '#76B82A'},type:'scatter', line: {shape: 'spline'},fill: 'toself',fillcolor: '#76B82A',  x: bellXValues, y:bellYValues},]}
      config={{ displayModeBar: false }}
      layout={ {width: 1000, height: 500, hovermode: false,  yaxis: {showline: true, showticklabels:false, showgrid:false},xaxis: {title: "Emissions intensity (Kg CO<sub>2</sub>/kg FPCM)", showgrid:false, mirror: 'ticks',zeroline: true,showline: true,tickmode: "array",showticklabels:true, tickvals: [0.6, 0.8, 1.0, 1.2, 1.4, 1.6, 1.8, 2.4, 2.6]},
        annotations: [
          {x: dataimport, y: 3.8, text: 'Your Value',showarrow: false, textposition: 'top right',xanchor: 'right',yanchor: 'top',  font: {color: 'rgb(40, 114, 79)'}},
          {x: 1.03, y: 3.6, text: 'AU Average',showarrow: false, textposition: 'top right', xanchor: 'right',yanchor: 'top', font: {color: 'rgb(41, 143, 194)'}},
          {x: 2.5, y: 3.6, text: 'Global Average',showarrow: false, textposition: 'right', font: {color: 'rgb(102,63,57)'}}],
        shapes: [{
        name: 'Your Value',
        type: 'line',
        x0: dataimport,
        y0: 0,
        x1: dataimport,
        y1: 3.7,
        line: {
          color: 'rgb(40, 114, 79)',
          width: 2, dash: 'dot'
        }}, 
        {name: 'AU Average',
        type: 'line',
        x0: 1.03,
        y0: 0,
        x1: 1.03,
        y1: 3.5,
        line: {
          color: 'rgb(41, 143, 194)',
          width: 2, dash: 'dot'
        }}, 
        {name: 'Global Average',
        type: 'line',
        x0: 2.5,
        y0: 0,
        x1: 2.5,
        y1: 3.5,
        line: {
          color: 'rgb(102,63,57)',
          width: 2, dash: 'dot'
        }}
      ], 
    }}/>
      </div>
  }

  </>
  
  );
} 

export default BellCarbon; 