import * as React from "react";
import Button from "@mui/material/Button";
import { useState, useEffect, useMemo, useRef, useContext } from "react";import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import useModuleLink from '../hooks/moduleLink';
import Next from "./Next";
import Back from "./Back";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Textarea from '@mui/joy/Textarea';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AppRunTimeContext } from '../Context/index'; 
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {addScore, subtractScore, GetSuggestion, practices, wantImprovement} from './Utilities.jsx'
import BellCarbon from "./BellCarbon";
import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import acquireAccessToken from "../accessToken"
import carbon_option1 from '../images/carbonoption1.PNG'; // Adjust the path as needed
import carbon_option2 from '../images/carbonoption2.PNG';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ProgressBar from "./ProgressBar";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// Module questions with corresponding subquestions. 
//Each checkbox(option) are given a unique key with the initial of the module - question no - subquestion no - checkbox no.
//For instance, XX-00-00-00.
//The value for each option is decided based on how good the practices are.
const QArray = [
  { number: 1, //Question no
    question: "As a cow eats more and produces more milk, the energy needed for maintenance is diluted lowering emission intensity (EI). Are your cows achieving >90% of their liveweight in kg of MS?", //Question content
    section: [
      {id:"1.1",
      statement: "",  //sub question
      option: {
        text: ["Above 90% MS/kg LW","At/just below 90% MS/kg LW","Producing at the farm target %","Well below 90% MS/kg LW","Not monitored"],  //options for checkbox
        value: [8, 5, 5, 1, 0],  // assigned value for each option
        key: ["CA-01-1-1","CA-01-1-2", "CA-01-1-3", "CA-01-1-4","CA-01-1-5"] 
        //key for each checkbox, name as: module's initial-Question no-SubQ no-Checkbox no
      }},
    ]},
   {
    number: 2, 
    question: "Increasing reproductive performance will decrease your emissions as there is less DMI requirement for young stock and less methane emissions. Have you been able to improve your reproductive performance?", 
    section: [
      {id:"2.1",
      statement:"2.1. In-calf rates",
      option: {
        text: ["Seasonal Calving","Year-round Calving","Exceeds recommended measures", "Not aware of in-calf rates"],
        value: [5, 3, 8, 0],
        key: ["CA-02-1-1","CA-02-1-2", "CA-02-1-3", "CA-02-1-4"] 
      }},
      {id:"2.2",
      statement:"2.2. Herd fertility program",
      option: {
        text: ["Program is benchmarked and is continually reviewed and improved","Program is benchmarked but does not meet requirement (it is identifying areas of improvement)","No monitoring"],
        value: [5, 3, 0],
        key: ["CA-02-2-1", "CA-02-2-2", "CA-02-2-3"] 
      }},
  ]},
  {
  number: 3, 
  question: "Direct leaching and nitrous oxide emissions can be minimised by avoiding applying fertiliser to waterlogged soils. Do you have an improved drainage program for your farm?", 
  section: [
    {id:"3.1",
    statement: "",
    option: {
      text: ["System is constantly improved with effective and efficient technologies","System is set and maintained in good order","System have deteriorated in recent years","No drainage system"],
      value: [8, 5, 1, 0],
      key: ["CA-03-1-1","CA-03-1-2", "CA-03-1-3", "CA-03-1-4"] 
    }},
  ]},
  {
    number: 4, 
    question: "Balancing the cow’s diets for nitrogen helps minimise nitrogen loss to the environment. Do you use low nitrogen feed or grow low nitrogen forages?", 
    section: [
      {id:"4.1",
      statement: "",
      option: {
        text: ["Yes, cows' diet is balanced by incorporating low nitrogen feed source (e.g. maize, silage or wheat) when the diet is in surplus or adding additional protein source (e.g. canola) when in deficit"],
        value: [8, 4, 0],
        key: ["CA-04-1-1","CA-04-1-2", "CA-04-1-3"] 
      }},
    ]},
    {
      number: 5, 
      question: "Using effluent as a fertiliser source and minimising build-up of manures helps limit nitrous oxide emissions. Do you have an active effluent management plan? ", 
      section: [
        {id:"5.1",
        statement: "",
        option: {
          text: ["Effluent is present including applying effluent and removing solids regularly","Effluent plan is present but not done regularly","No effluent plan is present"],
          value: [8, 5, 0] ,
          key: ["CA-05-1-1","CA-05-1-2", "CA-05-1-3"] 
        }},
      ]},
    {
      number: 6, 
      question: "Sequestering carbon in the soil can help offset GHG emissions. Are you aiming to increase soil carbon levels on your farm through practices such as reduced tillage, use of riparian buffers, deeper rooted species selection, improved grazing etc.?", 
      section: [
        {id:"6.1",
        statement: "6.1. Carbon monitoring",
        option: {
          text: ["Actively monitoring soil carbon (e.g. actively testing for carbon levels in the soil) ","Making conscious decisions to build soil carbon through change management practices but not actively monitoring ","Not monitoring and practices most likely leading to declining in levels of soil carbon"],
          value: [5, 3, 0],
          key: ["CA-06-1-1","CA-06-1-2", "CA-06-1-3"]  
        }},
        {id:"6.2",
        statement: "6.2. Grazing and cropping practice",
        option: {
          text: ["New grazing and cropping practices have been adopted to conserve soil carbon","No grazing and cropping practices to conserve soil carbon"],
          value: [4, 0],
          key: ["CA-06-2-1","CA-06-2-2"]  
        }},
      ]},
    {
      number: 7, 
      question: "Planting trees can help ‘offset’ emissions from your farm business without impacting on production. Do you strategically plant trees on the farm?", 
      section: [
        {id:"7.1",
        statement: "",
        option: {
          text: ["An active tree plantation plan is present","No active plan is present but current vegetation is maintained","Vegetation have been removed with plan to restore","Vegetation have been removed without plan to restore"],
          value: [8, 5, 2, 0],
          key: ["CA-07-1-1","CA-07-1-2", "CA-07-1-3", "CA-07-1-4"]  
        }},
      ]},
    {
      number: 8, 
      question: "On average energy and fuel account for 16% of the emissions on farm. Do you conduct any energy audits and/or use renewable energy sources?", 
      section: [
        {id:"8.1",
        statement: "8.1. Energy audits",
        option: {
          text: ["Performed with plan to reduce energy consumption","Performed for tracking only","Not performed"],
          value: [5, 3, 0] ,
          key: ["CA-08-1-1","CA-08-1-2", "CA-08-1-3"] 
        }},
        {id:"8.2",
        statement: "8.2. Renewable energy - RE",
        option: {
          text: ["RE sources are installed","Interested in installing RE sources","No plan to install RE sources"],
          value: [5, 3, 0] ,
          key: ["CA-08-2-1","CA-08-2-2", "CA-08-2-3"] 
        }},
      ]},
      {
        number: 9, 
        question: "Research is accelerating the development of feed additives that can mitigate methane. Have you considered adopting low emission feed additives?", 
        section: [
          {id:"9.1",
          statement: "",
          option: {
            text: ["Interested and willing to adopt","Interested with conditions","No interest"],
            value: [8, 5, 0],
            key: ["CA-09-1-1","CA-09-1-2", "CA-09-1-3"] 
          }},
      ]},
]


export default function Carbon() {

  const { instance, accounts } = useMsal();

  const {steps} = useContext(AppRunTimeContext);

  const {setCarbon, carbon} = useContext(AppRunTimeContext)
  const { farmDetails, setFarmDetails, payload, setPayload, operation, setOperation, highestRevisionNumber, setHighestRevisionNumber } = useContext(AppRunTimeContext);
  // Use the dictionary from generalFarm[images] to replace image filenames
  const formattedValue = (farmDetails.surveyData['Carbon'] ? farmDetails.surveyData['Carbon'].commentary : "").replace(/carbonCommentary\S+/g, match => {
    console.log(match)
    const base64 = carbon.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [value, setValue] = useState(formattedValue);

      const formattedValueAction = (farmDetails.surveyData['Carbon'] ? farmDetails.surveyData['Carbon'].action : "").replace(/carbonAction\S+/g, match => {
    console.log(match)
    const base64 = carbon.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [valueAction, setValueAction] = useState(formattedValueAction);

  
  var ScoresArray = carbon.scoreArray
  const {setNextPrev, getNext, getPrev} = useModuleLink();
  
  setNextPrev("/Carbon");
  let next = getNext();
  let prev = getPrev();

  const stepIndex = steps.findIndex(step => step.link === '/Carbon');

  const [postDisplay, setPostDisplay] = useState(true);
  const [state, setState] = React.useState(null);
  const divDisplay = true;

  const [openDialog, setOpenDialog] = useState(false);
  const [openSaveSuccessDialog, setOpenSaveSuccessDialog] = React.useState(false);
  const [openSaveFailedDialog, setOpenSaveFailedDialog] = React.useState(false);
  const [openSavingDialog, setOpenSavingDialog] = React.useState(false);
  const [isTextEdited, setIsTextEdited] = useState(false);

  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);
  const [unsavedChangesNavigationPath, setUnsavedChangesNavigationPath] = useState(null);
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] = useState(false);
  const stateRef = useRef();
  const [isDataEdited, setIsDataEdited] = useState(false);
  const [openImageFormatDialog, setOpenImageFormatDialog] = React.useState(false);
  const [openImageSizeDialog, setOpenImageSizeDialog] = React.useState(false);

  useEffect(() => {
    stateRef.current = {
      farmDetails,
      value,
      valueAction
    };
  }, [farmDetails, value, valueAction]);
  const navigate = useNavigate();
    
  const handleImageFormatDialogClose = () => {    
    setOpenImageFormatDialog(false);   
  };

  const handleImageSizeDialogClose = () => {
    setOpenImageSizeDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSaveSuccessDialogClose = () => {
    setOpenSaveSuccessDialog(false);
  };

  const handleSaveFailedDialogClose = () => {
    setOpenSaveFailedDialog(false);
  };
      const handleCharacterDialogClose = () => {
    setIsCharacterLimitExceeded(false);
  };

  const handleNextClick = () => {
    // const questions = farmDetails.surveyData['Carbon'].questions;
    // const graphData = farmDetails.surveyData['Carbon'].graph;

    // for (let i = 0; i < questions.length; i++) {
    //   if (!questions[i].practice || !questions[i].focusCheck) {
    //     console.log('open dialog')
    //     setOpenDialog(true);
    //     return;
    //   }
    // }

    
  // // Check if the graph data is still at its default value
  // if (graphData === "") {
  //   console.log('open dialog - graph data not set');
  //   setOpenDialog(true);
  //   return;
  // }

    // If all conditions are met, navigate to the next page.
    navigate(next);
  };
  


  useEffect(() => {


    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
        window.location.href = '/'; // Redirect to home page
    }


    if (farmDetails.surveyData['Carbon']) {
      // Your code here will run after the component has mounted
        // Loop through the surveyData[1].questions array
        farmDetails.surveyData['Carbon'].questions.forEach((question, index) => {
          // Update the generalFarm.practices array based on the practice in each question
          carbon.practices[index] = question.practice;
          carbon.focusArea[index] = question.focusCheck
        });
          console.log(carbon.practices)
          // console.log(tempModule)
          // console.log(farmDetails)
      }
    else {
      if(!(farmDetails.surveyData['Carbon'])) {
      setFarmDetails(prevDetails => ({
            ...prevDetails,
            surveyData: {
              ...prevDetails.surveyData,
              details: {
                farmOwner: farmDetails.farm_owner,
                planOwner: farmDetails.plan_owner,
                farmAddressLat: farmDetails.farmAddressLat,
                farmAddressLong: farmDetails.farmAddressLong,
                financialYear: farmDetails.financialYear,
                currentYear: "2023",
                futureYear: "2028",
                farmAddress: farmDetails.farmAddress
              },
              Carbon: {
                module: "Carbon",
                commentary: "",
                action: "",
                approach: "",
                focus: "",
                scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                focusAdditional: "",
                netGHG: "",
                vehicleEquivalent: "",
                GHGEmissionsIntensity: "",
                carbonPage: 0,
                graph: "",
                QuestionMapping: [
                  {
                    Q1_3: "As a cow eats more and produces more milk, the energy needed for maintenance is diluted lowering emission intensity (EI). Are your cows achieving >90% of their liveweight in kg of MS?",
                    Q2_3: "Increasing reproductive performance will decrease your emissions as there is less DMI requirement for young stock and less methane emissions. Have you been able to improve your reproductive performance?",
                    Q3_3: "Direct leaching and nitrous oxide emissions can be minimised by avoiding applying fertiliser to waterlogged soils. Do you have an improved drainage program for your farm?",
                    Q4_3: "Balancing the cow’s diets for nitrogen helps minimise nitrogen loss to the environment. Do you use low nitrogen feed or grow low nitrogen forages?",
                    Q5_3: "Using effluent as a fertiliser source and minimising build-up of manures helps limit nitrous oxide emissions. Do you have an active effluent management plan?",
                    Q6_3: "Sequestering carbon in the soil can help offset GHG emissions. Are you aiming to increase soil carbon levels on your farm through practices such as reduced tillage, use of riparian buffers, deeper rooted species selection, improved grazing etc.?",
                    Q7_3: "Planting trees can help ‘offset’ emissions from your farm business without impacting on production. Do you strategically plant trees on the farm?",
                    Q8_3: "On average energy and fuel account for 16% of the emissions on farm. Do you conduct any energy audits and/or use renewable energy sources?",
                    Q9_3: "Research is accelerating the development of feed additives that can mitigate methane. Have you considered adopting low emission feed additives?"
                  }
                ],
                questions: [
                  {
                    subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                  },
                {
                    subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                      [
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                }
              ]
            }
          }
    }));
  }
    }

  }, []);  // Empty dependency array means this useEffect runs once after initial render

  // Display the graph
  function Calc(e)  {
    carbon.vehicleEquivalent = Math.round((carbon.netGHG * (10**6)) / 1988350);
    farmDetails.surveyData['Carbon'].vehicleEquivalent = Math.round((carbon.netGHG * (10**6)) / 1988350);
    setIsDataEdited(true)
   }  
 
  // Check whether the checkboxes are ticked
  const getChecked = (key) => {
    if (carbon.carbonQuestions.get(key)) 
      return true;
    else
      return false; 
  }

  const handleNavigationAttempt = (navigationPath) => {
    if (isTextEdited)  {
      setUnsavedChangesNavigationPath(navigationPath); // Store the intended navigation path
      setOpenUnsavedChangesDialog(true); // If text is edited, show the warning dialog
    } else {
      navigate(navigationPath); // Otherwise, navigate immediately
    }
  };

// Called when a question is clicked to expand/collapse
  const handleClick = (post) => { 
    const objectCopy = {
      ...postDisplay
    };
    objectCopy[post] = !objectCopy[post];
    setPostDisplay(objectCopy);
  };

  useEffect(() => {
    // Function to be executed
    const intervalFunction = () => {
      console.log(operation);
      if (operation === "modify") {
        handleSave('autosave');
        console.log('This function runs every 30 seconds');
      }
      // Add your logic here
    };
  
    // Set the interval
    const interval = setInterval(intervalFunction, 120000); 
  
    // Clear interval on component unmount or when operation changes
    return () => clearInterval(interval);
  }, [operation]); // Include operation in the dependency array
  // Called when a checkbox is ticked/unticked
  const handleChange = (event) => {


    const postIndex = event.target.parentNode.dataset.post;
    const subQIndex = event.target.parentNode.dataset.subq;
    const optIndex = event.target.parentNode.dataset.opt;

    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });


    {console.log(postIndex)}
    {console.log(subQIndex)}
    {console.log(optIndex)}

    farmDetails.surveyData['Carbon'].questions[postIndex].subQuestions[subQIndex][optIndex] = event.target.checked;
    setIsDataEdited(true)


    //When checkbox is ticked, add score to module score array and set checkbox as ticked
    if (event.target.checked){
      ScoresArray = addScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue)
      carbon.scoreArray = ScoresArray
      carbon.carbonQuestions.set(event.target.name, true);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            Carbon: {
                ...prevFarmDetails.surveyData.Carbon,
                scoreArray: ScoresArray
            }
        }
    }));
    }
    //When checkbox is unticked, deduct score to module score array and set checkbox as unticked
    else{
      ScoresArray = subtractScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue)
      carbon.scoreArray = ScoresArray
      carbon.carbonQuestions.delete(event.target.name);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            Carbon: {
                ...prevFarmDetails.surveyData.Carbon,
                scoreArray: ScoresArray
            }
        }
    }));
    }
  };

  // Update practice selected by user 
  function updatePractice(e) {
    carbon.practices[e.target.name] = e.target.value
    // Create a deep copy of the farmDetails object
    const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
    // Update the nested property
    newFarmDetails.surveyData['Carbon'].questions[e.target.name].practice = e.target.value;
    // Update the state
    setFarmDetails(newFarmDetails);
    setIsDataEdited(true)

  }
  
  
  // Update whether user would like to focus on that area
  function updateFocus(e) {
    carbon.focusArea[e.target.name] = e.target.value
    // Create a deep copy of the farmDetails object
    const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
    // Update the nested property
    newFarmDetails.surveyData['Carbon'].questions[e.target.name].focusCheck = e.target.value;
    // Update the state
    setFarmDetails(newFarmDetails);
    setIsDataEdited(true)

  }
  

  const handleImageGenerated = (imageData) => {
    carbon.graph = imageData;
    farmDetails.surveyData['Carbon'].graph = imageData
    setIsDataEdited(true)

    console.log(imageData)
  };

const isAEDT = (date) => {
  const year = date.getFullYear();
  const startDST = new Date(Date.UTC(year, 9, 1)); // October 1st
  const endDST = new Date(Date.UTC(year, 3, 1)); // April 1st

  // Find the first Sunday in October
  startDST.setUTCDate(1 + (7 - startDST.getUTCDay()) % 7);
  
  // Find the first Sunday in April
  endDST.setUTCDate(1 + (7 - endDST.getUTCDay()) % 7);

  return (date >= startDST && date < endDST);
};


  const getCurrentDateTime = () => {
    const nowUTC = new Date(Date.now());

    const offset = isAEDT(nowUTC) ? 12 : 11; // UTC+13 if Daylight Saving Time, otherwise UTC+12
  
    const aet = new Date(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(),
                         nowUTC.getUTCHours() + offset, nowUTC.getUTCMinutes(), nowUTC.getUTCSeconds());
  
    const year = aet.getFullYear();
    const month = String(aet.getMonth() + 1).padStart(2, '0');
    const day = String(aet.getDate()).padStart(2, '0');
    const hour = String(aet.getHours()).padStart(2, '0');
    const minute = String(aet.getMinutes()).padStart(2, '0');
    const second = String(aet.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}${hour}${minute}${second}`;
  };


// Helper function to prepare payload
const preparePayload = (prevDetails, specificFields = {}) => {
  const currentState = stateRef.current;
  const { surveyData } = currentState.farmDetails;

  const surveyDataWithoutGraphs = { ...currentState.farmDetails.surveyData };

  if (surveyData.Carbon) {
    const { graph: carbonGraph, ...rest } = surveyData.Carbon;
    surveyDataWithoutGraphs.Carbon = rest;
  }

  if (surveyData.Nitrogen) {
    const { graph: nitrogenGraph, ...rest } = surveyData.Nitrogen;
    surveyDataWithoutGraphs.Nitrogen = rest;
  }

  if (surveyData.Irrigation) {
    const { graph: irrigationGraph, ...rest } = surveyData.Irrigation;
    surveyDataWithoutGraphs.Irrigation = rest;
  }

  if (surveyData.HealthyCalves) {
    const { colostrum, calfPathways, ...rest } = surveyData.HealthyCalves;
  
    // Remove graph1 and graph2 from colostrum
    const { graph1: colostrumGraph1, graph2: colostrumGraph2,graph3: colostrumGraph3, graph4: colostrumGraph4, ...colostrumRest } = colostrum;
    
    // Remove graph1 and graph2 from calfPathways
    const { graph1: calfPathwaysGraph1, graph2: calfPathwaysGraph2, ...calfPathwaysRest } = calfPathways;
  
    surveyDataWithoutGraphs.HealthyCalves = {
      ...rest,
      colostrum: colostrumRest,
      calfPathways: calfPathwaysRest
    };

    if (surveyData.GeneralFarm) {
      const { farmMap, ...restGeneralFarm } = surveyData.GeneralFarm;
      surveyDataWithoutGraphs.GeneralFarm = restGeneralFarm;
    }
  }

  return {
    ...prevDetails,
    dateEdited: getCurrentDateTime(),
    region: farmDetails.farmRegion,
    status: "DRAFT",
    financialYear: farmDetails.financialYear,
    farmId: farmDetails.supplier_no,
    surveyData: surveyDataWithoutGraphs,
    modules: farmDetails.modules,
    ...specificFields
  };
};



// Helper function to make Axios call
const makeAxiosCall = async (urlSuffix, payload) => {
  try {
    const token = await acquireAccessToken(instance, accounts[0]);
    localStorage.setItem("accessToken", token);
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/${urlSuffix}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('Response:', response.data);
    console.log(payload);
    return response;
  } catch (error) {
    console.error('An error occurred while performing Axios POST:', error);
    return null;  // Return null if there's an error
  }
};



const handleSave = (saveType) => {
  const currentState = stateRef.current;

  const urlSuffix = saveType === "autosave" ? "modifyFarmSurveyData" : "insertFarmSurveyData";
  saveType !== "autosave" && setOpenSavingDialog(true);

  if (operation !== "modify") {
    farmDetails.farmSurveyId = farmDetails.supplier_no + getCurrentDateTime();
  }
  // Check if highestRevisionNumber is defined and is a string of 4 digits
  let validRevisionNumber = highestRevisionNumber && /^\d{4}$/.test(highestRevisionNumber);
  let newRevisionNumber;

  if (saveType !== 'autosave') {
    if (validRevisionNumber) {
      // Increment the revision number if it's valid
      newRevisionNumber = (parseInt(highestRevisionNumber, 10) + 1).toString().padStart(4, '0');
    } else {
      // Default to '0001' if highestRevisionNumber is undefined or invalid
      newRevisionNumber = '0001';
    }
  }

   
      const regex1 = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
  let match1;
  const images1 = [];

  while ((match1 = regex1.exec(currentState.value)) !== null) {
    const imageName = saveType==='autosave' ? `carbonCommentary${images1.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `carbonCommentary${images1.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
    images1.push({ base64: match1[1], name: imageName });
  }

  console.log(images1);

  let modifiedText1 = currentState.value;

  // Replace base64 values with image names
  for (let i = 0; i < images1.length; i++) {
    modifiedText1 = modifiedText1.replace(images1[i].base64, images1[i].name);
  }

  // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
  modifiedText1 = modifiedText1.replace(/<img src="([^"]+)"[^>]*>/g, (match1, p1) => {
    return p1 + '\n';  // Add a newline character after the image name
  });

  // First, handle the <li> tags by converting them to bullet points
  modifiedText1 = modifiedText1.replace(/<li[^>]*>/gi, '• ');
  modifiedText1 = modifiedText1.replace(/<\/li>/gi, '\n');

  // Then, remove the <ul> and </ul> tags as they are no longer necessary
  modifiedText1 = modifiedText1.replace(/<\/?ul[^>]*>/gi, '\n');

  // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
  modifiedText1 = modifiedText1.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

  // Convert inline elements to spaces or remove them
  modifiedText1 = modifiedText1.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

  // Convert <br> tags to newlines
  modifiedText1 = modifiedText1.replace(/<br[^>]*>/gi, '\n');

  // Remove other HTML tags
  modifiedText1 = modifiedText1.replace(/<\/?[^>]+(>|$)/g, '').trim();

  // Replace multiple newlines with a single newline
  modifiedText1 = modifiedText1.replace(/\n+/g, '\n').trim();

  // Replace multiple spaces with a single space
  modifiedText1 = modifiedText1.replace(/ +/g, ' ').trim();
  
  carbon.commentary = modifiedText1;
  currentState.farmDetails.surveyData['Carbon'].commentary = modifiedText1;


      const regex2 = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
  let match2;
  const images2 = [];

  while ((match2 = regex2.exec(currentState.valueAction)) !== null) {
    const imageName = saveType==='autosave' ? `carbonAction${images2.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `carbonAction${images2.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
    images2.push({ base64: match2[1], name: imageName });
  }

  console.log(images2);

  let modifiedText2 = currentState.valueAction;
  console.log(modifiedText2)

  // Replace base64 values with image names
  for (let i = 0; i < images2.length; i++) {
    modifiedText2 = modifiedText2.replace(images2[i].base64, images2[i].name);
  }

  // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
  modifiedText2 = modifiedText2.replace(/<img src="([^"]+)"[^>]*>/g, (match2, p1) => {
    return p1 + '\n';  // Add a newline character after the image name
  });

  // First, handle the <li> tags by converting them to bullet points
  modifiedText2 = modifiedText2.replace(/<li[^>]*>/gi, '• ');
  modifiedText2 = modifiedText2.replace(/<\/li>/gi, '\n');

  // Then, remove the <ul> and </ul> tags as they are no longer necessary
  modifiedText2 = modifiedText2.replace(/<\/?ul[^>]*>/gi, '\n');

  // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
  modifiedText2 = modifiedText2.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

  // Convert inline elements to spaces or remove them
  modifiedText2 = modifiedText2.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

  // Convert <br> tags to newlines
  modifiedText2 = modifiedText2.replace(/<br[^>]*>/gi, '\n');

  // Remove other HTML tags
  modifiedText2 = modifiedText2.replace(/<\/?[^>]+(>|$)/g, '').trim();

  // Replace multiple newlines with a single newline
  modifiedText2 = modifiedText2.replace(/\n+/g, '\n').trim();

  // Replace multiple spaces with a single space
  modifiedText2 = modifiedText2.replace(/ +/g, ' ').trim();
  
  carbon.action = modifiedText2;
  currentState.farmDetails.surveyData['Carbon'].action = modifiedText2;




  // Define a helper function to make the image upload call
  const uploadGraph = async (imageName, graphData) => {
    if (imageName.startsWith('farmPic_')) {
      if (!farmDetails.hasFarmPicChanged) {
        return true
      }
      else {
        setFarmDetails(prevDetails => ({
          ...prevDetails,
          hasFarmPicChanged: false
        }));
      }
    }
    const imagePayload = {
      "imageName": imageName,
      "imageDetails": graphData
    };
    console.log("Image Payload:", imagePayload);
    return makeAxiosCall("storeImage", imagePayload);
  };

     const imageUploadPromises1 = images1.map(image => {
      return uploadGraph(image.name, image.base64);
    });

    const imageUploadPromises2 = images2.map(image => {
      return uploadGraph(image.name, image.base64);
    });


  setPayload(prevDetails => {
    const specificFields = operation === "modify" ? {
      revisionNumber: saveType==='autosave' ? 'AUTOSAVE' : newRevisionNumber,
      id: saveType==='autosave' ? farmDetails.supplier_no : farmDetails.supplier_no + `_${newRevisionNumber}`,
      editedBy: accounts[0].name
  } : {
      creatorName: accounts[0].name,
      dateCreated: getCurrentDateTime(),
      id: farmDetails.supplier_no + `_${newRevisionNumber}`,
      schemaVersionNum: "001",
      surveyVersionNumber: "1",
      status: "DRAFT",
      farmSurveyId: farmDetails.farmSurveyId,
      revisionNumber: newRevisionNumber,
      editedBy: accounts[0].name
  };

    const updatedPayload = preparePayload(prevDetails, specificFields);

    (async () => {
      try {
        const response = await makeAxiosCall(urlSuffix, updatedPayload);

        if (response) {
          if (saveType !== 'autosave') { 
            setHighestRevisionNumber(newRevisionNumber);
          }
          if (operation !== "modify") {
            farmDetails.farmSurveyId = updatedPayload.farmSurveyId;
            farmDetails.date_edited = updatedPayload.dateEdited;
            setOperation('modify');
          }

          const graphPaths = [
            { path: "HealthyCalves.colostrum.graph1", namePrefix: "healthyCalvesColostrumGraph1_" },
            { path: "HealthyCalves.colostrum.graph2", namePrefix: "healthyCalvesColostrumGraph2_" },
            { path: "HealthyCalves.colostrum.graph3", namePrefix: "healthyCalvesColostrumGraph3_" },
            { path: "HealthyCalves.colostrum.graph4", namePrefix: "healthyCalvesColostrumGraph4_" },
            { path: "HealthyCalves.calfPathways.graph1", namePrefix: "healthyCalvesCalfPathwaysGraph1_" },
            { path: "HealthyCalves.calfPathways.graph2", namePrefix: "healthyCalvesCalfPathwaysGraph2_" },
            { path: "Nitrogen.graph", namePrefix: "nitrogenGraph_" },
            { path: "Carbon.graph", namePrefix: "carbonGraph_" },
            { path: "Irrigation.graph", namePrefix: "irrigationGraph_" },
            { path: "farmPic", namePrefix: "farmPic_" },
            { path: "GeneralFarm.farmMap", namePrefix: "farmMap_" }
          ];

          const graphPromises = graphPaths.map(graph => {
            const parts = graph.path.split('.');
            let graphValue;

            switch (parts.length) {
              case 1:
                graphValue = currentState.farmDetails[parts[0]];
                break;
              case 2:
                if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]]) {
                  graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]];
                }
                break;
              case 3:
                if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]] && currentState.farmDetails.surveyData[parts[0]][parts[1]]) {
                  graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]][parts[2]];
                }
                break;
              default:
                break;
            }

            if (graphValue && graphValue !== "") {
              if (saveType === 'autosave') {
                return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_AUTOSAVE`, graphValue);
              }
              else {
                return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_${newRevisionNumber}`, graphValue);
              }
            }
            return Promise.resolve(true);
          });

           // Merge imageUploadPromises and graphPromises
           const allUploadPromises = [...imageUploadPromises1, ...imageUploadPromises2, ...graphPromises];

           const allUploadResponses = await Promise.all(allUploadPromises);

           if (allUploadResponses.every(res => res)) {
            setOpenSavingDialog(false);
            saveType !== "autosave" && setOpenSaveSuccessDialog(true);
            // After save is successful
            setIsTextEdited(false);
            setIsDataEdited(false)
            setOpenUnsavedChangesDialog(false);
          } else {
            setOpenSavingDialog(false);
            saveType !== "autosave" && setOpenSaveFailedDialog(true);
          }
        } else {
          setOpenSavingDialog(false);
          saveType !== "autosave" && setOpenSaveFailedDialog(true);
        }
      } catch (error) {
        console.error("An error occurred during saving:", error);
        setOpenSavingDialog(false);
        saveType !== "autosave" && setOpenSaveFailedDialog(true);
      }
    })();

    return updatedPayload;
  });
};

const [openDialog1, setOpenDialog1] = useState(false);
const handleCloseDialog1 = () => {
  setOpenDialog1(false);
};

const allQuestionsAnswered = () => {
  return QArray.every((arr, post) => {
    return farmDetails?.surveyData?.['Carbon']?.questions[post]?.notApplicable ||
           (farmDetails?.surveyData?.['Carbon']?.questions[post]?.practice && farmDetails?.surveyData?.['Carbon']?.questions[post]?.focusCheck);
  });
}

const quillRef = useRef(null);
const quillRef1 = useRef(null);// Reference to the ReactQuill component
useEffect(() => {
  if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage();                               
              return false;              
            }  
                              
            const sizeInBytes = (lastImage.length * 3) / 4;
                const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB);
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage();                
                      return false; 
                  }   
                }              
              }              
            }
        };

      // Attach the event listener
      quill.on('text-change', handleChange);

      // Clean up the event listener when the component unmounts
      return () => {
          quill.off('text-change', handleChange);
      };
  }
}, []);
useEffect(() => {
  if (quillRef1.current) {
      const quill = quillRef1.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage();                               
              return false;              
            }                        
            const sizeInBytes = (lastImage.length * 3) / 4;
                const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB);  
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage1();                
                      return false; 
                  }   
                }              
              }              
            }
        };

      // Attach the event listener
      quill.on('text-change', handleChange);

      // Clean up the event listener when the component unmounts
      return () => {
          quill.off('text-change', handleChange);
      };
  }
}, []);

const handleDeleteImage = () => {
  const quill = quillRef.current.getEditor();
  const selection = quill.getSelection();
  quill.history.undo(); 
};

const handleDeleteImage1 = () => {
  const quill = quillRef1.current.getEditor();
  const selection = quill.getSelection();
  quill.history.undo();
};

const getImageType = (lastImage) => {
  const matches = lastImage.match(/^data:(.*);base64,/);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return null;
};

const customImageHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    const content = editor.getContents();
    console.log("Custom image handler triggered"); // This should be printed whenever an image is added

    // Count the number of images
    let imageCount = 0;
    content.ops.forEach(op => {
        if (typeof op.insert === 'string' && op.insert.includes('<img')) {
            imageCount++;
        }
    });

    if (imageCount >= 5) {
        alert('You can only insert a maximum of 5 images.');
    } else {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const range = selection;
                editor.insertEmbed(range.index, 'image', reader.result);
            };

            reader.readAsDataURL(file);
        };
    }
};
const customImageHandler1 = () => {
    const editor = quillRef1.current.getEditor();
    const selection = editor.getSelection();
    const content = editor.getContents();
    console.log("Custom image handler triggered"); // This should be printed whenever an image is added

    // Count the number of images
    let imageCount = 0;
    content.ops.forEach(op => {
        if (typeof op.insert === 'string' && op.insert.includes('<img')) {
            imageCount++;
        }
    });

    if (imageCount >= 5) {
        alert('You can only insert a maximum of 5 images.');
    } else {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const range = selection;
                editor.insertEmbed(range.index, 'image', reader.result);
            };

            reader.readAsDataURL(file);
        };
    }
};
const modules = useMemo(() => ({
  toolbar: {
    container: [
      [{ list: 'bullet' }],
      ['image']
    ]
  }
}), [])

  const CustomInputLabel = ({ label }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ fontSize: '15px' }}>{label}</span>
    <span style={{ fontSize: '15px', color: 'red', fontWeight: 'bold', marginLeft: '4px' }}>*</span>
  </div>
   );

   const checkUnsavedChanges = () => {
    return isTextEdited ; // where isTextEdited is a state variable that tracks if the text has been edited
  };  


  // Display page
  return (
    <div className="carbon-page">
      {console.log(farmDetails)}
      {console.log(payload)}
      {console.log(carbon)}
                   <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
        <Grid item xs={3}>
         <div className="progressbar">
         <ProgressBar currentStep={stepIndex} checkUnsavedChanges={checkUnsavedChanges} setOpenUnsavedChangesDialog={setOpenUnsavedChangesDialog} setUnsavedChangesNavigationPath={setUnsavedChangesNavigationPath}/>
           </div>
           </Grid>
            <Grid item xs={9}>
      <header className="carbon-header">
        <h1 className="carbon-h1">Greenhouse Gas Emissions Module</h1>
                 


        <div className="module-questions">   
           <h5>* indicates <b>mandatory fields</b></h5>
              <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>Benchmark<sup>*</sup> </h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
                       <div className="benchmark" >
            <p>
              <TextField  
                variant="standard"
                required
                fullWidth
                type="number"
                id="outlined-required"
                defaultValue={farmDetails.surveyData['Carbon'] ? farmDetails.surveyData['Carbon'].netGHG : ""}
                label="Net GHG Emissions (t CO2/year)"
                InputProps={{ style: { fontSize: 20} }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                onChange={(e) => {
                    const newValue = e.target.value;
                  if ( /^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                   setCarbon({
                    ...carbon,
                    netGHG: e.target.value
                  })  
                  farmDetails.surveyData['Carbon'].netGHG = (e.target.value)
                  setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }
                  

                }}/>
            </p>

            <p>
              <TextField  
                variant="standard"
                required
                fullWidth
                type="number"
                id="outlined-required"
                defaultValue={farmDetails.surveyData['Carbon'] ? farmDetails.surveyData['Carbon'].GHGEmissionsIntensity : ""}
                label="GHG Emissions Intensity (kg CO2e/kg FPCM) "
                InputProps={{ style: { fontSize: 20 } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                                     setCarbon({
                    ...carbon,
                    GHGEmissionsIntensity: e.target.value
                  })  
                  farmDetails.surveyData['Carbon'].GHGEmissionsIntensity = (e.target.value)
                  setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

                  //setisGraph(false);
                }}/>
            </p>

        </div>
          

        <div>
        <BellCarbon dataimport = {carbon.GHGEmissionsIntensity} onImageGenerated={handleImageGenerated}  runCalculations={Calc}/>
        </div>



          </Typography>
        </AccordionDetails>
      </Accordion>
 




      <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
            Commentary
            {farmDetails?.surveyData?.['GeneralFarm']?.commentary &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
              <h3>Commentary<sup>*</sup></h3>
            </AccordionSummary>

            {/* <AccordionDetails>
              <Typography>
                <TextareaAutosize
                  minRows={3}
                  maxRows={5}
                  placeholder="Commentary"
                  defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].commentary : ""}
                  style={{
                    color: 'black',
                    width: "100%",
                    paddingBottom: '2%',
                    border: "2px solid #000000",
                    fontSize: 20,
                    fontFamily: "Arial",
                    backgroundColor: "#EBF4F9",
                  }}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm

                  //   setFarmDetails(prevFarmDetails => ({
                  //     ...prevFarmDetails,
                  //     surveyData: {
                  //       ...prevFarmDetails.surveyData,
                  //       GeneralFarm: {
                  //         ...prevFarmDetails.surveyData.GeneralFarm,
                  //         commentary: newValue
                  //       }
                  //     }
                  //   }));
                  // }}
                  onChange={(e) => {
                    generalFarm.commentary = (e.target.value)
                    farmDetails.surveyData['GeneralFarm'].commentary = (e.target.value)
                  }}
                />
              </Typography>
            </AccordionDetails> */}

          <AccordionDetails>
              <ReactQuill ref={quillRef} theme="snow" value={value} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValue(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)
                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
              <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>
            </AccordionDetails>
          </Accordion>





        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3>
          Questions<sup>*</sup> 
          { allQuestionsAnswered() && <CheckCircleIcon style={{ marginLeft: '10px', marginBottom: '-3px', color: 'green', fontSize: '1.5rem' }} /> }
        </h3>
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
                {/* map through the QArray to display questions and options */}  
          {QArray.map((arr, post) =>
            divDisplay ? (
              <div>
                <List
                  sx={{ width: "100%", bgcolor: "#EBF4F9"}}
                  component="nav"
                  aria-labelledby="nested-list-subheader" >

                  <ListItemButton style={{ fontSize: 20, fontWeight: 'bold' }} onClick={() => handleClick(post)}>
                  Question {post+1}.{arr.question}
                  { 
                    (farmDetails?.surveyData?.['Carbon']?.questions[post]?.notApplicable ||
                    (farmDetails?.surveyData?.['Carbon']?.questions[post]?.practice && farmDetails?.surveyData?.['Carbon']?.questions[post]?.focusCheck)) &&
                    <CheckIcon style={{ marginLeft: '10px', color: 'green' }} />
                  }
                </ListItemButton>

                  {postDisplay[post] ?
                  <FormControl>
                                    <div style={{ marginLeft: '2%', marginTop: '15px' }}>
                  <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={farmDetails.surveyData['Carbon'].questions[post].notApplicable}
                            onChange={() => {
                                // Copy the current farmDetails state
                                const updatedFarmDetails = {...farmDetails};
                                // Toggle the notApplicable value for the current question
                                updatedFarmDetails.surveyData['Carbon'].questions[post].notApplicable = !updatedFarmDetails.surveyData['Carbon'].questions[post].notApplicable;
                              
                                if (updatedFarmDetails.surveyData['Carbon'].questions[post].notApplicable) { 
                                    // Reset checkboxes
                                    farmDetails.surveyData['Carbon'].questions[post].subQuestions.forEach((subQ, j) => {
                                        const currentSection = QArray[post].section[j];
                                        currentSection.option.key.forEach((key, k) => {
                                            updatedFarmDetails.surveyData['Carbon'].questions[post].subQuestions[j][k] = false;
                                            // Update generalFarm.scoreArray and generalFarm.generalQuestions
                                            ScoresArray = subtractScore(ScoresArray, key, currentSection.option.value[k]);
                                            carbon.scoreArray = ScoresArray;
                                            carbon.carbonQuestions.delete(key);
                                            setFarmDetails(prevFarmDetails => ({
                                              ...prevFarmDetails,
                                              surveyData: {
                                                  ...prevFarmDetails.surveyData,
                                                  Carbon: {
                                                      ...prevFarmDetails.surveyData.Carbon,
                                                      scoreArray: ScoresArray
                                                  }
                                              }
                                          }));
                                        });
                                    });
                                    
                                    // Reset practice and focusCheck
                                    updatedFarmDetails.surveyData['Carbon'].questions[post].practice = "";
                                    updatedFarmDetails.surveyData['Carbon'].questions[post].focusCheck = "";
                                    carbon.practices[post] = "";
                                    carbon.focusArea[post] = "";
                                }
                                
                                // Update the state
                                setFarmDetails(updatedFarmDetails);
                                setIsDataEdited(true)

                            }}
                            name={`notApplicable_${post}`}
                        />
                    }
                    label={
                      <Typography variant="body1" style={{  fontStyle: 'italic' }}>
                          Not Applicable for the farm
                      </Typography>
                  }
                />

              </div>
                  {arr.section.map((innerArr,j) =>( 
                    <div key = {j}>
                      <FormLabel  component = "legend" style = {{color: 'black', marginLeft: '2%', paddingTop: '2%'}}> {innerArr.statement} </FormLabel>
                      {innerArr.option.text.map((text,k) => (
                        <FormGroup key = {k}>
                        <FormControlLabel control = {
                            <Checkbox 
                                onChange =  { handleChange }
                                name = {innerArr.option.key[k]}
                                value = {innerArr.option.value[k]}
                                style = {{marginLeft: '2%'}}
                                checked = {farmDetails.surveyData['Carbon'].questions[post].subQuestions[j][k]}
                                data-post={post}
                                data-subq={j}
                                data-opt={k}
                                disabled={farmDetails.surveyData['Carbon'].questions[post].notApplicable}
                            />}
                            label =  {
                                    <div>
                                    {text}
                                      {text === 'Seasonal Calving' && farmDetails.surveyData['Carbon'].questions[post].subQuestions[j][k] && (
                                        <img style={{marginLeft: '5%', marginTop: '5%', marginBottom: '5%'}} src={carbon_option1} alt="Seasonal Calving Image" />
                                      )}
                                      {text === 'Year-round Calving' && farmDetails.surveyData['Carbon'].questions[post].subQuestions[j][k] && (
                                        <img  style={{marginLeft: '5%', marginTop: '5%', marginBottom: '5%'}} src={carbon_option2} alt="Year-round Calving Image" />
                                      )}
                                       {/* Include the text label */}
                                    </div>
                                  }
                             style={{width: "110ch",wordWrap: 'break-word'}}
                       
                          
                        />
                    </FormGroup>
                      ))} 
                    </div>
                  ))}        

                  <h6 style={{marginLeft: '2%', fontSize: '20px'}}>
                    Suggestion: {GetSuggestion(ScoresArray[post])}
                  </h6>

                  <TextField
                    id="select-practice"
                    select
                    name={post}
                     label={<CustomInputLabel label="Select appropriate practice" />}
                    value={farmDetails.surveyData['Carbon'].questions[post].practice} 
                    style={{marginLeft: '2%', marginTop: '2%'}}
                    sx={{ m: 1, width: '42ch' }}
                    onChange={updatePractice}
              
                    disabled={farmDetails.surveyData['Carbon'].questions[post].notApplicable}
                    InputProps={{ style: { fontSize: 20} }}
                    InputLabelProps={{ style: { fontSize: 20} }}
            
                  >
                    {practices.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>


                  <TextField
                    id="select-improvement"
                    select
                    name = {post}
                    label={<CustomInputLabel label="Is this a focus area you want support with?" />}
                    value={farmDetails.surveyData['Carbon'].questions[post].focusCheck} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch' }}
                    onChange = {updateFocus}
                   
           
                    disabled={farmDetails.surveyData['Carbon'].questions[post].notApplicable}
                    
                    InputProps={{ style: { fontSize: 15} }}
                InputLabelProps={{ style: { fontSize: 15} }}>

                    {wantImprovement.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>





                  </FormControl>
                  : null}                 
                </List>    
               </div>
           ) : null
          )} 
          </Typography>
        </AccordionDetails>
      </Accordion>

        
        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {/* <h3>
          Area of focus
          {farmDetails?.surveyData?.['Carbon']?.focus &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
        </h3> */}
        <h3>Area of focus<sup>*</sup></h3>
      
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <h5>Maximum character limit - 550</h5>
        <Textarea
            maxRows={3}
            maxLength="551"
            placeholder="Focus"
            defaultValue={ farmDetails.surveyData['Carbon'] ? farmDetails.surveyData['Carbon'].focus : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   carbon.focus = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       Carbon: {
              //         ...prevFarmDetails.surveyData.Carbon,
              //         focus: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
              if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    carbon.focus = (e.target.value);
                    farmDetails.surveyData['Carbon'].focus = (e.target.value);
                    setIsDataEdited(true)

                  }

                  }}

              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 7 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 7) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 8).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  carbon.focus = (e.target.value)
                  farmDetails.surveyData['Carbon'].focus = (e.target.value)
                  setIsDataEdited(true)

                }
              }}/>
          </Typography>
        </AccordionDetails>
      </Accordion>

          <Accordion>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {/* <h3>
            Opportunity for follow up/support
            {farmDetails?.surveyData?.['Carbon']?.focusAdditional &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
          <h3>Opportunity for follow up/support - (Optional)</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <h5>Maximum character limit - 500</h5>
              <Textarea
            maxRows={3}
            maxLength="501"
            placeholder="Additional focus"
            defaultValue={farmDetails.surveyData['Carbon'] ? farmDetails.surveyData['Carbon'].focusAdditional : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              marginBottom: '3%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   carbon.focusAdditional = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       Carbon: {
              //         ...prevFarmDetails.surveyData.Carbon,
              //         focusAdditional: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
              if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    carbon.focusAdditional = (e.target.value)
                    farmDetails.surveyData['Carbon'].focusAdditional = (e.target.value)
                    setIsDataEdited(true)

                  }

              }}
              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 9 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 9) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 10).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  carbon.focusAdditional = (e.target.value)
                  farmDetails.surveyData['Carbon'].focusAdditional = (e.target.value)
                  setIsDataEdited(true)

                }
              }}
          />
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {/* <h3>
            Actions
            {farmDetails?.surveyData?.['Carbon']?.action &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
          <h3>Actions<sup>*</sup></h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>

                       <ReactQuill ref={quillRef1} theme="snow" value={valueAction} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValueAction(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)
                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
           {/* <TextareaAutosize
            minRows={3}
            maxRows={5}
           
            placeholder="Actions"
            defaultValue={farmDetails.surveyData['Carbon'] ? farmDetails.surveyData['Carbon'].action : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   carbon.action = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       Carbon: {
              //         ...prevFarmDetails.surveyData.Carbon,
              //         action: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
                carbon.action = (e.target.value)
                farmDetails.surveyData['Carbon'].action = (e.target.value)
              }}
          />*/}
          </Typography>
          <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>
        </AccordionDetails>
      </Accordion>

            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
                Approach
                {farmDetails?.surveyData?.['Carbon']?.approach &&
                  <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
                }
              </h3> */}
              <h3>Approach - (Optional)</h3>
            </AccordionSummary>
        <AccordionDetails>
          <Typography>
           <h5>Maximum character limit - 850</h5>
            <Textarea
                minRows={3}
                maxRows={5}
                maxLength="851"
                placeholder="Approach"
                defaultValue={farmDetails.surveyData['Carbon'] ? farmDetails.surveyData['Carbon'].approach : ""}
                style={{ 
                  color: 'black', 
                  width: "100%",
                  paddingBottom: '2%',
                  border: "2px solid #000000",
                  fontSize: 20, 
                  fontColor: "black", 
                  fontFamily: "Arial", 
                  backgroundColor:"#EBF4F9",}}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   carbon.approach = newValue; // Assuming you also need to update generalFarm
                    
                  //   setFarmDetails(prevFarmDetails => ({
                  //     ...prevFarmDetails,
                  //     surveyData: {
                  //       ...prevFarmDetails.surveyData,
                  //       Carbon: {
                  //         ...prevFarmDetails.surveyData.Carbon,
                  //         approach: newValue
                  //       }
                  //     }
                  //   }));
                  // }}
                  onChange={(e) => {

                  if ((e.target.value).length > 850) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    carbon.approach = (e.target.value)
                    farmDetails.surveyData['Carbon'].approach = (e.target.value)
                    setIsDataEdited(true)

                  }

                  }}
                  onKeyDown={(e) => {
                    const value = e.target.value;
                    const numberOfNewLines = (value.match(/\n/g) || []).length;
                    
                    if (numberOfNewLines >= 11 && e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => {
                    // Get clipboard data
                    const clipboardData = e.clipboardData || window.clipboardData;
                    const pastedData = clipboardData.getData('Text');
                
                    // Count the number of lines in the pasted text
                    const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
                
                    // Check if the number of lines exceeds 10
                    if (numberOfNewLines > 11) {
                      e.preventDefault();
                      // Trim the pasted text to 10 lines and insert it manually
                      const trimmedData = pastedData.split('\n').slice(0, 12).join('\n');
                      const selectionStart = e.target.selectionStart;
                      const before = e.target.value.substring(0, selectionStart);
                      const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                      e.target.value = before + trimmedData + after;
                      // Update state
                      carbon.approach = (e.target.value)
                      farmDetails.surveyData['Carbon'].approach = (e.target.value)
                      setIsDataEdited(true)

                    }
                  }}
              />
          </Typography>
        </AccordionDetails>
      </Accordion>
 
 


      <div className="back-next-button">
      <Back back = {prev} onClick={() => handleNavigationAttempt(prev)}/>
              <Next 
                next={next} 
                onClick={() => handleNavigationAttempt(next)} 
                disabled={true}
              />
                  <div className="btn-text-left">
                  <Button  variant="contained" href="#contained-buttons"  className="btn-save" style={{ backgroundColor: isDataEdited ? "#002D4E" : "#a8a8a8"}}  sx={{ ml: 1}} onClick={handleSave} disabled={!isDataEdited}>Save</Button>
                {/* <Link to="/ModulePage">
                    <Button  variant="contained" href="#contained-buttons" id = "module" style={{ backgroundColor:"#002D4E"}}  sx={{ ml: 5}}>Module Page</Button>
              </Link> */}
              </div>

            </div>

        </div>
      </header>
            </Grid>
        </Grid>
          </Box>

          {/* Dialog Component */}
          <Dialog
            open={openImageFormatDialog}
            onClose={handleImageFormatDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Type"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Invalid file type. Please select a JPEG or PNG file.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageFormatDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openImageSizeDialog}
            onClose={handleImageSizeDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Size"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              File size exceeds the limit (400 KB).
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageSizeDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Incomplete Questions"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please complete all questions/calculate & show plot before proceeding.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

                    <Dialog open={openDialog1} onClose={handleCloseDialog1}>
              <DialogTitle>Error</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Negative numbers are not allowed. Please enter a positive number.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog1} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            {/* Save Successful Dialog */}
            <Dialog
            open={openSaveSuccessDialog}
            onClose={handleSaveSuccessDialogClose}
            aria-labelledby="save-success-dialog-title"
            aria-describedby="save-success-dialog-description"
        >
            <DialogTitle id="save-success-dialog-title">{"Save Successful"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="save-success-dialog-description">
                    You have successfully saved the form.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveSuccessDialogClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

        
          {/* Save Failed Dialog */}
          <Dialog
        open={openSaveFailedDialog}
        onClose={handleSaveFailedDialogClose}
        aria-labelledby="save-failed-dialog-title"
        aria-describedby="save-failed-dialog-description"
    >
        <DialogTitle id="save-failed-dialog-title">{"Save Failed"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="save-failed-dialog-description">
                An error occurred while saving the form. Please try again.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
              <Button onClick={handleSaveFailedDialogClose} color="primary" autoFocus>
                  OK
              </Button>
          </DialogActions>
      </Dialog>

        {/* Saving Dialog */}
        <Dialog
        open={openSavingDialog}
        aria-labelledby="saving-dialog-title"
        aria-describedby="saving-dialog-description"
    >
        <DialogTitle id="saving-dialog-title">{"Saving..."}</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
                Please wait while we save your form.
            </DialogContentText>
            {/* Centered spinner with space above */}
            <CircularProgress size={40} style={{ marginTop: '10px' }} />
        </DialogContent>
        </Dialog>

        <Dialog
        open={openUnsavedChangesDialog}
        onClose={() => setOpenUnsavedChangesDialog(false)}
        aria-labelledby="unsaved-changes-title"
        aria-describedby="unsaved-changes-description"
      >
        <DialogTitle id="unsaved-changes-title">{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-description">
            You have unsaved changes in the commentary section. Please save before continuing.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
          onClick={() => {
            setOpenUnsavedChangesDialog(false);
            setIsTextEdited(false); // Reset the text edited state
            setIsDataEdited(false)
            navigate(unsavedChangesNavigationPath); // Navigate to the stored path
            setUnsavedChangesNavigationPath(null); // Reset the navigation path state
          }}
          style={{ color: 'red', marginRight: 'auto', marginLeft: '7px' }} // This aligns the button to the left
        >
          Continue without saving
        </Button>
        <div style={{ marginLeft: 'auto' }}> {/* This div groups the 'Cancel' and 'Save' buttons to the right */}
          <Button onClick={() => setOpenUnsavedChangesDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </div>
      </DialogActions>
      </Dialog>
             {/* Character Dialog */}
        <Dialog open={isCharacterLimitExceeded} onClose={handleCharacterDialogClose}>
      <DialogTitle>Character Limit Exceeded</DialogTitle>
      <DialogContent>
        You have exceeded the character limit. Please shorten your input.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCharacterDialogClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
}

