import React  from 'react';
import { Page, Text, Image, StyleSheet, View } from '@react-pdf/renderer';
import action from "../../../images/Action.jpg"
import {default as styles} from "../Styles"
import Icon from "../../../images/dairy_australia_icon.png";


const ColostrumStyle = StyleSheet.create({
  headerImageBox: {
    height: "22%",
  },
  tableContainer: {
    height: 50, 
    backgroundColor:"#EBF4F9", 
    borderBottomWidth: 1, 
    marginLeft: "5%", 
    marginRight: "5%"
    },
    trainingCompleted: {
    borderLeftWidth: 1, 
    borderRightWidth: 1, 
    height: 50, 
    width: "50%", 
    padding: 10
  },
  headerContainer: {
    height: 50, 
    backgroundColor:'#002D4E', 
    borderBottomWidth: 1, 
    marginLeft: "5%", 
    marginRight: "5%"
  },
  headerText: {
    fontFamily: "ArialBold",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff"
  },
  rowText:{
    fontFamily: "Arial",
    fontSize: 12,
    textAlign: "center",
    color: "#002D4E"
  },
  who:{
    borderRightWidth: 1, 
    height: 50, 
    width: "25%", 
    padding: 10
  },
  when:{
    borderRightWidth: 1, 
    height: 50, 
    width: "25%", 
    padding: 10
  }
})


export default function HealthyCalvesTeam(healthyCalves, farmDetails, setHealthyCalves){
  return(
    <Page>
      {/* header */}
      <div style = {ColostrumStyle.headerImageBox} >
        <Image 
          src = {action}
          style={{position: 'relative',
          display: 'flex',
          paddingBottom: 0,
          marginBottom: 0}} />
        <div style = {{flexDirection: "row", position: 'absolute'}}>
          <div style={{width: "80%"}}>
            <Text style={styles.title}>The Action</Text>
            <Text style={styles.secondSubtitle}>Animal Health and Welfare - Healthy Calves </Text>  
          </div>
          <div style = {{width: "20%"}}>
          <Image 
            src = {Icon} />
          </div>
        </div>
      </div>

      <div style = {[ColostrumStyle.headerText,ColostrumStyle.headerContainer]}>
        <View style = {{flexDirection: "row",height: 50}}>
          <Text style = {ColostrumStyle.trainingCompleted} >Training completed</Text>
          <Text style = {ColostrumStyle.who} >Who</Text>
          <Text style = {ColostrumStyle.when} >When</Text>
        </View>
      </div>

      {healthyCalves.trainingGrid.map((item) =>
      <div style = {ColostrumStyle.tableContainer}>
        <View style = {{flexDirection: "row",height: 50}}>
          <div style = {ColostrumStyle.trainingCompleted} >
            <Text style ={[ColostrumStyle.rowText,{textAlign: "justify"}]} > {item.training_complete}</Text>
          </div>
          <div style = {ColostrumStyle.who} >
            <Text style ={ColostrumStyle.rowText} > {item.who}</Text>
          </div>
          <div style = {ColostrumStyle.when} >
            <Text style ={ColostrumStyle.rowText} > {item.when}</Text>
          </div>
        </View>
      </div>
      )}

      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
}