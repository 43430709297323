import React, { useEffect, useRef, useState, useImperativeHandle } from "react";
import { GoogleMap, Autocomplete, Marker } from "@react-google-maps/api";
import { useContext } from 'react';
import { AppRunTimeContext } from '../Context/index'; 
import { useScreenshot } from "./useScreenshot";

const Map = React.forwardRef((props, ref) => {
  const {farmDetails, setFarmDetails} = useContext(AppRunTimeContext);
  // melbourne- oban default to center the map frame
  const defaultLat = parseFloat(-37.8136);
  const defaultLng = parseFloat(144.9631);
  
  const defaultZoom = 0;

  const mapContainerRef = useRef(null);
  const { downloadScreenshot } = useScreenshot(mapContainerRef, 'MapScreenshot');

  const containerStyle = {
    width: "100%",
    height: "90vh",
    position: "auto",
  };

  // Initialize the selectedPlace state based on farmDetails if they exist
  const [selectedPlace, setSelectedPlace] = useState(
    farmDetails.farmAddressLat && farmDetails.farmAddressLong
    ? {
        geometry: {
          location: {
            lat: () => farmDetails.farmAddressLat,
            lng: () => farmDetails.farmAddressLong
          }
        }
      }
    : null
  );
  

  const autocompleteRef = useRef(null);

  async function handlePlaceSelect() {
    if (autocompleteRef.current) {
        const place = autocompleteRef.current.getPlace();
        setSelectedPlace(place);
        // Trigger the "Setting address..." dialog
        props.onSettingAddress();
        // Extract the address details from the selected place
        const address = place.formatted_address ? place.formatted_address : '';
        // Extract the latitude and longitude from the selected place
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        // Use setFarmDetails to update the farmAddress, farmAddressLat, and farmAddressLong fields
        setFarmDetails(prevDetails => ({
            ...prevDetails,
            farmAddress: address,
            farmAddressLat: lat,
            farmAddressLong: lng,
            surveyData: {
              ...prevDetails.surveyData,
              details: {
                  farmOwner: farmDetails.farm_owner,
                  planOwner: farmDetails.plan_owner,
                  farmAddressLat: lat,
                  farmAddressLong: lng,
                  currentYear: "2023",
                  futureYear: "2028",
                  farmAddress: address
              }}
        }));



        console.log(address);

        // Introduce a delay (e.g., 2 seconds) before capturing the map
        setTimeout(async () => {
          const dataURI = await downloadScreenshot();
          props.updateFarmPic(dataURI);  // Calling the passed function from FarmDetails
        }, 2000);  // 2000 milliseconds = 2 seconds
    }
}

      // Expose downloadScreenshot function to parent components
  useImperativeHandle(ref, () => ({
    downloadScreenshot
  }));

    return (
      <div ref={mapContainerRef}>
     
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={
              selectedPlace
                ? {
                    lat: selectedPlace.geometry.location.lat(),
                    lng: selectedPlace.geometry.location.lng(),
                  }
                : { lat: defaultLat, lng: defaultLng } // Fallback center if no place is selected
            }
            zoom={selectedPlace ? 18 : 10} // Adjust the zoom level based on whether a place is selected
            mapTypeId="satellite" // This sets the map view to Satellite
          >

          {selectedPlace && (
            <Marker
              position={{
                lat: selectedPlace.geometry.location.lat(),
                lng: selectedPlace.geometry.location.lng(),
              }}
            />
          )}

          
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={handlePlaceSelect}
          >
            <input
              type="text"
              placeholder="Search places..."
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `300px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                top: "10px",
                right: "70px",
                zIndex: 1,
              }}
            />
          </Autocomplete>

          </GoogleMap>
      </div>
    );
});

export default Map;
