import React from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import generalCover from "../../../images/GeneralFarm.jpg"
import {default as styles} from "../Styles"


function getPageNumber(pageNumber, generalFarm, setGeneralFarm){
  if (pageNumber !== generalFarm.generalPage) {
    setGeneralFarm(prevDetails => ({
          ...prevDetails,
          generalPage: pageNumber
      }));
  }
}

export default function GfHeader(generalFarm, farmDetails, setGeneralFarm){
    return(
      <Page style={{backgroundColor: '#EBF4F9'}} >
        <div style={styles.general_image}>
          <Image 
          src = {generalCover} />
        </div>

        <div style={{position: 'absolute'}}>
          <Text style={styles.title}>The Headline</Text>
          <Text style={styles.subtitle}>General Farm Management</Text>         
          <Text style={styles.moduleIntroduction}>
            This module identifies farms environmental characteristics, farming practices and plans for their management 
            as aligned with Dairy Sat. The physical and biophysical characteristics of the farm system are identified, 
            and risk factors associated with the farm system are assessed.
          </Text>
          <Text style={{marginTop: 20, width: '70%',marginBottom: 8, marginLeft: '25%', fontSize: 14, textAlign: 'justify',fontFamily: 'Arial', color: '#002D4E',padding: 0, flexWrap: 'wrap'}}>
            Opportunities for assisting suppliers in achieving best practice are gathered via the below self-assessment.
          </Text>
        </div>

        <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}` && getPageNumber(pageNumber, generalFarm, setGeneralFarm)
        )} fixed />
        <Text style={styles.pageNumber} render={({ pageNumber }) => (
      `${pageNumber}`
      )} fixed />
      </Page>
    )
} 