import React, { useState } from 'react';
import Plot from 'react-plotly.js';
//PieChart made using plotly
//https://plotly.com/javascript/pie-charts/

export const PieChart = ({data}) => {


  return (
    <Plot
      data={[data]}
      layout={{
        width: 450,
        height: 450,
        showlegend: false,
        hovermode: false,
      }}
      config={{ displayModeBar: false }}
    />
  );
}



export default PieChart;
