import React from "react";
import { useEffect, useState, useContext } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import ModulePage from "./components/ModulePage";
import FarmDetails from "./components/FarmDetails";
import GeneralFarm from "./components/GeneralFarm.jsx";
import Nitrogen from "./components/Nitrogen";
import Effluent from "./components/Effluent";
import Carbon from "./components/Carbon";
import HealthyCalves from "./components/HealthyCalves";
import Irrigation from "./components/Irrigation";
import Biosecurity from "./components/Biosecurity";
import ReportTemplate from "./components/ReportTemplate";
import Review from "./components/Review";
import { RecoilRoot } from 'recoil';
import ScrollToTop from './ScrollToTop';
import { AppRunTimeContext } from './Context';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionType,
} from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import {
  useJsApiLoader,
  Autocomplete,
  GoogleMap,
  InfoWindow,
  Marker, LoadScript
} from "@react-google-maps/api";


export default function App() {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [user, setUser] = useState(null);
  const { g_setUser } = useContext(AppRunTimeContext);

  useEffect(() => {
    const fetchData = async () => {
      console.log(account)
      console.log(inProgress)
      console.log(user)
      if (account && inProgress === "none" && !user) {

        let MSADResponseData = {};


        try {
          MSADResponseData = await instance.acquireTokenSilent({
            scopes: loginRequest.scopes,
            account: account,
          });
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === "none") {
              MSADResponseData = await instance.acquireTokenPopup({
                scopes: loginRequest.scopes,
              });
            }
          }
        }

        setUser(MSADResponseData);
        g_setUser(MSADResponseData);
        localStorage.setItem("accessToken", MSADResponseData.accessToken);
        localStorage.setItem("ACCROLES", MSADResponseData.idTokenClaims.roles);
      }
    };

    fetchData();
  }, [account, inProgress, instance]);




  return (
    <MsalAuthenticationTemplate
      authenticationRequest={loginRequest}
      interactionType={InteractionType.Redirect}
    >
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places"]}
      >
        <RecoilRoot>
          <BrowserRouter>
            <ScrollToTop>
              <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/FarmDetails" element={<FarmDetails />} />
                <Route exact path="/ModulePage" element={<ModulePage />} />
                <Route exact path="/GeneralFarm" element={<GeneralFarm />} />
                <Route exact path="/Nitrogen" element={<Nitrogen />} />
                <Route exact path="/Effluent" element={<Effluent />} />
                <Route exact path="/Carbon" element={<Carbon />} />
                <Route exact path="/HealthyCalves" element={<HealthyCalves />} />
                <Route exact path="/Irrigation" element={<Irrigation />} />
                <Route exact path="/Biosecurity" element={<Biosecurity />} />
                <Route exact path="/Review" element={<Review />} />
                <Route exact path="/ReportTemplate" element={<ReportTemplate />} />
              </Routes>
            </ScrollToTop>
          </BrowserRouter>
        </RecoilRoot>
      </LoadScript>
    </MsalAuthenticationTemplate>

  );
};
