import React from 'react';
import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import nitrogenBenchmark from "../../../images/nitrogenBenchmark.jpg"
import {default as styles} from "../Styles"
import Icon from "../../../images/healthy_calves_benchmark_icon.png"
import healthyCalvesColostrumGraph1Placeholder from "../../../images/healthyCalvesColostrumGraph1Placeholder.png"
import healthyCalvesColostrumGraph2Placeholder from "../../../images/healthyCalvesColostrumGraph2Placeholder.png"

const colostrumStyle = StyleSheet.create({
    boxes:
    {
        flexDirection: 'row',
        alignContent: "center", 
    },
    first_column:{
        width: "37%",
    },
    second_column:{
        width: "10%",
    },
    third_column:{
        width: "45%",
    },
    graph_title:{
        fontFamily: 'Recoleta',
        fontSize: 20,
        color:'#002D4E',
        textAlign: 'center'
    },
    first_column2:{
        width: "30%",
    },
    second_column2:{
        width: "70%",
        textAlign: "left"
    },
    color_label:{
        marginLeft: "80%",
        width: "7%",
        height: "20%",
        marginBottom: "8%"
    },
    text_label:{
        fontFamily: 'Calibri',
        fontSize: 12,
        color:'#2C4C6D',
    },
    leftblankBox1:{
        position: 'absolute',
        left:"43%", 
        width:"77%", 
        height:35, 
        backgroundColor:"#fff"
    },
    leftblankBox2:{
        position: 'absolute',
        left:"83%",
        top:30, 
        width:"40%", 
        height:170, 
        backgroundColor:"#fff"
    },
    rightblankBox1:{
        position: 'absolute',
        left:"17%",
        width:"3%", 
        height:200, 
        backgroundColor:"#fff"
    },
    rightblankBox2:{
        position: 'absolute',
        left:"28%", 
        width:"80%", 
        height:35, 
        backgroundColor:"#fff"
    },
    rightblankBox3:{
        position: 'absolute',
        left:"80%", 
        top:35, 
        width:"20%", 
        height:165, 
        backgroundColor:"#fff"
    }
})


export default function ColostrumBenchMark(healthyCalves, farmDetails){
    console.log(healthyCalves.colostrum.graph1);
     console.log(healthyCalves.colostrum.graph2);
     console.log(healthyCalves.colostrum.graph3);
     console.log(healthyCalves.colostrum.graph4);
    return(
      <Page>
        <div style ={{height: "18%"}} >
            <Image 
                src = {nitrogenBenchmark}/>
            <div style={{position: 'absolute'}}>
                <Text style={styles.title}>The Benchmark</Text>
                <Text style={styles.secondSubtitle}>Animal Health and Welfare - Healthy Calves (Colostrum) </Text>  
            </div>
        </div>

        <div style ={{height: "5%", marginTop: "5%"}}>
            <View style = {colostrumStyle.boxes}>
                <div style = {colostrumStyle.first_column}>
                    <Text style = {colostrumStyle.graph_title}>{farmDetails.currentYear}</Text> 
                </div>
                <div style = {colostrumStyle.second_column}>
                    <Image 
                        src={Icon}/>
                </div>
                <div style = {colostrumStyle.third_column}>
                    <Text style = {colostrumStyle.graph_title}>{farmDetails.futureYear}</Text> 
                </div>
            </View>
        </div>
        <div style ={{height: "30%"}}>
            <View style = {colostrumStyle.boxes}>
                <div style = {{width: "100%"}}> 
                    <Image
                        id='calfPathwaysGraph1'
                        src={(healthyCalves.colostrum.graph1 === ""  || !healthyCalves.colostrum.graph1) ? healthyCalvesColostrumGraph1Placeholder : healthyCalves.colostrum.graph1}
                        alt = "calf Pathways benchmark plot"
                        style={{
                            width: "110%",
                            height: "88%",
                            marginLeft: "20%"           
                        }}/>
                        <div style={colostrumStyle.leftblankBox1}></div>
                        <div style={colostrumStyle.leftblankBox2}></div>
                </div>
                <div style = {{width: "100%"}}>
                    <Image
                        id='calfPathwaysGraph2'
                        src={(healthyCalves.colostrum.graph2 === ""  || !healthyCalves.colostrum.graph2) ? healthyCalvesColostrumGraph2Placeholder : healthyCalves.colostrum.graph2}
                        alt = "calf Pathways benchmark plot"
                        style={{
                            width: "110%",
                            height: "88%",
                        }}/>
                    <div style={colostrumStyle.rightblankBox1}></div>
                    <div style={colostrumStyle.rightblankBox2}></div>
                    <div style={colostrumStyle.rightblankBox3}></div>
                </div>
            </View>
        </div>


        <div style ={{height: "8%", marginTop: "-8%", marginBottom: "2%", marginLeft: "8%"}}>
            <View style = {{flexDirection: 'row'}}>
                <div style = {colostrumStyle.first_column2}>
                    <div style = {[colostrumStyle.color_label, {backgroundColor:"#2C4C6D"}]}> </div>
                    <div style = {[colostrumStyle.color_label, {backgroundColor:"#66B7E1"}]}> </div>

                </div>
                <div style = {[colostrumStyle.second_column2, colostrumStyle.text_label]}>
                    <Text style = {{marginBottom: 8}}>% of calves achieving passive transfer </Text>
                    <Text style = {{marginBottom: 8}}>% of calves with failure of passive transfer </Text>

                </div>
            </View>
        </div>

                <div style ={{height: "30%", marginTop:"-3px"}}>
            <View style = {colostrumStyle.boxes}>
                <div style = {{width: "110%"}}> 
                    <Image
                        id='calfPathwaysGraph3'
                        src={(healthyCalves.colostrum.graph3 === ""  || !healthyCalves.colostrum.graph3) ? healthyCalvesColostrumGraph1Placeholder : healthyCalves.colostrum.graph3}
                        alt = "calf Pathways benchmark plot"
                        style={{
                            width: "110%",
                            height: "88%",
                            marginLeft: "20%"           
                        }}/>
                    <div style={colostrumStyle.leftblankBox1}></div>
                    <div style={colostrumStyle.leftblankBox2}></div>
                </div>
                <div style = {{width: "110%"}}>
                    <Image
                        id='calfPathwaysGraph2'
                        src={(healthyCalves.colostrum.graph4 === ""  || !healthyCalves.colostrum.graph4) ? healthyCalvesColostrumGraph2Placeholder : healthyCalves.colostrum.graph4}
                        alt = "calf Pathways benchmark plot"
                        style={{
                            width: "110%",
                            height: "88%",
                        }}/>
                    <div style={colostrumStyle.rightblankBox1}></div>
                    <div style={colostrumStyle.rightblankBox2}></div>
                    <div style={colostrumStyle.rightblankBox3}></div>
                </div>
            </View>
        </div>

                <div style ={{height: "8%", marginTop: "-8%", marginBottom: "2%", marginLeft: "8%"}}>
            <View style = {{flexDirection: 'row'}}>
                <div style = {colostrumStyle.first_column2}>

                <div style = {[colostrumStyle.color_label, {backgroundColor:"#ED7D31"}]}> </div>
                    <div style = {[colostrumStyle.color_label, {backgroundColor:"#5FB33A"}]}> </div>
                    <div style = {[colostrumStyle.color_label, {backgroundColor:"#FFC000"}]}> </div>
                </div>
                <div style = {[colostrumStyle.second_column2, colostrumStyle.text_label]}>

                    <Text style = {{marginBottom: 8}}> % of calves fed colostrum {'<'} 12 hours </Text>
                    <Text style = {{marginBottom: 8}}> % of calves fed colostrum {'>'} 12 hours </Text>
                    <Text style = {{marginBottom: 8}}> % of calves not fed colostrum at all </Text>
                </div>
            </View>
        </div>

        <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (
         `${pageNumber}`
        )} fixed />
      </Page>
    )
}
