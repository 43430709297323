import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

const acquireAccessToken = async (instance, account) => {
    let MSADResponseData = {};

    try {
        MSADResponseData = await instance.acquireTokenSilent({
            scopes: loginRequest.scopes,
            account: account,
        });
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            MSADResponseData = await instance.acquireTokenPopup({
                scopes: loginRequest.scopes,
            });
        }
    }
    // console.log(MSADResponseData.accessToken)
    return MSADResponseData.accessToken;
  };

export default acquireAccessToken;