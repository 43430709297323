import * as React from "react";
import { useState, useEffect, useMemo, useRef } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import useModuleLink from '../hooks/moduleLink';
import Next from "./Next";
import Back from "./Back";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Textarea from '@mui/joy/Textarea';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AppRunTimeContext } from '../Context/index'; 
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {addScore, subtractScore, GetSuggestion, practices, wantImprovement} from './Utilities.jsx'
import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import acquireAccessToken from "../accessToken"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ProgressBar from "./ProgressBar";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// Module questions with corresponding subquestions. 
//Each checkbox(option) are given a unique key with the initial of the module - question no - subquestion no - checkbox no.
//For instance, XX-00-00-00.
//The value for each option is decided based on how good the practices are.
const QArray = [
  { number: 1, //Question no
    question: "Have you calculated the water usage in the dairy and facilities and identified measures to reduce water usage?", //Question content
    section: [
      {id:"1.1",
      statement: "1.1. Water usage",  //sub question
      option: {
        text: ["Calculated and maintained through a system (E.g., Dairy Australia dairy water use calculator) ","Calculated generically","No calculation or monitoring"],  //options for checkbox
        value: [5, 3, 0],  // assigned value for each option
        key: ["EF-01-1-1","EF-01-1-2", "EF-01-1-3"] 
        //key for each checkbox, name as: module's initial-Question no-SubQ no-Checkbox no
      }},
      {id:"1.2",
      statement: "1.2. Reuse",
      option: {
        text: ["Effluent recycled from ponds for washdown","Water reused in other places","No water is reused or recycled"],
        value: [5, 3, 0],
        key: ["EF-01-2-1","EF-01-2-2", "EF-01-2-3"] 
      }},
    ]},
   {
    number: 2, 
    question: "Do you have a system to separate solids and debris from the effluent stream?", 
    section: [
      {id:"2.1",
      statement: "2.1. Stockpile",
      option: {
        text: ["Stockpile tested periodically to assess nutrient level","Composting stockpiles are regulary turned to ensure aeration","Stockpile area close to separation system is present"],
        value: [5, 5, 3],
        key: ["EF-02-1-1","EF-02-1-2", "EF-02-1-3"] 
      }},
      {id:"2.2",
      statement: "2.2. Soild separation system",
      option: {
        text: ["Filters and screens for solid separations are regularly cleaned","Solids are separated using direct drainage","No solids separation system is present"],
        value: [5, 3, 0],
        key: ["EF-02-2-1","EF-02-2-2", "EF-02-2-3"] 
      }},
  ]},
  {
    number: 3, 
    question: "Is proper management in place to ensure adequate storage of effluent in your ponds during wet months or when application is not suitable?", 
    section: [
      {id:"3.1",
      statement: "",
      option: {
        text: ["Ponds are emptied prior to wet period","Ponds are agitated prior to application","Pond walls are monitored and maintained","Accumulating sludge are removed regularly","Weeds around pond are removed regularly","Ponds are desludged regularly","Ponds not managed properly"],
        value: [4, 4, 2, 2, 2, 2, 0],
        key: ["EF-03-1-1", "EF-03-1-2", "EF-03-1-3", "EF-03-1-4", "EF-03-1-5", "EF-03-1-6", "EF-03-1-7"] 
      }},
  ]},
  {
    number: 4, 
    question: "Is there proper management in place to prevent effluent from polluting groundwater, surface waters, or surrounding land in the case of roads, underpasses, laneways, and bridge crossings?", 
    section: [
      {id:"4.1",
      statement: "",
      option: {
        text: ["All are managed to fully prevent effluent pollution","Managed but there are effluent coming out of the property","No consideration for effluent pathways"],
        value: [8, 5, 0],
        key: ["EF-04-1-1", "EF-04-1-2", "EF-04-1-3"] 
      }},
  ]},
  {
    number: 5, 
    question: "How do you manage the reuse and application of the effluent to ensure no offsite discharge?", 
    section: [
      {id:"5.1",
      statement: "",
      option: {
        text: ["Avoiding effluent application on sensitive areas (i.e. waterways)","Avoiding effluent application in wet weather","Avoiding and restrict spray drift from sprinklers and irrigators","Overwatering of irrigation bays are minimised","Effluent application is within the property boundary","Movable sprinklers and irrigator as regularly relocated to distribute nutrients","Effluent is discharged offsite","No effluent application management"],
        value: [4, 4, 4, 2, 2, 2, 0, 0],
        key: ["EF-05-1-1", "EF-05-1-2", "EF-05-1-3", "EF-05-1-4", "EF-05-1-5", "EF-05-1-6", "EF-05-1-7", "EF-05-1-8"]  
      }},
  ]},
  {
    number: 6, 
    question: "How do you manage the reuse and application of the effluent to gain maximum productivity benefits from the nutrients,organic matter and water?", 
    section: [
      {id:"6.1",
      statement: "6.1. Rate",
      option: {
        text: ["Adjusted periodically to suit soil types and seasonal conditions","Adjusted initially with minimal changes","Fixed rate application"],
        value: [4, 2, 0],
        key: ["EF-06-1-1", "EF-06-1-2", "EF-06-1-3"]  
      }},
      {id:"6.2",
      statement: "6.2. Time",
      option: {
        text: ["Applied when pasture and crops are actively growing","Applied immediately after grazing","Applied without time consideration"],
        value: [3, 2, 0],
        key: ["EF-06-2-1", "EF-06-2-2", "EF-06-2-3"]  
      }},
      {id:"6.3",
      statement: "6.3. Area",
      option: {
        text: ["Applied to land that is about to be cultivated","Application areas are soil tested and monitored","Applied avoiding sensitive areas","No effluent application"],
        value: [3, 3, 3, 0],
        key: ["EF-06-3-1", "EF-06-3-2", "EF-06-3-3","EF-06-3-4"]  
      }},
  ]},
  {
    number: 7, 
    question: "Is a farm map used to plan irrigation from the effluent system (which incorporates soil nutrient and paddock elevation details)?", 
    section: [
      {id:"7.1",
      statement: "",
      option: {
        text: ["Available with relevant details (e.g. soil nutrient levels and topography)","Available with little to no details","No farm map for effluent system"],
        value: [8, 5, 0],
        key: ["EF-07-1-1", "EF-07-1-2", "EF-07-1-3"] 
      }},
  ]},

  {
    number: 8, 
    question: "How do you ensure to provide a safe work environment for staff ,visitors and service providers and do you provide adequate training to staff on risks related to effluent system?", 
    section: [
      {id:"8.1",
      statement: "",
      option: {
        text: ["Potential risks and hazards are identified","Training program for health and safety protocols","All staff are immunized against Q Fever and Leptospirosus","Equipments are maintained regularly","Basic PPE (Personal Protective Equipment) are used","No consideration for safe work environment"],
        value: [4, 4, 4, 3, 3, 0],
        key: ["EF-08-1-1", "EF-08-1-2", "EF-08-1-3", "EF-08-1-4", "EF-08-1-5", "EF-08-1-6"]  
      }},
    ]},
    {
      number: 9, 
      question: "How do you manage effluent to prevent and minimize the potential infection and spread of diseases within the herd?", 
      section: [
        {id:"9.1",
        statement: "",
        option: {
          text: ["Young stocks are not allowed to access land with effluent","Fencing and signage","Avoiding calving down cows on areas with effluent applications","Clean water are provided for stocks","Cattle grazed drainage do not impact calf-rearing areas","Effluent contaminates water used by stocks"],
          value: [4, 4, 3, 3, 0, 0],
          key: ["EF-9-1-1", "EF-9-1-2", "EF-9-1-3", "EF-9-1-4", "EF-9-1-5", "EF-9-1-6"]  
        }},
      ]},
      {
        number: 10, 
        question: "Do you have a contingency plan to prevent sudden shock loading of effluent in the pond, such as when you need to dispose of milk that is unacceptable for supply or cannot be collected by Fonterra and cannot be stored on-farm for animal feeding?", 
        section: [
          {id:"10.1",
          statement: "",
          option: {
            text: ["Detailed contingency(e.g. having a separate pond or the ability to dig one to put the shock load in and/ or ability to take the milk off site/ outside of the dairy) is in place plan (i.e. floods, milk disposal, power distruptions, etc) ","Contingency plan present with lacking details","No contingency plan is in place"],
            value: [8, 5, 0],
            key: ["EF-10-1-1", "EF-10-1-2", "EF-10-1-3"]  
          }},
        ]},
        {
          number: 11, 
          question: "Have you used a ‘Self Assessment tool’ to proactively identify the risks your business may pose to human health and the environment (General Environmental Duty)?", 
          section: [
            {id:"11.1",
            statement: "",
            option: {
              text: ["Risk assessed and mitigated under GED (General Environmental Duty)","Risk identified with little mitigation","No risk assessment has been done"],
              value: [8, 5, 0],
              key: ["EF-11-1-1", "EF-11-1-2", "EF-11-1-3"]  
            }},
          ]},
        {
          number: 12, 
          question: "Does your effluent management plan reflect the existing effluent system? Have changes been made on farm which would require the plan to be altered?", 
          section: [
            {id:"12.1",
            statement: "",
            option: {
              text: ["Plan reflects the existing system and improvement has been made","Plan reflect the existing system with little to no improvement","Plan does not reflect the existing system"],
              value: [8, 5, 0],
              key: ["EF-12-1-1", "EF-12-1-2", "EF-12-1-3"]  
            }},
          ]},
]

  const CustomInputLabel = ({ label }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ fontSize: '15px' }}>{label}</span>
    <span style={{ fontSize: '15px', color: 'red', fontWeight: 'bold', marginLeft: '4px' }}>*</span>
  </div>
   );

export default function Effluent() {
  const { effluent, setEffluent} = useContext(AppRunTimeContext);
  var ScoresArray = effluent.scoreArray
  const {setNextPrev, getNext, getPrev, getLinks} = useModuleLink();

  const { instance, accounts } = useMsal();

  const { farmDetails, setFarmDetails, payload, setPayload, operation, setOperation, highestRevisionNumber, setHighestRevisionNumber } = useContext(AppRunTimeContext);
  // Use the dictionary from generalFarm[images] to replace image filenames
  const formattedValue = (farmDetails.surveyData['Effluent'] ? farmDetails.surveyData['Effluent'].commentary : "").replace(/effluentCommentary\S+/g, match => {
    console.log(match)
    const base64 = effluent.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [value, setValue] = useState(formattedValue);

    const formattedValueAction = (farmDetails.surveyData['Effluent'] ? farmDetails.surveyData['Effluent'].action : "").replace(/effluentAction\S+/g, match => {
    console.log(match)
    const base64 = effluent.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [valueAction, setValueAction] = useState(formattedValueAction);
  const {steps} = useContext(AppRunTimeContext);
  const [isDataEdited, setIsDataEdited] = useState(false);

  setNextPrev("/Effluent");
  let next = getNext();
  let prev = getPrev();

  const stepIndex = steps.findIndex(step => step.link === '/Effluent');

  const [postDisplay, setPostDisplay] = useState(true);
  const [state, setState] = React.useState(null);
  const divDisplay = true;

  const [openDialog, setOpenDialog] = useState(false);
  const [openSaveSuccessDialog, setOpenSaveSuccessDialog] = React.useState(false);
  const [openSaveFailedDialog, setOpenSaveFailedDialog] = React.useState(false);
  const [openSavingDialog, setOpenSavingDialog] = React.useState(false);
  const [isTextEdited, setIsTextEdited] = useState(false);

  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);
  const [unsavedChangesNavigationPath, setUnsavedChangesNavigationPath] = useState(null);
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] = useState(false);
  const [openImageFormatDialog, setOpenImageFormatDialog] = React.useState(false);
  const [openImageSizeDialog, setOpenImageSizeDialog] = React.useState(false);

  const navigate = useNavigate();
  const stateRef = useRef();


  useEffect(() => {
    stateRef.current = {
      farmDetails,
      value,
      valueAction
    };
  }, [farmDetails, value, valueAction]);
    
  const handleImageFormatDialogClose = () => {    
    setOpenImageFormatDialog(false);   
  };

  const handleImageSizeDialogClose = () => {
    setOpenImageSizeDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSaveSuccessDialogClose = () => {
    setOpenSaveSuccessDialog(false);
  };

  const handleSaveFailedDialogClose = () => {
    setOpenSaveFailedDialog(false);
  };

  const handleCharacterDialogClose = () => {
    setIsCharacterLimitExceeded(false);
  };



  const handleNextClick = () => {
    // const questions = farmDetails.surveyData['Effluent'].questions;

    // // for (let i = 0; i < questions.length; i++) {
    // //   if (!questions[i].practice || !questions[i].focusCheck) {
    // //     console.log('open dialog')
    // //     setOpenDialog(true);
    // //     return;
    // //   }
    // // }
    // // If all conditions are met, navigate to the next page.
    // console.log(next)
    // {console.log(getLinks())}
    // {console.log(farmDetails.modules)}
    navigate(next);
  };

  useEffect(() => {

    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
        window.location.href = '/'; // Redirect to home page
    }

    
    if (farmDetails.surveyData['Effluent']) {
    // Your code here will run after the component has mounted
      // Loop through the surveyData[1].questions array
      farmDetails.surveyData['Effluent'].questions.forEach((question, index) => {
        // Update the generalFarm.practices array based on the practice in each question
        effluent.practices[index] = question.practice;
        effluent.focusArea[index] = question.focusCheck
      });
        console.log(effluent.practices)
        // console.log(tempModule)
        // console.log(farmDetails)
    }
    else {
      if(!(farmDetails.surveyData['Effluent'])) {
        setFarmDetails(prevDetails => ({
          ...prevDetails,
            surveyData: {
              ...prevDetails.surveyData,
              details: {
                farmOwner: farmDetails.farm_owner,
                planOwner: farmDetails.plan_owner,
                farmAddressLat: farmDetails.farmAddressLat,
                farmAddressLong: farmDetails.farmAddressLong,
                financialYear: farmDetails.financialYear,
                farmAddress: farmDetails.farmAddress
              },
              Effluent: {
                module: "Effluent",
                commentary: "",
                action: "",
                scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                approach: "",
                focus: "",
                focusAdditional: "",
                haApplied:0,
                effluentPage: 0,
                QuestionMapping: [
                  {
                    Q1_4: "Have you calculated the water usage in the dairy and facilities and identified measures to reduce water usage?",
                    Q2_4: "Do you have a system to separate solids and debris from the effluent stream?",
                    Q3_4: "Is proper management in place to ensure adequate storage of effluent in your ponds during wet months or when application is not suitable?",
                    Q4_4: "Is there proper management in place to prevent effluent from polluting groundwater, surface waters, or surrounding land in the case of roads, underpasses, laneways, and bridge crossings?",
                    Q5_4: "How do you manage the reuse and application of the effluent to ensure no offsite discharge?",
                    Q6_4: "How do you manage the reuse and application of the effluent to gain maximum productivity benefits from the nutrients, organic matter and water?",
                    Q7_4: "Is a farm map used to plan irrigation from the effluent system (which incorporates soil nutrient and paddock elevation details)?",
  
                    Q8_4: "How do you ensure to provide a safe work environment for staff, visitors and service providers and do you provide adequate training to staff on risks related to effluent system?",
                    Q9_4: "How do you manage effluent to prevent and minimize the potential infection and spread of diseases within the herd?",
                    Q10_4: "Do you have a contingency plan to prevent sudden shock loading of effluent in the pond, such as when you need to dispose of milk that is unacceptable for supply or cannot be collected by Fonterra and cannot be stored on-farm for animal feeding?",
                    Q11_4: "Have you used a ‘Self Assessment tool’ to proactively identify the risks your business may pose to human health and the environment (General Environmental Duty)?",
                    Q12_4: "Does your effluent management plan reflect the existing effluent system? Have changes been made on farm which would require the plan to be altered?"
                  }
                ],
                questions: [
                  {
                    subQuestions: [
                      [
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                  },
                {
                    subQuestions: [
                      [
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                      [
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                  {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                  subQuestions: [
                      [
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              },
                {
                  subQuestions: [
                      [
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
              }
              ]
            }
          }
    }));
  }
 }

  }, []);  // Empty dependency array means this useEffect runs once after initial render

  // Check whether the checkboxes are ticked
  const getChecked = (key) => {
    if (effluent.effluentQuestions.get(key)) 
      return true;
    else
      return false; 
  }

  const handleNavigationAttempt = (navigationPath) => {
    if (isTextEdited) {
      setUnsavedChangesNavigationPath(navigationPath); // Store the intended navigation path
      setOpenUnsavedChangesDialog(true); // If text is edited, show the warning dialog
    } else {
      navigate(navigationPath); // Otherwise, navigate immediately
    }
  };

  const handleClick = (post) => { 
    const objectCopy = {
      ...postDisplay
    };
    objectCopy[post] = !objectCopy[post];
    setPostDisplay(objectCopy);
  };

  useEffect(() => {
    // Function to be executed
    const intervalFunction = () => {
      console.log(operation);
      if (operation === "modify") {
        handleSave('autosave');
        console.log('This function runs every 30 seconds');
      }
      // Add your logic here
    };
  
    // Set the interval
    const interval = setInterval(intervalFunction, 120000); 
  
    // Clear interval on component unmount or when operation changes
    return () => clearInterval(interval);
  }, [operation]); // Include operation in the dependency array

 // Called when a checkbox is ticked/unticked
 const handleChange = (event) => {


  const postIndex = event.target.parentNode.dataset.post;
  const subQIndex = event.target.parentNode.dataset.subq;
  const optIndex = event.target.parentNode.dataset.opt;

  setState({
    ...state,
    [event.target.name]: event.target.checked,
  });


  {console.log(postIndex)}
  {console.log(subQIndex)}
  {console.log(optIndex)}

  farmDetails.surveyData['Effluent'].questions[postIndex].subQuestions[subQIndex][optIndex] = event.target.checked;
  setIsDataEdited(true)



  //When checkbox is ticked, add score to module score array and set checkbox as ticked
  if (event.target.checked){
    ScoresArray = addScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue)
    effluent.scoreArray = ScoresArray
    effluent.effluentQuestions.set(event.target.name, true);
    setFarmDetails(prevFarmDetails => ({
      ...prevFarmDetails,
      surveyData: {
          ...prevFarmDetails.surveyData,
          Effluent: {
              ...prevFarmDetails.surveyData.Effluent,
              scoreArray: ScoresArray
          }
      }
  }));
  }
  //When checkbox is unticked, deduct score to module score array and set checkbox as unticked
  else{
    ScoresArray = subtractScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue)
    effluent.scoreArray = ScoresArray
    effluent.effluentQuestions.delete(event.target.name);
    setFarmDetails(prevFarmDetails => ({
      ...prevFarmDetails,
      surveyData: {
          ...prevFarmDetails.surveyData,
          Effluent: {
              ...prevFarmDetails.surveyData.Effluent,
              scoreArray: ScoresArray
          }
      }
  }));
  }
};

// Update user's choice of practice
function updatePractice(e) {
  effluent.practices[e.target.name] = e.target.value
   // Create a deep copy of the farmDetails object
   const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
   // Update the nested property
   newFarmDetails.surveyData['Effluent'].questions[e.target.name].practice = e.target.value;
   // Update the state
   setFarmDetails(newFarmDetails);
   setIsDataEdited(true)
}

// Update whether user would like to focus on that area
function updateFocus(e) {
  effluent.focusArea[e.target.name] = e.target.value
  // Create a deep copy of the farmDetails object
  const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
  // Update the nested property
  newFarmDetails.surveyData['Effluent'].questions[e.target.name].focusCheck = e.target.value;
  // Update the state
  setFarmDetails(newFarmDetails);
  setIsDataEdited(true)
}

const isAEDT = (date) => {
  const year = date.getFullYear();
  const startDST = new Date(Date.UTC(year, 9, 1)); // October 1st
  const endDST = new Date(Date.UTC(year, 3, 1)); // April 1st

  // Find the first Sunday in October
  startDST.setUTCDate(1 + (7 - startDST.getUTCDay()) % 7);
  
  // Find the first Sunday in April
  endDST.setUTCDate(1 + (7 - endDST.getUTCDay()) % 7);

  return (date >= startDST && date < endDST);
};


  const getCurrentDateTime = () => {
    const nowUTC = new Date(Date.now());

    const offset = isAEDT(nowUTC) ? 12 : 11; // UTC+13 if Daylight Saving Time, otherwise UTC+12
  
    const aet = new Date(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(),
                         nowUTC.getUTCHours() + offset, nowUTC.getUTCMinutes(), nowUTC.getUTCSeconds());
  
    const year = aet.getFullYear();
    const month = String(aet.getMonth() + 1).padStart(2, '0');
    const day = String(aet.getDate()).padStart(2, '0');
    const hour = String(aet.getHours()).padStart(2, '0');
    const minute = String(aet.getMinutes()).padStart(2, '0');
    const second = String(aet.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}${hour}${minute}${second}`;
  };



// Helper function to prepare payload
const preparePayload = (prevDetails, specificFields = {}) => {
  const currentState = stateRef.current;
  const { surveyData } = currentState.farmDetails;
  console.log(currentState.farmDetails)
  const surveyDataWithoutGraphs = { ...currentState.farmDetails.surveyData };

  if (surveyData.Carbon) {
    const { graph: carbonGraph, ...rest } = surveyData.Carbon;
    surveyDataWithoutGraphs.Carbon = rest;
  }

  if (surveyData.Nitrogen) {
    const { graph: nitrogenGraph, ...rest } = surveyData.Nitrogen;
    surveyDataWithoutGraphs.Nitrogen = rest;
  }

  if (surveyData.Irrigation) {
    const { graph: irrigationGraph, ...rest } = surveyData.Irrigation;
    surveyDataWithoutGraphs.Irrigation = rest;
  }

  if (surveyData.HealthyCalves) {
    const { colostrum, calfPathways, ...rest } = surveyData.HealthyCalves;
  
    // Remove graph1 and graph2 from colostrum
    const { graph1: colostrumGraph1, graph2: colostrumGraph2,graph3: colostrumGraph3, graph4: colostrumGraph4, ...colostrumRest } = colostrum;
    
    // Remove graph1 and graph2 from calfPathways
    const { graph1: calfPathwaysGraph1, graph2: calfPathwaysGraph2, ...calfPathwaysRest } = calfPathways;
  
    surveyDataWithoutGraphs.HealthyCalves = {
      ...rest,
      colostrum: colostrumRest,
      calfPathways: calfPathwaysRest
    };

    if (surveyData.GeneralFarm) {
      const { farmMap, ...restGeneralFarm } = surveyData.GeneralFarm;
      surveyDataWithoutGraphs.GeneralFarm = restGeneralFarm;
    }
  }

  console.log(surveyDataWithoutGraphs)

  return {
    ...prevDetails,
    dateEdited: getCurrentDateTime(),
    region: farmDetails.farmRegion,
    status: "DRAFT",
    financialYear: farmDetails.financialYear,
    farmId: farmDetails.supplier_no,
    surveyData: surveyDataWithoutGraphs,
    modules: farmDetails.modules,
    ...specificFields
  };
};



// Helper function to make Axios call
const makeAxiosCall = async (urlSuffix, payload) => {
  try {
    const token = await acquireAccessToken(instance, accounts[0]);
    localStorage.setItem("accessToken", token);
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/${urlSuffix}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('Response:', response.data);
    console.log(payload);
    return response;
  } catch (error) {
    console.error('An error occurred while performing Axios POST:', error);
    return null;  // Return null if there's an error
  }
}



const handleSave = (saveType) => {
  const currentState = stateRef.current;

  const urlSuffix = saveType === "autosave" ? "modifyFarmSurveyData" : "insertFarmSurveyData";
  saveType !== "autosave" && setOpenSavingDialog(true);

  if (operation !== "modify") {
    farmDetails.farmSurveyId = farmDetails.supplier_no + getCurrentDateTime();
  }
  // Check if highestRevisionNumber is defined and is a string of 4 digits
  let validRevisionNumber = highestRevisionNumber && /^\d{4}$/.test(highestRevisionNumber);
  let newRevisionNumber;

  if (saveType !== 'autosave') {
    if (validRevisionNumber) {
      // Increment the revision number if it's valid
      newRevisionNumber = (parseInt(highestRevisionNumber, 10) + 1).toString().padStart(4, '0');
    } else {
      // Default to '0001' if highestRevisionNumber is undefined or invalid
      newRevisionNumber = '0001';
    }
  }

        const regex1 = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
  let match1;
  const images1 = [];

  while ((match1 = regex1.exec(currentState.value)) !== null) {
    const imageName = saveType==='autosave' ? `effluentCommentary${images1.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `effluentCommentary${images1.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
    images1.push({ base64: match1[1], name: imageName });
  }

  console.log(images1);

  let modifiedText1 = currentState.value;

  // Replace base64 values with image names
  for (let i = 0; i < images1.length; i++) {
    modifiedText1 = modifiedText1.replace(images1[i].base64, images1[i].name);
  }

  // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
  modifiedText1 = modifiedText1.replace(/<img src="([^"]+)"[^>]*>/g, (match1, p1) => {
    return p1 + '\n';  // Add a newline character after the image name
  });

  // First, handle the <li> tags by converting them to bullet points
  modifiedText1 = modifiedText1.replace(/<li[^>]*>/gi, '• ');
  modifiedText1 = modifiedText1.replace(/<\/li>/gi, '\n');

  // Then, remove the <ul> and </ul> tags as they are no longer necessary
  modifiedText1 = modifiedText1.replace(/<\/?ul[^>]*>/gi, '\n');

  // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
  modifiedText1 = modifiedText1.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

  // Convert inline elements to spaces or remove them
  modifiedText1 = modifiedText1.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

  // Convert <br> tags to newlines
  modifiedText1 = modifiedText1.replace(/<br[^>]*>/gi, '\n');

  // Remove other HTML tags
  modifiedText1 = modifiedText1.replace(/<\/?[^>]+(>|$)/g, '').trim();

  // Replace multiple newlines with a single newline
  modifiedText1 = modifiedText1.replace(/\n+/g, '\n').trim();

  // Replace multiple spaces with a single space
  modifiedText1 = modifiedText1.replace(/ +/g, ' ').trim();
  
  effluent.commentary = modifiedText1;
  currentState.farmDetails.surveyData['Effluent'].commentary = modifiedText1;


      const regex2 = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
  let match2;
  const images2 = [];

  while ((match2 = regex2.exec(currentState.valueAction)) !== null) {
    const imageName = saveType==='autosave' ? `effluentAction${images2.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `effluentAction${images2.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
    images2.push({ base64: match2[1], name: imageName });
  }

  console.log(images2);

  let modifiedText2 = currentState.valueAction;
  console.log(modifiedText2)

  // Replace base64 values with image names
  for (let i = 0; i < images2.length; i++) {
    modifiedText2 = modifiedText2.replace(images2[i].base64, images2[i].name);
  }

  // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
  modifiedText2 = modifiedText2.replace(/<img src="([^"]+)"[^>]*>/g, (match2, p1) => {
    return p1 + '\n';  // Add a newline character after the image name
  });

  // First, handle the <li> tags by converting them to bullet points
  modifiedText2 = modifiedText2.replace(/<li[^>]*>/gi, '• ');
  modifiedText2 = modifiedText2.replace(/<\/li>/gi, '\n');

  // Then, remove the <ul> and </ul> tags as they are no longer necessary
  modifiedText2 = modifiedText2.replace(/<\/?ul[^>]*>/gi, '\n');

  // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
  modifiedText2 = modifiedText2.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

  // Convert inline elements to spaces or remove them
  modifiedText2 = modifiedText2.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

  // Convert <br> tags to newlines
  modifiedText2 = modifiedText2.replace(/<br[^>]*>/gi, '\n');

  // Remove other HTML tags
  modifiedText2 = modifiedText2.replace(/<\/?[^>]+(>|$)/g, '').trim();

  // Replace multiple newlines with a single newline
  modifiedText2 = modifiedText2.replace(/\n+/g, '\n').trim();

  // Replace multiple spaces with a single space
  modifiedText2 = modifiedText2.replace(/ +/g, ' ').trim();
  
  effluent.action = modifiedText2;
  currentState.farmDetails.surveyData['Effluent'].action = modifiedText2;

  // Define a helper function to make the image upload call
  const uploadGraph = async (imageName, graphData) => {
    if (imageName.startsWith('farmPic_')) {
      if (!farmDetails.hasFarmPicChanged) {
        return true
      }
      else {
        setFarmDetails(prevDetails => ({
          ...prevDetails,
          hasFarmPicChanged: false
        }));
      }
    }
    const imagePayload = {
      "imageName": imageName,
      "imageDetails": graphData
    };
    console.log("Image Payload:", imagePayload);
    return makeAxiosCall("storeImage", imagePayload);
  };

      const imageUploadPromises1 = images1.map(image => {
      return uploadGraph(image.name, image.base64);
    });

    const imageUploadPromises2 = images2.map(image => {
      return uploadGraph(image.name, image.base64);
    });


  setPayload(prevDetails => {
    const specificFields = operation === "modify" ? {
      revisionNumber: saveType==='autosave' ? 'AUTOSAVE' : newRevisionNumber,
      id: saveType==='autosave' ? farmDetails.supplier_no : farmDetails.supplier_no + `_${newRevisionNumber}`,
      editedBy: accounts[0].name
  } : {
      creatorName: accounts[0].name,
      dateCreated: getCurrentDateTime(),
      id: farmDetails.supplier_no + `_${newRevisionNumber}`,
      schemaVersionNum: "001",
      surveyVersionNumber: "1",
      status: "DRAFT",
      farmSurveyId: farmDetails.farmSurveyId,
      revisionNumber: newRevisionNumber,
      editedBy: accounts[0].name
  };

    const updatedPayload = preparePayload(prevDetails, specificFields);

    (async () => {
      try {
        console.log(updatedPayload)
        const response = await makeAxiosCall(urlSuffix, updatedPayload);

        if (response) {
          if (saveType !== 'autosave') { 
            setHighestRevisionNumber(newRevisionNumber);
          }
          if (operation !== "modify") {
            farmDetails.farmSurveyId = updatedPayload.farmSurveyId;
            farmDetails.date_edited = updatedPayload.dateEdited;
            setOperation('modify');
          }

          const graphPaths = [
            { path: "HealthyCalves.colostrum.graph1", namePrefix: "healthyCalvesColostrumGraph1_" },
            { path: "HealthyCalves.colostrum.graph2", namePrefix: "healthyCalvesColostrumGraph2_" },
            { path: "HealthyCalves.colostrum.graph3", namePrefix: "healthyCalvesColostrumGraph3_" },
            { path: "HealthyCalves.colostrum.graph4", namePrefix: "healthyCalvesColostrumGraph4_" },
            { path: "HealthyCalves.calfPathways.graph1", namePrefix: "healthyCalvesCalfPathwaysGraph1_" },
            { path: "HealthyCalves.calfPathways.graph2", namePrefix: "healthyCalvesCalfPathwaysGraph2_" },
            { path: "Nitrogen.graph", namePrefix: "nitrogenGraph_" },
            { path: "Carbon.graph", namePrefix: "carbonGraph_" },
            { path: "Irrigation.graph", namePrefix: "irrigationGraph_" },
            { path: "farmPic", namePrefix: "farmPic_" },
            { path: "GeneralFarm.farmMap", namePrefix: "farmMap_" }
          ];
        
          const graphPromises = graphPaths.map(graph => {
            const parts = graph.path.split('.');
            let graphValue;
          
            switch (parts.length) {
              case 1:
                graphValue = currentState.farmDetails[parts[0]];
                break;
              case 2:
                if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]]) {
                  graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]];
                }
                break;
              case 3:
                if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]] && currentState.farmDetails.surveyData[parts[0]][parts[1]]) {
                  graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]][parts[2]];
                }
                break;
              default:
                break;
            }


            if (graphValue && graphValue !== "") {
              if (saveType === 'autosave') {
                return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_AUTOSAVE`, graphValue);
              }
              else {
                return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_${newRevisionNumber}`, graphValue);
              }
            }
            return Promise.resolve(true);
          });
          
           // Merge imageUploadPromises and graphPromises
           const allUploadPromises = [...imageUploadPromises1, ...imageUploadPromises2, ...graphPromises];

           const allUploadResponses = await Promise.all(allUploadPromises);

           if (allUploadResponses.every(res => res)) {
            setOpenSavingDialog(false);
            saveType !== "autosave" && setOpenSaveSuccessDialog(true);
            // After save is successful
            setIsTextEdited(false);
            setIsDataEdited(false)
            setOpenUnsavedChangesDialog(false);
          } else {
            setOpenSavingDialog(false);
            saveType !== "autosave" && setOpenSaveFailedDialog(true);
          }
        } else {
          setOpenSavingDialog(false);
          saveType !== "autosave" && setOpenSaveFailedDialog(true);
        }
      } catch (error) {
        console.error("An error occurred during saving:", error);
        setOpenSavingDialog(false);
        saveType !== "autosave" && setOpenSaveFailedDialog(true);
      }
    })();

    return updatedPayload;
  });
};

const allQuestionsAnswered = () => {
  return QArray.every((arr, post) => {
    return farmDetails?.surveyData?.['Effluent']?.questions[post]?.notApplicable ||
           (farmDetails?.surveyData?.['Effluent']?.questions[post]?.practice && farmDetails?.surveyData?.['Effluent']?.questions[post]?.focusCheck);
  });
}

const quillRef = useRef(null);
const quillRef1 = useRef(null);// Reference to the ReactQuill component
useEffect(() => {
  if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage();                               
              return false;              
            }                        
            const sizeInBytes = (lastImage.length * 3) / 4;
                const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB);  
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage();                
                      return false; 
                  }   
                }              
              }              
            }
        };

      // Attach the event listener
      quill.on('text-change', handleChange);

      // Clean up the event listener when the component unmounts
      return () => {
          quill.off('text-change', handleChange);
      };
  }
}, []);
useEffect(() => {
  if (quillRef1.current) {
      const quill = quillRef1.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage();                               
              return false;              
            }                        
            const sizeInBytes = (lastImage.length * 3) / 4;
                const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB);  
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage1();                
                      return false; 
                  }   
                }              
              }              
            }
        };

      // Attach the event listener
      quill.on('text-change', handleChange);

      // Clean up the event listener when the component unmounts
      return () => {
          quill.off('text-change', handleChange);
      };
  }
}, []);

const handleDeleteImage = () => {
  const quill = quillRef.current.getEditor();
  const selection = quill.getSelection();
  quill.history.undo();
};

const handleDeleteImage1 = () => {
  const quill = quillRef1.current.getEditor();
  const selection = quill.getSelection();
  quill.history.undo();
};

const getImageType = (lastImage) => {
  const matches = lastImage.match(/^data:(.*);base64,/);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return null;
};

const customImageHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    const content = editor.getContents();
    console.log("Custom image handler triggered"); // This should be printed whenever an image is added

    // Count the number of images
    let imageCount = 0;
    content.ops.forEach(op => {
        if (typeof op.insert === 'string' && op.insert.includes('<img')) {
            imageCount++;
        }
    });

    if (imageCount >= 5) {
        alert('You can only insert a maximum of 5 images.');
    } else {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const range = selection;
                editor.insertEmbed(range.index, 'image', reader.result);
            };

            reader.readAsDataURL(file);
        };
    }
};

const modules = useMemo(() => ({
  toolbar: {
    container: [
      [{ list: 'bullet' }],
      ['image']
    ]
  }
}), [])

const checkUnsavedChanges = () => {
  return isTextEdited ; // where isTextEdited is a state variable that tracks if the text has been edited
};  

  return (
    <div className="effluent-page">
      {console.log(farmDetails)}
      {console.log(payload)}
                         <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
        <Grid item xs={3}>
         <div className="progressbar">
         <ProgressBar currentStep={stepIndex} checkUnsavedChanges={checkUnsavedChanges} setOpenUnsavedChangesDialog={setOpenUnsavedChangesDialog} setUnsavedChangesNavigationPath={setUnsavedChangesNavigationPath}/>
           </div>
           </Grid>
            <Grid item xs={9}>
      <header className="effluent-header">
        <h1 className="effluent-h1">Effluent Management</h1>
                
        <div className="module-questions"> 
            <h5>* indicates <b>mandatory fields</b></h5>

                    <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
             
              <h3>Please enter value for hectare applied with effluent<sup>*</sup> </h3>
            </AccordionSummary>
            <AccordionDetails>

        <p>
          <TextField  
            variant="standard"
            type="number"
            required
            fullWidth
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Effluent'] ? farmDetails.surveyData['Effluent'].haApplied : ""}
            label="Ha applied with effluent"
            InputProps={{ style: { fontSize: 20 } }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            onChange={(e) => {
             const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                        effluent.haApplied = e.target.value;
                        farmDetails.surveyData['Effluent'].haApplied = (e.target.value)       
                        setIsDataEdited(true)
                    }
                else {
                    // setOpenDialog1(true);

                }
    
            }}/>
          </p>
          
            </AccordionDetails>
          </Accordion>


        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
            Commentary
            {farmDetails?.surveyData?.['GeneralFarm']?.commentary &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
              <h3>Commentary<sup>*</sup> </h3>
            </AccordionSummary>

            {/* <AccordionDetails>
              <Typography>
                <TextareaAutosize
                  minRows={3}
                  maxRows={5}
                  placeholder="Commentary"
                  defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].commentary : ""}
                  style={{
                    color: 'black',
                    width: "100%",
                    paddingBottom: '2%',
                    border: "2px solid #000000",
                    fontSize: 20,
                    fontFamily: "Arial",
                    backgroundColor: "#EBF4F9",
                  }}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm

                  //   setFarmDetails(prevFarmDetails => ({
                  //     ...prevFarmDetails,
                  //     surveyData: {
                  //       ...prevFarmDetails.surveyData,
                  //       GeneralFarm: {
                  //         ...prevFarmDetails.surveyData.GeneralFarm,
                  //         commentary: newValue
                  //       }
                  //     }
                  //   }));
                  // }}
                  onChange={(e) => {
                    generalFarm.commentary = (e.target.value)
                    farmDetails.surveyData['GeneralFarm'].commentary = (e.target.value)
                  }}
                />
              </Typography>
            </AccordionDetails> */}

            <AccordionDetails>
              <ReactQuill ref={quillRef} theme="snow" value={value} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValue(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)
                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
              <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.*Only Jpeg/Png images are allowed.</p>
            </AccordionDetails>
          </Accordion>

        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3>
          Questions<sup>*</sup>  
          { allQuestionsAnswered() && <CheckCircleIcon style={{ marginLeft: '10px', marginBottom: '-3px', color: 'green', fontSize: '1.5rem' }} /> }
        </h3>
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
                {/* map through the QArray to display questions and options */} 
        {QArray.map((arr, post) =>
          divDisplay ? (
            <div>
              <List
                sx={{ width: "100%", bgcolor: "#EBF4F9"}}
                component = "nav"
                aria-labelledby = "nested-list-subheader" >

                <ListItemButton style={{ fontSize: 20, fontWeight: 'bold' }} onClick={() => handleClick(post)}>
                  Question {post+1}.{arr.question}
                  { 
                    (farmDetails?.surveyData?.['Effluent']?.questions[post]?.notApplicable ||
                    (farmDetails?.surveyData?.['Effluent']?.questions[post]?.practice && farmDetails?.surveyData?.['Effluent']?.questions[post]?.focusCheck)) &&
                    <CheckIcon style={{ marginLeft: '10px', color: 'green' }} />
                  }
                </ListItemButton>

                {postDisplay[post] ?               
                <FormControl>
                                  <div style={{ marginLeft: '2%', marginTop: '15px' }}>
                  <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={farmDetails.surveyData['Effluent'].questions[post].notApplicable}
                            onChange={() => {
                                // Copy the current farmDetails state
                                const updatedFarmDetails = {...farmDetails};
                                // Toggle the notApplicable value for the current question
                                updatedFarmDetails.surveyData['Effluent'].questions[post].notApplicable = !updatedFarmDetails.surveyData['Effluent'].questions[post].notApplicable;
                              
                                if (updatedFarmDetails.surveyData['Effluent'].questions[post].notApplicable) { 
                                    // Reset checkboxes
                                    farmDetails.surveyData['Effluent'].questions[post].subQuestions.forEach((subQ, j) => {
                                        const currentSection = QArray[post].section[j];
                                        currentSection.option.key.forEach((key, k) => {
                                            updatedFarmDetails.surveyData['Effluent'].questions[post].subQuestions[j][k] = false;
                                            // Update generalFarm.scoreArray and generalFarm.generalQuestions
                                            ScoresArray = subtractScore(ScoresArray, key, currentSection.option.value[k]);
                                            effluent.scoreArray = ScoresArray;
                                            effluent.effluentQuestions.delete(key);
                                            setFarmDetails(prevFarmDetails => ({
                                              ...prevFarmDetails,
                                              surveyData: {
                                                  ...prevFarmDetails.surveyData,
                                                  Effluent: {
                                                      ...prevFarmDetails.surveyData.Effluent,
                                                      scoreArray: ScoresArray
                                                  }
                                              }
                                          }));
                                        });
                                    });
                                    
                                    // Reset practice and focusCheck
                                    updatedFarmDetails.surveyData['Effluent'].questions[post].practice = "";
                                    updatedFarmDetails.surveyData['Effluent'].questions[post].focusCheck = "";
                                    effluent.practices[post] = "";
                                    effluent.focusArea[post] = "";
                                }
                                
                                // Update the state
                                setFarmDetails(updatedFarmDetails);
                                setIsDataEdited(true)
                            }}
                            name={`notApplicable_${post}`}
                        />
                    }
                    label={
                      <Typography variant="body1" style={{  fontStyle: 'italic' }}>
                          Not Applicable for the farm
                      </Typography>
                  }
                />

              </div>
                  {arr.section.map((innerArr,j) =>(                  
                    <div key = {j}>
                      <FormLabel  component = "legend" style = {{color: 'black', marginLeft: '2%', paddingTop: '2%'}}> {innerArr.statement} </FormLabel>
                      {innerArr.option.text.map((text,k) => (
                        <FormGroup key = {k}>
                        <FormControlLabel control = {
                            <Checkbox 
                                onChange =  { handleChange }
                                name = {innerArr.option.key[k]}
                                value = {innerArr.option.value[k]}
                                style = {{marginLeft: '2%'}}
                                checked = {farmDetails.surveyData['Effluent'].questions[post].subQuestions[j][k]}
                                data-post={post}
                                data-subq={j}
                                data-opt={k}
                                disabled={farmDetails.surveyData['Effluent'].questions[post].notApplicable}
                            />}
                            label = {text}
                              style={{width: "110ch",wordWrap: 'break-word'}}
                        />
                    </FormGroup>
                      ))} 
                    </div>
                  ))}

                  <h6 style={{marginLeft: '2%', fontSize: '20px'}}>
                    Suggestion: {GetSuggestion(ScoresArray[post])}
                  </h6>

                  <TextField
                    id="select-practice"
                    select
                    name = {post}
                     label={<CustomInputLabel label="Select appropriate practice" />}
                    value={farmDetails.surveyData['Effluent'].questions[post].practice} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch' }}
                    onChange = {updatePractice}
             
                    disabled={farmDetails.surveyData['Effluent'].questions[post].notApplicable}
                    InputProps={{ style: { fontSize: 20} }}
                    InputLabelProps={{ style: { fontSize: 20} }}
                
                    >
                    
                    {practices.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="select-improvement"
                    select
                    name = {post}
                    label={<CustomInputLabel label="Is this a focus area you want support with?" />}
                    value={farmDetails.surveyData['Effluent'].questions[post].focusCheck} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch' }}
                    onChange = {updateFocus}
                    InputProps={{ style: { fontSize: 15} }}
                InputLabelProps={{ style: { fontSize: 15} }}>
                    {wantImprovement.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>



                </FormControl>
                : null}     
              </List>    
              </div>
          ) : null
        )} 
          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
       <h3>Area of focus<sup>*</sup> </h3>
 
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
                 <h5>Maximum character limit - 550</h5>
           <Textarea
            maxRows={3}
            maxLength="551"
            placeholder="Focus"
            defaultValue={farmDetails.surveyData['Effluent'] ? farmDetails.surveyData['Effluent'].focus : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              onChange={(e) => {
                 if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    effluent.focus = (e.target.value)
                    farmDetails.surveyData['Effluent'].focus = (e.target.value)
                    setIsDataEdited(true)
                  }

          }}
          onKeyDown={(e) => {
            const value = e.target.value;
            const numberOfNewLines = (value.match(/\n/g) || []).length;
            
            if (numberOfNewLines >= 7 && e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onPaste={(e) => {
            // Get clipboard data
            const clipboardData = e.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('Text');
        
            // Count the number of lines in the pasted text
            const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
        
            // Check if the number of lines exceeds 10
            if (numberOfNewLines > 7) {
              e.preventDefault();
              // Trim the pasted text to 10 lines and insert it manually
              const trimmedData = pastedData.split('\n').slice(0, 8).join('\n');
              const selectionStart = e.target.selectionStart;
              const before = e.target.value.substring(0, selectionStart);
              const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
              e.target.value = before + trimmedData + after;
              // Update state
              effluent.focus = (e.target.value)
              farmDetails.surveyData['Effluent'].focus = (e.target.value)
              setIsDataEdited(true)
            }
          }}/>
          </Typography>
        </AccordionDetails>
      </Accordion>

        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
       <h3>Opportunity for follow up/support - (Optional)</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           <h5>Maximum character limit - 500</h5>
           <Textarea
            maxRows={3}
            maxLength="501"
            placeholder="Focus"
            defaultValue={farmDetails.surveyData['Effluent'] ? farmDetails.surveyData['Effluent'].focusAdditional : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              onChange={(e) => {
                  if ((e.target.value).length > 500) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                      effluent.focusAdditional = (e.target.value)
                    farmDetails.surveyData['Effluent'].focusAdditional = (e.target.value)
                    setIsDataEdited(true)
                  }

          }}
          onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 9 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 9) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 10).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  effluent.focusAdditional = (e.target.value)
                  farmDetails.surveyData['Effluent'].focusAdditional = (e.target.value)
                  setIsDataEdited(true)
                }
              }}/>
          </Typography>
        </AccordionDetails>
      </Accordion>

              <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
       <h3>Actions<sup>*</sup> </h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
             <ReactQuill ref={quillRef1} theme="snow" value={valueAction} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValueAction(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)
                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
           {/*  <TextareaAutosize
            minRows={3}
            maxRows={5}
           
            placeholder="Actions"
            defaultValue={farmDetails.surveyData['Effluent'] ? farmDetails.surveyData['Effluent'].action : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              onChange={(e) => {
                effluent.action = (e.target.value)
                farmDetails.surveyData['Effluent'].action = (e.target.value)
        }}/>*/}
          </Typography>
          <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>
        </AccordionDetails>
      </Accordion>

                    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
       <h3>Approach - (Optional)</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           <h5>Maximum character limit - 850</h5>
          <Textarea
              minRows={3}
              maxRows={5}
              maxLength="851"
              placeholder="Approach"
              defaultValue={farmDetails.surveyData['Effluent'] ? farmDetails.surveyData['Effluent'].approach : ""}
              style={{ 
                color: 'black', 
                width: "100%",
                paddingBottom: '2%',
                border: "2px solid #000000",
                fontSize: 20, 
                fontColor: "black", 
                fontFamily: "Arial", 
                backgroundColor:"#EBF4F9",}}
                onChange={(e) => {
                   if ((e.target.value).length > 850) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                      effluent.approach = (e.target.value)
                      farmDetails.surveyData['Effluent'].approach = (e.target.value)
                      setIsDataEdited(true)
                  }

            }}
            onKeyDown={(e) => {
              const value = e.target.value;
              const numberOfNewLines = (value.match(/\n/g) || []).length;
              
              if (numberOfNewLines >= 11 && e.key === "Enter") {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              // Get clipboard data
              const clipboardData = e.clipboardData || window.clipboardData;
              const pastedData = clipboardData.getData('Text');
          
              // Count the number of lines in the pasted text
              const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
          
              // Check if the number of lines exceeds 10
              if (numberOfNewLines > 11) {
                e.preventDefault();
                // Trim the pasted text to 10 lines and insert it manually
                const trimmedData = pastedData.split('\n').slice(0, 12).join('\n');
                const selectionStart = e.target.selectionStart;
                const before = e.target.value.substring(0, selectionStart);
                const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                e.target.value = before + trimmedData + after;
                // Update state
                effluent.approach = (e.target.value)
                farmDetails.surveyData['Effluent'].approach = (e.target.value)
                setIsDataEdited(true)
              }
            }}/>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div className="back-next-button">
      <Back back = {prev} onClick={() => handleNavigationAttempt(prev)}/>
              <Next 
                next={next} 
                onClick={() => handleNavigationAttempt(next)} 
                disabled={true}
              />
                  <div className="btn-text-left">
                  <Button  variant="contained" href="#contained-buttons"  className="btn-save" style={{ backgroundColor: isDataEdited ? "#002D4E" : "#a8a8a8"}}  sx={{ ml: 1}} onClick={handleSave} disabled={!isDataEdited}>Save</Button>
                {/* <Link to="/ModulePage">
                    <Button  variant="contained" href="#contained-buttons" id = "module" style={{ backgroundColor:"#002D4E"}}  sx={{ ml: 5}}>Module Page</Button>
              </Link> */}
              </div>

        </div>

              {console.log(next)}

        </div>
      </header>
                  </Grid>
        </Grid>
          </Box>
              {/* Dialog Component */}
              <Dialog
            open={openImageFormatDialog}
            onClose={handleImageFormatDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Type"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Invalid file type. Please select a JPEG or PNG file.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageFormatDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openImageSizeDialog}
            onClose={handleImageSizeDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Size"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              File size exceeds the limit (400 KB).
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageSizeDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
              <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Incomplete Questions"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please complete all questions before proceeding.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          {/* Save Successful Dialog */}
          <Dialog
          open={openSaveSuccessDialog}
          onClose={handleSaveSuccessDialogClose}
          aria-labelledby="save-success-dialog-title"
          aria-describedby="save-success-dialog-description"
      >
          <DialogTitle id="save-success-dialog-title">{"Save Successful"}</DialogTitle>
          <DialogContent>
              <DialogContentText id="save-success-dialog-description">
                  You have successfully saved the form.
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleSaveSuccessDialogClose} color="primary" autoFocus>
                  OK
              </Button>
          </DialogActions>
      </Dialog>

            {/* Save Failed Dialog */}
            <Dialog
        open={openSaveFailedDialog}
        onClose={handleSaveFailedDialogClose}
        aria-labelledby="save-failed-dialog-title"
        aria-describedby="save-failed-dialog-description"
    >
        <DialogTitle id="save-failed-dialog-title">{"Save Failed"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="save-failed-dialog-description">
                An error occurred while saving the form. Please try again.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveFailedDialogClose} color="primary" autoFocus>
                OK
            </Button>
        </DialogActions>
        </Dialog>

        {/* Saving Dialog */}
        <Dialog
        open={openSavingDialog}
        aria-labelledby="saving-dialog-title"
        aria-describedby="saving-dialog-description"
    >
        <DialogTitle id="saving-dialog-title">{"Saving..."}</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
                Please wait while we save your form.
            </DialogContentText>
            {/* Centered spinner with space above */}
            <CircularProgress size={40} style={{ marginTop: '10px' }} />
        </DialogContent>
        </Dialog>

        <Dialog
        open={openUnsavedChangesDialog}
        onClose={() => setOpenUnsavedChangesDialog(false)}
        aria-labelledby="unsaved-changes-title"
        aria-describedby="unsaved-changes-description"
      >
        <DialogTitle id="unsaved-changes-title">{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-description">
            You have unsaved changes in the commentary section. Please save before continuing.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
          onClick={() => {
            setOpenUnsavedChangesDialog(false);
            setIsTextEdited(false); // Reset the text edited state
            setIsDataEdited(false)
            navigate(unsavedChangesNavigationPath); // Navigate to the stored path
            setUnsavedChangesNavigationPath(null); // Reset the navigation path state
          }}
          style={{ color: 'red', marginRight: 'auto', marginLeft: '7px' }} // This aligns the button to the left
        >
          Continue without saving
        </Button>
        <div style={{ marginLeft: 'auto' }}> {/* This div groups the 'Cancel' and 'Save' buttons to the right */}
          <Button onClick={() => setOpenUnsavedChangesDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </div>
      </DialogActions>
      </Dialog>
             {/* Character Dialog */}
        <Dialog open={isCharacterLimitExceeded} onClose={handleCharacterDialogClose}>
      <DialogTitle>Character Limit Exceeded</DialogTitle>
      <DialogContent>
        You have exceeded the character limit. Please shorten your input.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCharacterDialogClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
}
  