import React from 'react';
import { Page, Text, StyleSheet, Image } from '@react-pdf/renderer';
import focus from "../../../images/Focus.jpg"
import goodBox from "../../../images/GoodBox.png"
import innovativeBox from "../../../images/InnovativeBox.png"
import improvementBox from "../../../images/ImprovementBox.png"
import tick from "../../../images/tickIcon.png"
import {default as styles} from "../Styles"
import { CalculateScores } from '../../Utilities'; 


const calfPathwaysStyle = StyleSheet.create({
  boxes: {
    flexDirection: 'row',
    height: '100%',
    width: '32.5%',
    marginLeft:"2.5%",
    marginRight: "2.5%"
  },
  focusText: {
    marginBottom: '8%',
    marginHorizontal: "5%",
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Arial',
    color: '#002D4E',
    padding: 0,
    overflowWrap: 'break-word'
  },
  focusAreaContent: {
    marginBottom: '3%',
    marginHorizontal: "5%",
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Arial',
    color: '#002D4E',
    lineHeight:1.4,
    padding: 0,
    overflowWrap: 'break-word'
  },
  focusTextTick: {
    marginTop:'2%',
    marginBottom: '3%',
    marginHorizontal: "2%",
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Arial',
    color: '#002D4E',
    lineHeight:1.5,
    padding: 0,
    overflowWrap: 'break-word'
  },
  boxText: {    
    flex:1,
    width: '27.5%',
    marginTop:'15px',
    marginLeft:"6%",
    marginRight: "6%", /* Adjust margin to create space between boxes */
    alignContent:'center',
    justifyContent:'center',  
    fontFamily: 'ArialBold', 
    fontSize: 14, 
    color:"#000000", 
    overflowWrap: 'break-word',  
    textAlign: 'center'    
  }, 
  tick:{
    width:28,
    height:28, 
  },
  secondSubtitle: {
    fontSize: 14,
    marginBottom: 60,
    textAlign: 'left',
    color: '#002D4E',
    marginLeft: 30,
    marginTop: -80,
    fontFamily: 'RecoletaMedium',
  }
})


export default function ColostrumAreaOfFocus(healthyCalves, farmDetails){
  CalculateScores(healthyCalves.calfPathways, healthyCalves.calfPathways.practices)
  return(
    <Page>
      <div style={{height: "20%"}}>
        <Image 
          src = {focus} />       
        <div style = {{flexDirection: "row", position: 'absolute'}}>
          <div style={{width: "80%"}}>
            <Text style={styles.title}>The Focus</Text>
            <Text style={styles.secondSubtitle}>Animal Health and Welfare - Healthy Calves</Text> 
            <Text style={calfPathwaysStyle.secondSubtitle}>(Calf Pathways) </Text>   
          </div>         
        </div>
      </div>

      <div style = {{height: "auto",  display:'inline-block'}}>
        <Text style={styles.secondTitle}>Area of focus</Text>
        <Text style = {calfPathwaysStyle.focusAreaContent}>{healthyCalves.calfPathways.focus}</Text> 
      </div>
        
      <div style = {{height: "10%", display:'inline-block'}}>
        <div style = {{flexDirection: "row",  alignItems: "center", marginLeft: '3%', marginRight: '3%', marginTop:15,display:'inline-block'}}>
          <Image 
            src = {innovativeBox}
            style={calfPathwaysStyle.boxes} />
          <Image 
            src = {goodBox}
            style={calfPathwaysStyle.boxes} />
          <Image 
            src = {improvementBox}
            style={calfPathwaysStyle.boxes} />

          <div style = {{flexDirection: "row", flexWrap:'wrap', padding: 0,  width: '100%', height:'100%',marginTop:'3%', position: "absolute",justifyContent:'space-between'}}>   
            <Text style = {calfPathwaysStyle.boxText}>{healthyCalves.calfPathways.scores.innovative} x Innovative Practices</Text> 
            <Text style = {calfPathwaysStyle.boxText}>{healthyCalves.calfPathways.scores.good} x Good Practices</Text> 
            <Text style = {calfPathwaysStyle.boxText}>{healthyCalves.calfPathways.scores.improvement} x Improvement Practices</Text> 
          </div>
        </div>
      </div>

       {healthyCalves.calfPathways.focusAdditional !== '' && (
      <div style = {{ backgroundColor: '#EBF4F9', marginHorizontal: '5%', width: '90%', borderRadius: '10%',marginTop:30, display:'inline-block'}}>
        <div style ={{display:'flex', flexDirection: "row", marginLeft: '2%', marginRight: '2%', width: '96%', marginVertical: "auto", alignItems:'center'}}>            
            <div style ={{display:'flex', alignItems:'center', width: "9%", justifyContent:'center'}}> <Image 
            src = {tick}
            style={calfPathwaysStyle.tick} />
          </div>
          
          <div style = {{width: "91%"}}>
            <Text style = {calfPathwaysStyle.focusTextTick}>{healthyCalves.calfPathways.focusAdditional}</Text> 
          </div>
          
        </div>
      </div>
       )}

      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
      `${pageNumber}`
      )} fixed />
    </Page>
  )
}