import React  from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import focus from "../../../images/Focus.jpg"
import {default as styles} from "../Styles"
import { Table, TableHeader,TableFooter  } from '../../../components/Utilities'
import { CalculateScores, CalculateFocusArea , IconPicture} from '../../Utilities'; 


export default function ColostrumTable(healthyCalves, farmDetails){
  const borderHeight = [60,40,50,50,40];
  CalculateScores(healthyCalves.colostrum, healthyCalves.colostrum.practices)
  CalculateFocusArea(healthyCalves.colostrum, healthyCalves.colostrum.focusColumn);
  IconPicture(healthyCalves.colostrum.practices, healthyCalves.colostrum.goodColumn, healthyCalves.colostrum.improvementColumn, healthyCalves.colostrum.innovativeColumn)
  const data = {
    module: "Animal Health and Welfare (Colostrum)",
    total:
    [healthyCalves.colostrum.scores.innovative, healthyCalves.colostrum.scores.good, healthyCalves.colostrum.scores.improvement, healthyCalves.colostrum.scores.focusArea],
    items: []}
    for (var i = 0; i < healthyCalves.colostrum.questions.length; i += 1){
      data.items.push({
        question: healthyCalves.colostrum.questions[i] || "",
        innovative: healthyCalves.colostrum.innovativeColumn[i] || 0,
        good: healthyCalves.colostrum.goodColumn[i] || 0,
        areaOfImprovement: healthyCalves.colostrum.improvementColumn[i] || 0,
        focusArea: healthyCalves.colostrum.focusColumn[i] || "",
      },)
    }
    
  return(
    <Page size="A4" style={styles.page}>
      <div >
        <Image 
        src = {focus}
        style={styles.headerImage} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Focus</Text>
        <Text style={styles.secondSubtitle}>Animal Health and Welfare - Healthy Calves (Colostrum)</Text>
          
        {TableHeader(data)}
        {Table(data.items,borderHeight, 10)}
        {TableFooter(data.total, 30)}
      </div>
      
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
} 