import * as React from "react";
import Button from "@mui/material/Button";
import { useState, useEffect, useMemo, useRef } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import useModuleLink from '../hooks/moduleLink';
import Next from "./Next";
import Back from "./Back";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Textarea from '@mui/joy/Textarea';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AppRunTimeContext } from '../Context/index'; 
import { useContext } from 'react';
import { IrrigationLine } from "./IrrigationLine";
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {addScore, subtractScore, GetSuggestion, practices, wantImprovement} from './Utilities.jsx'
import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import acquireAccessToken from "../accessToken"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ProgressBar from "./ProgressBar";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// Module questions with corresponding subquestions. 
//Each checkbox(option) are given a unique key with the initial of the module - question no - subquestion no - checkbox no.
//For instance, XX-00-00-00.
//The value for each option is decided based on how good the practices are.
const QArray = [
  {
    name: "General",
    content:[
 { number: 1, //Question no
    question: "What type of irrigation system/s does your farm have?", //Question content
    section: [
      {id:"1.1",
      statement: "",  //sub question
      option: {
        text: ["Applied according to soil type","Applied according to agronomy needs","Applied according to climate information","Applied unplanned", "NA"],  //options for checkbox
        value: [3, 3, 3, 0, 0],  // assigned value for each option
        key: ["IR-01-1-1","IR-01-1-2", "IR-01-1-3", "IR-01-1-4", "IR-01-1-5"] 
        //key for each checkbox, name as: module's initial-Question no-SubQ no-Checkbox no
      }},
    ]},
    { number: 2, //Question no
    question: "What factors do you consider when deciding on irrigation start-up? Are all staff trained in the current irrigation practices on farm? ", //Question content
    section: [
      {id:"2.1",
      statement: "",  //sub question
      option: {
        text: ["Applied according to soil type","Applied according to agronomy needs","Applied according to climate information","Applied unplanned"],  //options for checkbox
        value: [3, 3, 3, 0],  // assigned value for each option
        key: ["IR-02-1-1","IR-02-1-2", "IR-02-1-3", "IR-02-1-4"] 
        //key for each checkbox, name as: module's initial-Question no-SubQ no-Checkbox no
      }},
    ]},
   {
    number: 3, 
    question: "How are irrigation intervals determined? Readily Available Water (RAW) and evapotranspiration are critical to determination of required irrigation interval.", 
    section: [
      {id:"3.1",
      statement:"",
      option: {
        text: ["Based on a recognized irrigation calculator (i.e. irripasture)","Based on manual measurement of plant requirements","Based on ad hoc or self decision"],
        value: [8, 5, 0],
        key: ["IR-03-1-1","IR-03-1-2", "IR-03-1-3"] 
      }},
  ]},
  {
  number: 4, 
  question: "What automation processes are used during the irrigation application?", 
  section: [
    {id:"4.1",
    statement: "",
    option: {
      text: ["Irrigation system is fully automated","Irrigation system is automated only at certain times","Irrigation system is automated only at certain areas","Irrigation system is not automated"],
      value: [8, 4, 4, 0],
      key: ["IR-04-1-1","IR-04-1-2", "IR-04-1-3", "IR-04-1-4"] 
    }},
  ]},
  {
    number: 5, 
    question: "Does the irrigation system design allow for efficient use of water and energy?", 
    section: [
      {id:"5.1",
      statement: "5.1. Irrigation system design",
      option: {
        text: ["Water use per ha is measured and recorded at each irrigation","Initial system design is applied without changes","Irrigation system design has not been made"],
        value: [5, 3, 0],
        key: ["IR-05-1-1","IR-05-1-2", "IR-05-1-3"] 
      }},
      {id:"5.2",
      statement: "5.2. Water distribution",
      option: {
        text: ["Checked and adjusted to expected application rates","Checked only","Not checked"],
        value: [5, 3, 0],
        key: ["IR-05-2-1","IR-05-2-2", "IR-05-2-3"] 
      }},
    ]},
    {
      number: 6, 
      question: "What monitoring equipment is used to determine efficient use of irrigation?", 
      section: [
        {id:"6.1",
        statement: "6.1. Time",
        option: {
          text: ["Records water application periodically","Records water application only for each season","Not recorded"],
          value: [5, 3, 0] ,
          key: ["IR-06-1-1","IR-06-1-2", "IR-06-1-3"] 
        }},
        {id:"6.2",
        statement: "6.2. Area",
        option: {
          text: ["Records water application for individual sections of the farm","Records water application only for the farm as a whole","Not recorded"],
          value: [5, 3, 0] ,
          key: ["IR-06-2-1","IR-06-2-2", "IR-06-2-3"] 
        }},
      ]},
    {
      number: 7, 
      question: "How are soil related risk factor such as salinity, water logging, erosion and compaction managed?", 
      section: [
        {id:"7.1",
        statement: "7.1. Salinity levels",
        option: {
          text: ["Accessed using a network of observation bores","Accessed using a shallow observation bores","Not aware of salinity levels on farm", "Not assessed"],
          value: [4, 2, 0, 0],
          key: ["IR-07-1-1","IR-07-1-2", "IR-07-1-3", "IR-07-1-4"]  
        }},
        {id:"7.2",
        statement: "7.2. Soil factoring time",
        option: {
          text: ["Accessed periodically","Accessed once in a while", "Not assessed"],
          value: [4, 2, 0],
          key: ["IR-07-2-1","IR-07-2-2","IR-07-2-3"]  
        }},
        {id:"7.3",
        statement: "7.3. Water table",
        option: {
          text: ["Water table levels are monitored","Not aware of water table levels", "Not assessed"],
          value: [4, 0, 0],
          key: ["IR-07-3-1","IR-07-3-2","IR-07-3-2"]  
        }},
      ]},
  ]},
  {
    name:"Flood Irrigation",
    content:[
    {
      number: 8, 
      question: "Precision flood irrigation is most efficient when water is on and off the paddock in 8 hours, Do your paddocks meet this benchmark?", 
      section: [
        {id:"1.1",
        statement: "1.1. Water logging",
        option: {
          text: ["No water logging","Minimal water logging","Large water logging"],
          value: [4, 2, 0],
          key: ["IR-08-1-1","IR-08-1-2", "IR-08-1-3"]  
        }},
        {id:"1.2",
        statement: "1.2. Watering times",
        option: {
          text: ["Designed related to soil type","Between 2-4 hours","Greater than 8 hours"],
          value: [4, 2, 0],
          key: ["IR-08-2-1","IR-08-2-2","IR-08-2-3"]  
        }},
        {id:"1.3",
        statement: "1.3. Water on bays",
        option: {
          text: ["Lying less than 12 hours after irrigation","Lying on bays 12-24 hours after irrigation","Lying on bays more than 24 hours after irrigation"],
          value: [4, 2, 0],
          key: ["IR-08-3-1","IR-08-3-2","IR-08-3-3"]  
        }},
      ]},
    {
      number: 9, 
      question: "Do you consider the 48 hour weather forecast prior to irrigation?", 
      section: [
        {id:"2.1",
        statement: "",
        option: {
          text: ["Considered and watering is adjusted with a smart irrigation controller","Considered and watering is adjusted minimally","Not considered"],
          value: [8, 5, 3] ,
          key: ["IR-09-1-1","IR-09-1-2", "IR-09-1-3"] 
        }},
      ]},
      {
        number: 10, 
        question: "Do you test and monitor water quality in bores regularly?", 
        section: [
          {id:"3.1",
          statement: "3.1. Irrigation system design",
          option: {
            text: ["System design measure and record water use per ha at each irrigation","Initial design is applied without changes","Irrigation system design has not been made"],
            value: [5, 3, 0],
            key: ["IR-10-1-1","IR-10-1-2", "IR-10-1-3"] 
          }},
          {id:"3.2",
          statement: "3.2. Water distribution",
          option: {
            text: ["Checked and adjusted to expected application rates","Checked only","Not checked"],
            value: [5, 3, 0],
            key: ["IR-10-2-1","IR-10-2-2", "IR-10-1-3"] 
          }},
      ]},
      {
        number: 11, 
        question: "Do you utilise the correct shandy rate when irrigating wastewater (effluent), bore water etc.?", 
        section: [
          {id:"4.1",
          statement: "",
          option: {
            text: ["Groundwater shandy rate are measured and recorded for each irrigation","Groundwater shandy rate are calculated periodically","Groundwater shandy rate are not measured","Groundwater is not utilised"],
            value: [8, 5, 1, 0] ,
            key: ["IR-11-1-1","IR-11-1-2", "IR-11-1-3","IR-11-1-4"] 
          }},
        ]},
        {
          number: 12, 
          question: "Do you have a water market strategy?", 
          section: [
            {id:"4.1",
            statement: "",
            option: {
              text: ["Present with consistent actions","Present with minimal actions","Not present"],
              value: [8, 5, 0] ,
              key: ["IR-12-1-1","IR-12-1-2", "IR-12-1-3"] 
            }},
          ]},
]},
{
name:"Spray Irrigation",
    content:[
    {
      number: 13, 
      question: "Do you calibrate spray irrigators prior to the season starting?", 
      section: [
        {id:"1.1",
        statement: "",
        option: {
          text: ["Calibrated regularly for specific crop type","Calibrated regularly for normal use","Not regularly calibrated"],
          value: [8, 5, 0],
          key: ["IR-13-1-1","IR-13-1-2", "IR-13-1-3"]  
        }},
    ]},
    {
      number: 14, 
      question: "Is there an ongoing maintenance schedule for irrigation equipment?", 
      section: [
        {id:"2.1",
        statement: "",
        option: {
          text: ["Regular maintenance with efficiency improvement","Regular maintenance for service","Repaired only when breakdown occurs"],
          value: [8, 5, 0],
          key: ["IR-14-1-1","IR-14-1-2", "IR-14-1-3"]  
        }},
    ]},
    {
      number: 15, 
      question: "Are Variable Rate Irrigation (VRI) applications used if the technology is installed?", 
      section: [
        {id:"3.1",
        statement: "",
        option: {
          text: ["VRI is applied with specific consideration","VRI is applied generically","VRI is not installed"],
          value: [8, 5, 0],
          key: ["IR-15-1-1","IR-15-1-2", "IR-15-1-3"]  
        }},
    ]},
]},
]


export default function Irrigation() {
  const { instance, accounts } = useMsal();

  const {steps} = useContext(AppRunTimeContext);

  const {setIrrigation,irrigation} = useContext(AppRunTimeContext)
  const { farmDetails, setFarmDetails, payload, setPayload, operation, setOperation, highestRevisionNumber, setHighestRevisionNumber } = useContext(AppRunTimeContext);
  // Use the dictionary from generalFarm[images] to replace image filenames
  const formattedValue = (farmDetails.surveyData['Irrigation'] ? farmDetails.surveyData['Irrigation'].commentary : "").replace(/irrigationCommentary\S+/g, match => {
    console.log(match)
    const base64 = irrigation.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [value, setValue] = useState(formattedValue);

    const formattedValueAction = (farmDetails.surveyData['Irrigation'] ? farmDetails.surveyData['Irrigation'].action : "").replace(/irrigationAction\S+/g, match => {
    console.log(match)
    const base64 = irrigation.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [valueAction, setValueAction] = useState(formattedValueAction);


  var ScoresArray = irrigation.scoreArray

  const {setNextPrev, getNext, getPrev} = useModuleLink();
  setNextPrev("/Irrigation");
  let next = getNext();
  let prev = getPrev();

  const stepIndex = steps.findIndex(step => step.link === '/Irrigation');

  const [postDisplay, setPostDisplay] = useState(true);
  const divDisplay = true;
  const [state, setState] = React.useState(null);

  
  const [commentary, setCommentary] = useState(irrigation.commentary);

  const [openDialog, setOpenDialog] = useState(false);
  const [openSaveSuccessDialog, setOpenSaveSuccessDialog] = React.useState(false);
  const [openSaveFailedDialog, setOpenSaveFailedDialog] = React.useState(false);
  const navigate = useNavigate();
  const [openSavingDialog, setOpenSavingDialog] = React.useState(false);
  const [isTextEdited, setIsTextEdited] = useState(false);

  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);
  const [unsavedChangesNavigationPath, setUnsavedChangesNavigationPath] = useState(null);
  const [selectedIrrigationType, setSelectedIrrigationType] = useState(irrigation.selectedIrrigationType);
  const stateRef = useRef();
  const [isDataEdited, setIsDataEdited] = useState(false);
  const [openImageFormatDialog, setOpenImageFormatDialog] = React.useState(false);
  const [openImageSizeDialog, setOpenImageSizeDialog] = React.useState(false);


  useEffect(() => {
    stateRef.current = {
      farmDetails,
      value,
      valueAction
    };
  }, [farmDetails, value, valueAction]);
  const handleIrrigationTypeChange = (event, type) => {
    if (event.target.checked) {
      setSelectedIrrigationType(type);
      irrigation.selectedIrrigationType = type;
      farmDetails.surveyData['Irrigation'].selectedIrrigationType = type;
      setIsDataEdited(true)
    } else {
      setSelectedIrrigationType(""); // Uncheck and remove the selection
      irrigation.selectedIrrigationType = "";
      farmDetails.surveyData['Irrigation'].selectedIrrigationType = "";
      setIsDataEdited(true)
    }

    // Now, update the notApplicable status for all questions based on the selection
    const updatedFarmDetails = { ...farmDetails };
    QArray.forEach((Arr, i) => {
      // Skip if the Arr name is 'General' or if the index is from 0 to 6
      if (Arr.name === 'General') return;
      // Iterate through the irrigation types
      Arr.content.forEach((contentItem, post) => {
        // Get the question index
        const questionIndex = contentItem.number - 1;
        // Set notApplicable to true if the current irrigation type is not selected
        updatedFarmDetails.surveyData['Irrigation'].questions[questionIndex].notApplicable = (type !== Arr.name);
        // If it's not applicable, you might want to reset other related states here
        if (type !== Arr.name) {
          Arr.content.forEach((arr, post) => {
            // Mark the question as Not Applicable
            updatedFarmDetails.surveyData['Irrigation'].questions[arr.number - 1].notApplicable = true;
            
            // Reset subQuestions, practices, focusCheck, and update scores
            arr.section.forEach((section, j) => {
              section.option.key.forEach((key, k) => {
                updatedFarmDetails.surveyData['Irrigation'].questions[arr.number - 1].subQuestions[j][k] = false;
                // Update scores and other related state
                ScoresArray = subtractScore(ScoresArray, key, section.option.value[k]);
                irrigation.scoreArray = ScoresArray;
                irrigation.irrigationQuestions.delete(key);
              });
            });
            updatedFarmDetails.surveyData['Irrigation'].questions[arr.number - 1].practice = "";
            updatedFarmDetails.surveyData['Irrigation'].questions[arr.number - 1].focusCheck = "";
            irrigation.practices[arr.number - 1] = "";
            irrigation.focusArea[arr.number - 1] = "";
          });
        }
      });
    });

    // Update the state with the new farm details
    setFarmDetails(updatedFarmDetails);
    setIsDataEdited(true)
  };
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] = useState(false);
   
  const handleImageFormatDialogClose = () => {    
    setOpenImageFormatDialog(false);   
  };

  const handleImageSizeDialogClose = () => {
    setOpenImageSizeDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSaveSuccessDialogClose = () => {
    setOpenSaveSuccessDialog(false);
  };

  const handleSaveFailedDialogClose = () => {
    setOpenSaveFailedDialog(false);
  };

    const handleCharacterDialogClose = () => {
    setIsCharacterLimitExceeded(false);
  };

  const handleNextClick = () => {
    // const questions = farmDetails.surveyData['Irrigation'].questions;
    // const graphData = farmDetails.surveyData['Irrigation'].graph;

    // for (let i = 0; i < 6; i++) {

    //   if (!questions[i].practice || !questions[i].focusCheck) {
    //     console.log('open dialog')
    //     setOpenDialog(true);
    //     return;
    //   }
    // }

    
  // // Check if the graph data is still at its default value
  // if (graphData === "") {
  //   console.log('open dialog - graph data not set');
  //   setOpenDialog(true);
  //   return;
  // }

    // If all conditions are met, navigate to the next page.
    navigate(next);
  };
  
   
    const handleTextareaChange = (event) => {
    setCommentary(event.target.value);
    farmDetails.surveyData['Irrigation'].commentary = (event.target.value)      
    setIsDataEdited(true)                

  };

  const handleTextareaKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setCommentary(commentary + '\n  ');
    }
  };

    const handleImageGenerated = (imageData) => {
    irrigation.graph = imageData;
    farmDetails.surveyData['Irrigation'].graph = imageData
    setIsDataEdited(true)
    console.log(imageData)
  };

  useEffect(() => {
    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
        window.location.href = '/'; // Redirect to home page
    }

    
    if (farmDetails.surveyData['Irrigation']) {
    // Your code here will run after the component has mounted
      // Loop through the surveyData[1].questions array
      farmDetails.surveyData['Irrigation'].questions.forEach((question, index) => {
        // Update the generalFarm.practices array based on the practice in each question
        irrigation.practices[index] = question.practice;
        irrigation.focusArea[index] = question.focusCheck
      });
        console.log(irrigation.practices)
        // console.log(tempModule)
        // console.log(farmDetails)
    }
    else {
      if(!(farmDetails.surveyData['Irrigation'])) {
          setFarmDetails(prevDetails => ({
            ...prevDetails,
            surveyData: {
              ...prevDetails.surveyData,
              details: {
                farmOwner: farmDetails.farm_owner,
                planOwner: farmDetails.plan_owner,
                farmAddressLat: farmDetails.farmAddressLat,
                farmAddressLong: farmDetails.farmAddressLong,
                financialYear: farmDetails.financialYear,
                farmAddress: farmDetails.farmAddress
              },
              Irrigation: {
                module: "Irrigation",
                commentary: "",
                action: "",
                approach: "",
                focus: "",
                focusAdditional: "",
                waterApplied: "",
                rainfall: "",
                yourGPWUI: "",
                pastureGrown: "",
                scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0],
                irrigationPage: 0,
                graph: "",
                benchmarkcommentary: "",
                selectedIrrigationType: "",
                QuestionMapping: [
                  {
                    Q1_6: "What type of irrigation system/s does your farm have?",
                    Q2_6: "What factors do you consider when deciding on irrigation start-up? Are all staff trained in the current irrigation practices on farm?",
                    Q3_6: "How are irrigation intervals determined? Readily Available Water (RAW) and evapotranspiration are critical to determination of required irrigation interval.",
                    Q4_6: "What automation processes are used during the irrigation application?",
                    Q5_6: "Does the irrigation system design allow for efficient use of water and energy?",
                    Q6_6: "What monitoring equipment is used to determine efficient use of irrigation?",
                    Q7_6: "How are soil related risk factor such as salinity, water logging, erosion and compaction managed?",
                    Q8_6: "Precision flood irrigation is most efficient when water is on and off the paddock in 8 hours. Do your paddocks meet this benchmark?",
                    Q9_6: "Do you consider the 48 hour weather forecast prior to irrigation?",
                    Q10_6: "Do you test and monitor water quality in bores regularly?",
                    Q11_6: "Do you utilise the correct shandy rate when irrigating wastewater (effluent), bore water etc.?",
                    Q12_6: "Do you have a water market strategy?",
                    Q13_6: "Do you calibrate spray irrigators prior to the season starting?",
                    Q14_6: "Is there an ongoing maintenance schedule for irrigation equipment?",
                    Q15_6: "Are Variable Rate Irrigation (VRI) applications used if the technology is installed?"
                  }
                ],
                questions: [
                  {
                    subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                  },
                  {
                    subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                  },
                {
                    subQuestions: [
                      [
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                      [
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                      [
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ],
                      [
                        false,
                        false,
                        false
                      ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                  },               
                 {
                  subQuestions: [
                      [
                        false,
                        false,
                        false,
                        false
                      ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              },
              {
                subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                ],
                practice: "",
                focusCheck: "",
                notApplicable: false
              }
              ]
            }
          }
    }));
    }
  }

  }, []);  // Empty dependency array means this useEffect runs once after initial render

  useEffect(() => {
    // Update the irrigation context whenever commentary changes
    setIrrigation({
      ...irrigation,
      commentary: commentary
    });
  }, [commentary, setIrrigation]);

  const getChecked = (key) => {
    if (irrigation.irrigationQuestions.get(key)) 
      return true;
    else
      return false; 
  }

  const handleNavigationAttempt = (navigationPath) => {
    if (isTextEdited) {
      setUnsavedChangesNavigationPath(navigationPath); // Store the intended navigation path
      setOpenUnsavedChangesDialog(true); // If text is edited, show the warning dialog
    } else {
      navigate(navigationPath); // Otherwise, navigate immediately
    }
  };

  const handleClick = (post) => { 
    const objectCopy = {
      ...postDisplay
    };
    objectCopy[post] = !objectCopy[post];
    setPostDisplay(objectCopy);
  };

  useEffect(() => {
    // Function to be executed
    const intervalFunction = () => {
      console.log(operation);
      if (operation === "modify") {
        handleSave('autosave');
        console.log('This function runs every 30 seconds');
      }
      // Add your logic here
    };
  
    // Set the interval
    const interval = setInterval(intervalFunction, 120000);
  
    // Clear interval on component unmount or when operation changes
    return () => clearInterval(interval);
  }, [operation]); // Include operation in the dependency array
  
  const handleChange = (event) => {


    const postIndex = event.target.parentNode.dataset.post;
    const subQIndex = event.target.parentNode.dataset.subq;
    const optIndex = event.target.parentNode.dataset.opt;
    const name = event.target.parentNode.dataset.name;

    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });


    {console.log(postIndex)}
    {console.log(subQIndex)}
    {console.log(optIndex)}
    console.log(name)

    farmDetails.surveyData['Irrigation'].questions[postIndex].subQuestions[subQIndex][optIndex] = event.target.checked;
    setIsDataEdited(true)

    if (event.target.checked){
      ScoresArray = addScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue)
      irrigation.scoreArray = ScoresArray
      irrigation.irrigationQuestions.set(event.target.name, true);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            Irrigation: {
                ...prevFarmDetails.surveyData.Irrigation,
                scoreArray: ScoresArray
            }
        }
    }));
    }
    else{
      ScoresArray = subtractScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue)
      irrigation.scoreArray = ScoresArray
      irrigation.irrigationQuestions.delete(event.target.name);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            Irrigation: {
                ...prevFarmDetails.surveyData.Irrigation,
                scoreArray: ScoresArray
            }
        }
    }));
    }
  };


  function Calc(event) {

    irrigation.pastureGrown = ((+irrigation.rainfall) + (+irrigation.waterApplied)) / 1000;

    
  }

// Update user's choice of practice
function updatePractice(e) {
  irrigation.practices[e.target.name] = e.target.value
   // Create a deep copy of the farmDetails object
   const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
   // Update the nested property
   newFarmDetails.surveyData['Irrigation'].questions[e.target.name].practice = e.target.value;
   // Update the state
   setFarmDetails(newFarmDetails);
   setIsDataEdited(true)
}

// Update whether user would like to focus on that area
function updateFocus(e) {
  irrigation.focusArea[e.target.name] = e.target.value
  // Create a deep copy of the farmDetails object
  const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
  // Update the nested property
  newFarmDetails.surveyData['Irrigation'].questions[e.target.name].focusCheck = e.target.value;
  // Update the state
  setFarmDetails(newFarmDetails);
  setIsDataEdited(true)
}

const isAEDT = (date) => {
  const year = date.getFullYear();
  const startDST = new Date(Date.UTC(year, 9, 1)); // October 1st
  const endDST = new Date(Date.UTC(year, 3, 1)); // April 1st

  // Find the first Sunday in October
  startDST.setUTCDate(1 + (7 - startDST.getUTCDay()) % 7);
  
  // Find the first Sunday in April
  endDST.setUTCDate(1 + (7 - endDST.getUTCDay()) % 7);

  return (date >= startDST && date < endDST);
};


  const getCurrentDateTime = () => {
    const nowUTC = new Date(Date.now());

    const offset = isAEDT(nowUTC) ? 12 : 11; // UTC+13 if Daylight Saving Time, otherwise UTC+12
  
    const aet = new Date(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(),
                         nowUTC.getUTCHours() + offset, nowUTC.getUTCMinutes(), nowUTC.getUTCSeconds());
  
    const year = aet.getFullYear();
    const month = String(aet.getMonth() + 1).padStart(2, '0');
    const day = String(aet.getDate()).padStart(2, '0');
    const hour = String(aet.getHours()).padStart(2, '0');
    const minute = String(aet.getMinutes()).padStart(2, '0');
    const second = String(aet.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}${hour}${minute}${second}`;
  };

// Helper function to prepare payload
const preparePayload = (prevDetails, specificFields = {}) => {
  const currentState = stateRef.current;
  const { surveyData } = currentState.farmDetails;

  const surveyDataWithoutGraphs = { ...currentState.farmDetails.surveyData };

  if (surveyData.Carbon) {
    const { graph: carbonGraph, ...rest } = surveyData.Carbon;
    surveyDataWithoutGraphs.Carbon = rest;
  }

  if (surveyData.Nitrogen) {
    const { graph: nitrogenGraph, ...rest } = surveyData.Nitrogen;
    surveyDataWithoutGraphs.Nitrogen = rest;
  }

  if (surveyData.Irrigation) {
    const { graph: irrigationGraph, ...rest } = surveyData.Irrigation;
    surveyDataWithoutGraphs.Irrigation = rest;
  }

  if (surveyData.HealthyCalves) {
    const { colostrum, calfPathways, ...rest } = surveyData.HealthyCalves;
  
    // Remove graph1 and graph2 from colostrum
    const { graph1: colostrumGraph1, graph2: colostrumGraph2, graph3: colostrumGraph3, graph4: colostrumGraph4,...colostrumRest } = colostrum;
    
    // Remove graph1 and graph2 from calfPathways
    const { graph1: calfPathwaysGraph1, graph2: calfPathwaysGraph2, ...calfPathwaysRest } = calfPathways;
  
    surveyDataWithoutGraphs.HealthyCalves = {
      ...rest,
      colostrum: colostrumRest,
      calfPathways: calfPathwaysRest
    };

    if (surveyData.GeneralFarm) {
      const { farmMap, ...restGeneralFarm } = surveyData.GeneralFarm;
      surveyDataWithoutGraphs.GeneralFarm = restGeneralFarm;
    }
  }

  return {
    ...prevDetails,
    dateEdited: getCurrentDateTime(),
    region: farmDetails.farmRegion,
    status: "DRAFT",
    financialYear: farmDetails.financialYear,
    farmId: farmDetails.supplier_no,
    surveyData: surveyDataWithoutGraphs,
    modules: farmDetails.modules,
    ...specificFields
  };
};



// Helper function to make Axios call
const makeAxiosCall = async (urlSuffix, payload) => {
  try {
    const token = await acquireAccessToken(instance, accounts[0]);
    localStorage.setItem("accessToken", token);
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/${urlSuffix}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('Response:', response.data);
    console.log(payload);
    return response;
  } catch (error) {
    console.error('An error occurred while performing Axios POST:', error);
    return null;  // Return null if there's an error
  }
};



const handleSave = (saveType) => {
  const currentState = stateRef.current;

  const urlSuffix = saveType === "autosave" ? "modifyFarmSurveyData" : "insertFarmSurveyData";
  saveType !== "autosave" && setOpenSavingDialog(true);

  if (operation !== "modify") {
    farmDetails.farmSurveyId = farmDetails.supplier_no + getCurrentDateTime();
  }
  // Check if highestRevisionNumber is defined and is a string of 4 digits
  let validRevisionNumber = highestRevisionNumber && /^\d{4}$/.test(highestRevisionNumber);
  let newRevisionNumber;

  if (saveType !== 'autosave') {
    if (validRevisionNumber) {
      // Increment the revision number if it's valid
      newRevisionNumber = (parseInt(highestRevisionNumber, 10) + 1).toString().padStart(4, '0');
    } else {
      // Default to '0001' if highestRevisionNumber is undefined or invalid
      newRevisionNumber = '0001';
    }
  }
 
      const regex1 = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
  let match1;
  const images1 = [];

  while ((match1 = regex1.exec(currentState.value)) !== null) {
    const imageName = saveType==='autosave' ? `irrigationCommentary${images1.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `irrigationCommentary${images1.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
    images1.push({ base64: match1[1], name: imageName });
  }

  console.log(images1);

  let modifiedText1 = currentState.value;

  // Replace base64 values with image names
  for (let i = 0; i < images1.length; i++) {
    modifiedText1 = modifiedText1.replace(images1[i].base64, images1[i].name);
  }

  // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
  modifiedText1 = modifiedText1.replace(/<img src="([^"]+)"[^>]*>/g, (match1, p1) => {
    return p1 + '\n';  // Add a newline character after the image name
  });

  // First, handle the <li> tags by converting them to bullet points
  modifiedText1 = modifiedText1.replace(/<li[^>]*>/gi, '• ');
  modifiedText1 = modifiedText1.replace(/<\/li>/gi, '\n');

  // Then, remove the <ul> and </ul> tags as they are no longer necessary
  modifiedText1 = modifiedText1.replace(/<\/?ul[^>]*>/gi, '\n');

  // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
  modifiedText1 = modifiedText1.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

  // Convert inline elements to spaces or remove them
  modifiedText1 = modifiedText1.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

  // Convert <br> tags to newlines
  modifiedText1 = modifiedText1.replace(/<br[^>]*>/gi, '\n');

  // Remove other HTML tags
  modifiedText1 = modifiedText1.replace(/<\/?[^>]+(>|$)/g, '').trim();

  // Replace multiple newlines with a single newline
  modifiedText1 = modifiedText1.replace(/\n+/g, '\n').trim();

  // Replace multiple spaces with a single space
  modifiedText1 = modifiedText1.replace(/ +/g, ' ').trim();
  
  irrigation.commentary = modifiedText1;
  currentState.farmDetails.surveyData['Irrigation'].commentary = modifiedText1;


      const regex2 = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
  let match2;
  const images2 = [];

  while ((match2 = regex2.exec(currentState.valueAction)) !== null) {
    const imageName = saveType==='autosave' ? `irrigationAction${images2.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `irrigationAction${images2.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
    images2.push({ base64: match2[1], name: imageName });
  }

  console.log(images2);

  let modifiedText2 = currentState.valueAction;
  console.log(modifiedText2)

  // Replace base64 values with image names
  for (let i = 0; i < images2.length; i++) {
    modifiedText2 = modifiedText2.replace(images2[i].base64, images2[i].name);
  }

  // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
  modifiedText2 = modifiedText2.replace(/<img src="([^"]+)"[^>]*>/g, (match2, p1) => {
    return p1 + '\n';  // Add a newline character after the image name
  });

  // First, handle the <li> tags by converting them to bullet points
  modifiedText2 = modifiedText2.replace(/<li[^>]*>/gi, '• ');
  modifiedText2 = modifiedText2.replace(/<\/li>/gi, '\n');

  // Then, remove the <ul> and </ul> tags as they are no longer necessary
  modifiedText2 = modifiedText2.replace(/<\/?ul[^>]*>/gi, '\n');

  // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
  modifiedText2 = modifiedText2.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

  // Convert inline elements to spaces or remove them
  modifiedText2 = modifiedText2.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

  // Convert <br> tags to newlines
  modifiedText2 = modifiedText2.replace(/<br[^>]*>/gi, '\n');

  // Remove other HTML tags
  modifiedText2 = modifiedText2.replace(/<\/?[^>]+(>|$)/g, '').trim();

  // Replace multiple newlines with a single newline
  modifiedText2 = modifiedText2.replace(/\n+/g, '\n').trim();

  // Replace multiple spaces with a single space
  modifiedText2 = modifiedText2.replace(/ +/g, ' ').trim();
  
  irrigation.action = modifiedText2;
  currentState.farmDetails.surveyData['Irrigation'].action = modifiedText2;


  // Define a helper function to make the image upload call
  const uploadGraph = async (imageName, graphData) => {
    if (imageName.startsWith('farmPic_')) {
      if (!farmDetails.hasFarmPicChanged) {
        return true
      }
      else {
        setFarmDetails(prevDetails => ({
          ...prevDetails,
          hasFarmPicChanged: false
        }));
      }
    }
    const imagePayload = {
      "imageName": imageName,
      "imageDetails": graphData
    };
    console.log("Image Payload:", imagePayload);
    return makeAxiosCall("storeImage", imagePayload);
  };

    const imageUploadPromises1 = images1.map(image => {
      return uploadGraph(image.name, image.base64);
    });

    const imageUploadPromises2 = images2.map(image => {
      return uploadGraph(image.name, image.base64);
    });


  setPayload(prevDetails => {
    const specificFields = operation === "modify" ? {
      revisionNumber: saveType==='autosave' ? 'AUTOSAVE' : newRevisionNumber,
      id: saveType==='autosave' ? farmDetails.supplier_no : farmDetails.supplier_no + `_${newRevisionNumber}`,
      editedBy: accounts[0].name
  } : {
      creatorName: accounts[0].name,
      dateCreated: getCurrentDateTime(),
      id: farmDetails.supplier_no + `_${newRevisionNumber}`,
      schemaVersionNum: "001",
      surveyVersionNumber: "1",
      status: "DRAFT",
      farmSurveyId: farmDetails.farmSurveyId,
      revisionNumber: newRevisionNumber,
      editedBy: accounts[0].name
  };

    const updatedPayload = preparePayload(prevDetails, specificFields);

    (async () => {
      try {
        const response = await makeAxiosCall(urlSuffix, updatedPayload);

        if (response) {
          if (saveType !== 'autosave') { 
            setHighestRevisionNumber(newRevisionNumber);
          }
          if (operation !== "modify") {
            farmDetails.farmSurveyId = updatedPayload.farmSurveyId;
            farmDetails.date_edited = updatedPayload.dateEdited;
            setOperation('modify');
          }

          const graphPaths = [
            { path: "HealthyCalves.colostrum.graph1", namePrefix: "healthyCalvesColostrumGraph1_" },
            { path: "HealthyCalves.colostrum.graph2", namePrefix: "healthyCalvesColostrumGraph2_" },
            { path: "HealthyCalves.colostrum.graph3", namePrefix: "healthyCalvesColostrumGraph3_" },
            { path: "HealthyCalves.colostrum.graph4", namePrefix: "healthyCalvesColostrumGraph4_" },
            { path: "HealthyCalves.calfPathways.graph1", namePrefix: "healthyCalvesCalfPathwaysGraph1_" },
            { path: "HealthyCalves.calfPathways.graph2", namePrefix: "healthyCalvesCalfPathwaysGraph2_" },
            { path: "Nitrogen.graph", namePrefix: "nitrogenGraph_" },
            { path: "Carbon.graph", namePrefix: "carbonGraph_" },
            { path: "Irrigation.graph", namePrefix: "irrigationGraph_" },
            { path: "farmPic", namePrefix: "farmPic_" },
            { path: "GeneralFarm.farmMap", namePrefix: "farmMap_" }
          ];

          const graphPromises = graphPaths.map(graph => {
            const parts = graph.path.split('.');
            let graphValue;

            switch (parts.length) {
              case 1:
                graphValue = currentState.farmDetails[parts[0]];
                break;
              case 2:
                if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]]) {
                  graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]];
                }
                break;
              case 3:
                if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]] && currentState.farmDetails.surveyData[parts[0]][parts[1]]) {
                  graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]][parts[2]];
                }
                break;
              default:
                break;
            }
            if (graphValue && graphValue !== "") {
              if (saveType === 'autosave') {
                return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_AUTOSAVE`, graphValue);
              }
              else {
                return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_${newRevisionNumber}`, graphValue);
              }
            }
            return Promise.resolve(true);
          });

           // Merge imageUploadPromises and graphPromises
           const allUploadPromises = [...imageUploadPromises1, ...imageUploadPromises2, ...graphPromises];

           const allUploadResponses = await Promise.all(allUploadPromises);

           if (allUploadResponses.every(res => res)) {
            setOpenSavingDialog(false);
            saveType !== "autosave" && setOpenSaveSuccessDialog(true);
            // After save is successful
            setIsTextEdited(false);
            setIsDataEdited(false)
            setOpenUnsavedChangesDialog(false);
          } else {
            setOpenSavingDialog(false);
            saveType !== "autosave" && setOpenSaveFailedDialog(true);
          }
        } else {
          setOpenSavingDialog(false);
          saveType !== "autosave" && setOpenSaveFailedDialog(true);
        }
      } catch (error) {
        console.error("An error occurred during saving:", error);
        setOpenSavingDialog(false);
        saveType !== "autosave" && setOpenSaveFailedDialog(true);
      }
    })();

    return updatedPayload;
  });
};

const [openDialog1, setOpenDialog1] = useState(false);
const handleCloseDialog1 = () => {
  setOpenDialog1(false);
};

const allQuestionsAnswered = () => {
  return QArray.every(Arr => {
    // If the name is not "General", return true so that this section is not enforced
    if (Arr.name !== 'General') return true;

    // Otherwise, check the questions inside the "General" section
    return Arr.content.every((arr) => {
      return farmDetails?.surveyData?.['Irrigation']?.questions[arr.number-1]?.notApplicable ||
             (farmDetails?.surveyData?.['Irrigation']?.questions[arr.number-1]?.practice && farmDetails?.surveyData?.['Irrigation']?.questions[arr.number-1]?.focusCheck);
    });
  });
}

const quillRef = useRef(null);
const quillRef1 = useRef(null);// Reference to the ReactQuill component
useEffect(() => {
  if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage();                               
              return false;              
            }                                
            const sizeInBytes = (lastImage.length * 3) / 4;
                const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB);
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit                     
                      setOpenImageSizeDialog(true);
                      handleDeleteImage();                
                      return false; 
                  }   
                }              
              }              
            }
        };

      // Attach the event listener
      quill.on('text-change', handleChange);

      // Clean up the event listener when the component unmounts
      return () => {
          quill.off('text-change', handleChange);
      };
  }
}, []);
useEffect(() => {
  if (quillRef1.current) {
      const quill = quillRef1.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage();                               
              return false;              
            }                        
            const sizeInBytes = (lastImage.length * 3) / 4;
                const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB);  
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage1();                
                      return false; 
                  }   
                }              
              }              
            }
        };

      // Attach the event listener
      quill.on('text-change', handleChange);

      // Clean up the event listener when the component unmounts
      return () => {
          quill.off('text-change', handleChange);
      };
  }
}, []);

const handleDeleteImage = () => {
  const quill = quillRef.current.getEditor();
  const selection = quill.getSelection();
  quill.history.undo();
};

const handleDeleteImage1 = () => {
  const quill = quillRef1.current.getEditor();
  const selection = quill.getSelection();
  quill.history.undo();
};

const getImageType = (lastImage) => {
  const matches = lastImage.match(/^data:(.*);base64,/);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return null;
};

const customImageHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    const content = editor.getContents();
    console.log("Custom image handler triggered"); // This should be printed whenever an image is added

    // Count the number of images
    let imageCount = 0;
    content.ops.forEach(op => {
        if (typeof op.insert === 'string' && op.insert.includes('<img')) {
            imageCount++;
        }
    });

    if (imageCount >= 5) {
        alert('You can only insert a maximum of 5 images.');
    } else {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const range = selection;
                editor.insertEmbed(range.index, 'image', reader.result);
            };

            reader.readAsDataURL(file);
        };
    }
};

const modules = useMemo(() => ({
  toolbar: {
    container: [
      [{ list: 'bullet' }],
      ['image']
    ]
  }
}), [])

  const CustomInputLabel = ({ label }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ fontSize: '15px' }}>{label}</span>
    <span style={{ fontSize: '15px', color: 'red', fontWeight: 'bold', marginLeft: '4px' }}>*</span>
  </div>
   );

   const checkUnsavedChanges = () => {
    return isTextEdited; // where isTextEdited is a state variable that tracks if the text has been edited
  };  

  return (
    <div className="irrigation-page">
      {console.log(farmDetails)}
      {console.log(payload)}
      {console.log(selectedIrrigationType)}
      {console.log(irrigation)}

                   <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
        <Grid item xs={3}>
         <div className="progressbar">
         <ProgressBar currentStep={stepIndex} checkUnsavedChanges={checkUnsavedChanges} setOpenUnsavedChangesDialog={setOpenUnsavedChangesDialog} setUnsavedChangesNavigationPath={setUnsavedChangesNavigationPath}/>
           </div>
           </Grid>
            <Grid item xs={9}>
      <header className="irrigation-header">
        <h1 className="irrigation-h1">Irrigation Management</h1>
               
        <div className="module-questions"> 
             <h5>* indicates <b>mandatory fields</b></h5>
               <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>Benchmark<sup>*</sup></h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
                    <div className="benchmark">
          
        <p>
            <TextField  
              variant="standard"
              required
              fullWidth
              type="number"
              id="outlined-required"
              defaultValue={farmDetails.surveyData['Irrigation'] ? farmDetails.surveyData['Irrigation'].waterApplied : ""}
              label="Water Applied (mm/ha)"
              InputProps={{ style: { fontSize: 20 } }}
              InputLabelProps={{ style: { fontSize: 20 } }}
              onChange={(e) => {
                   const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
               setIrrigation({
                  ...irrigation,
                  waterApplied: e.target.value
                })      
                farmDetails.surveyData['Irrigation'].waterApplied = (e.target.value)     
                setIsDataEdited(true)           
                    }
                else {
                    setOpenDialog1(true);

                }
      
              }}
                />
          </p>

          <p>
            <TextField  
              variant="standard"
              required
              fullWidth
              type="number"
              id="outlined-required"
              defaultValue={farmDetails.surveyData['Irrigation'] ? farmDetails.surveyData['Irrigation'].rainfall : ""}
              label="Rainfall/DM/ha"
              InputProps={{ style: { fontSize: 20 } }}
              InputLabelProps={{ style: { fontSize: 20 } }}
              onChange={(e) => {
               const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
              setIrrigation({
                  ...irrigation,
                  rainfall: e.target.value
                })      
                farmDetails.surveyData['Irrigation'].rainfall = (e.target.value)          
                setIsDataEdited(true)         
                    }
                else {
                    setOpenDialog1(true);

                }
                  
              }}
                />
          </p>

          <p>
            <TextField  
              variant="standard"
              required
              fullWidth
              type="number"
              id="outlined-required"
              defaultValue={farmDetails.surveyData['Irrigation'] ? farmDetails.surveyData['Irrigation'].yourGPWUI : ""}
              label="Your Farm GPWUI (t DM/ML)"
              InputProps={{ style: { fontSize: 20 } }}
              InputLabelProps={{ style: { fontSize: 20 } }}
              onChange={(e) => {
                    const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                   setIrrigation({
                  ...irrigation,
                  yourGPWUI: e.target.value
                })          
                farmDetails.surveyData['Irrigation'].yourGPWUI = (e.target.value)   
                setIsDataEdited(true)
                    }
                else {
                    setOpenDialog1(true);

                }
                   
              }}
                />
          </p>
        </div>


        
        <div> 
          <IrrigationLine dataimport={irrigation.yourGPWUI} onImageGenerated={handleImageGenerated} runCalculations={Calc}/>
        </div>

        <h3>Benchmark Commentary - Optional</h3>
        <h5>Maximum character limit - 550</h5>

                             <Textarea
            maxRows={3}
            maxLength="551"
            placeholder="Benchmark Commentary"
            defaultValue={farmDetails.surveyData['Irrigation'] ? farmDetails.surveyData['Irrigation'].benchmarkcommentary : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9"}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   nitrogen.focus = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       Nitrogen: {
              //         ...prevFarmDetails.surveyData.Nitrogen,
              //         focus: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
               if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                     irrigation.benchmarkcommentary = (e.target.value);
                    farmDetails.surveyData['Irrigation'].benchmarkcommentary = (e.target.value);
                    setIsDataEdited(true)
                  }

              }}
              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 7 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 7) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 8).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  irrigation.benchmarkcommentary = (e.target.value)
                  farmDetails.surveyData['Irrigation'].benchmarkcommentary = (e.target.value)
                  setIsDataEdited(true)
                }
              }}
          />
          </Typography>
        </AccordionDetails>
      </Accordion>

            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
            Commentary
            {farmDetails?.surveyData?.['GeneralFarm']?.commentary &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
              <h3>Commentary<sup>*</sup></h3>
            </AccordionSummary>

            {/* <AccordionDetails>
              <Typography>
                <TextareaAutosize
                  minRows={3}
                  maxRows={5}
                  placeholder="Commentary"
                  defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].commentary : ""}
                  style={{
                    color: 'black',
                    width: "100%",
                    paddingBottom: '2%',
                    border: "2px solid #000000",
                    fontSize: 20,
                    fontFamily: "Arial",
                    backgroundColor: "#EBF4F9",
                  }}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm

                  //   setFarmDetails(prevFarmDetails => ({
                  //     ...prevFarmDetails,
                  //     surveyData: {
                  //       ...prevFarmDetails.surveyData,
                  //       GeneralFarm: {
                  //         ...prevFarmDetails.surveyData.GeneralFarm,
                  //         commentary: newValue
                  //       }
                  //     }
                  //   }));
                  // }}
                  onChange={(e) => {
                    generalFarm.commentary = (e.target.value)
                    farmDetails.surveyData['GeneralFarm'].commentary = (e.target.value)
                  }}
                />
              </Typography>
            </AccordionDetails> */}

            <AccordionDetails>
              <ReactQuill ref={quillRef} theme="snow" value={value} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValue(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)
                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
              <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>
            </AccordionDetails>
          </Accordion>        

        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3>
          Questions<sup>*</sup> 
          {/* { allQuestionsAnswered() && <CheckCircleIcon style={{ marginLeft: '10px', marginBottom: '-3px', color: 'green', fontSize: '1.5rem' }} /> } */}
        </h3>
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
       {QArray.map((Arr,i) => 
        <div key = {i}>
                    {i === 1 && (
        <h3 style={{marginBottom: '0px'}}>Select only one irrigation type</h3>
      )}
          <Box display="flex" alignItems="center"> {/* This line adds flexbox */}

          {(Arr.name === "Flood Irrigation" || Arr.name === "Spray Irrigation") && (
              <Checkbox
                checked={selectedIrrigationType === Arr.name}
                onChange={(event) => handleIrrigationTypeChange(event, Arr.name)}
                color="primary"
                value={Arr.name}
                inputProps={{ 'aria-label': `select ${Arr.name}` }}
                style={{ marginBottom:'2px' }}
              />
            )}
            <h4>{Arr.name}</h4>
          </Box>
          {Arr.content.map((arr, post) =>
            divDisplay ? (
              <div>
                <List
                  sx={{ width: "100%", bgcolor: "#EBF4F9"}}
                  component="nav"
                  aria-labelledby="nested-list-subheader" >

                <ListItemButton style={{ fontSize: 20, fontWeight: 'bold' }} onClick={() => handleClick(arr.number-1)}>
                  Question {post+1}.{arr.question}
                  { 
                    (farmDetails?.surveyData['Irrigation']?.questions[arr.number-1]?.notApplicable ||
                    (farmDetails?.surveyData?.['Irrigation']?.questions[arr.number-1]?.practice && farmDetails?.surveyData?.['Irrigation']?.questions[arr.number-1]?.focusCheck)) &&
                    <CheckIcon style={{ marginLeft: '10px', color: 'green' }} />
                  }
                </ListItemButton>

                  {postDisplay[arr.number-1] ?
                  
                  <FormControl>
                                   <div style={{ marginLeft: '2%', marginTop: '15px' }}>
                  <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={farmDetails?.surveyData['Irrigation']?.questions?.[arr.number - 1]?.notApplicable}
                            onChange={() => {
                                // Copy the current farmDetails state
                                const updatedFarmDetails = {...farmDetails};
                                // Toggle the notApplicable value for the current question
                                updatedFarmDetails.surveyData['Irrigation'].questions[arr.number-1].notApplicable = !updatedFarmDetails.surveyData['Irrigation'].questions[arr.number-1].notApplicable;
                                // farmDetails?.surveyData['Irrigation']?.questions?.[arr.number - 1]?.subQuestions?.[j]?.[k]
                                if (updatedFarmDetails.surveyData['Irrigation'].questions[arr.number-1].notApplicable) { 
                                    // Reset checkboxes
                                    farmDetails?.surveyData['Irrigation']?.questions?.[arr.number - 1]?.subQuestions.forEach((subQ, j) => {
                                        const currentSection = QArray[i].content[post].section[j];
                                       
                                        console.log(post)
                                        currentSection.option.key.forEach((key, k) => {
                                            updatedFarmDetails.surveyData['Irrigation'].questions[arr.number-1].subQuestions[j][k] = false;
                                            // Update generalFarm.scoreArray and generalFarm.generalQuestions
                                            ScoresArray = subtractScore(ScoresArray, key, currentSection.option.value[k]);
                                            irrigation.scoreArray = ScoresArray;
                                            irrigation.irrigationQuestions.delete(key);
                                            setFarmDetails(prevFarmDetails => ({
                                              ...prevFarmDetails,
                                              surveyData: {
                                                  ...prevFarmDetails.surveyData,
                                                  Irrigation: {
                                                      ...prevFarmDetails.surveyData.Irrigation,
                                                      scoreArray: ScoresArray
                                                  }
                                              }
                                          }));
                                        });
                                    });
                                    
                                    // Reset practice and focusCheck
                                    updatedFarmDetails.surveyData['Irrigation'].questions[arr.number-1].practice = "";
                                    updatedFarmDetails.surveyData['Irrigation'].questions[arr.number-1].focusCheck = "";
                                    irrigation.practices[arr.number-1] = "";
                                    irrigation.focusArea[arr.number-1] = "";
                                }
                                
                                // Update the state
                                setFarmDetails(updatedFarmDetails);
                                setIsDataEdited(true)
                                console.log(QArray[i].content[post].section)
                                console.log(post)
                            }}
                            name={`notApplicable_${arr.number-1}`}
                            disabled={i > 0 && selectedIrrigationType !== Arr.name} // Disable if not selected
                        />
                    }
                    label={
                      <Typography
                      variant="body1"
                      style={{
                        fontStyle: 'italic',
                        color: (i > 0 && selectedIrrigationType !== Arr.name) ? '#00000061' : 'black',
                      }}
                    >
                      Not Applicable for the farm
                    </Typography>
                  }
                />

              </div>
                  {arr.section.map((innerArr,j) =>( 
                  
                    <div key = {j}>
                      <FormLabel  component = "legend" style = {{color: 'black', marginLeft: '2%', paddingTop: '2%'}}> {innerArr.statement} </FormLabel>
                      {innerArr.option.text.map((text,k) => (
                        
                        <FormGroup key = {k}>
                          {console.log(arr.number)}
                          {console.log(j)}
                          {console.log(k)}
                        <FormControlLabel control = {
                            <Checkbox 
                                onChange =  { handleChange }
                                name = {innerArr.option.key[k]}
                                value = {innerArr.option.value[k]}
                                style = {{marginLeft: '2%'}}
                                checked = {farmDetails?.surveyData['Irrigation']?.questions?.[arr.number - 1]?.subQuestions?.[j]?.[k]}
                                data-post={arr.number-1}
                                data-subq={j}
                                data-opt={k}
                                data-name={Arr.name}
                                disabled={farmDetails?.surveyData['Irrigation']?.questions?.[arr.number - 1]?.notApplicable || (i > 0 && selectedIrrigationType !== Arr.name)} 
                            />}
                            label = {text}
                              style={{width: "110ch",wordWrap: 'break-word'}}
                        />
                    </FormGroup>
                      ))} 
                    </div>
                  ))}

                  <h6 style={{marginLeft: '2%', fontSize: '20px'}}>
                    Suggestion: {GetSuggestion(ScoresArray[arr.number-1])}
                  </h6>

                  <TextField
                    id="select-practice"
                    select
                    name = {arr.number-1}
                    label={
                        Arr.name === 'General' ? (
                          <CustomInputLabel label="Select appropriate practice" />
                        ) : (
                          'Select appropriate practice' // Use a normal label if Arr.name is not 'general'
                        )
                      }
                     
                    value={farmDetails?.surveyData['Irrigation']?.questions?.[arr.number - 1]?.practice} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch' }}
                    onChange = {updatePractice}
                     
          
                    disabled={farmDetails?.surveyData['Irrigation']?.questions?.[arr.number - 1]?.notApplicable || (i > 0 && selectedIrrigationType !== Arr.name)}
                     InputProps={{ style: { fontSize: 20} }}
                    InputLabelProps={{ style: { fontSize: 20} }}
            
                  >
                    {practices.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="select-improvement"
                    select
                    name = {arr.number-1}
                    label={
                        Arr.name === 'General' ? (
                          <CustomInputLabel label="Is this a focus area you want support with?" />
                        ) : (
                          'Is this a focus area you want support with?' // Use a normal label if Arr.name is not 'general'
                        )
                      }
                    value={farmDetails?.surveyData['Irrigation']?.questions?.[arr.number - 1]?.focusCheck} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch' }}
                    onChange = {updateFocus}
    
                    disabled={farmDetails?.surveyData['Irrigation']?.questions?.[arr.number - 1]?.notApplicable || (i > 0 && selectedIrrigationType !== Arr.name)}
                    InputProps={{ style: { fontSize: 15} }}
                    InputLabelProps={{ style: { fontSize: 15 } }}
              
                  >
                    {wantImprovement.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>


 

                </FormControl>
                : null}
                  
                </List>    
               </div>
           ) : null      
            )} 
          </div>
        )}
          </Typography>
        </AccordionDetails>
      </Accordion> 
        
                   <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
       <h3>Area of focus<sup>*</sup></h3>

        </AccordionSummary>
        <AccordionDetails>
          <Typography>
                 <h5>Maximum character limit - 550</h5>
           <Textarea
            maxRows={3}
            maxLength="551"
            placeholder="Focus"
            defaultValue={farmDetails.surveyData['Irrigation'] ? farmDetails.surveyData['Irrigation'].focus : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              onChange={(e) => {
                 if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    irrigation.focus = (e.target.value)
                    farmDetails.surveyData['Irrigation'].focus = (e.target.value)
                    setIsDataEdited(true)
                  }

              }}
              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 7 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 7) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 8).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  irrigation.focus = (e.target.value)
                  farmDetails.surveyData['Irrigation'].focus = (e.target.value)
                  setIsDataEdited(true)
                }
              }}
          /> 
          </Typography>
        </AccordionDetails>
      </Accordion>

         <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
       <h3>Opportunity for follow up/support - (Optional)</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <h5>Maximum character limit - 500</h5>
            <Textarea
            maxRows={3}
            maxLength="501"
            placeholder="Focus"
            defaultValue={farmDetails.surveyData['Irrigation'] ? farmDetails.surveyData['Irrigation'].focusAdditional : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              marginBottom: '3%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              onChange={(e) => {
                 if ((e.target.value).length > 500) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                   irrigation.focusAdditional = (e.target.value)
                     farmDetails.surveyData['Irrigation'].focusAdditional = (e.target.value)
                     setIsDataEdited(true)
                  }

              }}
              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 9 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 9) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 10).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  irrigation.focusAdditional = (e.target.value)
                  farmDetails.surveyData['Irrigation'].focusAdditional = (e.target.value)
                  setIsDataEdited(true)
                }
              }}
          />   
          </Typography>
        </AccordionDetails>
      </Accordion>
 
               <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
       <h3>Actions<sup>*</sup></h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
                                  <ReactQuill ref={quillRef1} theme="snow" value={valueAction} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValueAction(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)
                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
          </Typography>
          <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>
        </AccordionDetails>
      </Accordion>
 
                     <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
       <h3>Approach  - (Optional)</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
             <h5>Maximum character limit - 850</h5>
            <Textarea
              minRows={3}
              maxRows={5}
              maxLength="851"
              placeholder="Focus"
              defaultValue={farmDetails.surveyData['Irrigation'] ? farmDetails.surveyData['Irrigation'].approach : ""}
              style={{ 
                color: 'black', 
                width: "100%",
                paddingBottom: '2%',
                border: "2px solid #000000",
                fontSize: 20, 
                fontColor: "black", 
                fontFamily: "Arial", 
                backgroundColor:"#EBF4F9",}}
                onChange={(e) => {
                 if ((e.target.value).length > 850) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    irrigation.approach = (e.target.value)
                    farmDetails.surveyData['Irrigation'].approach = (e.target.value)
                    setIsDataEdited(true)
                  }

                }}
                onKeyDown={(e) => {
                  const value = e.target.value;
                  const numberOfNewLines = (value.match(/\n/g) || []).length;
                  
                  if (numberOfNewLines >= 11 && e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => {
                  // Get clipboard data
                  const clipboardData = e.clipboardData || window.clipboardData;
                  const pastedData = clipboardData.getData('Text');
              
                  // Count the number of lines in the pasted text
                  const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
              
                  // Check if the number of lines exceeds 10
                  if (numberOfNewLines > 11) {
                    e.preventDefault();
                    // Trim the pasted text to 10 lines and insert it manually
                    const trimmedData = pastedData.split('\n').slice(0, 12).join('\n');
                    const selectionStart = e.target.selectionStart;
                    const before = e.target.value.substring(0, selectionStart);
                    const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                    e.target.value = before + trimmedData + after;
                    // Update state
                    irrigation.approach = (e.target.value)
                    farmDetails.surveyData['Irrigation'].approach = (e.target.value)
                    setIsDataEdited(true)
                  }
                }}
            />    
          </Typography>
        </AccordionDetails>
      </Accordion>
 


      <div className="back-next-button">
      <Back back = {prev} onClick={() => handleNavigationAttempt(prev)}/>
              <Next 
                next={next} 
                onClick={() => handleNavigationAttempt(next)} 
                disabled={true}
              />
                  <div className="btn-text-left">
                  <Button  variant="contained" href="#contained-buttons"  className="btn-save" style={{ backgroundColor: isDataEdited ? "#002D4E" : "#a8a8a8"}}  sx={{ ml: 1}} onClick={handleSave} disabled={!isDataEdited}>Save</Button>
                {/* <Link to="/ModulePage">
                    <Button  variant="contained" href="#contained-buttons" id = "module" style={{ backgroundColor:"#002D4E"}}  sx={{ ml: 5}}>Module Page</Button>
              </Link> */}
              </div>

        </div>
         
        </div>
      </header>
            </Grid>
        </Grid>
          </Box>


              {/* Dialog Component */}
              <Dialog
            open={openImageFormatDialog}
            onClose={handleImageFormatDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Type"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Invalid file type. Please select a JPEG or PNG file.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageFormatDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openImageSizeDialog}
            onClose={handleImageSizeDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Size"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              File size exceeds the limit (400 KB).
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageSizeDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
              <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Incomplete Questions"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              Please complete all questions/calculate & show plot before proceeding.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

                    <Dialog open={openDialog1} onClose={handleCloseDialog1}>
              <DialogTitle>Error</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Negative numbers are not allowed. Please enter a positive number.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog1} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            {/* Save Successful Dialog */}
            <Dialog
            open={openSaveSuccessDialog}
            onClose={handleSaveSuccessDialogClose}
            aria-labelledby="save-success-dialog-title"
            aria-describedby="save-success-dialog-description"
        >
            <DialogTitle id="save-success-dialog-title">{"Save Successful"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="save-success-dialog-description">
                    You have successfully saved the form.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveSuccessDialogClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

                {/* Save Failed Dialog */}
              <Dialog
            open={openSaveFailedDialog}
            onClose={handleSaveFailedDialogClose}
            aria-labelledby="save-failed-dialog-title"
            aria-describedby="save-failed-dialog-description"
                >
            <DialogTitle id="save-failed-dialog-title">{"Save Failed"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="save-failed-dialog-description">
                    An error occurred while saving the form. Please try again.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveFailedDialogClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

        {/* Saving Dialog */}
        <Dialog
        open={openSavingDialog}
        aria-labelledby="saving-dialog-title"
        aria-describedby="saving-dialog-description"
    >
        <DialogTitle id="saving-dialog-title">{"Saving..."}</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
                Please wait while we save your form.
            </DialogContentText>
            {/* Centered spinner with space above */}
            <CircularProgress size={40} style={{ marginTop: '10px' }} />
        </DialogContent>
        </Dialog>

        <Dialog
        open={openUnsavedChangesDialog}
        onClose={() => setOpenUnsavedChangesDialog(false)}
        aria-labelledby="unsaved-changes-title"
        aria-describedby="unsaved-changes-description"
      >
        <DialogTitle id="unsaved-changes-title">{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-description">
            You have unsaved changes in the commentary section. Please save before continuing.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
          onClick={() => {
            setOpenUnsavedChangesDialog(false);
            setIsTextEdited(false); // Reset the text edited state
            setIsDataEdited(false)
            navigate(unsavedChangesNavigationPath); // Navigate to the stored path
            setUnsavedChangesNavigationPath(null); // Reset the navigation path state
          }}
          style={{ color: 'red', marginRight: 'auto', marginLeft: '7px' }} // This aligns the button to the left
        >
          Continue without saving
        </Button>
        <div style={{ marginLeft: 'auto' }}> {/* This div groups the 'Cancel' and 'Save' buttons to the right */}
          <Button onClick={() => setOpenUnsavedChangesDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </div>
      </DialogActions>
      </Dialog>

          {/* Character Dialog */}
        <Dialog open={isCharacterLimitExceeded} onClose={handleCharacterDialogClose}>
      <DialogTitle>Character Limit Exceeded</DialogTitle>
      <DialogContent>
        You have exceeded the character limit. Please shorten your input.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCharacterDialogClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
}
