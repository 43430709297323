import React  from 'react';
import { Page, Text,StyleSheet, Image } from '@react-pdf/renderer';
import focus from "../../../images/Focus.jpg"
import {default as styles} from "../Styles"
import { Table, TableHeader,TableFooter  } from '../../../components/Utilities'
import { CalculateScores, CalculateFocusArea , IconPicture} from '../../Utilities'; 

const calfPathwaysStyle = StyleSheet.create({
  secondSubtitle: {
    fontSize: 14,
    marginBottom: 60,
    textAlign: 'left',
    color: '#002D4E',
    marginLeft: 30,
    marginTop: -80,
    fontFamily: 'RecoletaMedium',
  }
})


export default function CalfPathwayTable(healthyCalves, farmDetails){
  const borderHeight = [55,75,65,40,40];
  CalculateScores(healthyCalves.calfPathways, healthyCalves.calfPathways.practices)
  CalculateFocusArea(healthyCalves.calfPathways, healthyCalves.calfPathways.focusColumn);
  IconPicture(healthyCalves.calfPathways.practices, healthyCalves.calfPathways.goodColumn, healthyCalves.calfPathways.improvementColumn, healthyCalves.calfPathways.innovativeColumn)
  const data = {
    module: "Animal Health and Welfare (Calf Pathways)",
    total:
    [healthyCalves.calfPathways.scores.innovative, healthyCalves.calfPathways.scores.good, healthyCalves.calfPathways.scores.improvement, healthyCalves.calfPathways.scores.focusArea],
    items: []}
    for (var i = 0; i < healthyCalves.calfPathways.questions.length; i += 1){
      data.items.push({
        question: healthyCalves.calfPathways.questions[i] || "",
        innovative: healthyCalves.calfPathways.innovativeColumn[i] || 0,
        good: healthyCalves.calfPathways.goodColumn[i] || 0,
        areaOfImprovement: healthyCalves.calfPathways.improvementColumn[i] || 0,
        focusArea: healthyCalves.calfPathways.focusColumn[i] || "",
      },)
    } 

  return(
    <Page size="A4" style={styles.page}>
      <div >
        <Image 
        src = {focus}
        style={styles.headerImage} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Focus</Text>        
        <Text style={styles.secondSubtitle}>Animal Health and Welfare - Healthy Calves</Text> 
        <Text style={calfPathwaysStyle.secondSubtitle}>(Calf Pathways) </Text>  
        {TableHeader(data)}
        {Table(data.items,borderHeight, 11)}
        {TableFooter(data.total, 30)}
      </div>
      
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
} 