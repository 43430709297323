import React from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import FEP_Cover from "../../images/FEP_Cover.png"
import {default as styles} from "./Styles.jsx"

export default function pdfCover(farmDetails) {
    return(
      <Page>
        < div>
          <Image 
          src = {FEP_Cover}
          style={styles.pageBackground} />
        </div>
        <Text style={styles.frontPageTitle} fixed>
          Farm                        Environment Plan  
        </Text>
        <Text style={styles.farmName_cover} fixed>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>        
      </Page>
    )
}
