import React  from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import focus from "../../../images/Focus.jpg"
import {default as styles} from "../Styles"
import { Table, TableHeader,TableFooter  } from '../../../components/Utilities'
import { CalculateScores, CalculateFocusArea , IconPicture} from '../../Utilities'; 


export default function NitrogenTable(nitrogen, farmDetails){
  const borderHeight = [40,40,40,40,40,40,40,40,40,40];
  CalculateScores(nitrogen, nitrogen.practices);
  CalculateFocusArea(nitrogen, nitrogen.focusColumn);
  IconPicture(nitrogen.practices,nitrogen.goodColumn, nitrogen.improvementColumn, nitrogen.innovativeColumn)
  
  const data = {
      module: "Nutrient Management (Nitrogen)",
      total:
      [nitrogen.scores.innovative, nitrogen.scores.good, nitrogen.scores.improvement, nitrogen.scores.focusArea],
      items: []
  }
  for (var i = 0; i < nitrogen.questions.length; i += 1){
    data.items.push({
      sr: 1,
      question: nitrogen.questions[i] || "",
      innovative: nitrogen.innovativeColumn[i] || 0,
      good: nitrogen.goodColumn[i] || 0,
      areaOfImprovement: nitrogen.improvementColumn[i] || 0,
      focusArea: nitrogen.focusColumn[i] || "",
    },)
  }
  
  return(
    <Page size="A4" style={styles.page}>
      <div >
        <Image 
        src = {focus}
        style={styles.headerImage} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Focus</Text>
        <Text style={styles.secondSubtitle}>Nutrient Management (Nitrogen)</Text>  
        {TableHeader(data)}
        {Table(data.items,borderHeight, 10)}
        {TableFooter(data.total, 30)}
      </div>
      
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
} 