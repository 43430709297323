import React from "react";
import "./index.css";
import ReactDOM from "react-dom";
import { ContextProvider } from './Context/index.jsx';
import App from './App.jsx';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { loginRequest } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account)
    msalInstance.setActiveAccount(event.payload.account);

  if (event.eventType === EventType.LOGIN_FAILURE)
    console.log(JSON.stringify(event));
});

const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount();

  if (!activeAccount && accounts.length === 0) {
    alert("Please login with your Fonterra Domain. Refreshing Page");
    window.location.reload();
  }

  const authResult = await msalInstance.acquireTokenSilent({
    scopes: loginRequest.scopes,
    account: activeAccount || accounts[0],
  });

  return authResult.accessToken;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <React.StrictMode>
      <ContextProvider>
        <App />
      </ContextProvider>
    </React.StrictMode>
  </MsalProvider>
);
