import React, { useState, useEffect } from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import nitrogenBenchmark from "../../../images/nitrogenBenchmark.jpg"
import {default as styles} from "../Styles"
import irrigationGraphPlaceholder from "../../../images/irrigationGraphPlaceholder.png"


export default function IrrigationBenchMark(irrigation, farmDetails){
  return(
    <Page>
      <div style = {{height: "18%"}} >
        <Image 
          src = {nitrogenBenchmark}/>
        <div style={{position: 'absolute'}}>
          <Text style={styles.title}>The Benchmark</Text>
          <Text style={styles.secondSubtitle}>Irrigation Management</Text>  
        </div>
      </div>

      {/* Placeholder for graph */}
      <div style = {{height: 285, width: "75%",alignItems: "center", marginTop: "2%",marginLeft:"5%", marginRight: "20%", position: "relative", overflow:"hidden"}}>
        <Image
            id='irrigationGraph'
            src={(irrigation.graph === ""  || !irrigation.graph) ? irrigationGraphPlaceholder : irrigation.graph}
            alt = "Irrigation benchmark plot"
            style={{             
              width: "120%",                      
              height: 285,             
              marginLeft:"30%",                       
        }}/>  
        <div style={{position: 'absolute',left:"88%", width:"8%", height:290, backgroundColor:"#fff"}}></div>
      </div>
      
    {irrigation.benchmarkcommentary !== '' && (
                <div style = {{height: "30%", marginLeft:"5%",marginRight:"5%", marginTop: "10%"}}>
        <Text style ={{fontFamily:"Arial", fontSize: 14, color: '#002D4E'}}> 
         {irrigation.benchmarkcommentary}
        </Text>
      </div>
        )}

      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
}
