import React  from 'react';
import { useState } from "react";
import { Page, Text, StyleSheet, Image } from '@react-pdf/renderer';
import Biosecurity from "../../../images/Biosecurity.jpg"
import Lock from "../../../images/lock.jpg"
import {default as styles} from "../Styles"
import tickImage from "../../../images/tick.PNG"
import yesImage from "../../../images/yes.png"
import noImage from "../../../images/no.png"

const BioStyle = StyleSheet.create({
    header_image: {
        marginTop:0,
        marginVertical: 0,
        marginHorizontal: 0,
        padding: 0,
        height: "45%"
    },   
    header_text: {
        fontSize: 18,
        color: '#002D4E',
        fontFamily: 'Arial',
        marginTop: 10
    },
    header_number: {
      fontSize: 18,
      color: '#002D4E',
      fontFamily: 'Recoleta',
    },
    tick_icon: {      
      width:24, 
      height:24, 
      marginTop:25,
      display:'block'      
    }
})

function getPageNumber(pageNumber, biosecurity, setBiosecurity){
  if (pageNumber !== biosecurity.biosecurityPage) {
    setBiosecurity(prevDetails => ({
          ...prevDetails,
          biosecurityPage: pageNumber
      }));
  }
}


export default function BiosecurityHeader(biosecurity, farmDetails, setBiosecurity){
   const count = [];
   const renderRiskAssessment = () => {
    const  bioriskAssessment = biosecurity.riskAssessment.toLowerCase();
    if (bioriskAssessment === "yes") {
      count.push(1);
      return <Image src={tickImage} style = {BioStyle.tick_icon} />;
    } else if (bioriskAssessment === "no") {
        count.push(0);
      return " X";
    } else {
      count.push(1);
      return `(${biosecurity.riskAssessment.trim()})`;
    }
};


const renderManagementplan = () => {
  const  biomanagementPlan = biosecurity.managementPlan.toLowerCase();
    if (biomanagementPlan === "yes") {
        count.push(1);
        return <Image src={tickImage} style = {BioStyle.tick_icon} />;
    } else if (biomanagementPlan === "no") {
        count.push(0);
        return " X";
    } else {
        count.push(1);
        return `(${biosecurity.managementPlan.trim()})`;
    }
};


const rendervisitorRegister = () => {
    const  biovisitorRegister = biosecurity.visitorRegister.toLowerCase();
    if (biovisitorRegister === "yes") {
        count.push(1);
        return <Image src={tickImage} style = {BioStyle.tick_icon} />;
    } else if (biovisitorRegister === "no") {
        count.push(0);
        return " X";
    } else {
        count.push(1);
        return `(${biosecurity.visitorRegister.trim()})`;
    }
};


const renderpropertySignage = () => {
    const  biopropertySignage = biosecurity.propertySignage.toLowerCase();
    if (biopropertySignage === "yes") {
        count.push(1);
        return <Image src={tickImage} style = {BioStyle.tick_icon} />;
    } else if (biopropertySignage === "no") {
        count.push(0);
      return " X";
    } else {
        count.push(1);
      return `(${biosecurity.propertySignage.trim()})`;
    }
};


const renderplanReview = () => {
  const  bioplanReview = biosecurity.planReview.toLowerCase();
    if (bioplanReview === "yes") {
        count.push(1);
        return <Image src={tickImage} style = {BioStyle.tick_icon} />;
    } else if (bioplanReview === "no") {
        count.push(0);
      return " X";
    } else {
        count.push(1);
        return `(${biosecurity.planReview.trim()})`;
    }
};
console.log(count);

const ImageRenderer = () => {
  return (
    <div style={{ 
      display:'flex', 
      flexDirection:'row',
      width:'250px', 
      flexWrap:'nowrap',
      justifyContent:'center',
      alignItems:'center', 
      marginBottom:15}}
      >
      {
      count.slice().sort().reverse().map((item, index) => {        
      let imageSource = item === 1 ? yesImage : noImage;        
        return (        
            <Image
              key={index}
              src={imageSource}
              style={{ width: 24, height: 24, marginLeft: 0, marginRight: 5 }}
            />         
         );
      })}
    </div>
  );
};

return(
  <Page>
      <div style={BioStyle.header_image} >
          <Image 
              src = {Biosecurity}/>
          <div style={{position: 'absolute'}}>
              <Text style={styles.title}>The Headline</Text>
              <Text style={styles.subtitle}>Farm Biosecurity</Text>         
          </div>
      </div>        
      <div style = {{ backgroundColor: "#F7F6FB", alignItems: "center"}}>  
        <Image 
            src = {Lock} 
            style = {{width: "18%", height: "20%", marginBottom: 20, marginTop: 30}}/>
        <ImageRenderer/>        
        <Text style = {BioStyle.header_text}>Documented biosecurity risk assessment:
          {renderRiskAssessment()}
        </Text>
        <Text style = {BioStyle.header_text}>Documented biosecurity management plan:         
          {renderManagementplan()}
        </Text>
        <Text style = {BioStyle.header_text}>Farm visitor register and risk assessment available:
          {rendervisitorRegister()}
        </Text>
        <Text style = {BioStyle.header_text}>Biosecurity signage at all property access points: 
          {renderpropertySignage()}</Text>
        <Text style = {BioStyle.header_text}>Annual biosecurity management plan review: 
            {renderplanReview()}
        </Text>
      </div> 
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
      `${pageNumber}` && getPageNumber(pageNumber, biosecurity, setBiosecurity)
      )} fixed />
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
      `${pageNumber}`
      )} fixed />
    </Page>
  )
} 
