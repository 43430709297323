import * as React from "react";
import { useState, useEffect, useMemo, useRef } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import useModuleLink from '../hooks/moduleLink';
import Next from "./Next";
import Back from "./Back";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Textarea from '@mui/joy/Textarea';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AppRunTimeContext } from '../Context/index'; 
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BioActionTable from "./BioActionTable";
import {addScore, subtractScore, GetSuggestion, practices, wantImprovement} from './Utilities.jsx';

import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import acquireAccessToken from "../accessToken"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

import { debounce } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ProgressBar from "./ProgressBar";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// Module questions with corresponding subquestions. 
//Each checkbox(option) are given a unique key with the initial of the module - question no - subquestion no - checkbox no.
//For instance, XX-00-00-00.
//The value for each option is decided based on how good the practices are.


const QArray = [
  { number: 1, //Question no
    question: "UNDERSTANDING: Do you have a documented biosecurity plan for your farm that includes and addresses the key risks for your individual system, and is clearly communicated both verbally and visually to all people entering our property and facilities?", //Question content
    section: [
      {id:"1.1",
      statement: "1.1. Documented Biosecurity Plan",  //sub question
      option: {
        text: ["Documented Biosecurity plan in place, with a written team agreement on farm biosecurity requirements.","Biosecurity plan exists, but is undocumented, undisplayed or no understanding by farm team of the requirements.","Biosecurity plan not in place and farm team not aware of any risks."],  //options for checkbox
        value: [8, 5, 0],  // assigned value for each option
        key: ["BS-01-1-1","BS-01-1-2", "BS-01-1-3"] 
      }},
    {id:"1.2",
      statement: "1.2. Biosecurity Signage present on all farm entries",  //sub question
      option: {
        text: ["Biosecurity signage on all property and/or facility entry points.","Biosecurity signage on some or main entry points.", "No biosecurity signage."],  //options for checkbox
        value: [9, 4, 0],  // assigned value for each option
        key: ["BS-01-2-1","BS-01-2-2", "BS-01-2-3"] 
      }},
        {id:"1.3",
      statement: "1.3. Visitor and Risk Assessment log book",  //sub question
      option: {
        text: ["Visitor log and risk assessment available and used regularly.","Visitor log and risk assessment available but rarely used.","No visitor log or risk assessment."],  //options for checkbox
        value: [8, 5, 0],  // assigned value for each option
        key: ["BS-01-3-1","BS-01-3-2", "BS-01-3-3"] 
      }},
    ]},
   {
    number: 2, 
    question: "PREVENTING and MANAGING: Do you know disease status of your herd, and existing farm weeds and pests? Do you employ screening and preventative programs to suit your individual farm biosecurity requirements?", 
    section: [
      {id:"2.1",
      statement: "2.1. Disesase status of herd",
      option: {
        text: ["Disease status of herd is known and monitored.","Disease status of herd known based on historical testing.","Testing not performed unless to diagnose an outbreak. "],
        value: [8, 4, 0],
        key: ["BS-02-1-1","BS-02-1-2", "BS-02-1-3"] 
      }},
  {id:"2.2",
      statement: "2.2. Herd health program",
      option: {
        text: ["Farm has a routine preventative herd heath and testing program suitable for their individual herd requirements.","Herd health program is ad-hoc or only in the face of a likely outbreak.","No vaccination program or preventative measures taken."],
        value: [8, 4, 0],
        key: ["BS-02-2-1","BS-02-2-2", "BS-02-2-3"] 
      }},
        {id:"2.3",
      statement: "2.3. Screening inputs",
      option: {
        text: ["New stock, purchased fodder and other inputs are routinely screened for pests, weeds or disease before entry, and documented quarantine periods in place.","System for screening and/or quarantining is in place, but could be improved.","Inputs (including livestock) are not screened or quarantined prior to farm entry"],
        value: [8, 4, 0],
        key: ["BS-02-3-1","BS-02-3-2", "BS-02-3-3"] 
      }},
  ]},
  {
  number: 3, 
  question: "CONTROLLING and PROTECTING: Do you understand the biosecurity risks posed by farm inputs, pest species, neighbours, equipment, vehicles, and people, and have appropriate strategies and controls in place to manage these risks?", 
  section: [
    {id:"3.1",
    statement: "3.1. Cleaning Facilites, Protocols and Equipment for visitors/contract workers on the farm and the farm team",
    option: {
      text: ["Cleaning facilities, equipment, and protocols are available for farm visitors and contractors.Everyone in the farm team maintains clean outerwear, footwear and equipment that is not used on other properties. ", "Where possible, contractors and visitors have restricted areas of access on the property.","May have cleaning protocols or procedures but not documented or displayed, or no equipment available to implement them.", "No protocols or procedures available."],
      value: [8, 8, 4, 0],
      key: ["BS-03-1-1","BS-03-1-2", "BS-03-1-3","BS-03-1-4"] 
    }},
    {id:"3.2",
    statement: "3.2. Standown periods after international travel",
    option: {
      text: ["Standdown periods are followed as required following international travel.","The concept of biosecurity is understood in principle on this farm, but specific expectations are unclear or assumed.","No management practices in place for existing biosecurity challenges on farm. "],
      value: [9, 4, 2],
      key: ["BS-03-2-1","BS-03-2-2", "BS-03-2-3"] 
    }},
    {id:"3.3",
    statement: "3.3. Maintenance of farm boundaries and their communication with neighbours about minimising potential biosecurity risks",
    option: {
      text: ["Farm boundary fences are secure and maintained and inspected regularly. Mutual understanding and communication between neighbours regarding limiting spread or impact of new or existing disease.","There is some effort to reduce biosecurity risks posed by neighbours, visitors etc but room for improvement. ","No management practices in place for existing biosecurity challenges on farm."],
      value: [9, 4, 2] ,
      key: ["BS-03-3-1","BS-03-3-2", "BS-03-3-3"] 
    }},
  ]},
  {
    number: 4, 
    question: "CONTAINING and SEGREGATING: Are sick and dead animals, effluent, and waste materials isolated for management in a way that prevents spread of disease?", 
    section: [
      {id:"4.1",
      statement: "4.1 Identification, segregation, and containing animals that are sick or deceased",
      option: {
        text: ["Sick animals are identified rapidly and isolated away from other livestock for management and monitoring and dead animals are segregated and disposed of appropriately","Sick and dead animal management is adequate, but ad-hoc.","No clear system for managing dead stock and sick animals. "],
        value: [8,3,0],
        key: ["BS-04-1-1","BS-04-1-2", "BS-04-1-3"] 
      }},
            {id:"4.2",
      statement: "4.2 Storage and maintenance of effluent and waste materials",
      option: {
        text: ["Effluent and waste materials are stored and used in a way that prevents disease spread and observes withholding times and record keeping.","Effluent and waste materials are stored and used in a way that prevents disease spread and observes withholding times but record keeping could improve.","Waste management compromises biosecurity. "],
        value: [8,3,0],
        key: ["BS-04-2-1","BS-04-2-2", "BS-04-2-3"] 
      }},
    ]},
    {
      number: 5, 
      question: "MONITORING: Do you identify stock and record movements appropriately and regularly assess and review the adequacy and efficacy of your biosecurity management plan?", 
      section: [
        {id:"5.1",
        statement: "5.1. Review of biosecurity plan",
        option: {
          text: ["Biosecurity plan reviewed at least annually","Biosecurity plan only reviewed on ad hoc basis or when farm biosecurity is breached or at imminent risk.","No plan in place. Or, biosecurity plan not reviewed, and is stored in a drawer for LPA audits"],
          value: [5, 3, 0] ,
          key: ["BS-05-1-1","BS-05-1-2", "BS-05-1-3"] 
        }},
        {id:"5.2",
        statement: "5.2. Livestock is visually and electronically identified, with corresponding record-keeping",
        option: {
          text: ["All livestock have both visual and electronic identification, and records are maintained.","Most livestock have both visual and electronic�identification.","Visual identification of livestock is a challenge, EID only used for livestock leaving property"],
          value: [5, 3, 0],
          key: ["BS-05-2-1","BS-05-2-2", "BS-05-2-3"]  
        }},
      ]},

]

export default function Biosecurity() {

  const {setNextPrev, getNext, getPrev} = useModuleLink();
  const { biosecurity, setBiosecurity } = useContext(AppRunTimeContext);
  var ScoresArray = biosecurity.scoreArray;

  const { instance, accounts } = useMsal();
  const { farmDetails, setFarmDetails, payload, setPayload, operation, setOperation, highestRevisionNumber, setHighestRevisionNumber } = useContext(AppRunTimeContext);
  // Use the dictionary from generalFarm[images] to replace image filenames
  const formattedValue = (farmDetails.surveyData['Biosecurity'] ? farmDetails.surveyData['Biosecurity'].commentary : "").replace(/biosecurityCommentary\S+/g, match => {
    console.log(match)
    const base64 = biosecurity.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [value, setValue] = useState(formattedValue);
  const {steps} = useContext(AppRunTimeContext);

  //Routing for the application
  setNextPrev("/Biosecurity");
  let next = getNext();
  let prev = getPrev();

  const stepIndex = steps.findIndex(step => step.link === '/Biosecurity');

  //State to expand/close the questions
  const [postDisplay, setPostDisplay] = useState(true);
  //State to handle the ticked/unticked checkboxes
  const [state, setState] = React.useState(null);
  const divDisplay = true;


  const [openDialog, setOpenDialog] = useState(false);
  const [openSaveSuccessDialog, setOpenSaveSuccessDialog] = React.useState(false);
  const [openSaveFailedDialog, setOpenSaveFailedDialog] = React.useState(false);
  const navigate = useNavigate();
  const [openSavingDialog, setOpenSavingDialog] = React.useState(false);
  const [isTextEdited, setIsTextEdited] = useState(false);
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);
  const [unsavedChangesNavigationPath, setUnsavedChangesNavigationPath] = useState(null);

  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] = useState(false);
  const stateRef = useRef();  
  const [isDataEdited, setIsDataEdited] = useState(false);
  const [openImageFormatDialog, setOpenImageFormatDialog] = React.useState(false);
  const [openImageSizeDialog, setOpenImageSizeDialog] = React.useState(false);


  useEffect(() => {
    stateRef.current = {
      farmDetails,
      value
    };
  }, [farmDetails, value]);
  
  const handleImageFormatDialogClose = () => {    
    setOpenImageFormatDialog(false);   
  };

  const handleImageSizeDialogClose = () => {
    setOpenImageSizeDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSaveSuccessDialogClose = () => {
    setOpenSaveSuccessDialog(false);
  };

  const handleSaveFailedDialogClose = () => {
    setOpenSaveFailedDialog(false);
  };

  const handleCharacterDialogClose = () => {
    setIsCharacterLimitExceeded(false);
  };

  const handleNextClick = () => {
    // const questions = farmDetails.surveyData['Biosecurity'].questions;
    // for (let i = 0; i < questions.length; i++) {
    //   if (!questions[i].practice || !questions[i].focusCheck) {
    //     console.log('open dialog')
    //     setOpenDialog(true);
    //     return;
    //   }
    // }
    // If all conditions are met, navigate to the next page.
    navigate(next);
  };

  useEffect(() => {

    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
        window.location.href = '/'; // Redirect to home page
    }


    if (farmDetails.surveyData['Biosecurity']) {
        // Your code here will run after the component has mounted
        // Loop through the surveyData[1].questions array
        farmDetails.surveyData['Biosecurity'].questions.forEach((question, index) => {
            // Update the generalFarm.practices array based on the practice in each question
            biosecurity.practices[index] = question.practice;
            biosecurity.focusArea[index] = question.focusCheck
        });
        console.log(biosecurity.practices)
        // console.log(tempModule)
        // console.log(farmDetails)
    } else {
        if (!(farmDetails.surveyData['Biosecurity'])) {
            setFarmDetails(prevDetails => ({
                ...prevDetails,
                surveyData: {
                    ...prevDetails.surveyData,
                    details: {
                        farmOwner: farmDetails.farm_owner,
                        planOwner: farmDetails.plan_owner,
                        farmAddressLat: farmDetails.farmAddressLat,
                        farmAddressLong: farmDetails.farmAddressLong,
                        financialYear: farmDetails.financialYear,
                        farmAddress: farmDetails.farmAddress
                    },
                    Biosecurity: {
                        module: "Biosecurity",
                        commentary: "",
                        focus: "",
                        scoreArray: [0, 0, 0, 0, 0],
                        focusAdditional: "",
                        riskAssessment: "",
                        managementPlan: "",
                        visitorRegister: "",
                        propertySignage: "",
                        planReview: "",
                        biosecurityPage: 0,
                        actionTable: [{
                                id: '1',
                                goals: 'Eg: To ensure farm team are mitigating our biosecurity risks to the best possible standard.',
                                action: 'Eg: Document biosecurity management plan, develop a clear set of decontamination protocols, provide required equipment, and communicate to team and display in dairy.',
                                priority_who: '',
                                priority_when: ''
                            },
                            {
                                id: '2',
                                goals: 'Eg.: Manage our biosecurity risks when introducing purchased livestock.',
                                action: ' Eg: Purchase low-risk animals and conduct screening tests prior to purchasing livestock, and segregate and quarantine upon arrival (and plan to purchase animals with enough lead time to allow for this, ie bulls intended for upcoming use). ',
                                priority_who: '',
                                priority_when: ''
                            },
                            {
                                id: '3',
                                goals: 'Eg: Explore options for managing sick lactating cows as a separate herd or to milk last. ',
                                action: 'Eg: Create a separate hospital area for sick calves away from healthy animals with little to no risk of cross-contamination.',
                                priority_who: '',
                                priority_when: ''
                            },
                            {
                                id: '4',
                                goals: '',
                                action: '',
                                priority_who: '',
                                priority_when: ''
                            },
                            {
                                id: '5',
                                goals: '',
                                action: '',
                                priority_who: '',
                                priority_when: ''
                            }
                        ],
                        QuestionMapping: [
                          {
                            Q1_7: "UNDERSTANDING: Do you have a documented biosecurity plan for your farm that includes and addresses the key risks for your individual system, and is clearly communicated both verbally and visually to all people entering our property and facilities?",
                            Q2_7: "PREVENTING and MANAGING: Do you know disease status of your herd, and existing farm weeds and pests? Do you employ screening and preventative programs to suit your individual farm biosecurity requirements?",
                            Q3_7: "CONTROLLING and PROTECTING: Do you understand the biosecurity risks posed by farm inputs, pest species, neighbours, equipment, vehicles, and people, and have appropriate strategies and controls in place to manage these risks?",
                            Q4_7: "Are sick and dead animals, effluent, and waste materials isolated for management in a way that prevents spread of disease?",
                            Q5_7: "Do you identify stock and record movements appropriately and regularly assess and review the adequacy and efficacy of your biosecurity management plan?"
                          }
                        ],
                        questions: [
                          {
                            subQuestions: [
                              [
                                false,
                                false,
                                false
                              ],
                              [
                                false,
                                false,
                                false
                              ],
                              [
                                false,
                                false,
                                false
                              ]
                            ],
                            practice: "",
                            focusCheck: "",
                            notApplicable: false
                          },
                          {
                            subQuestions: [
                              [
                                false,
                                false,
                                false
                              ],
                              [
                                false,
                                false,
                                false
                              ],
                              [
                                false,
                                false,
                                false
                              ]
                            ],
                            practice: "",
                            focusCheck: "",
                            notApplicable: false
                          },
                          {
                            subQuestions: [
                              [
                                false,
                                false,
                                false,
                                false
                              ],
                              [
                                false,
                                false,
                                false
                              ],
                              [
                                false,
                                false,
                                false
                              ]
                            ],
                            practice: "",
                            focusCheck: "",
                            notApplicable: false
                          },
                          {
                            subQuestions: [
                              [
                                false,
                                false,
                                false
                              ],
                              [
                                false,
                                false,
                                false
                              ]
                            ],
                            practice: "",
                            focusCheck: "",
                            notApplicable: false
                          },
                          {
                            subQuestions: [
                              [
                                false,
                                false,
                                false
                              ],
                              [
                                false,
                                false,
                                false
                              ]
                            ],
                            practice: "",
                            focusCheck: "",
                            notApplicable: false
                          }
                        ]
                    }
                }
            }))
        }
    }
}, []); // Empty dependency array means this useEffect runs once after initial render


  // Check whether the checkboxes are ticked
  const getChecked = (key) => {
    if (biosecurity.biosecurityQuestions.get(key)) 
      return true;
    else
      return false; 
  }

  const handleNavigationAttempt = (navigationPath) => {
    if (isTextEdited) {
      setUnsavedChangesNavigationPath(navigationPath); // Store the intended navigation path
      setOpenUnsavedChangesDialog(true); // If text is edited, show the warning dialog
    } else {
      navigate(navigationPath); // Otherwise, navigate immediately
    }
  };

  //Expand/close each questions individually
  const handleClick = (post) => { 
    const objectCopy = {
      ...postDisplay
    };
    objectCopy[post] = !objectCopy[post];
    setPostDisplay(objectCopy);
  };

  useEffect(() => {
    // Function to be executed
    const intervalFunction = () => {
      console.log(operation);
      if (operation === "modify") {
        handleSave('autosave');
        console.log('This function runs every 30 seconds');
      }
      // Add your logic here
    };
  
    // Set the interval
    const interval = setInterval(intervalFunction, 120000); 
  
    // Clear interval on component unmount or when operation changes
    return () => clearInterval(interval);
  }, [operation]); // Include operation in the dependency array

  // Called when a checkbox is ticked/unticked
 const handleChange = (event) => {


  const postIndex = event.target.parentNode.dataset.post;
  const subQIndex = event.target.parentNode.dataset.subq;
  const optIndex = event.target.parentNode.dataset.opt;

  setState({
    ...state,
    [event.target.name]: event.target.checked,
  });


  {console.log(postIndex)}
  {console.log(subQIndex)}
  {console.log(optIndex)}

  farmDetails.surveyData['Biosecurity'].questions[postIndex].subQuestions[subQIndex][optIndex] = event.target.checked;
  setIsDataEdited(true)


  //When checkbox is ticked, add score to module score array and set checkbox as ticked
  if (event.target.checked){
    ScoresArray = addScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue)
    biosecurity.scoreArray = ScoresArray
    biosecurity.biosecurityQuestions.set(event.target.name, true);
    setFarmDetails(prevFarmDetails => ({
      ...prevFarmDetails,
      surveyData: {
          ...prevFarmDetails.surveyData,
          Biosecurity: {
              ...prevFarmDetails.surveyData.Biosecurity,
              scoreArray: ScoresArray
          }
      }
  }));
  }
  //When checkbox is unticked, deduct score to module score array and set checkbox as unticked
  else{
    ScoresArray = subtractScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue)
    biosecurity.scoreArray = ScoresArray
    biosecurity.biosecurityQuestions.delete(event.target.name);
    setFarmDetails(prevFarmDetails => ({
      ...prevFarmDetails,
      surveyData: {
          ...prevFarmDetails.surveyData,
          Biosecurity: {
              ...prevFarmDetails.surveyData.Biosecurity,
              scoreArray: ScoresArray
          }
      }
  }));
  }
};

// Update user's choice of practice
function updatePractice(e) {
  biosecurity.practices[e.target.name] = e.target.value
   // Create a deep copy of the farmDetails object
   const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
   // Update the nested property
   newFarmDetails.surveyData['Biosecurity'].questions[e.target.name].practice = e.target.value;
   // Update the state
   setFarmDetails(newFarmDetails);
   setIsDataEdited(true)
}

// Update whether user would like to focus on that area
function updateFocus(e) {
  biosecurity.focusArea[e.target.name] = e.target.value
  // Create a deep copy of the farmDetails object
  const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
  // Update the nested property
  newFarmDetails.surveyData['Biosecurity'].questions[e.target.name].focusCheck = e.target.value;
  // Update the state
  setFarmDetails(newFarmDetails);
  setIsDataEdited(true)
}

const isAEDT = (date) => {
  const year = date.getFullYear();
  const startDST = new Date(Date.UTC(year, 9, 1)); // October 1st
  const endDST = new Date(Date.UTC(year, 3, 1)); // April 1st

  // Find the first Sunday in October
  startDST.setUTCDate(1 + (7 - startDST.getUTCDay()) % 7);
  
  // Find the first Sunday in April
  endDST.setUTCDate(1 + (7 - endDST.getUTCDay()) % 7);

  return (date >= startDST && date < endDST);
};


  const getCurrentDateTime = () => {
    const nowUTC = new Date(Date.now());

    const offset = isAEDT(nowUTC) ? 12 : 11; // UTC+13 if Daylight Saving Time, otherwise UTC+12
  
    const aet = new Date(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(),
                         nowUTC.getUTCHours() + offset, nowUTC.getUTCMinutes(), nowUTC.getUTCSeconds());
  
    const year = aet.getFullYear();
    const month = String(aet.getMonth() + 1).padStart(2, '0');
    const day = String(aet.getDate()).padStart(2, '0');
    const hour = String(aet.getHours()).padStart(2, '0');
    const minute = String(aet.getMinutes()).padStart(2, '0');
    const second = String(aet.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}${hour}${minute}${second}`;
  };

  // Helper function to prepare payload
  const preparePayload = (prevDetails, specificFields = {}) => {
    const currentState = stateRef.current;
    const { surveyData } = currentState.farmDetails;
  
    const surveyDataWithoutGraphs = { ...currentState.farmDetails.surveyData };
  
    if (surveyData.Carbon) {
      const { graph: carbonGraph, ...rest } = surveyData.Carbon;
      surveyDataWithoutGraphs.Carbon = rest;
    }

  if (surveyData.Nitrogen) {
    const { graph: nitrogenGraph, ...rest } = surveyData.Nitrogen;
    surveyDataWithoutGraphs.Nitrogen = rest;
  }

  if (surveyData.Irrigation) {
    const { graph: irrigationGraph, ...rest } = surveyData.Irrigation;
    surveyDataWithoutGraphs.Irrigation = rest;
  }

  if (surveyData.HealthyCalves) {
    const { colostrum, calfPathways, ...rest } = surveyData.HealthyCalves;
  
    // Remove graph1 and graph2 from colostrum
    const { graph1: colostrumGraph1, graph2: colostrumGraph2,graph3: colostrumGraph3, graph4: colostrumGraph4, ...colostrumRest } = colostrum;
    
    // Remove graph1 and graph2 from calfPathways
    const { graph1: calfPathwaysGraph1, graph2: calfPathwaysGraph2, ...calfPathwaysRest } = calfPathways;
  
    surveyDataWithoutGraphs.HealthyCalves = {
      ...rest,
      colostrum: colostrumRest,
      calfPathways: calfPathwaysRest
    };

    if (surveyData.GeneralFarm) {
      const { farmMap, ...restGeneralFarm } = surveyData.GeneralFarm;
      surveyDataWithoutGraphs.GeneralFarm = restGeneralFarm;
    }
  }

  return {
    ...prevDetails,
    dateEdited: getCurrentDateTime(),
    region: farmDetails.farmRegion,
    status: "DRAFT",
    financialYear: farmDetails.financialYear,
    farmId: farmDetails.supplier_no,
    surveyData: surveyDataWithoutGraphs,
    modules: farmDetails.modules,
    ...specificFields
  };
};



// Helper function to make Axios call
const makeAxiosCall = async (urlSuffix, payload) => {
  try {
    const token = await acquireAccessToken(instance, accounts[0]);
    localStorage.setItem("accessToken", token);
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/${urlSuffix}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('Response:', response.data);
    console.log(payload);
    setOpenSaveSuccessDialog(true);
    return response;
  } catch (error) {
    console.error('An error occurred while performing Axios POST:', error);
    setOpenSaveFailedDialog(true);
  }
};
const debouncedSetPayload = debounce(setPayload, 500);

useEffect(() => {
  return () => {
    debouncedSetPayload.cancel(); 
  }
}, [])


// Define the handleSave function which is debounced
const handleSave = debounce(async (saveType) => {
  const currentState = stateRef.current;

  const urlSuffix = saveType === "autosave" ? "modifyFarmSurveyData" : "insertFarmSurveyData";
  saveType !== "autosave" && setOpenSavingDialog(true);

  if (operation !== "modify") {
    farmDetails.farmSurveyId = farmDetails.supplier_no + getCurrentDateTime();
  }
  // Check if highestRevisionNumber is defined and is a string of 4 digits
  let validRevisionNumber = highestRevisionNumber && /^\d{4}$/.test(highestRevisionNumber);
  let newRevisionNumber;

  if (saveType !== 'autosave') {
    if (validRevisionNumber) {
      // Increment the revision number if it's valid
      newRevisionNumber = (parseInt(highestRevisionNumber, 10) + 1).toString().padStart(4, '0');
    } else {
      // Default to '0001' if highestRevisionNumber is undefined or invalid
      newRevisionNumber = '0001';
    }
  }
  // Step 2: Extract images and modify the commentary text
  const regex = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
  let match;
  const images = [];

  while ((match = regex.exec(currentState.value)) !== null) {
    const imageName = saveType==='autosave' ? `biosecurityCommentary${images.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `biosecurityCommentary${images.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
    images.push({ base64: match[1], name: imageName });
  }

  console.log(images);

  let modifiedText = currentState.value;
  console.log(modifiedText)

  // Replace base64 values with image names
  for (let i = 0; i < images.length; i++) {
    modifiedText = modifiedText.replace(images[i].base64, images[i].name);
  }

   // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
   modifiedText = modifiedText.replace(/<img src="([^"]+)"[^>]*>/g, (match, p1) => {
    return p1 + '\n';  // Add a newline character after the image name
  });

    // First, handle the <li> tags by converting them to bullet points
    modifiedText = modifiedText.replace(/<li[^>]*>/gi, '• ');
    modifiedText = modifiedText.replace(/<\/li>/gi, '\n');

    // Then, remove the <ul> and </ul> tags as they are no longer necessary
    modifiedText = modifiedText.replace(/<\/?ul[^>]*>/gi, '\n');

    // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
    modifiedText = modifiedText.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

    // Convert inline elements to spaces or remove them
    modifiedText = modifiedText.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

    // Convert <br> tags to newlines
    modifiedText = modifiedText.replace(/<br[^>]*>/gi, '\n');

    // Remove other HTML tags
    modifiedText = modifiedText.replace(/<\/?[^>]+(>|$)/g, '').trim();

    // Replace multiple newlines with a single newline
    modifiedText = modifiedText.replace(/\n+/g, '\n').trim();

    // Replace multiple spaces with a single space
    modifiedText = modifiedText.replace(/ +/g, ' ').trim();

  console.log(modifiedText);

   // Update the commentary field
   biosecurity.commentary = modifiedText;
   currentState.farmDetails.surveyData['Biosecurity'].commentary = modifiedText;
 
   // Define a helper function to make the image upload call
   const uploadGraph = async (imageName, graphData) => {
    if (imageName.startsWith('farmPic_')) {
      if (!farmDetails.hasFarmPicChanged) {
        return true
      }
      else {
        setFarmDetails(prevDetails => ({
          ...prevDetails,
          hasFarmPicChanged: false
        }));
      }
    }
    const imagePayload = {
      "imageName": imageName,
      "imageDetails": graphData
    };
    console.log("Image Payload:", imagePayload);
    return makeAxiosCall("storeImage", imagePayload);
  };

  const imageUploadPromises = images.map(image => {
    return uploadGraph(image.name, image.base64);
  });

  const specificFields = operation === "modify" ? {
    revisionNumber: saveType==='autosave' ? 'AUTOSAVE' : newRevisionNumber,
    id: saveType==='autosave' ? farmDetails.supplier_no : farmDetails.supplier_no + `_${newRevisionNumber}`,
    editedBy: accounts[0].name
} : {
    creatorName: accounts[0].name,
    dateCreated: getCurrentDateTime(),
    id: farmDetails.supplier_no + `_${newRevisionNumber}`,
    schemaVersionNum: "001",
    surveyVersionNumber: "1",
    status: "DRAFT",
    farmSurveyId: farmDetails.farmSurveyId,
    revisionNumber: newRevisionNumber,
    editedBy: accounts[0].name
};

  const updatedPayload = preparePayload(payload, specificFields);

  try {
    const response = await makeAxiosCall(urlSuffix, updatedPayload);

    if (response) {
      if (saveType !== 'autosave') { 
        setHighestRevisionNumber(newRevisionNumber);
      }
      if (operation !== "modify") {
        farmDetails.farmSurveyId = updatedPayload.farmSurveyId;
        farmDetails.date_edited = updatedPayload.dateEdited;
        setOperation('modify');
      }

      const graphPaths = [
        { path: "HealthyCalves.colostrum.graph1", namePrefix: "healthyCalvesColostrumGraph1_" },
        { path: "HealthyCalves.colostrum.graph2", namePrefix: "healthyCalvesColostrumGraph2_" },
        { path: "HealthyCalves.colostrum.graph3", namePrefix: "healthyCalvesColostrumGraph3_" },
        { path: "HealthyCalves.colostrum.graph4", namePrefix: "healthyCalvesColostrumGraph4_" },
        { path: "HealthyCalves.calfPathways.graph1", namePrefix: "healthyCalvesCalfPathwaysGraph1_" },
        { path: "HealthyCalves.calfPathways.graph2", namePrefix: "healthyCalvesCalfPathwaysGraph2_" },
        { path: "Nitrogen.graph", namePrefix: "nitrogenGraph_" },
        { path: "Carbon.graph", namePrefix: "carbonGraph_" },
        { path: "Irrigation.graph", namePrefix: "irrigationGraph_" },
        { path: "farmPic", namePrefix: "farmPic_" },
        { path: "GeneralFarm.farmMap", namePrefix: "farmMap_" }
      ];
    
      const graphPromises = graphPaths.map(graph => {
        const parts = graph.path.split('.');
        let graphValue;
      
        switch (parts.length) {
          case 1:
            graphValue = currentState.farmDetails[parts[0]];
            break;
          case 2:
            if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]]) {
              graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]];
            }
            break;
          case 3:
            if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]] && currentState.farmDetails.surveyData[parts[0]][parts[1]]) {
              graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]][parts[2]];
            }
            break;
          default:
            break;
        }


        if (graphValue && graphValue !== "") {
          if (saveType === 'autosave') {
            return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_AUTOSAVE`, graphValue);
          }
          else {
            return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_${newRevisionNumber}`, graphValue);
          }
        }
        return Promise.resolve(true);
      });

       // Merge imageUploadPromises and graphPromises
       const allUploadPromises = [...imageUploadPromises, ...graphPromises];

       const allUploadResponses = await Promise.all(allUploadPromises);

       if (allUploadResponses.every(res => res)) {
        setOpenSavingDialog(false);
        saveType !== "autosave" && setOpenSaveSuccessDialog(true);
        // After save is successful
        setIsTextEdited(false);
        setIsDataEdited(false)
        setOpenUnsavedChangesDialog(false);
      } else {
        setOpenSavingDialog(false);
        saveType !== "autosave" && setOpenSaveFailedDialog(true);
      }
    } else {
      setOpenSavingDialog(false);
      saveType !== "autosave" && setOpenSaveFailedDialog(true);
    }
  } catch (error) {
    console.error("An error occurred during saving:", error);
    setOpenSavingDialog(false);
    saveType !== "autosave" && setOpenSaveFailedDialog(true);
  }
}, 500); // Debounce time of 500ms

const allQuestionsAnswered = () => {
  return QArray.every((arr, post) => {
    return farmDetails?.surveyData?.['Biosecurity']?.questions[post]?.notApplicable ||
           (farmDetails?.surveyData?.['Biosecurity']?.questions[post]?.practice && farmDetails?.surveyData?.['Biosecurity']?.questions[post]?.focusCheck);
  });
}

const quillRef = useRef(null);  // Reference to the ReactQuill component
useEffect(() => {
  if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {        
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage();                               
              return false;              
            }
            const sizeInBytes = (lastImage.length * 3) / 4;
                const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB); 
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage();                
                      return false; 
                  }   
                }              
              }              
            }
        };

      // Attach the event listener
      quill.on('text-change', handleChange);

      // Clean up the event listener when the component unmounts
      return () => {
          quill.off('text-change', handleChange);
      };
  }
}, []);

const handleDeleteImage = () => {
  const quill = quillRef.current.getEditor();
  const selection = quill.getSelection();
  quill.history.undo();
};

const getImageType = (lastImage) => {
  const matches = lastImage.match(/^data:(.*);base64,/);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return null;
};

const customImageHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    const content = editor.getContents();
    console.log("Custom image handler triggered"); // This should be printed whenever an image is added

    // Count the number of images
    let imageCount = 0;
    content.ops.forEach(op => {
        if (typeof op.insert === 'string' && op.insert.includes('<img')) {
            imageCount++;
        }
    });

    if (imageCount >= 5) {
        alert('You can only insert a maximum of 5 images.');
    } else {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const range = selection;
                editor.insertEmbed(range.index, 'image', reader.result);
            };

            reader.readAsDataURL(file);
        };
    }
};
const modules = useMemo(() => ({
  toolbar: {
    container: [
      [{ list: 'bullet' }],
      ['image']
    ]
  }
}), [])
  const CustomInputLabel = ({ label }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ fontSize: '15px' }}>{label}</span>
    <span style={{ fontSize: '15px', color: 'red', fontWeight: 'bold', marginLeft: '4px' }}>*</span>
  </div>
   );

   const checkUnsavedChanges = () => {
    return isTextEdited; // where isTextEdited is a state variable that tracks if the text has been edited
  };  

  // In your parent component
  const handleDataEdit = () => {
    setIsDataEdited(true);
  }; 

  return (
    <div className="generalFarm-page">
      {console.log(farmDetails)}
      {console.log(payload)}
      {console.log(biosecurity)}
       <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
        <Grid item xs={3}>
         <div className="progressbar">
         <ProgressBar currentStep={stepIndex} checkUnsavedChanges={checkUnsavedChanges} setOpenUnsavedChangesDialog={setOpenUnsavedChangesDialog} setUnsavedChangesNavigationPath={setUnsavedChangesNavigationPath}/>
           </div>
           </Grid>
            <Grid item xs={9}>
      <header className="generalFarm-header">
        <h1 className="generalFarm-h1">Farm Biosecurity</h1>
                 
        <div className="module-questions"> 
           <h5>* indicates <b>mandatory fields</b></h5>
        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
            Commentary
            {farmDetails?.surveyData?.['GeneralFarm']?.commentary &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
              <h3>Commentary<sup>*</sup> </h3>
            </AccordionSummary>

            {/* <AccordionDetails>
              <Typography>
                <TextareaAutosize
                  minRows={3}
                  maxRows={5}
                  placeholder="Commentary"
                  defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].commentary : ""}
                  style={{
                    color: 'black',
                    width: "100%",
                    paddingBottom: '2%',
                    border: "2px solid #000000",
                    fontSize: 20,
                    fontFamily: "Arial",
                    backgroundColor: "#EBF4F9",
                  }}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm

                  //   setFarmDetails(prevFarmDetails => ({
                  //     ...prevFarmDetails,
                  //     surveyData: {
                  //       ...prevFarmDetails.surveyData,
                  //       GeneralFarm: {
                  //         ...prevFarmDetails.surveyData.GeneralFarm,
                  //         commentary: newValue
                  //       }
                  //     }
                  //   }));
                  // }}
                  onChange={(e) => {
                    generalFarm.commentary = (e.target.value)
                    farmDetails.surveyData['GeneralFarm'].commentary = (e.target.value)
                  }}
                />
              </Typography>
            </AccordionDetails> */}

<AccordionDetails>
              <ReactQuill ref={quillRef} theme="snow" value={value} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValue(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)
                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
              <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>
            </AccordionDetails>
          </Accordion>

                <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>The Headline<sup>*</sup> </h3>

        </AccordionSummary>
        <AccordionDetails>
        <p>If you want to see the tick in the PDF, please enter "Yes"</p>
        <p>If you want to see an 'X' in the PDF, please enter "No"</p>
        <p>If you want to see a percentage like "20%", please enter as it is</p>
           <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="string"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Biosecurity'] ? farmDetails.surveyData['Biosecurity'].riskAssessment : ""}
            label="Documented biosecurity risk assessment"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              biosecurity.riskAssessment = (e.target.value);
              farmDetails.surveyData['Biosecurity'].riskAssessment = (e.target.value)
              setIsDataEdited(true)
            }}/>
        </p>
        <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="string"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Biosecurity'] ? farmDetails.surveyData['Biosecurity'].managementPlan : ""}
            label="Documented biosecurity management plan"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              biosecurity.managementPlan = (e.target.value);
              farmDetails.surveyData['Biosecurity'].managementPlan = (e.target.value)
              setIsDataEdited(true)
            }}/>
        </p>
         <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="string"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Biosecurity'] ? farmDetails.surveyData['Biosecurity'].visitorRegister : ""}
            label="Farm visitor register and risk assessment available"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              biosecurity.visitorRegister = (e.target.value);
              farmDetails.surveyData['Biosecurity'].visitorRegister = (e.target.value)
              setIsDataEdited(true)
            }}/>
        </p>
       <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="string"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Biosecurity'] ? farmDetails.surveyData['Biosecurity'].propertySignage : ""}
            label="Biosecurity signage at all property access points"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              biosecurity.propertySignage = (e.target.value);
              farmDetails.surveyData['Biosecurity'].propertySignage = (e.target.value)
              setIsDataEdited(true)
            }}/>
        </p>
      <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="string"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Biosecurity'] ? farmDetails.surveyData['Biosecurity'].planReview : ""}
            label="Annual biosecurity management plan review"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              biosecurity.planReview = (e.target.value);
              farmDetails.surveyData['Biosecurity'].planReview = (e.target.value)
              setIsDataEdited(true)
            }}/>
        </p>
         
        </AccordionDetails>
      </Accordion>
 
      <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3>
          Questions<sup>*</sup>  
          { allQuestionsAnswered() && <CheckCircleIcon style={{ marginLeft: '10px', marginBottom: '-3px', color: 'green', fontSize: '1.5rem' }} /> }
        </h3>
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {/* map through the QArray to display questions and options */}
          {QArray.map((arr, post) =>
            divDisplay ? (
              <div>
                <List
                  sx={{ width: "100%", bgcolor: "#EBF4F9"}}
                  component="nav"
                  aria-labelledby="nested-list-subheader" >

                <ListItemButton style={{ fontSize: 20, fontWeight: 'bold' }} onClick={() => handleClick(post)}>
                  Question {post+1}.{arr.question}
                  { 
                    (farmDetails?.surveyData?.['Biosecurity']?.questions[post]?.notApplicable ||
                    (farmDetails?.surveyData?.['Biosecurity']?.questions[post]?.practice && farmDetails?.surveyData?.['Biosecurity']?.questions[post]?.focusCheck)) &&
                    <CheckIcon style={{ marginLeft: '10px', color: 'green' }} />
                  }
                </ListItemButton>


                  {postDisplay[post] ?
                  
                  <FormControl>

                               <div style={{ marginLeft: '2%', marginTop: '15px' }}>
                  <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={farmDetails.surveyData['Biosecurity'].questions[post].notApplicable}
                            onChange={() => {
                                // Copy the current farmDetails state
                                const updatedFarmDetails = {...farmDetails};
                                // Toggle the notApplicable value for the current question
                                updatedFarmDetails.surveyData['Biosecurity'].questions[post].notApplicable = !updatedFarmDetails.surveyData['Biosecurity'].questions[post].notApplicable;
                              
                                if (updatedFarmDetails.surveyData['Biosecurity'].questions[post].notApplicable) { 
                                    // Reset checkboxes
                                    farmDetails.surveyData['Biosecurity'].questions[post].subQuestions.forEach((subQ, j) => {
                                        const currentSection = QArray[post].section[j];
                                        currentSection.option.key.forEach((key, k) => {
                                            updatedFarmDetails.surveyData['Biosecurity'].questions[post].subQuestions[j][k] = false;
                                            // Update generalFarm.scoreArray and generalFarm.generalQuestions
                                            ScoresArray = subtractScore(ScoresArray, key, currentSection.option.value[k]);
                                            biosecurity.scoreArray = ScoresArray;
                                            biosecurity.biosecurityQuestions.delete(key);
                                            setFarmDetails(prevFarmDetails => ({
                                              ...prevFarmDetails,
                                              surveyData: {
                                                  ...prevFarmDetails.surveyData,
                                                  Biosecurity: {
                                                      ...prevFarmDetails.surveyData.Biosecurity,
                                                      scoreArray: ScoresArray
                                                  }
                                              }
                                          }));
                                        });
                                    });
                                    
                                    // Reset practice and focusCheck
                                    updatedFarmDetails.surveyData['Biosecurity'].questions[post].practice = "";
                                    updatedFarmDetails.surveyData['Biosecurity'].questions[post].focusCheck = "";
                                    biosecurity.practices[post] = "";
                                    biosecurity.focusArea[post] = "";
                                }
                                
                                // Update the state
                                setFarmDetails(updatedFarmDetails);
                                setIsDataEdited(true)
                            }}
                            name={`notApplicable_${post}`}
                        />
                    }
                    label={
                      <Typography variant="body1" style={{  fontStyle: 'italic' }}>
                          Not Applicable for the farm
                      </Typography>
                  }
                />

              </div>

                  {arr.section.map((innerArr,j) =>( 
                  
                    <div key = {j}>
                      <FormLabel  component = "legend" style = {{color: 'black', marginLeft: '2%', paddingTop: '2%'}}> {innerArr.statement} </FormLabel>
                      {innerArr.option.text.map((text,k) => (
                          <FormGroup key = {k}>
                          <FormControlLabel control = {
                              <Checkbox 
                                  onChange =  { handleChange }
                                  name = {innerArr.option.key[k]}
                                  value = {innerArr.option.value[k]}
                                  style = {{marginLeft: '2%'}}
                                  checked = {farmDetails.surveyData['Biosecurity'].questions[post].subQuestions[j][k]}
                                  data-post={post}
                                  data-subq={j}
                                  data-opt={k}
                                  disabled={farmDetails.surveyData['Biosecurity'].questions[post].notApplicable}
                              />}
                              label = {text}
                               style={{width: "110ch",wordWrap: 'break-word'}}
                          />
                      </FormGroup>
                      ))} 
                    </div>
                  ))}

                  <h6 style={{marginLeft: '2%', fontSize: '20px'}}>
                    Suggestion: {GetSuggestion(ScoresArray[post])}
                  </h6>

                  <TextField
                    id="select-practice"
                    select
                    name = {post}
                    label={<CustomInputLabel label="Select appropriate practice" />}
                    value={farmDetails.surveyData['Biosecurity'].questions[post].practice} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch' }}
                    onChange = {updatePractice}
                
                    disabled={farmDetails.surveyData['Biosecurity'].questions[post].notApplicable}
                    InputProps={{ style: { fontSize: 20} }}
                    InputLabelProps={{ style: { fontSize: 20 } }}
              
                  >
                    {practices.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="select-improvement"
                    select
                    name = {post}
                    label={<CustomInputLabel label="Is this a focus area you want support with?" />}
                    value={farmDetails.surveyData['Biosecurity'].questions[post].focusCheck} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch' }}
                    onChange = {updateFocus}
              
                    disabled={farmDetails.surveyData['Biosecurity'].questions[post].notApplicable}
                    InputProps={{ style: { fontSize: 15} }}
                    InputLabelProps={{ style: { fontSize: 15 } }}
          
                  >
                    {wantImprovement.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>



                  </FormControl>
                  : null}
                </List>  
               </div>
           ) : null
          )}       
          </Typography>
        </AccordionDetails>
      </Accordion>

 

       <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         <h3>Area of focus<sup>*</sup> </h3>
      
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
             <h5>Maximum character limit - 550</h5>
           <Textarea
            maxRows={3}
            maxLength="551"
            placeholder="Focus"
            defaultValue={farmDetails.surveyData['Biosecurity'] ? farmDetails.surveyData['Biosecurity'].focus : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              onChange={(e) => {
                if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    biosecurity.focus = (e.target.value)
                    farmDetails.surveyData['Biosecurity'].focus = (e.target.value)
                    setIsDataEdited(true)
                  }

          }}
          onKeyDown={(e) => {
            const value = e.target.value;
            const numberOfNewLines = (value.match(/\n/g) || []).length;
            
            if (numberOfNewLines >= 7 && e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onPaste={(e) => {
            // Get clipboard data
            const clipboardData = e.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('Text');
        
            // Count the number of lines in the pasted text
            const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
        
            // Check if the number of lines exceeds 10
            if (numberOfNewLines > 7) {
              e.preventDefault();
              // Trim the pasted text to 10 lines and insert it manually
              const trimmedData = pastedData.split('\n').slice(0, 8).join('\n');
              const selectionStart = e.target.selectionStart;
              const before = e.target.value.substring(0, selectionStart);
              const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
              e.target.value = before + trimmedData + after;
              // Update state
              biosecurity.focus = (e.target.value)
              farmDetails.surveyData['Biosecurity'].focus = (e.target.value)
              setIsDataEdited(true)
            }
          }}/>
          </Typography>
        </AccordionDetails>
      </Accordion>

             <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         <h3>Opportunity for follow up/support - (Optional)</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <h5>Maximum character limit - 500</h5>
           <Textarea
            maxRows={3}
            maxLength="501"
            placeholder="Focus"
            defaultValue={farmDetails.surveyData['Biosecurity'] ? farmDetails.surveyData['Biosecurity'].focusAdditional : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              onChange={(e) => {
                if ((e.target.value).length > 500) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    biosecurity.focusAdditional = (e.target.value)
                    farmDetails.surveyData['Biosecurity'].focusAdditional = (e.target.value)
                    setIsDataEdited(true)
                  }

          }}
          onKeyDown={(e) => {
            const value = e.target.value;
            const numberOfNewLines = (value.match(/\n/g) || []).length;
            
            if (numberOfNewLines >= 9 && e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onPaste={(e) => {
            // Get clipboard data
            const clipboardData = e.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('Text');
        
            // Count the number of lines in the pasted text
            const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
        
            // Check if the number of lines exceeds 10
            if (numberOfNewLines > 9) {
              e.preventDefault();
              // Trim the pasted text to 10 lines and insert it manually
              const trimmedData = pastedData.split('\n').slice(0, 10).join('\n');
              const selectionStart = e.target.selectionStart;
              const before = e.target.value.substring(0, selectionStart);
              const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
              e.target.value = before + trimmedData + after;
              // Update state
              biosecurity.focusAdditional = (e.target.value)
              farmDetails.surveyData['Biosecurity'].focusAdditional = (e.target.value)
              setIsDataEdited(true)
            }
          }}/>
          </Typography>
        </AccordionDetails>
      </Accordion>
     
       <BioActionTable onDataEdit={handleDataEdit}/>
    
         


       <div className="back-next-button">
       <Back back = {prev} onClick={() => handleNavigationAttempt(prev)}/>
              <Next 
                next={next} 
                onClick={() => handleNavigationAttempt(next)} 
                disabled={true}
              />
                  <div className="btn-text-right">
                  <Button  variant="contained" href="#contained-buttons"  className="btn-save" style={{ backgroundColor: isDataEdited ? "#002D4E" : "#a8a8a8"}}  sx={{ ml: 1}} onClick={handleSave} disabled={!isDataEdited}>Save</Button>
                {/* <Link to="/ModulePage">
                    <Button  variant="contained" href="#contained-buttons" id = "module" style={{ backgroundColor:"#002D4E"}}  sx={{ ml: 5}}>Module Page</Button>
              </Link> */}
              </div>
              <div className="btn-text-right">

           </div>
        </div>

        </div>
      </header>
   </Grid>
        </Grid>
          </Box>


          {/* Dialog Component */}
          <Dialog
            open={openImageFormatDialog}
            onClose={handleImageFormatDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Type"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Invalid file type. Please select a JPEG or PNG file.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageFormatDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>  
          <Dialog
            open={openImageSizeDialog}
            onClose={handleImageSizeDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Size"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              File size exceeds the limit (400 KB).
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageSizeDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>       
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Incomplete Questions"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please complete all questions before proceeding.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

            {/* Save Successful Dialog */}
            <Dialog
            open={openSaveSuccessDialog}
            onClose={handleSaveSuccessDialogClose}
            aria-labelledby="save-success-dialog-title"
            aria-describedby="save-success-dialog-description"
        >
            <DialogTitle id="save-success-dialog-title">{"Save Successful"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="save-success-dialog-description">
                    You have successfully saved the form.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveSuccessDialogClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

          {/* Save Failed Dialog */}
          <Dialog
        open={openSaveFailedDialog}
        onClose={handleSaveFailedDialogClose}
        aria-labelledby="save-failed-dialog-title"
        aria-describedby="save-failed-dialog-description"
    >
        <DialogTitle id="save-failed-dialog-title">{"Save Failed"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="save-failed-dialog-description">
                An error occurred while saving the form. Please try again.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveFailedDialogClose} color="primary" autoFocus>
                OK
            </Button>
        </DialogActions>
    </Dialog>

      {/* Saving Dialog */}
      <Dialog
      open={openSavingDialog}
      aria-labelledby="saving-dialog-title"
      aria-describedby="saving-dialog-description"
      >
      <DialogTitle id="saving-dialog-title">{"Saving..."}</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
              Please wait while we save your form.
          </DialogContentText>
          {/* Centered spinner with space above */}
          <CircularProgress size={40} style={{ marginTop: '10px' }} />
      </DialogContent>
      </Dialog>

      <Dialog
        open={openUnsavedChangesDialog}
        onClose={() => setOpenUnsavedChangesDialog(false)}
        aria-labelledby="unsaved-changes-title"
        aria-describedby="unsaved-changes-description"
      >
        <DialogTitle id="unsaved-changes-title">{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-description">
            You have unsaved changes in the commentary section. Please save before continuing.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
          onClick={() => {
            setOpenUnsavedChangesDialog(false);
            setIsTextEdited(false); // Reset the text edited state
            setIsDataEdited(false)
            navigate(unsavedChangesNavigationPath); // Navigate to the stored path
            setUnsavedChangesNavigationPath(null); // Reset the navigation path state
          }}
          style={{ color: 'red', marginRight: 'auto', marginLeft: '7px' }} // This aligns the button to the left
        >
          Continue without saving
        </Button>
        <div style={{ marginLeft: 'auto' }}> {/* This div groups the 'Cancel' and 'Save' buttons to the right */}
          <Button onClick={() => setOpenUnsavedChangesDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </div>
      </DialogActions>
      </Dialog>
             {/* Character Dialog */}
        <Dialog open={isCharacterLimitExceeded} onClose={handleCharacterDialogClose}>
      <DialogTitle>Character Limit Exceeded</DialogTitle>
      <DialogContent>
        You have exceeded the character limit. Please shorten your input.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCharacterDialogClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>

    </div>
  );
}