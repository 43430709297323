import html2canvas from 'html2canvas';

/**
 * A hook that exports a function allowing to capture a specific HTMLElement as an image.
 */
export function useScreenshot(ref, filename) {
    const takeScreenShot = async (node) => {
        if (!node) {
            return;
        }
    
        const canvas = await html2canvas(node, { useCORS: true });
        const dataURI = canvas.toDataURL();
        console.log(dataURI); // Log the dataURI
        return dataURI;
    };
    
    
    

  const download = (canvas) => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = canvas?.toDataURL() ?? '';
    a.download = `${filename}.jpeg`;
    a.click();
  };

  
  const downloadScreenshot = () => takeScreenShot(ref.current);

  return { downloadScreenshot };
}
