import React, { useCallback, useState,useEffect,useRef } from 'react';
import Button from "@mui/material/Button";
import Papa from "papaparse";
import { useDropzone } from 'react-dropzone';
import Plot from 'react-plotly.js';
import * as  htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
//Package for uploading csv file: https://www.npmjs.com/package/react-dropzone

//Package for plot: https://plotly.com/javascript/line-charts/


export const IrrigationLine = ({dataimport, onImageGenerated, runCalculations}) => {  
  const [parsedCsvData, setParsedCsvData] = useState([]); //set processed data from csv file
  const [regiony1, setregiony1] = useState([]); //set y axis for Northern Vic
  const [regiony2, setregiony2] = useState([]); //set y axis for Gippsland
  const [regiony3, setregiony3] = useState([]); //set y axis for South West Vic
  const [regiony4, setregiony4] = useState([]);  //set y axis for Tasmania
  const [regionx1, setregionx1] = useState([]); //set x axis for Northern Vic
  const [regionx2, setregionx2] = useState([]); //set x axis for Gippsland
  const [regionx3, setregionx3] = useState([]);  //set x axis for South West Vic
  const [regionx4, setregionx4] = useState([]);  //set x axis for Tasmania
  const [isshow, setisshow] = useState(false); //show plot
    const graphRef = useRef(null);
  const [imageDataUrl, setImageDataUrl] = useState('');
  const [triggerImageGeneration, setTriggerImageGeneration] = useState(false);

  useEffect(() => {
    if (isshow) {
        new Promise(resolve => setTimeout(() => resolve(htmlToImage.toPng(graphRef.current)), 1000))
            .then((dataUrl) => {
                onImageGenerated(dataUrl);  // Send the data URL to the parent component
            })
            .catch((error) => {
                console.error('Error converting to image:', error);
            });
    }
  }, [triggerImageGeneration, isshow]);

  //function to get csv file
  const load = function(){


        // First, run the Calculations function
        runCalculations();

        // Toggle the triggerImageGeneration state
        setTriggerImageGeneration(prev => !prev)

        fetch( './GPWUI.csv' )
            .then( response => response.text() )
            .then( responseText => {
                setisshow(true);
              
                parseFile( responseText );
            });
    };
  
  //function to  get csv file data 
  const parseFile = file => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: results => {
        setParsedCsvData(results.data)
        processData();
      }
    });
  };
  
  //function to process csv file 
  useEffect(() => { 
    processData();
    return () => {}}, [parsedCsvData])



  //function to process data to separate columns and regions and calculate ecdf for each region
  function processData() {
    var temp = Object.values(parsedCsvData);
    var regions = [];
    var gpwui = [];
  
    temp.map((parsedData) => (
      regions.push(parsedData.Region),
      gpwui.push(parsedData['GPWUI (t DM/ML)'])
    ))

    var arr1 = [];
    var arr2 = [];
    var arr3 = [];
    var arr4 = [];

    regions.forEach((region, index) => {
      if (region === 'Northern Victoria') {
        arr1.push(gpwui[index]);
      }
      if (region === 'Gippsland') {
        arr2.push(gpwui[index]);        
      }
     if (region === 'South West Victoria') {
        arr3.push(gpwui[index]);
      }
      if (region === 'Tasmania') {
        arr4.push(gpwui[index]);
      }  
  });

    setregionx1(arr1);
    setregionx2(arr2);
    setregionx3(arr3);
    setregionx4(arr4);

    if(arr1.length > 0){
      var temp1 = [];
      arr1.forEach((x, index) => {
        temp1.push(index/arr1.length);
      });
      setregiony1(temp1);
    }

    if(arr2.length > 0){
      var temp2 = [];
      arr2.forEach((x, index) => {
        temp2.push(index/arr2.length);
      });
      setregiony2(temp2);
    }

    if(arr3.length > 0){
      var temp3 = [];
      arr3.forEach((x, index) => {
        temp3.push(index/arr3.length);
      });
      setregiony3(temp3);
    }

    if(arr4.length > 0){
      var temp4 = [];
      arr4.forEach((x, index) => {
        temp4.push(index/arr4.length);
      });
      setregiony4(temp4);
    }
  }

  //plotly.js syntax to define each line/region data
  var trace1 = {name: 'Northern Victoria', marker: {color: '#FFB6C1'}, type: 'scatter', y: regiony1, x: regionx1 };
  var trace2 = {name: 'Gippsland', marker: {color: '#00008B'}, type: 'scatter', y: regiony2, x: regionx2 };
  var trace3 = {name: 'South West Victoria', marker: {color: '#FF0000'}, type: 'scatter', y: regiony3, x: regionx3 };
  var trace4 =  {name: 'Tasmania', marker: {color: '#76B82A'}, type: 'scatter', y: regiony4, x: regionx4 }

  return (

    <><>
    <div>
      <h4>Irrigation Benchmark Box Plot</h4>
    </div>
    <div className="btn-save">
        <Button variant="contained"  style={{ backgroundColor:"#002D4E"}} onClick={ load }>Calculate</Button>
    </div>
    
    </>
     {/*Plot */}
    { isshow && 
    <div ref={graphRef}>
      <Plot
      data = {[ trace1,trace2,trace3,trace4 ]}
      config={{ displayModeBar: false }}
      layout={ {width: 800, height: 500,  hovermode: false, title: 'Percentage of farms that have a water use index at or below the specified value', yaxis: {title: 'Percentage of Data'},xaxis: {title: 'Gross Production Water Use Index (t DM/ML)'},
      shapes: [{
        type: 'line',
        x0: dataimport,
        y0: 0,
        x1: dataimport,
        y1: 1,
        line: {
          color: 'rgb(40, 114, 79)',
          width: 2, dash: 'dot'
        }
      }], 
      annotations: [{x: dataimport, y: 1, text: 'Your Value',showarrow: false, textposition: 'bottom', font: {color: 'rgb(40, 114, 79)'}}]
      } }/>
         </div>
    }

    </> 
  );
} 

 export default IrrigationLine; 