import React from 'react';
import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import {default as styles} from "../Styles"
import action from "../../../images/Action.jpg"

export default function IrrigationApproach(irrigation, farmDetails){

const irrigationStyles = StyleSheet.create({
  text_action:{
    color: '#002D4E',
    font: 'Arial',
    fontSize: 12,
    paddingHorizontal: 10,
    marginBottom: 10,
    lineHeight:1.4
  },
})
	 return(
      <Page>
      {/* header */}
      <div style = {styles.approachHeaderImageBox} >
        <Image 
          src = {action}
          style={{position: 'relative',
          display: 'flex',
          paddingBottom: 0,
          marginBottom: 0}} />
      
        <div style={{position: 'absolute'}}>
          <Text style={styles.title}>The Action</Text>
          <Text style={styles.secondSubtitle}>Irrigation Management</Text>  
        </div>
      </div>

      <View fixed style = {{height: 15}}></View>
      
      
      {/* Approach */}
      {irrigation.approach !== '' && (
      <div style = {styles.approach}>
        <Text style={styles.approach_text}>Approach</Text>
        <Text style = {irrigationStyles.text_action}>{irrigation.approach} </Text>
      </div>
      )}
      <View fixed style = {{marginTop: "30%"}}></View> 
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
      </Page>
    )
}