import { StyleSheet } from '@react-pdf/renderer';


const borderColor = '#000000';

const styles = StyleSheet.create({
  headerContainer: {
      flexDirection: 'row',
      backgroundColor: '#002D4E',
      borderWidth: 2,
      height: 50,
      flexGrow: 1,
      marginLeft:"5%",
      marginRight:"5%",
  },
  headerText:{
    fontFamily: 'ArialBold',
      fontSize: 10,
      color:"#ffffff",
      textAlign: 'center',
  },
  headerQuestion:{
    width: "48%",
    borderColor: borderColor,
    borderRightWidth: 2,
    height: 48,
    paddingVertical: 15,
  },
  page:{
      fontSize: 11,
      flexDirection: "column",
    },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: '#000000',
    borderBottomWidth: 2,
    marginLeft:"5%",
    marginRight:"5%",
  },
  footerText:{
    fontFamily: 'ArialBold',
    fontSize: 14,
    color:"#002D4E",
    textAlign: 'center',
  },
  pageBackground: {
    position: 'fixed',
    minWidth: '100%',
    minHeight: '100%',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  coverLogo: {
    position: 'relative',
    height: '30%',
    width: '10%',
    marginLeft: '10%',
    marginTop: '0%'
  },
  frontPageTitle: {
    width: 500,
    fontSize: 65,
    textAlign: 'left',
    paddingBottom: 600,
    paddingLeft: 30,
    marginTop: 10,
    color: 'white',
    fontFamily: 'Recoleta',
    },
  title: {
    fontSize: 30,
    textAlign: 'left',
    color: '#002D4E',
    marginLeft: 30,
    marginBottom: 120,
    marginTop: 15,
    fontFamily: 'Recoleta',
  },
  secondTitle: {
    fontSize: 30,
    textAlign: 'left',
    color: '#002D4E',
    marginLeft: 30,
    marginBottom: 20,
    marginTop: 15,
    fontFamily: 'Recoleta',
  },
  subtitle: {
    fontSize: 26,
    marginBottom: '3%',
    textAlign: 'left',
    color: '#002D4E',
    marginLeft: 30,
    marginTop: -110,
    fontFamily: 'RecoletaMedium',
  },
  secondSubtitle: {
    fontSize: 14,
    marginBottom: 80,
    textAlign: 'left',
    color: '#002D4E',
    marginLeft: 30,
    marginTop: -110,
    fontFamily: 'RecoletaMedium',
  },
  text: {
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 30,
    marginRight: 30,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Arial',
    color: '#002D4E',
    padding: 0,
    lineHeight:1.4,
    flexWrap: 'wrap'
  },
  wrapper: {
    position:"relative",
    display:"flex", 
    flexDirection: "column",
    marginTop:5,    
  },
  commentary: {
    marginTop: '-10%',
    marginBottom: '3%',
    marginLeft: '8%',
    marginRight: '8%',
    fontSize: 12,
    lineHeight:1.5,
    textAlign: 'justify',
    fontFamily: 'Arial',
    color: '#002D4E',
    flexWrap: 'wrap'
  },
  commentaryAction:{
    color: '#002D4E',
    font: 'Arial',
    fontSize: 12,
    // paddingHorizontal: 10,
    marginBottom: 10,
    lineHeight:1.5,
    marginHorizontal: "5%",    
    paddingBottom:30    
  },
  commentarySection:{
    color: '#002D4E',
    font: 'Arial',
    fontSize: 12,
    // paddingHorizontal: 10,  
    marginBottom: 10,
    marginHorizontal: "5%", 
    lineHeight:1.5, 
    paddingBottom:20,   
  },
  commentaryImageWrapper:{
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center", 
    paddingTop:40, 
    paddingBottom:20, 
    flexGrow:1,
    marginHorizontal: "5%",
  },
  moduleIntroduction: {
    marginTop: 380,
    width: '70%',
    marginBottom: 8,
    marginLeft: '25%',
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Arial',
    color: '#002D4E',
    lineHeight:1.5,
    padding: 0, 
    flexWrap: 'wrap'
  },
  moduleIntroduction2: {
    marginTop: '5%',
    marginLeft: '15%',
    marginRight: '15%',
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Arial',
    color: '#002D4E',
    lineHeight:1.5,
    flexWrap: 'wrap'
  },
  effluentHeaderIntro: {
    width: '85%',
    marginBottom: 8, 
    marginLeft: '15%', 
    fontSize: 12, 
    textAlign: 'left',
    fontFamily: 'Arial', 
    color: '#002D4E',
    padding: 0,
    lineHeight:1.5,
    flexWrap: 'wrap'
  },
  headerImageBox: {
    height: "25%",
  },
  commentaryHeaderImageBox: {
    height: "auto",
  },  
  approachHeaderImageBox: {
    height: "auto",
  }, 
  actionHeaderImageBox: {   
    height: "auto",   
  },
  text_dark_square:{
    fontSize: 14,
    textAlign: 'left',
    fontFamily: 'ArialBold',
    color:"#ffffff",
    paddingLeft: '3%',
    paddingTop: '2%'
  },
    dark_square_box: {
    width: "90%", 
    height: "5%", 
    marginLeft: "5%", 
    marginRight: "5%", 
    backgroundColor: '#002D4E',
  },
    map_holder:{
    width: "90%", 
    height: "35%", 
    marginLeft: "5%",
    backgroundColor: "#EBF4F9",
    alignItems: "center"
  },
    text_leftbox: {
    marginLeft: '5%',
    marginRight: '10%',
    marginTop: 15,
    paddingTop: '30px',
    fontSize: 15,
    lineHeight: '1.8',
    textAlign: 'left',
    fontFamily: 'Arial',
    color: '#002D4E',
    },
    text_rightbox: {
      paddingTop: '30px',
      marginTop: 15,
      marginLeft: '10%',
      marginRight: "10%",
      fontSize: 22,
      lineHeight: '1.5',
      textAlign: 'left',
      fontFamily: 'ArialBold',
      color: '#002D4E',

    },
    nitrogen_icons: {
      paddingTop: 0,
      height: "25%",
    },
    farm_details_box:{
      flexDirection: 'row',
      position: "absolute",
      marginLeft: "5%",
    },
    farm_details_first_column:{
      width: "35%",
      textAlign: 'left',
      marginLeft: 30,
    },
    farm_details_second_column:{
      width: "65%",
      alignItems: 'left',
    },
    contents:{
      marginVertical: 20,
      fontSize: 14,
      fontFamily: 'ArialBold',
      color: '#002D4E',
    },
    tableOfContents:{
      marginTop: 8,
      marginBottom: 8,
      marginLeft: 60,
      marginRight: 60,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Arial',
      color: '#002D4E',
    },
    farm_details:{
      fontSize: 14,
      marginVertical: 20,
      textAlign: 'justify',
      fontFamily: 'Arial',
      color: '#002D4E',

    },
    farm_details1:{
      fontSize: 14,
      marginVertical: 20,
      textAlign: 'justify',
      fontFamily: 'Arial',
      color: '#002D4E',
      width: "80%",
     flexWrap: 'wrap'

    },
    signature: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 30,
      marginRight: 30,
      fontSize: 10,
      textAlign: 'justify',
      fontFamily: 'Arial',
      color: '#002D4E',
      padding: 0
    },
    general_image: {
      marginTop:0,
      marginVertical: 0,
      marginHorizontal: 0,
      padding: 0,
      height: "50%"
    },
    header: {
      fontSize: 30,
      marginBottom: 20,
      textAlign: 'left',
      color: '#002D4E',
    },
    action:{
      marginTop: '5%',
      padding:0,
      marginBottom:'5%',
      marginHorizontal: '5%',
      height: '10%',
    },

    text_action:{
      fontSize: 12,
      fontFamily: "Arial",
      color: '#002D4E', 
      padding: 12,
      lineHeight:1.4

    },
    approach:{

      marginHorizontal: '5%',
      display:'inline-block',
      backgroundColor: '#FCF8EC',
      alignItems: 'flex-start',
      marginTop:'1%', 
  
     },
     commentaryContainer:{    
      marginLeft: '5%',
      textAlign:'left',
      paddingLeft:0,
      marginRight: '5%',
      display:'inline-block',     
      alignItems: 'flex-start',
      marginTop:'3%', 
     },
    approach_text:{
      fontFamily: 'Recoleta',
      fontSize: 16, 
      color: '#002D4E', 
      paddingVertical: 10, 
      paddingHorizontal: 10,      
      overflowWrap: 'break-word'
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'right',
      marginRight: 30,
      color:  '#002D4E',
      fontFamily: 'Recoleta',
      marginTop: '15%'
    },
    farmName:{
      position: 'absolute',
      fontSize: 12,
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'left',
      marginLeft: 30,
      marginTop: 50,
      color:  '#002D4E',
      fontFamily: 'Recoleta',
      marginTop: '15%'
    },
    farmName_cover:{
      position: 'absolute',
      fontSize: 12,
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'left',
      marginLeft: 30,
      marginTop: 50,
      color:  '#ffffff',
      fontFamily: 'Recoleta',
      marginTop: '15%'
    }
  }
)

export default styles;