import * as React from "react";
import Button from "@mui/material/Button";
import { useState, useEffect, useMemo, useRef } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import useModuleLink from '../hooks/moduleLink';
import Next from "./Next";
import Back from "./Back";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Textarea from '@mui/joy/Textarea';

import Boxplot from "./BoxplotNitrogen";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AppRunTimeContext } from '../Context/index'; 
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {addScore, subtractScore, GetSuggestion, practices, wantImprovement} from './Utilities.jsx'
import acquireAccessToken from "../accessToken"
import { useMsal } from "@azure/msal-react";
import axios from 'axios';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ProgressBar from "./ProgressBar";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// Module questions with corresponding subquestions. 
//Each checkbox(option) are given a unique key with the initial of the module - question no - subquestion no - checkbox no.
//For instance, XX-00-00-00.
//The value for each option is decided based on how good the practices are.
const QArray = [
  { number: 1, //Question no
    question: "Is your fertiliser spreader calibrated correctly and checked regularly?", //Question content
    section: [
      {id:"1.1",
      statement: "",  //sub question
      option: {
        text: ["Calibrated correctly and checked regularly with an accredited spreader","Calibrated correctly and checked regularly without an accredited spreader","Calibrated correctly but not checked regularly ","Not calibrated"],  //options for checkbox
        value: [8, 5, 1, 0],  // assigned value for each option
        key: ["NI-01-1-1","NI-01-1-2", "NI-01-1-3", "NI-01-1-4"] 
        //key for each checkbox, name as: module's initial-Question no-SubQ no-Checkbox no
      }},
    ]},
   {
    number: 2, 
    question: "Do you assess the current weather and soil conditions before applying Nitrogen fertiliser?", 
    section: [
      {id:"2.1",
      statement: "",
      option: {
        text: ["Applied when soil moisture is adequate","Applied when there is a good prospect of irrigation (summer and autumn)","Applied before the soil started to dry out","Applied when soil temperature is adequate (Above 4 Degree Celcius for temperate grasses and/or 10 Degree Celcius for subtropical grasses)","Applied without consideration"],
        value: [4, 4, 4, 4, 0],
        key: ["NI-02-1-1","NI-02-1-2", "NI-02-1-3","NI-02-1-4","NI-02-1-5"] 
      }},
  ]},
  {
  number: 3, 
  question: "Are other limiting growth factors considered into your Nitrogen fertiliser decision?", 
  section: [
    {id:"3.1",
    statement: "",
    option: {
      text: ["Applied when pasture is actively growing","Applied to pastures with high density of desired species (i.e. sown)","Applied to pastures with good ground cover","Applied to pastures that have no limitations to major soil nutrients","Not applied at high risk areas (i.e. wet spots)","Applied without consideration"],
      value: [5, 5, 5, 3, 3, 0],
      key: ["NI-03-1-1","NI-03-1-2", "NI-03-1-3","NI-03-1-4","NI-03-1-5","NI-03-1-6"] 
    }},
  ]},
  {
    number: 4, 
    question: "Do you assess pasture and feed requirements before applying Nitrogen?", 
    section: [
      {id:"4.1",
      statement: "",
      option: {
        text: ["Extra pasture grown is utilised through grazing and harvested forage","Extra pasture grown is utilised through grazing","Extra pasture grown is utilised as harvested forage","Not assessed"],
        value: [8, 4, 4, 0],
        key: ["NI-04-1-1","NI-04-1-2", "NI-04-1-3", "NI-04-1-4"] 
      }},
    ]},
    {
      number: 5, 
      question: "Do you adjust your Nitrogen fertilisation rates based on seasonal conditions?", 
      section: [
        {id:"5.1",
        statement: "5.1. Range",
        option: {
          text: ["Within 25-50 kg N/ha range","Out of 25-50 kg N/ha range","No measurement of rate"],
          value: [8, 3, 0] ,
          key: ["NI-05-1-1","NI-05-1-2", "NI-05-1-3"] 
        }},
        {id:"5.2",
        statement: "5.2. Area",
        option: {
          text: ["Different rate for different areas (i.e. less moisture, less fertiliser)","Same rate for all paddocks"],
          value: [4, 1],
          key: ["NI-05-2-1","NI-05-2-2"]  
        }},
        {id:"5.3",
        statement: "5.3. Season",
        option: {
          text: ["Feed budgeting tool for seasonal application is utilised","Variable rate in different seasons without budgeting tool","Fix rate for all season"],
          value: [5, 3, 0],
          key: ["NI-05-3-1","NI-05-3-2", "NI-05-3-3"]  
        }},
      ]},
    {
      number: 6, 
      question: "Is your grazing round adjusted throughout the season to optimise the Nitrogen response?", 
      section: [
        {id:"6.1",
        statement: "6.1. Grazing time",
        option: {
          text: ["Nitrogen is applied as soon after grazing as possible","Fertiliser is applied within 1-3 days after grazing (during spring and summer)","Fertiliser is applied within 5-7 days after grazing (during winter)","No consideration in grazing time"],
          value: [5,3,3,0],
          key: ["NI-06-1-1","NI-06-1-2", "NI-06-1-3", "NI-06-1-4"]  
        }},
        {id:"6.2",
        statement: "6.2. Leaf stage",
        option: {
          text: ["Grazing is avoided until the growth has reached 2.5 leaf stage (for temperate grass, i.e. rye grass)","Grazing is avoided until the growth has reached 3 leaf stage (for tropical grass, i.e. kikuyu)","No consideration"],
          value: [5, 5, 0],
          key: ["NI-06-2-1","NI-06-2-2", "NI-06-2-3"]  
        }},
      ]},
    {
      number: 7, 
      question: "Do you feed test for CP and adjust your diet accordingly?", 
      section: [
        {id:"7.1",
        statement: "",
        option: {
          text: ["MUN testing is used to adjust diet in conjunction with ration balancing and feed testing","MUN testing is used to adjust diet but not in conjunction with ration balancing and feed testing","Ration balancing and feed testing is used to adjust diet","No consideration is given to adjust diet"],
          value: [8, 6, 4, 0],
          key: ["NI-07-1-1","NI-07-1-2", "NI-07-1-3", "NI-07-1-4"]  
        }},
      ]},
    {
      number: 8, 
      question: "Do you adjust your Nitrogen formulation for minimising Nitrogen losses to the environment?", 
      section: [
        {id:"8.1",
        statement: "",
        option: {
          text: ["Nitrogen is not applied where soil moisture is limiting","Spray irrigation - Nitrogen fertiliser is applied within 24 hours prior to irrigation","Flood irrgation - Nitrogen is applied just before irrigation","Nitrogen applications near streams are avoided","No consideration is given to minimise Nitrogen losses"],
          value: [4, 4, 4, 4, 0] ,
          key: ["NI-08-1-1","NI-08-1-2", "NI-08-1-3", "NI-08-1-4", "NI-08-1-5"] 
        }},
      ]},
      {
        number: 9, 
        question: "Do you manage and monitor the legume content of your pasture?", 
        section: [
          {id:"9.1",
          statement: "",
          option: {
            text: ["Managed and monitored according to type of pastures","Managed and monitored without consideration of pasture type","Not managed and monitored"],
            value: [8, 5, 0],
            key: ["NI-09-1-1","NI-09-1-2", "NI-09-1-3"] 
          }},
      ]},
      {
      number: 10, 
      question: "Do you grow alternative pasture and forage to limit Nitrogen losses to the environment?", 
      section: [
        {id:"10.1",
        statement: "",
        option: {
          text: ["Well-balanced grass and clover pasture","Alternative crops of legume and low Nitrogen content grass","Only grow legume","Only grow low Nitrogen content grass"],
          value: [8, 4, 4, 1],
          key: ["NI-10-1-1","NI-10-1-2", "NI-10-1-3", "NI-10-1-4"]
        }},
        ]},
]


export default function Nitrogen() {

  
  const { instance, accounts } = useMsal();

  const { farmDetails, setFarmDetails, payload, setPayload, operation, setOperation, highestRevisionNumber, setHighestRevisionNumber } = useContext(AppRunTimeContext);
  const { nitrogen, setNitrogen, generalFarm } = useContext(AppRunTimeContext)
  // Use the dictionary from generalFarm[images] to replace image filenames
  const formattedValue = (farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].commentary : "").replace(/nitrogenCommentary\S+/g, match => {
    console.log(match)
    const base64 = nitrogen.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [value, setValue] = useState(formattedValue);
    
  const formattedValueAction = (farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].action : "").replace(/nitrogenAction\S+/g, match => {
    console.log(match)
    const base64 = nitrogen.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [valueAction, setValueAction] = useState(formattedValueAction);
  const {steps} = useContext(AppRunTimeContext);
  const [isTextEdited, setIsTextEdited] = useState(false);
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);
  const [unsavedChangesNavigationPath, setUnsavedChangesNavigationPath] = useState(null);

  var ScoresArray = nitrogen.scoreArray
  const { setNextPrev, getNext, getPrev} = useModuleLink();
  
   //Routing 
  setNextPrev("/Nitrogen");
  let next = getNext();
  let prev = getPrev();

  const stepIndex = steps.findIndex(step => step.link === '/Nitrogen');

  const [postDisplay, setPostDisplay] = useState(true);
  const divDisplay = true;
  const [state, setState] = React.useState(null);
  const [N_surplus, setN_surplus] = React.useState(0);//surpleis
  const [N_res, setN_res] = React.useState(0); //response
  const boxplotData = [ (+nitrogen.nitrogenFertiliser),  N_surplus, N_res];

  const [openDialog, setOpenDialog] = useState(false);
  const [openSaveSuccessDialog, setOpenSaveSuccessDialog] = React.useState(false);
  const [openSaveFailedDialog, setOpenSaveFailedDialog] = React.useState(false);
  const [openSavingDialog, setOpenSavingDialog] = React.useState(false);
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] = useState(false);
  const stateRef = useRef();
  const [isDataEdited, setIsDataEdited] = useState(false);
  const [openImageFormatDialog, setOpenImageFormatDialog] = React.useState(false);
  const [openImageSizeDialog, setOpenImageSizeDialog] = React.useState(false);


  useEffect(() => {
    stateRef.current = {
      farmDetails,
      value,
      valueAction
    };
  }, [farmDetails, value, valueAction]);
  const navigate = useNavigate();
    
  const handleImageFormatDialogClose = () => {    
    setOpenImageFormatDialog(false);   
  };

  const handleImageSizeDialogClose = () => {
    setOpenImageSizeDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSaveSuccessDialogClose = () => {
    setOpenSaveSuccessDialog(false);
  };

  const handleSaveFailedDialogClose = () => {
    setOpenSaveFailedDialog(false);
  };

  const handleCharacterDialogClose = () => {
    setIsCharacterLimitExceeded(false);
  };

  const handleNextClick = () => {
    // const questions = farmDetails.surveyData['Nitrogen'].questions;
    // const graphData = farmDetails.surveyData['Nitrogen'].graph;

    // for (let i = 0; i < questions.length; i++) {
    //   if (!questions[i].practice || !questions[i].focusCheck) {
    //     console.log('open dialog')
    //     setOpenDialog(true);
    //     return;
    //   }
    // }

    
  // // Check if the graph data is still at its default value
  // if (graphData === "") {
  //   console.log('open dialog - graph data not set');
  //   setOpenDialog(true);
  //   return;
  // }

    // If all conditions are met, navigate to the next page.
    navigate(next);
  };

  useEffect(() => {

    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
        window.location.href = '/'; // Redirect to home page
    }


    if (farmDetails.surveyData['Nitrogen']) {
    // Your code here will run after the component has mounted
      // Loop through the surveyData[1].questions array
      farmDetails.surveyData['Nitrogen'].questions.forEach((question, index) => {
        // Update the generalFarm.practices array based on the practice in each question
        nitrogen.practices[index] = question.practice;
        nitrogen.focusArea[index] = question.focusCheck
      });
        console.log(nitrogen.practices)
        // console.log(tempModule)
        // console.log(farmDetails)
    }
    else {
      if(!(farmDetails.surveyData['Nitrogen'])) {
      setFarmDetails(prevDetails => ({
        ...prevDetails,
          surveyData: {
            ...prevDetails.surveyData,
            details: {
              farmOwner: farmDetails.farm_owner,
              planOwner: farmDetails.plan_owner,
              farmAddressLat: farmDetails.farmAddressLat,
              farmAddressLong: farmDetails.farmAddressLong,
              financialYear: farmDetails.financialYear,
              farmAddress: farmDetails.farmAddress
            },
            Nitrogen: {
              module: "Nitrogen",
              commentary: "",
              action: "",
              approach: "",
              focus: "",
              focusAdditional: "",
              nitrogenFertiliser: "",
              purchasedFeed: "",
              milkingCows: "",
              nitrogenPage: 0,
              scoreArray:  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              milkingHectares: "",
              milkSolids: "",
              milkingFat: "",
              milkProtein: "",
              homegrownFeed: "",
              importedFeed: "",
              exportedPro: "",
              surplus: "",
              graph: "",
     
              benchmarkcommentary: "",
              QuestionMapping: [
                {
                  Q1_2: "Is your fertiliser spreader calibrated correctly and checked regularly?",
                  Q2_2: "Do you assess the current weather and soil conditions before applying Nitrogen fertiliser?",
                  Q3_2: "Are other limiting growth factors considered into your Nitrogen fertiliser decision?",
                  Q4_2: "Do you assess pasture and feed requirements before applying Nitrogen?",
                  Q5_2: "Do you adjust your Nitrogen fertilisation rates based on seasonal conditions?",
                  Q6_2: "Is your grazing round adjusted throughout the season to optimise the Nitrogen response?",
                  Q7_2: "Do you feed test for CP and adjust your diet accordingly?",
                  Q8_2: "Do you adjust your Nitrogen formulation for minimising Nitrogen losses to the environment?",
                  Q9_2: "Do you manage and monitor the legume content of your pasture?",
                  Q10_2: "Do you grow alternative pasture and forage to limit Nitrogen losses to the environment?"
                }
              ],
              questions: [
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                },
                {
                    subQuestions: [
                        [
                          false,
                          false,
                          false,
                          false
                        ]
                    ],
                    practice: "",
                    focusCheck: "",
                    notApplicable: false
                }
              ]
            }
          }
    }));
  }
    }

  }, []);  // Empty dependency array means this useEffect runs once after initial render

  const handleClick = (post) => { 
    const objectCopy = {
      ...postDisplay
    };
    objectCopy[post] = !objectCopy[post];
    setPostDisplay(objectCopy);
  };

  useEffect(() => {
    // Function to be executed
    const intervalFunction = () => {
      console.log(operation);
      if (operation === "modify") {
        handleSave('autosave');
        console.log('This function runs every 30 seconds');
      }
      // Add your logic here
    };
  
    // Set the interval
    const interval = setInterval(intervalFunction, 120000); 
  
    // Clear interval on component unmount or when operation changes
    return () => clearInterval(interval);
  }, [operation]); // Include operation in the dependency array

  const getChecked = (key) => {
    if (nitrogen.nitrogenQuestions.get(key)) 
      return true;
    else
      return false; 
  }

  const handleNavigationAttempt = (navigationPath) => {
    if (isTextEdited) {
      setUnsavedChangesNavigationPath(navigationPath); // Store the intended navigation path
      setOpenUnsavedChangesDialog(true); // If text is edited, show the warning dialog
    } else {
      navigate(navigationPath); // Otherwise, navigate immediately
    }
  };
  
  //Update the score of each subquestion
  const handleChange = (event) => {


    const postIndex = event.target.parentNode.dataset.post;
    const subQIndex = event.target.parentNode.dataset.subq;
    const optIndex = event.target.parentNode.dataset.opt;

    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });

    // {console.log(farmDetails.surveyData[1].questions)}
    {console.log(postIndex)}
    {console.log(subQIndex)}
    {console.log(optIndex)}
    
    farmDetails.surveyData['Nitrogen'].questions[postIndex].subQuestions[subQIndex][optIndex] = event.target.checked;
    setIsDataEdited(true)

    if (event.target.checked){
      ScoresArray = addScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue);
      nitrogen.scoreArray = ScoresArray
      nitrogen.nitrogenQuestions.set(event.target.name, true);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            Nitrogen: {
                ...prevFarmDetails.surveyData.Nitrogen,
                scoreArray: ScoresArray
            }
        }
    }));
    }
    else{
      ScoresArray = subtractScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue);
      nitrogen.scoreArray = ScoresArray
      nitrogen.nitrogenQuestions.delete(event.target.name);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            Nitrogen: {
                ...prevFarmDetails.surveyData.Nitrogen,
                scoreArray: ScoresArray
            }
        }
    }));
    }
    console.log(ScoresArray)
    //event.target.name - Gets key of checkbox
    //event.target.attributes.value.nodeValue - Gets value of checkbox
  };

  
  function showGraph (e)  {
    Calculations();
   }

//Calculate data for the graph
  function Calculations() {
    var nfert = +nitrogen.nitrogenFertiliser;    
    var pfeed = +nitrogen.purchasedFeed;
    var mcows = +nitrogen.milkingCows;
    var mhec = +nitrogen.milkingHectares;
    var msol = +nitrogen.milkSolids;
    var mfat = (+nitrogen.milkingFat)/100;
    var mpro = (+nitrogen.milkProtein)/100;
    var hfeed= +nitrogen.homegrownFeed;
    
    var nim = ((pfeed * mcows)/ mhec ) * 22.5; //inported feed
    var nex = (msol * mcows / mhec) * ((mpro * 0.1623412)/(mfat + mpro));//exported pro
    var sur = nfert + nim - nex; //surplus
    var nres = nfert/hfeed; //nitrogen response
    
    nitrogen.importedFeed = Math.round(nim)
    farmDetails.surveyData['Nitrogen'].importedFeed = Math.round(nim)
    nitrogen.exportedPro = Math.round(nex)
    console.log(nitrogen.exportedPro)
    farmDetails.surveyData['Nitrogen'].exportedPro = Math.round(nex)
    console.log(Math.round(nim))
    console.log(Math.round(nex))
    setN_surplus(sur); //surplus
    nitrogen.surplus = Math.round(sur)
    farmDetails.surveyData['Nitrogen'].surplus = Math.round(sur)
    setIsDataEdited(true)

    setN_res(nres);
  }

 // Update user's choice of practice
function updatePractice(e) {
  nitrogen.practices[e.target.name] = e.target.value
   // Create a deep copy of the farmDetails object
   const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
   // Update the nested property
   newFarmDetails.surveyData['Nitrogen'].questions[e.target.name].practice = e.target.value;
   // Update the state
   setFarmDetails(newFarmDetails);
   setIsDataEdited(true)

}
  
  // Update whether user would like to focus on that area
function updateFocus(e) {
  nitrogen.focusArea[e.target.name] = e.target.value
  // Create a deep copy of the farmDetails object
const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
// Update the nested property
newFarmDetails.surveyData['Nitrogen'].questions[e.target.name].focusCheck = e.target.value;
// Update the state
setFarmDetails(newFarmDetails);
setIsDataEdited(true)

}

const handleImageGenerated = (imageData) => {
  nitrogen.graph = imageData;
  farmDetails.surveyData['Nitrogen'].graph=imageData
  setIsDataEdited(true)

  console.log(imageData)
};

const isAEDT = (date) => {
  const year = date.getFullYear();
  const startDST = new Date(Date.UTC(year, 9, 1)); // October 1st
  const endDST = new Date(Date.UTC(year, 3, 1)); // April 1st

  // Find the first Sunday in October
  startDST.setUTCDate(1 + (7 - startDST.getUTCDay()) % 7);
  
  // Find the first Sunday in April
  endDST.setUTCDate(1 + (7 - endDST.getUTCDay()) % 7);

  return (date >= startDST && date < endDST);
};


  const getCurrentDateTime = () => {
    const nowUTC = new Date(Date.now());

    const offset = isAEDT(nowUTC) ? 12 : 11; // UTC+13 if Daylight Saving Time, otherwise UTC+12
  
    const aet = new Date(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(),
                         nowUTC.getUTCHours() + offset, nowUTC.getUTCMinutes(), nowUTC.getUTCSeconds());
  
    const year = aet.getFullYear();
    const month = String(aet.getMonth() + 1).padStart(2, '0');
    const day = String(aet.getDate()).padStart(2, '0');
    const hour = String(aet.getHours()).padStart(2, '0');
    const minute = String(aet.getMinutes()).padStart(2, '0');
    const second = String(aet.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}${hour}${minute}${second}`;
  };


// Helper function to prepare payload
const preparePayload = (prevDetails, specificFields = {}) => {
  const currentState = stateRef.current;
  const { surveyData } = currentState.farmDetails;

  const surveyDataWithoutGraphs = { ...currentState.farmDetails.surveyData };

  if (surveyData.Carbon) {
    const { graph: carbonGraph, ...rest } = surveyData.Carbon;
    surveyDataWithoutGraphs.Carbon = rest;
  }

  if (surveyData.Nitrogen) {
    const { graph: nitrogenGraph, ...rest } = surveyData.Nitrogen;
    surveyDataWithoutGraphs.Nitrogen = rest;
  }

  if (surveyData.Irrigation) {
    const { graph: irrigationGraph, ...rest } = surveyData.Irrigation;
    surveyDataWithoutGraphs.Irrigation = rest;
  }

  if (surveyData.HealthyCalves) {
    const { colostrum, calfPathways, ...rest } = surveyData.HealthyCalves;
  
    // Remove graph1 and graph2 from colostrum
    const { graph1: colostrumGraph1, graph2: colostrumGraph2, graph3: colostrumGraph3, graph4: colostrumGraph4,...colostrumRest } = colostrum;
    
    // Remove graph1 and graph2 from calfPathways
    const { graph1: calfPathwaysGraph1, graph2: calfPathwaysGraph2, ...calfPathwaysRest } = calfPathways;
  
    surveyDataWithoutGraphs.HealthyCalves = {
      ...rest,
      colostrum: colostrumRest,
      calfPathways: calfPathwaysRest
    };

    if (surveyData.GeneralFarm) {
      const { farmMap, ...restGeneralFarm } = surveyData.GeneralFarm;
      surveyDataWithoutGraphs.GeneralFarm = restGeneralFarm;
    }
  }

  return {
    ...prevDetails,
    dateEdited: getCurrentDateTime(),
    region: farmDetails.farmRegion,
    status: "DRAFT",
    financialYear: farmDetails.financialYear,
    farmId: farmDetails.supplier_no,
    surveyData: surveyDataWithoutGraphs,
    modules: farmDetails.modules,
    ...specificFields
  };
};

// Helper function to make Axios call
const makeAxiosCall = async (urlSuffix, payload) => {
  try {
    const token = await acquireAccessToken(instance, accounts[0]);
    localStorage.setItem("accessToken", token);
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/${urlSuffix}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('Response:', response.data);
    console.log(payload);
    return response;
  } catch (error) {
    console.error('An error occurred while performing Axios POST:', error);
    return null;  // Return null if there's an error
  }
};



const handleSave = (saveType) => {
  const currentState = stateRef.current;

  const urlSuffix = saveType === "autosave" ? "modifyFarmSurveyData" : "insertFarmSurveyData";
  saveType !== "autosave" && setOpenSavingDialog(true);

  if (operation !== "modify") {
    farmDetails.farmSurveyId = farmDetails.supplier_no + getCurrentDateTime();
  }
  // Check if highestRevisionNumber is defined and is a string of 4 digits
  let validRevisionNumber = highestRevisionNumber && /^\d{4}$/.test(highestRevisionNumber);
  let newRevisionNumber;

  if (saveType !== 'autosave') {
    if (validRevisionNumber) {
      // Increment the revision number if it's valid
      newRevisionNumber = (parseInt(highestRevisionNumber, 10) + 1).toString().padStart(4, '0');
    } else {
      // Default to '0001' if highestRevisionNumber is undefined or invalid
      newRevisionNumber = '0001';
    }
  }

    const regex1 = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
  let match1;
  const images1 = [];

  while ((match1 = regex1.exec(currentState.value)) !== null) {
    const imageName = saveType==='autosave' ? `nitrogenCommentary${images1.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `nitrogenCommentary${images1.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
    images1.push({ base64: match1[1], name: imageName });
  }

  console.log(images1);

  let modifiedText1 = currentState.value;

  // Replace base64 values with image names
  for (let i = 0; i < images1.length; i++) {
    modifiedText1 = modifiedText1.replace(images1[i].base64, images1[i].name);
  }

  // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
  modifiedText1 = modifiedText1.replace(/<img src="([^"]+)"[^>]*>/g, (match1, p1) => {
    return p1 + '\n';  // Add a newline character after the image name
  });

  // First, handle the <li> tags by converting them to bullet points
  modifiedText1 = modifiedText1.replace(/<li[^>]*>/gi, '• ');
  modifiedText1 = modifiedText1.replace(/<\/li>/gi, '\n');

  // Then, remove the <ul> and </ul> tags as they are no longer necessary
  modifiedText1 = modifiedText1.replace(/<\/?ul[^>]*>/gi, '\n');

  // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
  modifiedText1 = modifiedText1.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

  // Convert inline elements to spaces or remove them
  modifiedText1 = modifiedText1.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

  // Convert <br> tags to newlines
  modifiedText1 = modifiedText1.replace(/<br[^>]*>/gi, '\n');

  // Remove other HTML tags
  modifiedText1 = modifiedText1.replace(/<\/?[^>]+(>|$)/g, '').trim();

  // Replace multiple newlines with a single newline
  modifiedText1 = modifiedText1.replace(/\n+/g, '\n').trim();

  // Replace multiple spaces with a single space
  modifiedText1 = modifiedText1.replace(/ +/g, ' ').trim();
  
  nitrogen.commentary = modifiedText1;
  currentState.farmDetails.surveyData['Nitrogen'].commentary = modifiedText1;


      const regex2 = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
  let match2;
  const images2 = [];
  
  while ((match2 = regex2.exec(currentState.valueAction)) !== null) {
    const imageName = saveType==='autosave' ? `nitrogenAction${images2.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `nitrogenAction${images2.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
    images2.push({ base64: match2[1], name: imageName });
  }

  console.log(images2);

  let modifiedText2 = currentState.valueAction;
  console.log(modifiedText2)

  // Replace base64 values with image names
  for (let i = 0; i < images2.length; i++) {
    modifiedText2 = modifiedText2.replace(images2[i].base64, images2[i].name);
  }

  // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
  modifiedText2 = modifiedText2.replace(/<img src="([^"]+)"[^>]*>/g, (match2, p1) => {
    return p1 + '\n';  // Add a newline character after the image name
  });

  // First, handle the <li> tags by converting them to bullet points
  modifiedText2 = modifiedText2.replace(/<li[^>]*>/gi, '• ');
  modifiedText2 = modifiedText2.replace(/<\/li>/gi, '\n');

  // Then, remove the <ul> and </ul> tags as they are no longer necessary
  modifiedText2 = modifiedText2.replace(/<\/?ul[^>]*>/gi, '\n');

  // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
  modifiedText2 = modifiedText2.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

  // Convert inline elements to spaces or remove them
  modifiedText2 = modifiedText2.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

  // Convert <br> tags to newlines
  modifiedText2 = modifiedText2.replace(/<br[^>]*>/gi, '\n');

  // Remove other HTML tags
  modifiedText2 = modifiedText2.replace(/<\/?[^>]+(>|$)/g, '').trim();

  // Replace multiple newlines with a single newline
  modifiedText2 = modifiedText2.replace(/\n+/g, '\n').trim();

  // Replace multiple spaces with a single space
  modifiedText2 = modifiedText2.replace(/ +/g, ' ').trim();
  
  nitrogen.action = modifiedText2;
  currentState.farmDetails.surveyData['Nitrogen'].action = modifiedText2;

  // Define a helper function to make the image upload call
  const uploadGraph = async (imageName, graphData) => {
    if (imageName.startsWith('farmPic_')) {
      if (!farmDetails.hasFarmPicChanged) {
        return true
      }
      else {
        setFarmDetails(prevDetails => ({
          ...prevDetails,
          hasFarmPicChanged: false
        }));
      }
    }
    const imagePayload = {
      "imageName": imageName,
      "imageDetails": graphData
    };
    console.log("Image Payload:", imagePayload);
    return makeAxiosCall("storeImage", imagePayload);
  };

     const imageUploadPromises1 = images1.map(image => {
      return uploadGraph(image.name, image.base64);
    });

    const imageUploadPromises2 = images2.map(image => {
      return uploadGraph(image.name, image.base64);
    });


  setPayload(prevDetails => {
    const specificFields = operation === "modify" ? {
      revisionNumber: saveType==='autosave' ? 'AUTOSAVE' : newRevisionNumber,
      id: saveType==='autosave' ? farmDetails.supplier_no : farmDetails.supplier_no + `_${newRevisionNumber}`,
      editedBy: accounts[0].name
  } : {
      creatorName: accounts[0].name,
      dateCreated: getCurrentDateTime(),
      id: farmDetails.supplier_no + `_${newRevisionNumber}`,
      schemaVersionNum: "001",
      surveyVersionNumber: "1",
      status: "DRAFT",
      farmSurveyId: farmDetails.farmSurveyId,
      revisionNumber: newRevisionNumber,
      editedBy: accounts[0].name
  };

    const updatedPayload = preparePayload(prevDetails, specificFields);

    (async () => {
      try {
        const response = await makeAxiosCall(urlSuffix, updatedPayload);

        if (response) {
          if (saveType !== 'autosave') { 
            setHighestRevisionNumber(newRevisionNumber);
          }
          
          if (operation !== "modify") {
            farmDetails.farmSurveyId = updatedPayload.farmSurveyId;
            farmDetails.date_edited = updatedPayload.dateEdited;
            setOperation('modify');
          }

          const graphPaths = [
            { path: "HealthyCalves.colostrum.graph1", namePrefix: "healthyCalvesColostrumGraph1_" },
            { path: "HealthyCalves.colostrum.graph2", namePrefix: "healthyCalvesColostrumGraph2_" },
            { path: "HealthyCalves.colostrum.graph3", namePrefix: "healthyCalvesColostrumGraph3_" },
            { path: "HealthyCalves.colostrum.graph4", namePrefix: "healthyCalvesColostrumGraph4_" },
            { path: "HealthyCalves.calfPathways.graph1", namePrefix: "healthyCalvesCalfPathwaysGraph1_" },
            { path: "HealthyCalves.calfPathways.graph2", namePrefix: "healthyCalvesCalfPathwaysGraph2_" },
            { path: "Nitrogen.graph", namePrefix: "nitrogenGraph_" },
            { path: "Carbon.graph", namePrefix: "carbonGraph_" },
            { path: "Irrigation.graph", namePrefix: "irrigationGraph_" },
            { path: "farmPic", namePrefix: "farmPic_" },
            { path: "GeneralFarm.farmMap", namePrefix: "farmMap_" }
          ];

          const graphPromises = graphPaths.map(graph => {
            const parts = graph.path.split('.');
            let graphValue;

            switch (parts.length) {
              case 1:
                graphValue = currentState.farmDetails[parts[0]];
                break;
              case 2:
                if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]]) {
                  graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]];
                }
                break;
              case 3:
                if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]] && currentState.farmDetails.surveyData[parts[0]][parts[1]]) {
                  graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]][parts[2]];
                }
                break;
              default:
                break;
            }

            if (graphValue && graphValue !== "") {
              if (saveType === 'autosave') {
                return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_AUTOSAVE`, graphValue);
              }
              else {
                return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_${newRevisionNumber}`, graphValue);
              }
            }
            return Promise.resolve(true);
          });

           // Merge imageUploadPromises and graphPromises
           const allUploadPromises = [...imageUploadPromises1, ...imageUploadPromises2, ...graphPromises];

           const allUploadResponses = await Promise.all(allUploadPromises);

           if (allUploadResponses.every(res => res)) {
            setOpenSavingDialog(false);
            saveType !== "autosave" && setOpenSaveSuccessDialog(true);
            // After save is successful
            setIsTextEdited(false);
            setIsDataEdited(false)
            setOpenUnsavedChangesDialog(false);
          } else {
            setOpenSavingDialog(false);
            saveType !== "autosave" && setOpenSaveFailedDialog(true);
          }
        } else {
          setOpenSavingDialog(false);
          saveType !== "autosave" && setOpenSaveFailedDialog(true);
        }
      } catch (error) {
        console.error("An error occurred during saving:", error);
        setOpenSavingDialog(false);
        saveType !== "autosave" && setOpenSaveFailedDialog(true);
      }
    })();

    return updatedPayload;
  });
};

const allQuestionsAnswered = () => {
  return QArray.every((arr, post) => {
    return farmDetails?.surveyData?.['Nitrogen']?.questions[post]?.notApplicable ||
           (farmDetails?.surveyData?.['Nitrogen']?.questions[post]?.practice && farmDetails?.surveyData?.['Nitrogen']?.questions[post]?.focusCheck);
  });
}

const quillRef = useRef(null); 
const quillRef1 = useRef(null); // Reference to the ReactQuill component
useEffect(() => {
  if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage();                               
              return false;              
            }                        
            const sizeInBytes = (lastImage.length * 3) / 4;     
            const sizeInKB = sizeInBytes / 1024;
                const fileSizeInKB = Math.round(sizeInKB);  
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) { 
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage();                
                      return false; 
                  }   
                }              
              }              
            }
        };

      // Attach the event listener
      quill.on('text-change', handleChange);

      // Clean up the event listener when the component unmounts
      return () => {
          quill.off('text-change', handleChange);
      };
  }
}, []);
useEffect(() => {
  if (quillRef1.current) {
      const quill = quillRef1.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage();                               
              return false;              
            }                        
            const sizeInBytes = (lastImage.length * 3) / 4;         
            //const sizeInBytes = lastImage.length; 
            const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB);
                     
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage1();                
                      return false; 
                  }   
                }              
              }              
            }
        };

      // Attach the event listener
      quill.on('text-change', handleChange);

      // Clean up the event listener when the component unmounts
      return () => {
          quill.off('text-change', handleChange);
      };
  }
}, []);

const handleDeleteImage1 = () => {
  const quill = quillRef1.current.getEditor();
  const selection = quill.getSelection();
  quill.history.undo();
};

const handleDeleteImage = () => {
  const quill = quillRef.current.getEditor();
  const selection = quill.getSelection();
  quill.history.undo();
};

const getImageType = (lastImage) => {
  const matches = lastImage.match(/^data:(.*);base64,/);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return null;
};

const customImageHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    const content = editor.getContents();
    console.log("Custom image handler triggered"); // This should be printed whenever an image is added

    // Count the number of images
    let imageCount = 0;
    content.ops.forEach(op => {
        if (typeof op.insert === 'string' && op.insert.includes('<img')) {
            imageCount++;
        }
    });

    if (imageCount >= 5) {
        alert('You can only insert a maximum of 5 images.');
    } else {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const range = selection;
                editor.insertEmbed(range.index, 'image', reader.result);
            };

            reader.readAsDataURL(file);
        };
    }
};

const modules = useMemo(() => ({
  toolbar: {
    container: [
      [{ list: 'bullet' }],
      ['image']
    ]
  }
}), [])

const [openDialog1, setOpenDialog1] = useState(false);
const [openDialog2, setOpenDialog2] = useState(false);
const handleCloseDialog1 = () => {
  setOpenDialog1(false);
};
const handleCloseDialog2 = () => {
  setOpenDialog2(false);
};

  const CustomInputLabel = ({ label }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ fontSize: '15px' }}>{label}</span>
    <span style={{ fontSize: '15px', color: 'red', fontWeight: 'bold', marginLeft: '4px' }}>*</span>
  </div>
   );


   const checkUnsavedChanges = () => {
    return isTextEdited; // where isTextEdited is a state variable that tracks if the text has been edited

  };  

  return (
    <div className="nitrogen-page">
                             <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
        <Grid item xs={3}>
         <div className="progressbar">
         <ProgressBar currentStep={stepIndex} checkUnsavedChanges={checkUnsavedChanges} setOpenUnsavedChangesDialog={setOpenUnsavedChangesDialog} setUnsavedChangesNavigationPath={setUnsavedChangesNavigationPath}/>
           </div>
           </Grid>
            <Grid item xs={9}>
      {console.log(farmDetails)}
      {/* {console.log(payload)} */}
      {console.log(generalFarm)}
      {console.log(next)}
      <header className="nitrogen-header">
        <h1 className="nitrogen-h1">Nutrient Management (Nitrogen)</h1>
                  
        <div className="module-questions"> 
          <h5>* indicates <b>mandatory fields</b></h5>
                  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>Benchmark<sup>*</sup></h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {/* Input data for calculation */}
        <div className="benchmark">
          <p>
            <TextField  
              variant="standard"
              type="number"
              required
              fullWidth
              id="outlined-required"
              defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].nitrogenFertiliser : ""}
              label="Nitrogen Fertiliser (kg/N/ha)"
              InputProps={{ style: { fontSize: 20 } }}
              InputLabelProps={{ style: { fontSize: 20 } }}
              onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                       nitrogen.nitrogenFertiliser = e.target.value;
                        farmDetails.surveyData['Nitrogen'].nitrogenFertiliser = (e.target.value)
                        setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

              }}/>
          </p>

          <h4 >N Imported feed </h4>

          <p>
            <TextField  
              variant="standard"
              type="number"
              required
              fullWidth
              id="outlined-required"
              defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].purchasedFeed : ""}
              label="Purchased feed ton's dry matter a cow"
              InputProps={{ style: { fontSize: 20 } }}
              InputLabelProps={{ style: { fontSize: 20 } }}
              onChange={(e) => {
                    const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                       nitrogen.purchasedFeed = e.target.value;
                        farmDetails.surveyData['Nitrogen'].purchasedFeed = (e.target.value)
                        setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

            }}/>
          </p>

          <p>
          <TextField  
            variant="standard"
            type="number"
            required
            fullWidth
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].milkingCows : ""}
            label="Milking Cows"
            InputProps={{ style: { fontSize: 20 } }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            onChange={(e) => {
                 const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                            nitrogen.milkingCows = e.target.value;    
                            farmDetails.surveyData['Nitrogen'].milkingCows = (e.target.value)     
                            setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }
   
            }}/>
          </p>

          <p>
          <TextField  
            variant="standard"
            type="number"
            required
            fullWidth
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].milkingHectares : ""}
            label="Milking Hectares"
            InputProps={{ style: { fontSize: 20 } }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                             nitrogen.milkingHectares = e.target.value;
                            farmDetails.surveyData['Nitrogen'].milkingHectares = (e.target.value)   
                            setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }
     
            }}/>
          </p>

          <h4>Exported Product </h4>
          <p>
          <TextField  
            variant="standard"
            type="number"
            required
            fullWidth
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].milkSolids : ""}
            label="Milk solids per cow"
            InputProps={{ style: { fontSize: 20 } }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                                nitrogen.milkSolids = e.target.value;
                                farmDetails.surveyData['Nitrogen'].milkSolids = (e.target.value)      
                                setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }
  
            }}/>
          </p>

          <p>
          <TextField  
            variant="standard"
            type="number"
            required
            fullWidth
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].milkingFat : ""}
            label="Milk fat %, E.g: 3.92"
            InputProps={{ style: { fontSize: 20 } }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            onChange={(e) => {
                   const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                         nitrogen.milkingFat= e.target.value;
                        farmDetails.surveyData['Nitrogen'].milkingFat = (e.target.value)         
                        setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }
                if(e.target.value >  0) {
           
                    nitrogen.milkingFat= e.target.value;
                }
                else {
             
                    setOpenDialog2(true);

                }
           }}/>
          </p>

          <p>
          <TextField  
            variant="standard"
            type="number"
            required
            fullWidth
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].milkProtein : ""}
            label="Milk protein %, E.g: 3.92"
            InputProps={{ style: { fontSize: 20 } }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            onChange={(e) => {
                   const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                         nitrogen.milkProtein = e.target.value;  
                        farmDetails.surveyData['Nitrogen'].milkProtein = (e.target.value)        
                        setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

               if(e.target.value > 0) {

                    nitrogen.milkProtein= e.target.value;
                }
                else {
              
                    setOpenDialog2(true);

                }
     
            }}/>
          </p>

          <p>
          <TextField  
            variant="standard"
            type="number"
            required
            fullWidth
            id="outlined-required"
            defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].homegrownFeed : ""}
            label="Home grown Feed Tons D/m per ha"
            InputProps={{ style: { fontSize: 20 } }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            onChange={(e) => {
             const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                        nitrogen.homegrownFeed = e.target.value;
                        farmDetails.surveyData['Nitrogen'].homegrownFeed = (e.target.value)   
                        setIsDataEdited(true)
    
                    }
                else {
                    setOpenDialog1(true);

                }
    
            }}/>
          </p>



        </div>
         <p>
          <b>Calculated milk export per hectare: {nitrogen.exportedPro}</b>
        </p> 


        <div>
            {/* Call the boxplot from the BoxplotNitrogen.js file */}
            <Boxplot dataimport = {boxplotData} onImageGenerated={handleImageGenerated} runCalculations={Calculations}/>
        </div>  



              <h3>Benchmark Commentary - Optional</h3>
              <h5>Maximum character limit - 550</h5>
             <Textarea
            
            maxRows={3}
            maxLength="551"
            placeholder="Benchmark Commentary"
            defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].benchmarkcommentary : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9"}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   nitrogen.focus = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       Nitrogen: {
              //         ...prevFarmDetails.surveyData.Nitrogen,
              //         focus: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
                 if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                     nitrogen.benchmarkcommentary = (e.target.value);
                    farmDetails.surveyData['Nitrogen'].benchmarkcommentary = (e.target.value);
                    setIsDataEdited(true)

                  }

              }}
              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 7 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 7) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 8).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  nitrogen.benchmarkcommentary = (e.target.value)
                  farmDetails.surveyData['Nitrogen'].benchmarkcommentary = (e.target.value)
                  setIsDataEdited(true)

                }
              }}
          />
           </Typography>
        </AccordionDetails>
      </Accordion>





       <Accordion>
       <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3>
          Questions <sup>*</sup>
          { allQuestionsAnswered() && <CheckCircleIcon style={{ marginLeft: '10px', marginBottom: '-3px', color: 'green', fontSize: '1.5rem' }} /> }
        </h3>
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
                      {/* map through the QArray to display questions and options */}
          {QArray.map((arr, post) =>
            divDisplay ? (
              <div>
                <List
                  sx={{ width: "100%", bgcolor: "#EBF4F9"}}
                  component="nav"
                  aria-labelledby="nested-list-subheader" >

                  <ListItemButton style={{ fontSize: 20, fontWeight: 'bold' }} onClick={() => handleClick(post)}>
                  Question {post+1}.{arr.question}
                  { 
                    (farmDetails?.surveyData?.['Nitrogen']?.questions[post]?.notApplicable ||
                    (farmDetails?.surveyData?.['Nitrogen']?.questions[post]?.practice && farmDetails?.surveyData?.['Nitrogen']?.questions[post]?.focusCheck)) &&
                    <CheckIcon style={{ marginLeft: '10px', color: 'green' }} />
                  }
                </ListItemButton>

                  {postDisplay[post] ?
                  
                  <FormControl>
                                    <div style={{ marginLeft: '2%', marginTop: '15px' }}>
                  <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={farmDetails.surveyData['Nitrogen'].questions[post].notApplicable}
                            onChange={() => {
                                // Copy the current farmDetails state
                                const updatedFarmDetails = {...farmDetails};
                                // Toggle the notApplicable value for the current question
                                updatedFarmDetails.surveyData['Nitrogen'].questions[post].notApplicable = !updatedFarmDetails.surveyData['Nitrogen'].questions[post].notApplicable;
                              
                                if (updatedFarmDetails.surveyData['Nitrogen'].questions[post].notApplicable) { 
                                    // Reset checkboxes
                                    farmDetails.surveyData['Nitrogen'].questions[post].subQuestions.forEach((subQ, j) => {
                                        const currentSection = QArray[post].section[j];
                                        currentSection.option.key.forEach((key, k) => {
                                            updatedFarmDetails.surveyData['Nitrogen'].questions[post].subQuestions[j][k] = false;
                                            // Update generalFarm.scoreArray and generalFarm.generalQuestions
                                            ScoresArray = subtractScore(ScoresArray, key, currentSection.option.value[k]);
                                            nitrogen.scoreArray = ScoresArray;
                                            nitrogen.nitrogenQuestions.delete(key);
                                            setFarmDetails(prevFarmDetails => ({
                                              ...prevFarmDetails,
                                              surveyData: {
                                                  ...prevFarmDetails.surveyData,
                                                  Nitrogen: {
                                                      ...prevFarmDetails.surveyData.Nitrogen,
                                                      scoreArray: ScoresArray
                                                  }
                                              }
                                          }));
                                        });
                                    });
                                    
                                    // Reset practice and focusCheck
                                    updatedFarmDetails.surveyData['Nitrogen'].questions[post].practice = "";
                                    updatedFarmDetails.surveyData['Nitrogen'].questions[post].focusCheck = "";
                                    nitrogen.practices[post] = "";
                                    nitrogen.focusArea[post] = "";
                                }
                                
                                // Update the state
                                setFarmDetails(updatedFarmDetails);
                                setIsDataEdited(true)

                            }}
                            name={`notApplicable_${post}`}
                        />
                    }
                    label={
                      <Typography variant="body1" style={{  fontStyle: 'italic' }}>
                          Not Applicable for the farm
                      </Typography>
                  }
                />

              </div>
                  {arr.section.map((innerArr,j) =>( 
                  
                    <div key = {j}>
                      <FormLabel  component = "legend" style = {{color: 'black', marginLeft: '2%', paddingTop: '2%'}}> {innerArr.statement} </FormLabel>
                      {innerArr.option.text.map((text,k) => (
                        <FormGroup key = {k}>
                        <FormControlLabel control = {
                            <Checkbox 
                                onChange =  { handleChange }
                                name = {innerArr.option.key[k]}
                                value = {innerArr.option.value[k]}
                                style = {{marginLeft: '2%'}}
                                checked = {farmDetails.surveyData['Nitrogen'].questions[post].subQuestions[j][k]}
                                data-post={post}
                                data-subq={j}
                                data-opt={k}
                                disabled={farmDetails.surveyData['Nitrogen'].questions[post].notApplicable}
                            />}
                            label = {text}
                             style={{width: "110ch",wordWrap: 'break-word'}}
                        />
                    </FormGroup>
                      ))} 
                    </div>
                  ))}

                  <h6 style={{marginLeft: '2%', fontSize: '20px'}}>
                    Suggestion: {GetSuggestion(ScoresArray[post])}
                  </h6>

                  <TextField
                    id="select-practice"
                    select
                    name= {post}
                    label={<CustomInputLabel label="Select appropriate practice" />}
                    value={farmDetails.surveyData['Nitrogen'].questions[post].practice} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch' }}
                      InputProps={{ style: { fontSize: 20} }}
                    InputLabelProps={{ style: { fontSize: 20 } }}
                    onChange = {updatePractice}
                
                    disabled={farmDetails.surveyData['Nitrogen'].questions[post].notApplicable}
                  >
                    {practices.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="select-improvement"
                    select
                    name = {post}
                     label={<CustomInputLabel label="Is this a focus area you want support with?" />}
                    value={farmDetails.surveyData['Nitrogen'].questions[post].focusCheck} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch' }}
                     InputProps={{ style: { fontSize: 15} }}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                    onChange = {updateFocus}
                  
                    disabled={farmDetails.surveyData['Nitrogen'].questions[post].notApplicable}
                  >
                    {wantImprovement.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>



                  </FormControl>
                  : null}
                  
                </List>    
               </div>
           ) : null
          )} 
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
            Commentary
            {farmDetails?.surveyData?.['GeneralFarm']?.commentary &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
              <h3>Commentary<sup>*</sup></h3>
            </AccordionSummary>

            {/* <AccordionDetails>
              <Typography>
                <TextareaAutosize
                  minRows={3}
                  maxRows={5}
                  placeholder="Commentary"
                  defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].commentary : ""}
                  style={{
                    color: 'black',
                    width: "100%",
                    paddingBottom: '2%',
                    border: "2px solid #000000",
                    fontSize: 20,
                    fontFamily: "Arial",
                    backgroundColor: "#EBF4F9",
                  }}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm

                  //   setFarmDetails(prevFarmDetails => ({
                  //     ...prevFarmDetails,
                  //     surveyData: {
                  //       ...prevFarmDetails.surveyData,
                  //       GeneralFarm: {
                  //         ...prevFarmDetails.surveyData.GeneralFarm,
                  //         commentary: newValue
                  //       }
                  //     }
                  //   }));
                  // }}
                  onChange={(e) => {
                    generalFarm.commentary = (e.target.value)
                    farmDetails.surveyData['GeneralFarm'].commentary = (e.target.value)
                  }}
                />
              </Typography>
            </AccordionDetails> */}

            <AccordionDetails>
              <ReactQuill ref={quillRef} theme="snow" value={value} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValue(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)
                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
              <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>
            </AccordionDetails>
          </Accordion>

        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {/* <h3>
          Area of focus
          {farmDetails?.surveyData?.['Nitrogen']?.focus &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
        </h3> */}
        <h3>Area of focus<sup>*</sup></h3>
    
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
              <h5>Maximum character limit - 550</h5>
             <Textarea
            maxRows={3}
            maxLength="551"
            placeholder="Focus"
            defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].focus : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9"}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   nitrogen.focus = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       Nitrogen: {
              //         ...prevFarmDetails.surveyData.Nitrogen,
              //         focus: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
                  if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    nitrogen.focus = (e.target.value)
                   farmDetails.surveyData['Nitrogen'].focus = (e.target.value)
                   setIsDataEdited(true)

                  }

              }}
              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 7 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 7) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 8).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  nitrogen.focus = (e.target.value)
                  farmDetails.surveyData['Nitrogen'].focus = (e.target.value)
                  setIsDataEdited(true)

                }
              }}
          />
          </Typography>
        </AccordionDetails>
      </Accordion>

       <Accordion>
       <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {/* <h3>
            Opportunity for follow up/support
            {farmDetails?.surveyData?.['Nitrogen']?.focusAdditional &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
          <h3>Opportunity for follow up/support - (Optional)</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <h5>Maximum character limit - 500</h5>
          <Textarea
              maxRows={3}
              maxLength="501"
              placeholder="Focus"
              defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].focusAdditional : ""}
              style={{ 
                color: 'black', 
                width: "100%",
                paddingBottom: '2%',
                border: "2px solid #000000",
                fontSize: 20, 
                fontColor: "black", 
                fontFamily: "Arial", 
                backgroundColor:"#EBF4F9"}}
                // onChange={(e) => {
                //   const newValue = e.target.value;
                //   nitrogen.focusAdditional = newValue; // Assuming you also need to update generalFarm
                  
                //   setFarmDetails(prevFarmDetails => ({
                //     ...prevFarmDetails,
                //     surveyData: {
                //       ...prevFarmDetails.surveyData,
                //       Nitrogen: {
                //         ...prevFarmDetails.surveyData.Nitrogen,
                //         focusAdditional: newValue
                //       }
                //     }
                //   }));
                // }}
                onChange={(e) => {
                              if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    nitrogen.focusAdditional = (e.target.value);
                  farmDetails.surveyData['Nitrogen'].focusAdditional = (e.target.value);
                  setIsDataEdited(true)

                  }
                }}
              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 9 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 9) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 10).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  nitrogen.focusAdditional = (e.target.value);
                  farmDetails.surveyData['Nitrogen'].focusAdditional = (e.target.value);
                  setIsDataEdited(true)

                }
              }}
                        
            />

          </Typography>
        </AccordionDetails>
      </Accordion>

             <Accordion>
             <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
                Actions
                {farmDetails?.surveyData?.['Nitrogen']?.action &&
                  <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
                }
              </h3> */}
              <h3>Actions<sup>*</sup></h3>
            </AccordionSummary>
        <AccordionDetails>
          <Typography>
                        <ReactQuill ref={quillRef1} theme="snow" value={valueAction} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValueAction(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)
                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>

          {/*            <TextareaAutosize
            minRows={3}
            maxRows={5}
           
            placeholder="Actions"
            defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].action : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   nitrogen.action = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       Nitrogen: {
              //         ...prevFarmDetails.surveyData.Nitrogen,
              //         action: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
                nitrogen.action = (e.target.value)
                farmDetails.surveyData['Nitrogen'].action = (e.target.value)
              }}
          /> */}
          </Typography>
          <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>
        </AccordionDetails>
      </Accordion>

             <Accordion>
             <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
                Approach
                {farmDetails?.surveyData?.['Nitrogen']?.approach &&
                  <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
                }
              </h3> */}
              <h3>Approach - (Optional)</h3>
            </AccordionSummary>
        <AccordionDetails>
          <Typography>
             <h5>Maximum character limit - 850</h5>
             <Textarea
            minRows={3}
            maxRows={5}
            maxLength="851"
            placeholder="Additional focus"
            defaultValue={farmDetails.surveyData['Nitrogen'] ? farmDetails.surveyData['Nitrogen'].approach : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   nitrogen.approach = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       Nitrogen: {
              //         ...prevFarmDetails.surveyData.Nitrogen,
              //         approach: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
                if ((e.target.value).length > 850) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                      nitrogen.approach = (e.target.value)
                    farmDetails.surveyData['Nitrogen'].approach = (e.target.value)
                    setIsDataEdited(true)

                  }

              }}
              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 11 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 11) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 12).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  nitrogen.approach = e.target.value;
                  farmDetails.surveyData['Nitrogen'].approach = e.target.value;
                  setIsDataEdited(true)

                }
              }}
          />
          </Typography>
        </AccordionDetails>
      </Accordion>
    

      <div className="back-next-button">
      <Back back = {prev} onClick={() => handleNavigationAttempt(prev)}/>
              <Next 
                next={next} 
                onClick={() => handleNavigationAttempt(next)} 
                disabled={true}
              />
                  <div className="btn-text-left">
                  <Button  variant="contained" href="#contained-buttons"  className="btn-save" style={{ backgroundColor: isDataEdited ? "#002D4E" : "#a8a8a8"}}  sx={{ ml: 1}} onClick={handleSave} disabled={!isDataEdited}>Save</Button>
                {/* <Link to="/ModulePage">
                    <Button  variant="contained" href="#contained-buttons" id = "module" style={{ backgroundColor:"#002D4E"}}  sx={{ ml: 5}}>Module Page</Button>
              </Link> */}
              </div>
              {/* <Button
                  id="next"
                  style={{ backgroundColor:"#002D4E", marginTop:55, marginBottom:55 }}
                  variant="contained"
                  onClick={() => { window.location.href = '/'; }}
              >
                  Home
              </Button> */}
            </div>

        </div>
      </header>
                  </Grid>
        </Grid>
          </Box>

                    {/* Dialog Component */}
                    <Dialog
            open={openImageFormatDialog}
            onClose={handleImageFormatDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Type"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Invalid file type. Please select a JPEG or PNG file.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageFormatDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openImageSizeDialog}
            onClose={handleImageSizeDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Size"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              File size exceeds the limit (400 KB).
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageSizeDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
                    <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Incomplete Questions"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please complete all questions/calculate & show plot before proceeding.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          {/* Negative Dialog */}

                    <Dialog open={openDialog1} onClose={handleCloseDialog1}>
              <DialogTitle>Error</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Negative numbers are not allowed. Please enter a positive number.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog1} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            {/* Less than 0 Dialog */}

                    <Dialog open={openDialog2} onClose={handleCloseDialog2}>
              <DialogTitle>Error</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please enter a number more than 0
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog2} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            {/* Save Successful Dialog */}
            <Dialog
            open={openSaveSuccessDialog}
            onClose={handleSaveSuccessDialogClose}
            aria-labelledby="save-success-dialog-title"
            aria-describedby="save-success-dialog-description"
        >
            <DialogTitle id="save-success-dialog-title">{"Save Successful"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="save-success-dialog-description">
                    You have successfully saved the form.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveSuccessDialogClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

                  {/* Save Failed Dialog */}
                  <Dialog
            open={openSaveFailedDialog}
            onClose={handleSaveFailedDialogClose}
            aria-labelledby="save-failed-dialog-title"
            aria-describedby="save-failed-dialog-description"
        >
            <DialogTitle id="save-failed-dialog-title">{"Save Failed"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="save-failed-dialog-description">
                    An error occurred while saving the form. Please try again.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveFailedDialogClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

                {/* Saving Dialog */}
                <Dialog
        open={openSavingDialog}
        aria-labelledby="saving-dialog-title"
        aria-describedby="saving-dialog-description"
    >
        <DialogTitle id="saving-dialog-title">{"Saving..."}</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
                Please wait while we save your form.
            </DialogContentText>
            {/* Centered spinner with space above */}
            <CircularProgress size={40} style={{ marginTop: '10px' }} />
        </DialogContent>
        </Dialog>

        <Dialog
        open={openUnsavedChangesDialog}
        onClose={() => setOpenUnsavedChangesDialog(false)}
        aria-labelledby="unsaved-changes-title"
        aria-describedby="unsaved-changes-description"
      >
        <DialogTitle id="unsaved-changes-title">{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-description">
            You have unsaved changes in the commentary section. Please save before continuing.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
          onClick={() => {
            setOpenUnsavedChangesDialog(false);
            setIsTextEdited(false); // Reset the text edited state
            setIsDataEdited(false)
            navigate(unsavedChangesNavigationPath); // Navigate to the stored path
            setUnsavedChangesNavigationPath(null); // Reset the navigation path state
          }}
          style={{ color: 'red', marginRight: 'auto', marginLeft: '7px' }} // This aligns the button to the left
        >
          Continue without saving
        </Button>
        <div style={{ marginLeft: 'auto' }}> {/* This div groups the 'Cancel' and 'Save' buttons to the right */}
          <Button onClick={() => setOpenUnsavedChangesDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </div>
      </DialogActions>
      </Dialog>
             {/* Character Dialog */}
        <Dialog open={isCharacterLimitExceeded} onClose={handleCharacterDialogClose}>
      <DialogTitle>Character Limit Exceeded</DialogTitle>
      <DialogContent>
        You have exceeded the character limit. Please shorten your input.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCharacterDialogClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
}
  