import React,{ useState, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Input } from 'react-spreadsheet-grid';
import { AppRunTimeContext } from '../Context/index'; 

//Biosecurity Action Table used in Biosecurity module made using react-spreadsheet-grid
//https://www.npmjs.com/package/react-spreadsheet-grid

export const BioActionTable = ({ onDataEdit }) =>  {
    const {biosecurity, farmDetails} = useContext(AppRunTimeContext);

    const BioActions = () => {
    var actionsTable = biosecurity.actionTable; //making it global using contextprovider

    // Rows are stored in the state.
    const [actionsrows, setRows] = useState(actionsTable);
    
    // A callback called every time a value changed.
    // Every time it save a new value to the state.
    const onFieldChange = (rowId, field) => (value) => {
        // Find the row that is being changed
        const row = actionsrows.find(({id}) => id === rowId);
        
        // Change a value of a field
        row[field] = value;
        setRows([].concat(actionsrows))
        biosecurity.actionTable = actionsrows;
        farmDetails.surveyData['Biosecurity'].actionTable = actionsrows
        // Call the callback to indicate that data has been edited
        if(onDataEdit) {
            onDataEdit();
        }
    }

    const initColumns = () => [
        {
            title: () => 'Our Goals',
            value: (row, { focus }) => {
            // You can use the built-in Input.
            return (
                <Input style = {{background: 'rgb(0, 45, 78)'}}
                value={row.goals}
                focus={focus}
                onChange={onFieldChange(row.id, 'goals')}
                />
            );
            }
        }, 
        {
        title: () => 'Our Actions',
        value: (row, { focus }) => {
            // You can use the built-in Input.
            return (
            <Input style = {{background: 'rgb(0, 45, 78)'}}
                value={row.action}
                focus={focus}
                onChange={onFieldChange(row.id, 'action')}
            />
            );
            }
        }, 
        {
        title: () => 'Priority (Who)',
        value: (row, { focus }) => {
            // You can use the built-in Input.
            return (
            <Input  style = {{background: 'rgb(0, 45, 78)'}}
                value={row.priority_who}
                focus={focus}
                onChange={onFieldChange(row.id, 'priority_who')}
            />
            );
        }
        },
        {
        title: () => 'Priority (When)',
        value: (row, { focus }) => {
            // You can use the built-in Input.
            return (
            <Input  style = {{background: 'rgb(0, 45, 78)'}}
                value={row.priority_when}
                focus={focus}
                onChange={onFieldChange(row.id, 'priority_when')}
            />
            );
        }
        }, 
    ]
          
    biosecurity.actions = actionsrows;
          
    return (
    <Grid style = {{background: 'rgb(235, 244, 249)'}}
        columns={initColumns()}
        rows={actionsrows}
        isColumnsResizable
        //onColumnResize={onColumnResize}
        getRowKey={row => row.id}
    />
    )
    }
    
    return (
        <div style={{width: '100%'}}>
               <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>Actions - (Optional)</h3>
        </AccordionSummary>
        <AccordionDetails>
          <BioActions/>
        </AccordionDetails>
      </Accordion>

        </div>
    );
  }
export default BioActionTable;