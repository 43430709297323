import * as React from "react";
import Button from "@mui/material/Button";
import { useState, useEffect, useRef, useMemo } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import useModuleLink from '../hooks/moduleLink';
import Next from "./Next";
import Back from "./Back";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Textarea from '@mui/joy/Textarea';


import {PieChart} from "./PieChart";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {addScore, subtractScore, GetSuggestion, practices, wantImprovement} from './Utilities.jsx'
import { AppRunTimeContext } from '../Context/index'; 
import { useContext } from 'react';
import TrainingTable from "./TrainingTable";
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalfPathwaysActionTable from "./CalfPathwaysActionTable";
import ColostrumActionTable from "./ColostrumActionTable";
import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import acquireAccessToken from "../accessToken"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

import * as  htmlToImage from 'html-to-image';

import { debounce } from 'lodash';
import _ from 'lodash'
import CircularProgress from '@mui/material/CircularProgress';

import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ProgressBar from "./ProgressBar";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QArray1 = [
  { number: 1, //Question no
  question: "Are teats washed, disinfected and dried prior to colostrum harvesting? Are calves collected and cows milked soon as possible after calving to manage colostrum quality and mastitis risk?", //Question content
  section: [
    {id:"1.1",
    statement: "1.1. Teats cleaning",  //sub question
    option: {
      text: ["Teats are cleaned and dried","Aware of how to clean teats but only intermittently", "Teats are not cleaned and dried prior to collection "],  //options for checkbox
      value: [4, 2, 0],  // assigned value for each option
      key: ["HC-01-1-1","HC-01-1-2","HC-01-1-3"] 
      //key for each checkbox, name as: module's initial-Question no-SubQ no-Checkbox no
    }},
    {id:"1.2",
    statement: "1.2. Fresh and cow and calves",
    option: {
      text: ["Collected from calving area more than 2 times daily","Collected from calving area once","Left in calving area for extended periods"],
      value: [4, 2, 0] ,
      key: ["HC-01-2-1","HC-01-2-2", "HC-01-2-3"] 
    }},
    {id:"1.3",
    statement: "1.3. Teat Cleaning Process ",
    option: {
      text: ["Clear and documented teat cleaning process in place","Undocumented cleaning system","No proper cleaning process"],
      value: [4, 2, 0] ,
      key: ["HC-01-3-1","HC-01-3-2", "HC-01-3-3"] 
    }},
  ]},
  {
    number: 2, 
    question: "Is colostrum quality tested, classified and either used immediately or stored appropriately?", 
    section: [
      {id:"2.1",
      statement: "2.1. Testing",
      option: {
        text: ["All colostrum tested using a Brix meter and recorded","Some colostrum tested without documentation","No testing performed"],
        value: [5, 3, 0] ,
        key: ["HC-02-1-1","HC-02-1-2", "HC-02-1-3"] 
      }},
      {id:"2.2",
      statement: "2.2. Storage",
      option: {
        text: ["Colostrum storage is appropriate (covered, refrigerated or frozen with preservative if refrigerating for > 2 days","Colostrum storage could be improved","Colostrum storage is inappropriate"],
        value: [5, 3, 0] ,
        key: ["HC-02-2-1","HC-02-2-2", "HC-02-2-3"] 
      }},
    ]},
    { number: 3,
    question: "Are calves are actively fed an adequate volume of warm supplementary colostrum as soon as possible?",
    section: [
      {id:"3.1",
      statement: "3.1. Volume", 
      option: {
        text: ["Fed adequate (2-3L), high quality (>22% Brix), supplementary colostrum twice in first 24 hours of life, with first feed in first 12 hours of life","Calves fed colostrum, but one or more factors could still be improved","No active feeding, or calves left with cows > 24hours after birth"], 
        value: [4, 2, 0],  
        key: ["HC-03-1-1","HC-03-1-2", "HC-03-1-3"] 
      }},
      {id:"3.2",
      statement: "3.2. Time",
      option: {
        text: ["Actively fed supplementary colostrum within 12 hours of birth","Fed with supplementary colostrum after more than 12 hours of birth","Not fed with supplementary colostrum"],
        value: [4, 2, 0] ,
        key: ["HC-03-2-1","HC-03-2-2", "HC-03-2-3"] 
      }},
      {id:"3.3",
      statement: "3.3. Feeding system",
      option: {
        text: ["Clear feeding system with proper training","Ad hoc feeding system","No proper handling and feeding system"],
        value: [4, 2, 0] ,
        key: ["HC-01-3-1","HC-01-3-2", "HC-01-3-3"] 
      }},
    ]},
    {
      number: 4, 
      question: "Has everyone on the farm team received training in how to correctly collect, test and store colostrum; and stomach tube a calf?", 
      section: [
        {id:"4.1",
        statement: "",
        option: {
          text: ["Documented training performed with right resources (i.e. Dairy Australia)","Ad hoc training performed","No proper training"],
          value: [8, 5, 0] ,
          key: ["HC-04-1-1","HC-04-1-2", "HC-04-1-3"] 
        }},
      ]},
      {
        number: 5, 
        question: "Do you regularly assess and review the adequacy of our colostrum management program?", 
        section: [
          {id:"5.1",
          statement: "5.1. Calves blood testing",
          option: {
            text: ["All calf groups (bulls, heifers, beef etc) blood tested at least once per calving period","Calves blood tested sporadically, or if there is a calf health problem","Testing not performed"],
            value: [8, 3, 0] ,
            key: ["HC-05-1-1","HC-05-1-2", "HC-05-1-3"] 
          }},
          {id:"5.2",
          statement: "5.2. Colostrum management program",
          option: {
            text: ["Colostrum management program reviewed annually and also immediately if >20% of calves < 55mg/ml","Program present but never reviewed","No colostrum management program"],
            value: [5, 3, 0] ,
            key: ["HC-05-2-1","HC-05-2-2", "HC-05-2-3"] 
          }},
        ]},
]

const QArray2 = [
  {
    number: 1, 
    question: "Do all calves that you rear receive the same standard of care, whether they are replacement heifers or surplus/non-replacements?", 
    section: [
      {id:"1.1",
      statement: "",
      option: {
        text: ["All calves receive same standard of care","Calves receive same standard of care, with exceptions","Calves receive different standards of care depending on their pathway"],
        value: [8, 5, 0],
        key: ["HC-01-1-1","HC-01-1-2", "HC-01-1-3"]  
      }},
  ]},
  {
    number: 2, 
    question: "Does everybody in your farm team understand the legal requirements for transporting bobby calves (5-30 days old), and have a clear system for communicating which calves are fit for transport and sale and when they were last fed?", 
    section: [
      {id:"2.1",
      statement: "2.1. Legal requirements",
      option: {
        text: ["All farm team aware and signed a documented agreement","All farm team aware of legal requirements","Not aware of legal requirements"],
        value: [4, 2, 0],
        key: ["HC-02-1-1","HC-02-1-2", "HC-02-1-3"]  
      }},
      {id:"2.2",
      statement: "2.2. Calves transport system",
      option: {
        text: ["Clear system for identifying calves fit for transport (i.e. clearly marked, recorded, and/or moved to transport area)","System for identifying calves fit for transport exists, but is somewhat unclear/confusing","No system for identifying calves fit for transport"],
        value: [8, 2, 0],
        key: ["HC-02-2-1","HC-02-2-2", "HC-02-2-3"]  
      }},
      {id:"2.3",
      statement: "2.3. Time of last feed",
      option: {
        text: ["Time of last feed always recorded","Time of last feed communicated or assumed, but not recorded","No system for communicating time of last feed"],
        value: [4, 2, 0],
        key: ["HC-02-3-1","HC-02-3-2", "HC-02-3-3"]  
      }},
  ]},
  {
    number: 3, 
    question: "Do you use sexed semen to breed replacement females from the highest genetic merit dams in the herd, determined using a combination of production data and genomic testing?", 
    section: [
      {id:"3.1",
      statement: "3.1. Cow production data",
      option: {
        text: ["Cow production data is known","Unknown individual cow production data"],
        value: [3, 0],
        key: ["HC-03-1-1","HC-03-1-2"]  
      }},
      {id:"3.2",
      statement: "3.2. Sexed semen",
      option: {
        text: ["Utilised strategically in highest genetic merit dams and/or those that best fit our production system","Not used strategically, but is regularly considered","Has not been considered"],
        value: [4, 2, 0],
        key: ["HC-03-2-1","HC-03-2-2", "HC-03-2-3"]  
      }},
      {id:"3.3",
      statement: "3.3. Genomic testing",
      option: {
        text: ["Genomic testing is performed","Genomic testing is not performed"],
        value: [3, 0],
        key: ["HC-03-3-1","HC-03-3-2"]  
      }},
  ]},
  {
    number: 4, 
    question: "Are beef sires used for cross breeding selected strategically for target markets?", 
    section: [
      {id:"4.1",
      statement: "4.1. Beef (Bulls or AI)",
      option: {
        text: ["Strategically selected genetics with desirable traits are used for non-replacement calves for intended beef markets","Used for non-replacement calves generically, but bull selection not strategic","No beef genetics utilised"],
        value: [5, 3, 0],
        key: ["HC-04-1-1","HC-04-1-2", "HC-04-1-3"]  
      }},
      {id:"4.2",
      statement: "4.2. Dairy beef market",
      option: {
        text: ["Good understanding on dairy beef market and desired characteristics","Interested in learning more about dairy beef market","No interest in exploring the market"],
        value: [5, 3, 0],
        key: ["HC-04-2-1","HC-04-2-2", "HC-04-2-3"]  
      }},
  ]},
  {
    number: 5, 
    question: "Is the proportions and numbers of calves reared reviewed annually for profitability?", 
    section: [
      {id:"5.1",
      statement: "5.1. Cost of rearing",
      option: {
        text: ["Farm has calculated their true cost of heifer and surplus/non-replacement calf rearing.","True cost estimated, profitability estimated but not monitored","True cost has not been considered, profitability unknown"],
        value: [8, 2, 0],
        key: ["HC-05-1-1","HC-05-1-2", "HC-05-1-3"]  
      }},
      {id:"5.2",
      statement: "5.2. Calf group numbers and proportions (replacements & non-replacements)",
      option: {
        text: ["Numbers and proportions are planned and profitability is measured and monitored annually.","Numbers and proportions are ad hoc or opportunistic","Numbers and proportions are unplanned or unknown"],
        value: [8, 2, 0],
        key: ["HC-05-2-1","HC-05-2-2", "HC-05-2-3"]  
      }},
      {id:"5.3",
      statement: "5.3. Time of last feed",
      option: {
        text: ["Profitability measured and monitored annually","Profitability estimated with minimal monitoring","Profitability unknown"],
        value: [4, 2, 0],
        key: ["HC-05-3-1","HC-05-3-2", "HC-05-3-3"]  
      }},
  ]},
]


export default function HealthyCalves() {
  const {healthyCalves, farmDetails, setHealthyCalves, setFarmDetails, operation, setOperation, payload, setPayload, highestRevisionNumber, setHighestRevisionNumber} = useContext(AppRunTimeContext)
  const {steps} = useContext(AppRunTimeContext)
  const { instance, accounts } = useMsal();
  var ColostrumScoresArray = healthyCalves.colostrum.scoreArray
  var CalfScoresArray = healthyCalves.calfPathways.scoreArray
  //using the custom hook for routing between module pages
  const {setNextPrev, getNext, getPrev} = useModuleLink();
  setNextPrev("/HealthyCalves");
  let next = getNext();
  let prev = getPrev();
  // Use the dictionary from generalFarm[images] to replace image filenames
  const colostrumFormattedValue = (farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].colostrum.commentary : "").replace(/colostrumCommentary\S+/g, match => {
    console.log(match)
    const base64 = healthyCalves.colostrum.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const calfPathwaysFormattedValue = (farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].calfPathways.commentary : "").replace(/calfPathwaysCommentary\S+/g, match => {
    console.log(match)
    const base64 = healthyCalves.calfPathways.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [value1, setValue1] = useState(colostrumFormattedValue);
  const [value2, setValue2] = useState(calfPathwaysFormattedValue);
  const stepIndex = steps.findIndex(step => step.link === '/HealthyCalves');

  const colostrumGraphRef1 = useRef(null);
  const colostrumGraphRef2 = useRef(null);
  const colostrumGraphRef3 = useRef(null);
  const colostrumGraphRef4 = useRef(null);
  const calfPathwaysGraphRef1 = useRef(null);
  const calfPathwaysGraphRef2 = useRef(null);

  const [postDisplay1, setPostDisplay1] = useState(true);
  const [postDisplay2, setPostDisplay2] = useState(true);
  const divDisplay1 = true;
  const divDisplay2 = true;
  const [state1, setState1] = React.useState(null);
  const [state2, setState2] = React.useState(null);
  //shows graphs with button click
  const [isGraph, setisGraph] = React.useState(false);
  const [isGraph2, setisGraph2] = React.useState(false);
  const [isGraph3, setisGraph3] = React.useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [openSaveSuccessDialog, setOpenSaveSuccessDialog] = React.useState(false);
  const [openSaveFailedDialog, setOpenSaveFailedDialog] = React.useState(false);
  const [openSavingDialog, setOpenSavingDialog] = React.useState(false);
  const [openPercentDialog, setOpenPercentDialog] = React.useState(false);

  const [isTextEdited, setIsTextEdited] = useState(false);
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);
  const [unsavedChangesNavigationPath, setUnsavedChangesNavigationPath] = useState(null);
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] = useState(false);
  const stateRef = useRef();
  const [isDataEdited, setIsDataEdited] = useState(false);
  const [openImageFormatDialog, setOpenImageFormatDialog] = React.useState(false);
  const [openImageSizeDialog, setOpenImageSizeDialog] = React.useState(false);


  useEffect(() => {
    stateRef.current = {
      farmDetails,
      value1,
      value2
    };
  }, [farmDetails, value1, value2]);
  const navigate = useNavigate();
     
  const handleImageFormatDialogClose = () => {    
    setOpenImageFormatDialog(false);   
  };

  const handleImageSizeDialogClose = () => {
    setOpenImageSizeDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSaveSuccessDialogClose = () => {
    setOpenSaveSuccessDialog(false);
  };

  const handleSaveFailedDialogClose = () => {
    setOpenSaveFailedDialog(false);
  };

   const handleCharacterDialogClose = () => {
    setIsCharacterLimitExceeded(false);
  };
   const handlePercentDialogClose = () => {
    setOpenPercentDialog(false);
  };
  const handleNextClick = () => {
    // const colostrumQuestions = farmDetails.surveyData['HealthyCalves'].colostrum.questions;
    // const colostrumGraph1Data = farmDetails.surveyData['HealthyCalves'].colostrum.graph1;
    // const colostrumGraph2Data = farmDetails.surveyData['HealthyCalves'].colostrum.graph2;


    // const calfPathwaysQuestions = farmDetails.surveyData['HealthyCalves'].calfPathways.questions;
    // const calfPathwaysGraph1Data = farmDetails.surveyData['HealthyCalves'].calfPathways.graph1;
    // const calfPathwaysGraph2Data = farmDetails.surveyData['HealthyCalves'].calfPathways.graph2;


    // for (let i = 0; i < colostrumQuestions.length; i++) {
    //   if (!colostrumQuestions[i].practice || !colostrumQuestions[i].focusCheck) {
    //     console.log('open dialog')
    //     setOpenDialog(true);
    //     return;
    //   }
    // }

    // for (let i = 0; i < calfPathwaysQuestions.length; i++) {
    //   if (!calfPathwaysQuestions[i].practice || !calfPathwaysQuestions[i].focusCheck) {
    //     console.log('open dialog')
    //     setOpenDialog(true);
    //     return;
    //   }
    // }



    navigate(next);
  };

  useEffect(() => {


    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
        window.location.href = '/'; // Redirect to home page
    }


    if (farmDetails.surveyData['HealthyCalves']) {
      // Your code here will run after the component has mounted
        // Loop through the surveyData[1].questions array
        farmDetails.surveyData['HealthyCalves'].colostrum.questions.forEach((question, index) => {
          // Update the generalFarm.practices array based on the practice in each question
          healthyCalves.colostrum.practices[index] = question.practice;
          healthyCalves.colostrum.focusArea[index] = question.focusCheck
        });
        farmDetails.surveyData['HealthyCalves'].calfPathways.questions.forEach((question, index) => {
          // Update the generalFarm.practices array based on the practice in each question
          healthyCalves.calfPathways.practices[index] = question.practice;
          healthyCalves.calfPathways.focusArea[index] = question.focusCheck
        });
          console.log(healthyCalves.colostrum.practices)
          console.log(healthyCalves.calfPathways.practices)
          // console.log(tempModule)
          // console.log(farmDetails)
      }
    else {
      if(!(farmDetails.surveyData['HealthyCalves'])) {
      setFarmDetails(prevDetails => ({
            ...prevDetails,
            surveyData: {
              ...prevDetails.surveyData,
              details: {
                farmOwner: farmDetails.farm_owner,
                planOwner: farmDetails.plan_owner,
                farmAddressLat: farmDetails.farmAddressLat,
                farmAddressLong: farmDetails.farmAddressLong,
                financialYear: farmDetails.financialYear,
                farmAddress: farmDetails.farmAddress
              },
              HealthyCalves: {
                module: "HealthyCalves",
                currentYear: " ",
                futureYear:" ",
                trainingGrid: [
                  { id: '1', training_complete: '', who: '', when:''},
                  { id: '2', training_complete: '', who: '', when:''},
                  { id: '3', training_complete: '', who: '', when:''},
                  { id: '4', training_complete: '', who: '', when:''},
                  { id: '5', training_complete: '', who: '', when:''},
                  { id: '6', training_complete: '', who: '', when:''},
                  { id: '7', training_complete: '', who: '', when:''},
                  { id: '8', training_complete: '', who: '', when:''},
                  { id: '9', training_complete: '', who: '', when:''},
                  { id: '10', training_complete: '', who: '', when:''}
              ],
                achievingTransfer: "",
                failureTransfer: "",
                less12hours: "",
                more12hours: "",
                noColostrum: "",
                goalachievingTransfer: "",
                goalfailureTransfer: "",
                goalless12hours: "",
                goalmore12hours: "",
                goalnoColostrum: "",
                replacement1: "",
                beef1: "",
                export1: "",
                rearing1: "",
                euthanised1:"",
                bobby1:"",
                replacement2: "",
                beef2: "",
                export2: "",
                rearing2: "",
                euthanised2:"",
                bobby2:"",
                healthyCalvesPage: 0,
                colostrum: {
                  commentary: "",
                  focus: "",
                  focusAdditional: "",
                  scoreArray:  [0, 0, 0, 0, 0],
                  graph1: "",
                  graph2: "",
                  graph3: "",
                  graph4: "",
                  actionTable: [{ id: '1', goals: '', priority_who:'', priority_when:''},
                  { id: '2', goals: '', priority_who:'', priority_when:''},
                  { id: '3', goals: '', action: '', priority_who:'', priority_when:''},
                  { id: '4', goals: '', action: '', priority_who:'', priority_when:''},
                  { id: '5', goals: '', action: '', priority_who:'', priority_when:''}],
                  QuestionMapping: [
                    {
                      Q1_5: "Are teats washed, disinfected and dried prior to colostrum harvesting? Are calves collected and cows milked soon as possible after calving to manage colostrum quality and mastitis risk?",
                      Q2_5: "Is colostrum quality tested, classified and either used immediately or stored appropriately?",
                      Q3_5: "Are calves are actively fed an adequate volume of warm supplementary colostrum as soon as possible?",
                      Q4_5: "Has everyone on the farm team received training in how to correctly collect, test and store colostrum; and stomach tube a calf?",
                      Q5_5: "Do you regularly assess and review the adequacy of our colostrum management program?"
                    }
                  ],
                  questions: [
                    {
                      subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                      ],
                      practice: "",
                      focusCheck: "",
                      notApplicable: false
                    },
                    {
                      subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                      ],
                      practice: "",
                      focusCheck: "",
                      notApplicable: false
                    },
                    {
                      subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                      ],
                      practice: "",
                      focusCheck: "",
                      notApplicable: false
                    },
                    {
                      subQuestions: [
                        [
                          false,
                          false,
                          false
                        ]
                      ],
                      practice: "",
                      focusCheck: "",
                      notApplicable: false
                    },
                    {
                      subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                      ],
                      practice: "",
                      focusCheck: "",
                      notApplicable: false
                    }
                  ]
                },
                calfPathways: {
                  commentary: "",
                  focus: "",
                  focusAdditional: "",
                  graph1: "",
                  scoreArray:  [0, 0, 0, 0, 0],
                  graph2: "",
                  actionTable: [{ id: '1', goals: '', priority_who:'', priority_when:''},
                  { id: '2', goals: '', priority_who:'', priority_when:''},
                  { id: '3', goals: '', action: '', priority_who:'', priority_when:''},
                  { id: '4', goals: '', action: '', priority_who:'', priority_when:''},
                  { id: '5', goals: '', action: '', priority_who:'', priority_when:''}],
                  QuestionMapping: [
                    {
                      Q1_5: "Do all calves that you rear receive the same standard of care, whether they are replacement heifers or surplus/non-replacements?",
                      Q2_5: "Does everybody in your farm team understand the legal requirements for transporting bobby calves (5-30 days old), and have a clear system for communicating which calves are fit for transport and sale and when they were last fed?",
                      Q3_5: "Do you use sexed semen to breed replacement females from the highest genetic merit dams in the herd, determined using a combination of production data and genomic testing?",
                      Q4_5: "Are beef sires used for cross breeding selected strategically for target markets?",
                      Q5_5: "Is the proportions and numbers of calves reared reviewed annually for profitability?"
                    }
                  ],
                  questions: [
                    {
                      subQuestions: [
                        [
                          false,
                          false,
                          false
                        ]
                      ],
                      practice: "",
                      focusCheck: "",
                      notApplicable: false
                    },
                    {
                      subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                      ],
                      practice: "",
                      focusCheck: "",
                      notApplicable: false
                    },
                    {
                      subQuestions: [
                        [
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false
                        ]
                      ],
                      practice: "",
                      focusCheck: "",
                      notApplicable: false
                    },
                    {
                      subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                      ],
                      practice: "",
                      focusCheck: "",
                      notApplicable: false
                    },
                    {
                      subQuestions: [
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ],
                        [
                          false,
                          false,
                          false
                        ]
                      ],
                      practice: "",
                      focusCheck: "",
                      notApplicable: false
                    }
                  ]
                }

              }
          }
    }));
  }
    }

  }, []);  // Empty dependency array means this useEffect runs once after initial render

  const getChecked1 = (key) => {
    if (healthyCalves.colostrum.calvesQuestions.get(key)) 
      return true;
    else
      return false; 
  }

  const getChecked2 = (key) => {
    if (healthyCalves.calfPathways.calvesQuestions.get(key)) 
      return true;
    else
      return false; 
  }

  const handleNavigationAttempt = (navigationPath) => {
    if (isTextEdited) {
      setUnsavedChangesNavigationPath(navigationPath); // Store the intended navigation path
      setOpenUnsavedChangesDialog(true); // If text is edited, show the warning dialog
    } else {
      navigate(navigationPath); // Otherwise, navigate immediately
    }
  };

  const handleClick1 = (post1) => { 
    const objectCopy1 = {
      ...postDisplay1
    };
    objectCopy1[post1] = !objectCopy1[post1];
    setPostDisplay1(objectCopy1);
  };

  const handleClick2 = (post2) => { 
    const objectCopy2 = {
      ...postDisplay2
    };
    objectCopy2[post2] = !objectCopy2[post2];
    setPostDisplay2(objectCopy2);
  };

  useEffect(() => {
    // Function to be executed
    const intervalFunction = () => {
      console.log(operation);
      if (operation === "modify") {
        handleSave('autosave');
        console.log('This function runs every 30 seconds');
      }
      // Add your logic here
    };
  
    // Set the interval
    const interval = setInterval(intervalFunction, 120000); 
  
    // Clear interval on component unmount or when operation changes
    return () => clearInterval(interval);
  }, [operation]); // Include operation in the dependency array

  const handleChangeColostrum = (event) => {
    setState1({
      ...state1,
      [event.target.name]: event.target.checked,
    });


    const postIndex = event.target.parentNode.dataset.post;
    const subQIndex = event.target.parentNode.dataset.subq;
    const optIndex = event.target.parentNode.dataset.opt;
    {console.log(postIndex)}
    {console.log(subQIndex)}
    {console.log(optIndex)} 
    {console.log(event.target)}

    farmDetails.surveyData['HealthyCalves'].colostrum.questions[postIndex].subQuestions[subQIndex][optIndex] = event.target.checked;
    setIsDataEdited(true)

    if (event.target.checked){
      ColostrumScoresArray = addScore(ColostrumScoresArray, event.target.name, event.target.attributes.value.nodeValue)
      healthyCalves.colostrum.scoreArray = ColostrumScoresArray
      healthyCalves.colostrum.calvesQuestions.set(event.target.name, true);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            HealthyCalves: {
                ...prevFarmDetails.surveyData.HealthyCalves,
                colostrum: {
                  ...prevFarmDetails.surveyData.HealthyCalves.colostrum,
                  scoreArray: ColostrumScoresArray
              }
            }
        }
    }));
    }
    else{
      ColostrumScoresArray = subtractScore(ColostrumScoresArray, event.target.name, event.target.attributes.value.nodeValue)
      healthyCalves.colostrum.scoreArray = ColostrumScoresArray
      healthyCalves.colostrum.calvesQuestions.delete(event.target.name);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            HealthyCalves: {
                ...prevFarmDetails.surveyData.HealthyCalves,
                colostrum: {
                  ...prevFarmDetails.surveyData.HealthyCalves.colostrum,
                  scoreArray: ColostrumScoresArray
              }
            }
        }
    }));
    }

  };

  const handleChangeCalf = (event) => {
    setState2({
      ...state2,
      [event.target.name]: event.target.checked,
    });


    const postIndex = event.target.parentNode.dataset.post;
    const subQIndex = event.target.parentNode.dataset.subq;
    const optIndex = event.target.parentNode.dataset.opt;
    {console.log(postIndex)}
    {console.log(subQIndex)}
    {console.log(optIndex)}

    farmDetails.surveyData['HealthyCalves'].calfPathways.questions[postIndex].subQuestions[subQIndex][optIndex] = event.target.checked;
    setIsDataEdited(true)

    if (event.target.checked){
      CalfScoresArray = addScore(CalfScoresArray, event.target.name, event.target.attributes.value.nodeValue)
      healthyCalves.calfPathways.scoreArray = CalfScoresArray
      healthyCalves.calfPathways.calvesQuestions.set(event.target.name, true);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            HealthyCalves: {
                ...prevFarmDetails.surveyData.HealthyCalves,
                calfPathways: {
                  ...prevFarmDetails.surveyData.HealthyCalves.calfPathways,
                  scoreArray: CalfScoresArray
              }
            }
        }
    }));
    }
    else{
      CalfScoresArray = subtractScore(CalfScoresArray, event.target.name, event.target.attributes.value.nodeValue)
      healthyCalves.calfPathways.scoreArray = CalfScoresArray
      healthyCalves.calfPathways.calvesQuestions.delete(event.target.name);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            HealthyCalves: {
                ...prevFarmDetails.surveyData.HealthyCalves,
                calfPathways: {
                  ...prevFarmDetails.surveyData.HealthyCalves.calfPathways,
                  scoreArray: CalfScoresArray
              }
            }
        }
    }));
    }
  };

  //to format pie charts inline based on MUI code
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

     const CustomInputLabel = ({ label }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ fontSize: '15px' }}>{label}</span>
    <span style={{ fontSize: '15px', color: 'red', fontWeight: 'bold', marginLeft: '4px' }}>*</span>
  </div>
   );

    // Dataset for piechart based on plotly.js syntax
    const data1 = {
      labels: ['% of calves achieving passive transfer', '% of calves failure of pasive transfer'],
      values: [
        farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].achievingTransfer : healthyCalves.achievingTransfer,
        farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].failureTransfer : healthyCalves.failureTransfer,

      ],
        type: 'pie',
        textinfo: 'none',
        hoverinfo: 'label+percent',
        marker: {
          colors: ['#2C4C6D','#66B7E1']
        },
      }
    
    
    // Dataset for piechart based on plotly.js syntax
    const data2 = {
      labels:   ['% of calves achieving passive transfer', '% of calves failure of pasive transfer'],
      values: [
        farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].goalachievingTransfer : healthyCalves.goalachievingTransfer,
        farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].goalfailureTransfer : healthyCalves.goalfailureTransfer,

      ],
        type: 'pie',
        textinfo: 'none',
        hoverinfo: 'label+percent',
        marker: {
          colors: ['#2C4C6D','#66B7E1']
        },
      }
      // Dataset for piechart based on plotly.js syntax
    const data3 = {
      labels: [ '% of calves fed colostrum <12 hours', '% of calves fed colostrum >12 hours','% of calves not fed colostrum at all'],
      values: [

        farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].less12hours : healthyCalves.less12hours,
        farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].more12hours : healthyCalves.more12hours,
        farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].noColostrum : healthyCalves.noColostrum
      ],
        type: 'pie',
        textinfo: 'none',
        hoverinfo: 'label+percent',
        marker: {
          colors: ['#ED7D31','#5FB33A','#FFC000']
        },
      }
    
    
    // Dataset for piechart based on plotly.js syntax
    const data4 = {
      labels:   [ '% of calves fed colostrum <12 hours', '% of calves fed colostrum >12 hours','% of calves not fed colostrum at all'],
      values: [

        farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].goalless12hours : healthyCalves.goalless12hours,
        farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].goalmore12hours : healthyCalves.goalmore12hours,
        farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].goalnoColostrum : healthyCalves.goalnoColostrum
      ],
        type: 'pie',
        textinfo: 'none',
        hoverinfo: 'label+percent',
        marker: {
          colors: ['#ED7D31','#5FB33A','#FFC000']
        },
      }
       // Dataset for piechart based on plotly.js syntax
      const data5 = {
        labels: ['Reared for replacements','Reared for beef','Reared for live export','Sold for off-farm rearing (beef or dairy)','Euthanised at birth','Processed as bobby calves 5-30 days old'],
        values: [
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].replacement1 : healthyCalves.replacement1,
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].beef1 : healthyCalves.beef1,
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].export1 : healthyCalves.export1,
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].rearing1 : healthyCalves.rearing1,
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].euthanised1 : healthyCalves.euthanised1,
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].bobby1 : healthyCalves.bobby1
        ],
          type: 'pie',
          textinfo: 'none',
          hoverinfo: 'label+percent',
          marker: {
            colors: ['#2C4C6D','#66B7E1','#ED7D31','#5FB33A','#FFC000', '#6864FC']
          },
        }

  
             // Dataset for piechart based on plotly.js syntax
      const data6 = {
        labels: ['Reared for replacements','Reared for beef','Reared for live export','Sold for off-farm rearing (beef or dairy)','Euthanised at birth', 'Processed as bobby calves 5-30 days old'],
        values: [
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].replacement2 : healthyCalves.replacement2,
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].beef2 : healthyCalves.beef2,
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].export2 : healthyCalves.export2,
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].rearing2 : healthyCalves.rearing2,
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].euthanised2 : healthyCalves.euthanised2,
          farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].bobby2 : healthyCalves.bobby2
        ],
          type: 'pie',
          textinfo: 'none',
          hoverinfo: 'label+percent',
          marker: {
            colors: ['#2C4C6D','#66B7E1','#ED7D31','#5FB33A','#FFC000', '#6864FC']
          },
        }

  function updateColostrumPractice(e){
    healthyCalves.colostrum.practices[e.target.name] = e.target.value
    // Create a deep copy of the farmDetails object
    const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
    // Update the nested property
    newFarmDetails.surveyData['HealthyCalves'].colostrum.questions[e.target.name].practice = e.target.value;
    // Update the state
    setFarmDetails(newFarmDetails);
    setIsDataEdited(true)

  }

  function updateCalfPractice(e){
    healthyCalves.calfPathways.practices[e.target.name] = e.target.value
    // Create a deep copy of the farmDetails object
    const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
    // Update the nested property
    newFarmDetails.surveyData['HealthyCalves'].calfPathways.questions[e.target.name].practice = e.target.value;
    // Update the state
    setFarmDetails(newFarmDetails);
    setIsDataEdited(true)

  }
  
  function updateColostrumFocus(e){
    healthyCalves.colostrum.focusArea[e.target.name] = e.target.value
    // Create a deep copy of the farmDetails object
    const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
    // Update the nested property
    newFarmDetails.surveyData['HealthyCalves'].colostrum.questions[e.target.name].focusCheck = e.target.value;
    // Update the state
    setFarmDetails(newFarmDetails);
    setIsDataEdited(true)

  }

  function updateCalfFocus(e){
    healthyCalves.calfPathways.focusArea[e.target.name] = e.target.value
    // Create a deep copy of the farmDetails object
    const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
    // Update the nested property
    newFarmDetails.surveyData['HealthyCalves'].calfPathways.questions[e.target.name].focusCheck = e.target.value;
    // Update the state
    setFarmDetails(newFarmDetails);
    setIsDataEdited(true)

  }

  function showGraph1 (e)  {
    var at = +healthyCalves.achievingTransfer;
    var ft = +healthyCalves.failureTransfer;
    var gat = +healthyCalves.goalachievingTransfer;
    var gft = +healthyCalves.goalfailureTransfer;

   if(((at +  ft) != 100) || ((gat + gft) != 100)) {
         setOpenPercentDialog(true);
   }
    else {
            setisGraph(true);
    }      


  }
  function showGraph2 (e)  {

  var less = +healthyCalves.less12hours;
  var more = +healthyCalves.more12hours;
  var no = +healthyCalves.noColostrum;
  var lessf = +healthyCalves.goalless12hours;
  var moref = +healthyCalves.goalmore12hours;
  var nof = +healthyCalves.goalnoColostrum;

   if(((less +  more + no) != 100) || ((lessf + moref + nof) != 100)) {
         setOpenPercentDialog(true);
   }
   else {
    setisGraph2(true);
   }
  }

  function showGraph3 (e)  {
    setisGraph3(true);
  }

 const isAEDT = (date) => {
  const year = date.getFullYear();
  const startDST = new Date(Date.UTC(year, 9, 1)); // October 1st
  const endDST = new Date(Date.UTC(year, 3, 1)); // April 1st

  // Find the first Sunday in October
  startDST.setUTCDate(1 + (7 - startDST.getUTCDay()) % 7);
  
  // Find the first Sunday in April
  endDST.setUTCDate(1 + (7 - endDST.getUTCDay()) % 7);

  return (date >= startDST && date < endDST);
};


  const getCurrentDateTime = () => {
    const nowUTC = new Date(Date.now());

    const offset = isAEDT(nowUTC) ? 12 : 11; // UTC+13 if Daylight Saving Time, otherwise UTC+12
  
    const aet = new Date(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(),
                         nowUTC.getUTCHours() + offset, nowUTC.getUTCMinutes(), nowUTC.getUTCSeconds());
  
    const year = aet.getFullYear();
    const month = String(aet.getMonth() + 1).padStart(2, '0');
    const day = String(aet.getDate()).padStart(2, '0');
    const hour = String(aet.getHours()).padStart(2, '0');
    const minute = String(aet.getMinutes()).padStart(2, '0');
    const second = String(aet.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}${hour}${minute}${second}`;
  };

    


  
  // Helper function to prepare payload
const preparePayload = (prevDetails, specificFields = {}) => {
  const currentState = stateRef.current;
  const { surveyData } = currentState.farmDetails;

  const surveyDataWithoutGraphs = { ...currentState.farmDetails.surveyData };

  if (surveyData.Carbon) {
    const { graph: carbonGraph, ...rest } = surveyData.Carbon;
    surveyDataWithoutGraphs.Carbon = rest;
  }

  if (surveyData.Nitrogen) {
    const { graph: nitrogenGraph, ...rest } = surveyData.Nitrogen;
    surveyDataWithoutGraphs.Nitrogen = rest;
  }

  if (surveyData.Irrigation) {
    const { graph: irrigationGraph, ...rest } = surveyData.Irrigation;
    surveyDataWithoutGraphs.Irrigation = rest;
  }

  if (surveyData.HealthyCalves) {
    const { colostrum, calfPathways, ...rest } = surveyData.HealthyCalves;
  
    // Remove graph1 and graph2 from colostrum
    const { graph1: colostrumGraph1, graph2: colostrumGraph2,graph3: colostrumGraph3, graph4: colostrumGraph4, ...colostrumRest } = colostrum;
    
    // Remove graph1 and graph2 from calfPathways
    const { graph1: calfPathwaysGraph1, graph2: calfPathwaysGraph2, ...calfPathwaysRest } = calfPathways;
  
    surveyDataWithoutGraphs.HealthyCalves = {
      ...rest,
      colostrum: colostrumRest,
      calfPathways: calfPathwaysRest
    };

    if (surveyData.GeneralFarm) {
      const { farmMap, ...restGeneralFarm } = surveyData.GeneralFarm;
      surveyDataWithoutGraphs.GeneralFarm = restGeneralFarm;
    }
  }

  return {
    ...prevDetails,
    dateEdited: getCurrentDateTime(),
    region: farmDetails.farmRegion,
    status: "DRAFT",
    financialYear: farmDetails.financialYear,
    farmId: farmDetails.supplier_no,
    surveyData: surveyDataWithoutGraphs,
    modules: farmDetails.modules,
    ...specificFields
  };
};



// Helper function to make Axios call
const makeAxiosCall = async (urlSuffix, payload) => {
  try {
    const token = await acquireAccessToken(instance, accounts[0]);
    localStorage.setItem("accessToken", token);
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/${urlSuffix}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('Response:', response.data);
    console.log(payload);
    return response;
  } catch (error) {
    console.error('An error occurred while performing Axios POST:', error);
    return null;  // Return null if there's an error
  }
};
const debouncedSetPayload = debounce(setPayload, 500);

  useEffect(() => {
    return () => {
      debouncedSetPayload.cancel(); 
    }
  }, [])

  // Define the handleSave function which is debounced
  const handleSave = debounce(async (saveType) => {
    const currentState = stateRef.current;

    const urlSuffix = saveType === "autosave" ? "modifyFarmSurveyData" : "insertFarmSurveyData";
    saveType !== "autosave" && setOpenSavingDialog(true);
    if (operation !== "modify") {
      farmDetails.farmSurveyId = farmDetails.supplier_no + getCurrentDateTime();
    }
    // Check if highestRevisionNumber is defined and is a string of 4 digits
    let validRevisionNumber = highestRevisionNumber && /^\d{4}$/.test(highestRevisionNumber);
    let newRevisionNumber;

    if (saveType !== 'autosave') {
      if (validRevisionNumber) {
        // Increment the revision number if it's valid
        newRevisionNumber = (parseInt(highestRevisionNumber, 10) + 1).toString().padStart(4, '0');
      } else {
        // Default to '0001' if highestRevisionNumber is undefined or invalid
        newRevisionNumber = '0001';
      }
    }
    // Step 2: Extract images and modify the commentary text
    const regex1 = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
    let match1;
    const images1 = [];

    while ((match1 = regex1.exec(currentState.value1)) !== null) {
      const imageName = saveType==='autosave' ? `colostrumCommentary${images1.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `colostrumCommentary${images1.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
      images1.push({ base64: match1[1], name: imageName });
    }
  
    console.log(images1);
  
    let modifiedText1 = currentState.value1;

    // Replace base64 values with image names
    for (let i = 0; i < images1.length; i++) {
      modifiedText1 = modifiedText1.replace(images1[i].base64, images1[i].name);
    }

    // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
    modifiedText1 = modifiedText1.replace(/<img src="([^"]+)"[^>]*>/g, (match1, p1) => {
      return p1 + '\n';  // Add a newline character after the image name
    });

    // First, handle the <li> tags by converting them to bullet points
    modifiedText1 = modifiedText1.replace(/<li[^>]*>/gi, '• ');
    modifiedText1 = modifiedText1.replace(/<\/li>/gi, '\n');

    // Then, remove the <ul> and </ul> tags as they are no longer necessary
    modifiedText1 = modifiedText1.replace(/<\/?ul[^>]*>/gi, '\n');

    // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
    modifiedText1 = modifiedText1.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

    // Convert inline elements to spaces or remove them
    modifiedText1 = modifiedText1.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

    // Convert <br> tags to newlines
    modifiedText1 = modifiedText1.replace(/<br[^>]*>/gi, '\n');

    // Remove other HTML tags
    modifiedText1 = modifiedText1.replace(/<\/?[^>]+(>|$)/g, '').trim();

    // Replace multiple newlines with a single newline
    modifiedText1 = modifiedText1.replace(/\n+/g, '\n').trim();

    // Replace multiple spaces with a single space
    modifiedText1 = modifiedText1.replace(/ +/g, ' ').trim();

    console.log(modifiedText1);

    // Update the commentary field
    healthyCalves.colostrum.commentary = modifiedText1;
    currentState.farmDetails.surveyData['HealthyCalves'].colostrum.commentary = modifiedText1;

    // Step 2: Extract images and modify the commentary text
    const regex2 = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
    let match2;
    const images2 = [];

    while ((match2 = regex2.exec(currentState.value2)) !== null) {
      const imageName = saveType==='autosave' ? `calfPathwaysCommentary${images2.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `calfPathwaysCommentary${images2.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
      images2.push({ base64: match2[1], name: imageName });
    }
  
    console.log(images2);
  
    let modifiedText2 = currentState.value2;

    // Replace base64 values with image names
    for (let i = 0; i < images2.length; i++) {
      modifiedText2 = modifiedText2.replace(images2[i].base64, images2[i].name);
    }

    // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
    modifiedText2 = modifiedText2.replace(/<img src="([^"]+)"[^>]*>/g, (match2, p1) => {
      return p1 + '\n';  // Add a newline character after the image name
    });

    // First, handle the <li> tags by converting them to bullet points
    modifiedText2 = modifiedText2.replace(/<li[^>]*>/gi, '• ');
    modifiedText2 = modifiedText2.replace(/<\/li>/gi, '\n');

    // Then, remove the <ul> and </ul> tags as they are no longer necessary
    modifiedText2 = modifiedText2.replace(/<\/?ul[^>]*>/gi, '\n');

    // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
    modifiedText2 = modifiedText2.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

    // Convert inline elements to spaces or remove them
    modifiedText2 = modifiedText2.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

    // Convert <br> tags to newlines
    modifiedText2 = modifiedText2.replace(/<br[^>]*>/gi, '\n');

    // Remove other HTML tags
    modifiedText2 = modifiedText2.replace(/<\/?[^>]+(>|$)/g, '').trim();

    // Replace multiple newlines with a single newline
    modifiedText2 = modifiedText2.replace(/\n+/g, '\n').trim();

    // Replace multiple spaces with a single space
    modifiedText2 = modifiedText2.replace(/ +/g, ' ').trim();

    console.log(modifiedText2);

    // Update the commentary field
    healthyCalves.calfPathways.commentary = modifiedText2;
    currentState.farmDetails.surveyData['HealthyCalves'].calfPathways.commentary = modifiedText2;

    // Define a helper function to make the image upload call
    const uploadGraph = async (imageName, graphData) => {
      if (imageName.startsWith('farmPic_')) {
        if (!farmDetails.hasFarmPicChanged) {
          return true
        }
        else {
          setFarmDetails(prevDetails => ({
            ...prevDetails,
            hasFarmPicChanged: false
          }));
        }
      }
      const imagePayload = {
        "imageName": imageName,
        "imageDetails": graphData
      };
      console.log("Image Payload:", imagePayload);
      return makeAxiosCall("storeImage", imagePayload);
    };

    const imageUploadPromises1 = images1.map(image => {
      return uploadGraph(image.name, image.base64);
    });

    const imageUploadPromises2 = images2.map(image => {
      return uploadGraph(image.name, image.base64);
    });

    const specificFields = operation === "modify" ? {
        revisionNumber: saveType==='autosave' ? 'AUTOSAVE' : newRevisionNumber,
        id: saveType==='autosave' ? farmDetails.supplier_no : farmDetails.supplier_no + `_${newRevisionNumber}`,
        editedBy: accounts[0].name
    } : {
        creatorName: accounts[0].name,
        dateCreated: getCurrentDateTime(),
        id: farmDetails.supplier_no + `_${newRevisionNumber}`,
        schemaVersionNum: "001",
        surveyVersionNumber: "1",
        status: "DRAFT",
        farmSurveyId: farmDetails.farmSurveyId,
        revisionNumber: newRevisionNumber,
        editedBy: accounts[0].name
    };

  const updatedPayload = preparePayload(payload, specificFields);

  try {
    const response = await makeAxiosCall(urlSuffix, updatedPayload);

    if (response) {
      if (saveType !== 'autosave') { 
        setHighestRevisionNumber(newRevisionNumber);
      }
      if (operation !== "modify") {
        farmDetails.farmSurveyId = updatedPayload.farmSurveyId;
        farmDetails.date_edited = updatedPayload.dateEdited;
        setOperation('modify');
      }

      const graphPaths = [
        { path: "HealthyCalves.colostrum.graph1", namePrefix: "healthyCalvesColostrumGraph1_" },
        { path: "HealthyCalves.colostrum.graph2", namePrefix: "healthyCalvesColostrumGraph2_" },
        { path: "HealthyCalves.colostrum.graph3", namePrefix: "healthyCalvesColostrumGraph3_" },
        { path: "HealthyCalves.colostrum.graph4", namePrefix: "healthyCalvesColostrumGraph4_" },
        { path: "HealthyCalves.calfPathways.graph1", namePrefix: "healthyCalvesCalfPathwaysGraph1_" },
        { path: "HealthyCalves.calfPathways.graph2", namePrefix: "healthyCalvesCalfPathwaysGraph2_" },
        { path: "Nitrogen.graph", namePrefix: "nitrogenGraph_" },
        { path: "Carbon.graph", namePrefix: "carbonGraph_" },
        { path: "Irrigation.graph", namePrefix: "irrigationGraph_" },
        { path: "farmPic", namePrefix: "farmPic_" },
        { path: "GeneralFarm.farmMap", namePrefix: "farmMap_" }
      ];
    
      const graphPromises = graphPaths.map(graph => {
        const parts = graph.path.split('.');
        let graphValue;

        switch (parts.length) {
          case 1:
            graphValue = currentState.farmDetails[parts[0]];
            break;
          case 2:
            if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]]) {
              graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]];
            }
            break;
          case 3:
            if (currentState.farmDetails.surveyData && currentState.farmDetails.surveyData[parts[0]] && currentState.farmDetails.surveyData[parts[0]][parts[1]]) {
              graphValue = currentState.farmDetails.surveyData[parts[0]][parts[1]][parts[2]];
            }
            break;
          default:
            break;
        }

        if (graphValue && graphValue !== "") {
          if (saveType === 'autosave') {
            return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_AUTOSAVE`, graphValue);
          }
          else {
            return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_${newRevisionNumber}`, graphValue);
          }
        }
        return Promise.resolve(true);
      });
      

      // Merge imageUploadPromises and graphPromises
      const allUploadPromises = [...imageUploadPromises1, ...imageUploadPromises2, ...graphPromises];

      const allUploadResponses = await Promise.all(allUploadPromises);

      if (allUploadResponses.every(res => res)) {
        setOpenSavingDialog(false);
        saveType !== "autosave" && setOpenSaveSuccessDialog(true);
        // After save is successful
        setIsTextEdited(false);
        setIsDataEdited(false)
        setOpenUnsavedChangesDialog(false);
      } else {
        setOpenSavingDialog(false);
        saveType !== "autosave" && setOpenSaveFailedDialog(true);
      }
    } else {
      setOpenSavingDialog(false);
      saveType !== "autosave" && setOpenSaveFailedDialog(true);
    }
  } catch (error) {
    console.error("An error occurred during saving:", error);
    setOpenSavingDialog(false);
    saveType !== "autosave" && setOpenSaveFailedDialog(true);
  }
}, 500); // Debounce time of 500ms

const quillRef1 = useRef(null);
const quillRef2 = useRef(null);// Reference to the ReactQuill component
useEffect(() => {
  if (quillRef1.current) {
      const quill = quillRef1.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage1();                               
              return false;              
            }                                
            const sizeInBytes = (lastImage.length * 3) / 4;
                const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB);
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage1();                
                      return false; 
                  }   
                }              
              }              
            }
        };

      // Attach the event listener
      quill.on('text-change', handleChange);

      // Clean up the event listener when the component unmounts
      return () => {
          quill.off('text-change', handleChange);
      };
  }
}, []);
useEffect(() => {
  if (quillRef2.current) {
      const quill = quillRef2.current.getEditor();

      // This function will be called whenever there's a text change in the editor
      const handleChange = () => {
        const numberOfImages = quill.container.querySelectorAll("img").length;
        
        if (numberOfImages > 5) {
            alert("You can only add up to 5 images.");
            // Revert the change using the history module's undo method
            quill.history.undo();
        } else {
          const contents = quill.getContents();
          let lastImage = null;
          contents.ops.forEach(op => {               
            if (op.insert && op.insert.image) {
              lastImage = op.insert.image;                  
            }
          });
          if (lastImage) {                
            const imageType = getImageType(lastImage); 
            if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
              setOpenImageFormatDialog(true);                  
              handleDeleteImage2();                               
              return false;              
            }                        
            const sizeInBytes = (lastImage.length * 3) / 4;
                const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB);  
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage2();                
                      return false; 
                  }   
                }              
              }              
            }
        };

        // Attach the event listener
        quill.on('text-change', handleChange);

        // Clean up the event listener when the component unmounts
        return () => {
            quill.off('text-change', handleChange);
        };
    }
  }, []);
  
  const handleDeleteImage1 = () => {
    const quill = quillRef1.current.getEditor();
    const selection = quill.getSelection();
    quill.history.undo();
  };

  const handleDeleteImage2 = () => {
    const quill = quillRef2.current.getEditor();
    const selection = quill.getSelection();
    quill.history.undo();
  };

  const getImageType = (lastImage) => {
    const matches = lastImage.match(/^data:(.*);base64,/);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return null;
  };

  const customImageHandler = () => {
      const editor = quillRef1.current.getEditor();
      const selection = editor.getSelection();
      const content = editor.getContents();
      console.log("Custom image handler triggered"); // This should be printed whenever an image is added

      // Count the number of images
      let imageCount = 0;
      content.ops.forEach(op => {
          if (typeof op.insert === 'string' && op.insert.includes('<img')) {
              imageCount++;
          }
      });

      if (imageCount >= 5) {
          alert('You can only insert a maximum of 5 images.');
      } else {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.click();

          input.onchange = () => {
              const file = input.files[0];
              const reader = new FileReader();

              reader.onload = () => {
                  const range = selection;
                  editor.insertEmbed(range.index, 'image', reader.result);
              };

              reader.readAsDataURL(file);
          };
      }
  };
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ list: 'bullet' }],
        ['image']
      ]
    }
  }), [])

const handleColostrumGraph1Generated = (imageData) => {
  healthyCalves.colostrum.graph1 = imageData;
  farmDetails.surveyData['HealthyCalves'].colostrum.graph1 = imageData
  setIsDataEdited(true)

  console.log(imageData)
};

const handleColostrumGraph2Generated = (imageData) => {
  healthyCalves.colostrum.graph2 = imageData;
  farmDetails.surveyData['HealthyCalves'].colostrum.graph2 = imageData
  setIsDataEdited(true)

  console.log(imageData)
};

const handleColostrumGraph3Generated = (imageData) => {
  healthyCalves.colostrum.graph3 = imageData;
  farmDetails.surveyData['HealthyCalves'].colostrum.graph3 = imageData
  setIsDataEdited(true)

  console.log(imageData)
};

const handleColostrumGraph4Generated = (imageData) => {
  healthyCalves.colostrum.graph4 = imageData;
  farmDetails.surveyData['HealthyCalves'].colostrum.graph4 = imageData
  setIsDataEdited(true)

  console.log(imageData)
};

const handleCalfPathwaysGraph1Generated = (imageData) => {
  healthyCalves.calfPathways.graph1 = imageData;
  farmDetails.surveyData['HealthyCalves'].calfPathways.graph1 = imageData
  setIsDataEdited(true)

  console.log(imageData)
};

const handleCalfPathwaysGraph2Generated = (imageData) => {
  healthyCalves.calfPathways.graph2 = imageData;
  farmDetails.surveyData['HealthyCalves'].calfPathways.graph2 = imageData
  setIsDataEdited(true)

  console.log(imageData)
};

useEffect(() => {
  if (isGraph) {
    new Promise(resolve => setTimeout(() => resolve(htmlToImage.toPng(colostrumGraphRef1.current, { width: 800, height: 500 })), 1000))
      .then((dataUrl) => {
        handleColostrumGraph1Generated(dataUrl);  // Send the data URL to the parent component
      })
      .catch((error) => {
        console.error('Error converting to image:', error);
      });

      new Promise(resolve => setTimeout(() => resolve(htmlToImage.toPng(colostrumGraphRef2.current, { width: 800, height: 500 })), 1000))
      .then((dataUrl) => {
        handleColostrumGraph2Generated(dataUrl);  // Send the data URL to the parent component
      })
      .catch((error) => {
        console.error('Error converting to image:', error);
      });
  }

    if (isGraph2) {
    new Promise(resolve => setTimeout(() => resolve(htmlToImage.toPng(colostrumGraphRef3.current, { width: 800, height: 500 })), 1000))
      .then((dataUrl) => {
        handleColostrumGraph3Generated(dataUrl);  // Send the data URL to the parent component
      })
      .catch((error) => {
        console.error('Error converting to image:', error);
      });

      new Promise(resolve => setTimeout(() => resolve(htmlToImage.toPng(colostrumGraphRef4.current, { width: 800, height: 500 })), 1000))
      .then((dataUrl) => {
        handleColostrumGraph4Generated(dataUrl);  // Send the data URL to the parent component
      })
      .catch((error) => {
        console.error('Error converting to image:', error);
      });
  }

  if (isGraph3) {
    new Promise(resolve => setTimeout(() => resolve(htmlToImage.toPng(calfPathwaysGraphRef1.current, { width: 800, height: 500 })), 1000))
      .then((dataUrl) => {
        handleCalfPathwaysGraph1Generated(dataUrl);  // Send the data URL to the parent component
      })
      .catch((error) => {
        console.error('Error converting to image:', error);
      });

      new Promise(resolve => setTimeout(() => resolve(htmlToImage.toPng(calfPathwaysGraphRef2.current, { width: 800, height: 500 })), 1000))
      .then((dataUrl) => {
        handleCalfPathwaysGraph2Generated(dataUrl);  // Send the data URL to the parent component
      })
      .catch((error) => {
        console.error('Error converting to image:', error);
      });
  }
}, [isGraph, isGraph2, isGraph3]);

const [openDialog1, setOpenDialog1] = useState(false);
const handleCloseDialog1 = () => {
  setOpenDialog1(false);
};

const allColostrumQuestionsAnswered = () => {
  return QArray1.every((arr, post1) => {
    return farmDetails?.surveyData['HealthyCalves']?.colostrum?.questions[post1]?.notApplicable ||
           (farmDetails?.surveyData['HealthyCalves']?.colostrum?.questions[post1]?.practice && farmDetails?.surveyData['HealthyCalves']?.colostrum?.questions[post1]?.focusCheck);
  });
}

const allCalfQuestionsAnswered = () => {
  return QArray2.every((arr, post2) => {
    return farmDetails?.surveyData['HealthyCalves']?.calfPathways?.questions[post2]?.notApplicable ||
           (farmDetails?.surveyData['HealthyCalves']?.calfPathways?.questions[post2]?.practice && farmDetails?.surveyData['HealthyCalves']?.calfPathways?.questions[post2]?.focusCheck);
  });
}

const checkUnsavedChanges = () => {
  return isTextEdited; // where isTextEdited is a state variable that tracks if the text has been edited
};  

// In your parent component
const handleDataEdit = () => {
  setIsDataEdited(true);
};


  return (
    <div className="healthyCalves-page">
      <header className="healthyCalves-header">
        <h1 className="healthyCalves-h1">Animal Health and Welfare (Healthy Calves)</h1>
                  
                                     <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
        <Grid item xs={3}>
         <div className="progressbar">
         <ProgressBar currentStep={stepIndex} checkUnsavedChanges={checkUnsavedChanges} setOpenUnsavedChangesDialog={setOpenUnsavedChangesDialog} setUnsavedChangesNavigationPath={setUnsavedChangesNavigationPath}/>
           </div>
           </Grid>
            <Grid item xs={9}>
        {console.log(farmDetails)}
        {console.log(payload)}
        {console.log(healthyCalves)}
        <div className="module-questions"> 
          <h5>* indicates <b>mandatory fields</b></h5>
        <h3>The Team</h3>
        <TrainingTable/> 

          <h2>Colostrum </h2>


     
    
       <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>Colostrum Benchmark<sup>*</sup></h3>
        </AccordionSummary>
        <AccordionDetails>

        
                <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].colostrum.currentYear : ""}
            label="Current Year"
            InputProps={{ style: { fontSize: 25, fontWeight: 'bold' } }}
            InputLabelProps={{ style: { fontSize: 25 } }}
            onChange={(e) => {
              const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                      healthyCalves.colostrum.currentYear = (e.target.value);
                      setisGraph(false);
                      farmDetails.surveyData['HealthyCalves'].colostrum.currentYear = (newValue)
                      setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

            }}/>
        </p>

        <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].achievingTransfer : ""}
            label="% of calves achieving passive transfer"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                      healthyCalves.achievingTransfer = (e.target.value);
                      setisGraph(false);
                      farmDetails.surveyData['HealthyCalves'].achievingTransfer = (newValue)
                      setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

            }}/>
        </p>

        <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].failureTransfer : ""}
            label="% of calves with failure of passive transfer"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                   healthyCalves.failureTransfer = (e.target.value);
                  setisGraph(false);
                  farmDetails.surveyData['HealthyCalves'].failureTransfer = (e.target.value)
                  setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

            }}


              />
        </p>

                   <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].colostrum.futureYear : ""}
            label="Future Year"
            InputProps={{ style: { fontSize: 25, fontWeight: 'bold' } }}
            InputLabelProps={{ style: { fontSize: 25 } }}
            onChange={(e) => {
              const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                      healthyCalves.colostrum.futureYear = (e.target.value);
                      setisGraph(false);
                      farmDetails.surveyData['HealthyCalves'].colostrum.futureYear = (newValue)
                      setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

            }}/>
        </p>
        
        <p>
        <TextField  
          variant="standard"
          required
          fullWidth
          type="number"
          id="outlined-required"
          defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].goalachievingTransfer : ""}
          label="% of calves achieving passive transfer"
          InputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                    healthyCalves.goalachievingTransfer = (e.target.value);
                    setisGraph(false);
                    farmDetails.surveyData['HealthyCalves'].goalachievingTransfer = (e.target.value)
                    setIsDataEdited(true)

                                        }
                else {
                    setOpenDialog1(true);

                }
         
          }}
            />
        </p>

        <p>
        <TextField  
          variant="standard"
          required
          fullWidth
          type="number"
          id="outlined-required"
          defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].goalfailureTransfer : ""}
          label="% of calves with failure of passive transfer"
          InputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          onChange={(e) => {
             const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                    healthyCalves.goalfailureTransfer = (e.target.value);
                    setisGraph(false);
                    farmDetails.surveyData['HealthyCalves'].goalfailureTransfer = (e.target.value)
                    setIsDataEdited(true)

                                        }
                else {
                    setOpenDialog1(true);

                }
            
          }}
            />
        </p>

                {/* PieChart display */}
        { isGraph  &&
          
          <Box sx={{ flexGrow: 1, marginBottom: 4}}>
          <Grid container direction="row"  spacing={1}>
          <div ref={colostrumGraphRef1}>
          <Grid item xs={6}>
            <Item>
         
              <div style={{ height: '100%', width: '80%', marginLeft: '10%'}}>
                 <h2><b>{healthyCalves.colostrum.currentYear}</b></h2>
                <PieChart data={data1} />
              </div>
            </Item>
          </Grid>
          </div>
          <div ref={colostrumGraphRef2}>
          <Grid item xs={6}>
            <Item style={{ marginRight: '10%', marginLeft: '60%'}}>
          
              <div style={{ height: '100%', width: '80%'}}>
                <h2><b>{healthyCalves.colostrum.futureYear}</b></h2>
                <PieChart data={data2} />
              </div>
            </Item>
          </Grid>
          </div>
        </Grid>
      </Box>
             
        }

       <div className="btn-pieChart">
        <Button id = "displayGraph" variant="contained" onClick = {(e) => showGraph1(e)} style={{backgroundColor: "#002D4E"}}>Show Plot</Button>
      </div>

                        <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].colostrum.currentYear : ""}
            label="Current Year"
            InputProps={{ style: { fontSize: 25, fontWeight: 'bold' } }}
            InputLabelProps={{ style: { fontSize: 25 } }}
            onChange={(e) => {
              const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                      healthyCalves.colostrum.currentYear = (e.target.value);
                      setisGraph2(false);
                      farmDetails.surveyData['HealthyCalves'].colostrum.currentYear = (newValue)
                      setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

            }}/>
        </p>

        <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].less12hours : ""}
            label="% of calves fed colostrum <12 hours"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                         healthyCalves.less12hours = (e.target.value);
                          setisGraph2(false);
                          farmDetails.surveyData['HealthyCalves'].less12hours = (e.target.value)
                          setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }
              
        
            }}
              />
        </p>
          
        <p>
        <TextField  
          variant="standard"
          required
          fullWidth
          type="number"
          id="outlined-required"
          defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].more12hours : ""}
          label="% of calves fed colostrum >12 hours"
          InputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          onChange={(e) => {
            const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                     healthyCalves.more12hours = (e.target.value);
                    setisGraph2(false);
                    farmDetails.surveyData['HealthyCalves'].more12hours = (e.target.value)
                    setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }


          }}
            />
        </p>

        <p>
        <TextField  
          sx={{  mb: 4}}
          variant="standard"
          required
          fullWidth
          type="number"
          id="outlined-required"
          defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].noColostrum : ""}
          label="% of calves not fed colostrum at all"
          InputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          onChange={(e) => {
                        const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                                healthyCalves.noColostrum = (e.target.value);
                                setisGraph2(false);
                                farmDetails.surveyData['HealthyCalves'].noColostrum = (e.target.value)
                                setIsDataEdited(true)

                                        }
                else {
                    setOpenDialog1(true);

                }

          }}
            />
        </p>
                         
       <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].colostrum.futureYear : ""}
            label="Future Year"
            InputProps={{ style: { fontSize: 25, fontWeight: 'bold' } }}
            InputLabelProps={{ style: { fontSize: 25 } }}
            onChange={(e) => {
              const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                      healthyCalves.colostrum.futureYear = (e.target.value);
                      setisGraph2(false);
                      farmDetails.surveyData['HealthyCalves'].colostrum.futureYear = (newValue)
                      setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

            }}/>
        </p>

        <p>
        <TextField  
          variant="standard"
          required
          fullWidth
          type="number"
          id="outlined-required"
          defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].goalless12hours : ""}
          label="% of calves fed colostrum <12 hours"
          InputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          onChange={(e) => {
            const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                      healthyCalves.goalless12hours = (e.target.value);
                        setisGraph2(false);
                        farmDetails.surveyData['HealthyCalves'].goalless12hours = (e.target.value)
                        setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }
          
          }}
            />
        </p>
          
        <p>
        <TextField  
          variant="standard"
          required
          fullWidth
          type="number"
          id="outlined-required"
          defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].goalmore12hours : ""}
          label="% of calves fed colostrum >12 hours"
          InputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          onChange={(e) => {
            const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                                  healthyCalves.goalmore12hours = (e.target.value);
                                setisGraph2(false);
                                farmDetails.surveyData['HealthyCalves'].goalmore12hours = (e.target.value)
                                setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

          }}
            />
        </p>

        <p>
        <TextField  
          variant="standard"
          required
          fullWidth
          type="number"
          id="outlined-required"
          defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].goalnoColostrum : ""}
          label="% of calves not fed colostrum at all"
          InputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          onChange={(e) => {
                        const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                                             healthyCalves.goalnoColostrum = (e.target.value);
                                            setisGraph2(false);
                                            farmDetails.surveyData['HealthyCalves'].goalnoColostrum = (e.target.value)
                                            setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

          }}
            />
        </p>

        {/* PieChart display */}
        { isGraph2  &&
          
          <Box sx={{ flexGrow: 1, marginBottom: 4}}>
          <Grid container direction="row"  spacing={1}>
          <div ref={colostrumGraphRef3}>
          <Grid item xs={6}>
            <Item>
         
              <div style={{ height: '100%', width: '80%', marginLeft: '10%'}}>
                 <h2><b>{healthyCalves.colostrum.currentYear}</b></h2>
                <PieChart data={data3} />
              </div>
            </Item>
          </Grid>
          </div>
          <div ref={colostrumGraphRef4}>
          <Grid item xs={6}>
            <Item style={{ marginRight: '10%', marginLeft: '60%'}}>
          
              <div style={{ height: '100%', width: '80%'}}>
                <h2><b>{healthyCalves.colostrum.futureYear}</b></h2>
                <PieChart data={data4} />
              </div>
            </Item>
          </Grid>
          </div>
        </Grid>
      </Box>
             
        }

     <div className="btn-pieChart">
        <Button id = "displayGraph" variant="contained" onClick = {(e) => showGraph2(e)} style={{backgroundColor: "#002D4E"}}>Show Plot</Button>
      </div>

 
      

    
        </AccordionDetails>
      </Accordion>


      <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
            Commentary
            {farmDetails?.surveyData?.['GeneralFarm']?.commentary &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
              <h3>Commentary<sup>*</sup></h3>
            </AccordionSummary>

            {/* <AccordionDetails>
              <Typography>
                <TextareaAutosize
                  minRows={3}
                  maxRows={5}
                  placeholder="Commentary"
                  defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].commentary : ""}
                  style={{
                    color: 'black',
                    width: "100%",
                    paddingBottom: '2%',
                    border: "2px solid #000000",
                    fontSize: 20,
                    fontFamily: "Arial",
                    backgroundColor: "#EBF4F9",
                  }}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm

                  //   setFarmDetails(prevFarmDetails => ({
                  //     ...prevFarmDetails,
                  //     surveyData: {
                  //       ...prevFarmDetails.surveyData,
                  //       GeneralFarm: {
                  //         ...prevFarmDetails.surveyData.GeneralFarm,
                  //         commentary: newValue
                  //       }
                  //     }
                  //   }));
                  // }}
                  onChange={(e) => {
                    generalFarm.commentary = (e.target.value)
                    farmDetails.surveyData['GeneralFarm'].commentary = (e.target.value)
                  }}
                />
              </Typography>
            </AccordionDetails> */}

          <AccordionDetails>
              <ReactQuill ref={quillRef1} theme="snow" value={value1} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValue1(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)

                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
              <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>
            </AccordionDetails>
          </Accordion>

             <Accordion>
             <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3>
          Colostrum Questions<sup>*</sup> 
          { allColostrumQuestionsAnswered() && <CheckCircleIcon style={{ marginLeft: '10px', marginBottom: '-3px', color: 'green', fontSize: '1.5rem' }} /> }
        </h3>
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
                      {/* Colostrum questions */}
            {QArray1.map((Arr1,post1) =>
            divDisplay1 ? (
              <div >
                <List
                  sx={{ width: "100%", bgcolor: "#EBF4F9"}}
                  component="nav"
                  aria-labelledby="nested-list-subheader" >

                  <ListItemButton style={{ fontSize: 20, fontWeight: 'bold' }} onClick={() => handleClick1(post1)}>
                  Question {Arr1.number}.{Arr1.question}
                  { 
                    (farmDetails?.surveyData['HealthyCalves']?.colostrum?.questions[post1]?.notApplicable ||
                    (farmDetails?.surveyData['HealthyCalves']?.colostrum?.questions[post1]?.practice && farmDetails?.surveyData['HealthyCalves']?.colostrum?.questions[post1]?.focusCheck)) &&
                    <CheckIcon style={{ marginLeft: '10px', color: 'green' }} />
                  }
                </ListItemButton>



                  {postDisplay1[post1] ?
                  <FormControl>
                                                      <div style={{ marginLeft: '2%', marginTop: '15px' }}>
                  <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={farmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].notApplicable}
                            onChange={() => {
                                // Copy the current farmDetails state
                                const updatedFarmDetails = {...farmDetails};
                                // Toggle the notApplicable value for the current question
                                updatedFarmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].notApplicable = !updatedFarmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].notApplicable;
                              
                                if (updatedFarmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].notApplicable) { 
                                    // Reset checkboxes
                                    farmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].subQuestions.forEach((subQ, j) => {
                                        const currentSection = QArray1[post1].section[j];
                                        currentSection.option.key.forEach((key, k) => {
                                            updatedFarmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].subQuestions[j][k] = false;
                                            // Update generalFarm.scoreArray and generalFarm.generalQuestions
                                          
                                            ColostrumScoresArray = subtractScore(ColostrumScoresArray, key, currentSection.option.value[k]);
                                            healthyCalves.colostrum.scoreArray = ColostrumScoresArray;
                                            healthyCalves.colostrum.calvesQuestions.delete(key);
                                            setFarmDetails(prevFarmDetails => ({
                                              ...prevFarmDetails,
                                              surveyData: {
                                                  ...prevFarmDetails.surveyData,
                                                  HealthyCalves: {
                                                      ...prevFarmDetails.surveyData.HealthyCalves,
                                                      colostrum: {
                                                        ...prevFarmDetails.surveyData.HealthyCalves.colostrum,
                                                        scoreArray: ColostrumScoresArray
                                                    }
                                                  }
                                              }
                                          }));
                                        });
                                    });
                                    
                                    // Reset practice and focusCheck
                                    updatedFarmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].practice = "";
                                    updatedFarmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].focusCheck = "";
                                    healthyCalves.colostrum.practices[post1] = "";
                                    healthyCalves.colostrum.focusArea[post1] = "";
                                }
                                
                                // Update the state
                                setFarmDetails(updatedFarmDetails);
                                setIsDataEdited(true)

                            }}
                            name={`notApplicable_${post1}`}
                        />
                    }
                    label={
                      <Typography variant="body1" style={{  fontStyle: 'italic' }}>
                          Not Applicable for the farm
                      </Typography>
                  }
                />

              </div>
                    {Arr1.section.map((innerArr1,i) =>(                   
                      <div key = {i}>
                        <FormLabel  component = "legend" style = {{color: 'black', marginLeft: '2%', paddingTop: '2%'}}> {innerArr1.statement} </FormLabel>
                          {innerArr1.option.text.map((text,k) => (
                        <FormGroup key = {k}>
                        <FormControlLabel control = {
                            <Checkbox 
                                onChange =  { handleChangeColostrum }
                                name = {innerArr1.option.key[k]}
                                value = {innerArr1.option.value[k]}
                                style = {{marginLeft: '2%'}}
                                checked = {farmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].subQuestions[i][k]}
                                data-post={post1}
                                data-subq={i}
                                data-opt={k}
                                disabled={farmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].notApplicable}
                            />}
                            label = {text}
                             style={{width: "110ch",wordWrap: 'break-word'}}
                        />
                    </FormGroup>
                        ))} 
                      </div>
                    ))}

                    <h6 style={{marginLeft: '2%', fontSize: '20px'}}>
                      Suggestion: {GetSuggestion(ColostrumScoresArray[post1])}
                    </h6>

                    <TextField
                      id="select-practice"
                      select
                      name= {post1}
                     label={<CustomInputLabel label="Select appropriate practice" />}
                      value={farmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].practice} 
                      style = {{marginLeft: '2%', marginTop: '2%' }}
                        sx={{ m: 1, width: '42ch' }}
                      onChange = {updateColostrumPractice}
                     
         
                      disabled={farmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].notApplicable}
                      InputProps={{ style: { fontSize: 20} }}
                    InputLabelProps={{ style: { fontSize: 20} }}
                   
                    >
                      {practices.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      id="select-improvement"
                      select
                      name = {post1}
                     label={<CustomInputLabel label="Is this a focus area you want support with?" />}
                      value={farmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].focusCheck} 
                      style = {{marginLeft: '2%', marginTop: '2%' }}
                        sx={{ m: 1, width: '42ch' }}
                        onChange = {updateColostrumFocus}
                      
         
                        disabled={farmDetails.surveyData['HealthyCalves'].colostrum.questions[post1].notApplicable}
                        InputProps={{ style: { fontSize: 15} }}
                    InputLabelProps={{ style: { fontSize: 15 } }}
            
                    >
                      {wantImprovement.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>



                  </FormControl>
                  : null}
                  
                </List>    
               </div>
           ) : null
           )}
          </Typography>
        </AccordionDetails>
      </Accordion>
 
        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {/* <h3>
          Area of focus
          {farmDetails?.surveyData['HealthyCalves']?.colostrum?.focus &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
           }
        </h3> */}
        <h3>Area of focus<sup>*</sup></h3>
    
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
               <h5>Maximum character limit - 550</h5>
                <Textarea
            maxRows={3}
            maxLength="551"
            placeholder="Focus"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].colostrum.focus : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   healthyCalves.colostrum.focus = newValue; // Assuming you also need to update healthyCalves
              
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       HealthyCalves: {
              //         ...prevFarmDetails.surveyData.HealthyCalves,
              //         colostrum: {
              //           ...prevFarmDetails.surveyData.HealthyCalves.colostrum,
              //           focus: newValue
              //         }
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
                if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    healthyCalves.colostrum.focus = (e.target.value)
                    farmDetails.surveyData['HealthyCalves'].colostrum.focus = (e.target.value)
                    setIsDataEdited(true)

                  }

              }}
              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 7 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 7) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 8).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  healthyCalves.colostrum.focus = (e.target.value)
                  farmDetails.surveyData['HealthyCalves'].colostrum.focus = (e.target.value)
                  setIsDataEdited(true)

                }
              }}
          />
          </Typography>
        </AccordionDetails>
      </Accordion>
 
      
        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {/* <h3>
          Opportunity for follow up/support
          {farmDetails?.surveyData['HealthyCalves']?.colostrum?.focusAdditional &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
           }
        </h3> */}
        <h3>Opportunity for follow up/support - (Optional)</h3>
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
           <h5>Maximum character limit - 500</h5>
             <Textarea
              maxRows={3}
              maxLength="501"
              placeholder="Additional focus"
              defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].colostrum.focusAdditional : ""}
              style={{ 
                color: 'black', 
                width: "100%",
                paddingBottom: '2%',
                border: "2px solid #000000",
                fontSize: 20, 
                fontColor: "black", 
                fontFamily: "Arial", 
                marginBottom: "2%",
                backgroundColor:"#EBF4F9",}}
                // onChange={(e) => {
                //   const newValue = e.target.value;
                //   healthyCalves.colostrum.focusAdditional = newValue; // Assuming you also need to update healthyCalves
                
                //   setFarmDetails(prevFarmDetails => ({
                //     ...prevFarmDetails,
                //     surveyData: {
                //       ...prevFarmDetails.surveyData,
                //       HealthyCalves: {
                //         ...prevFarmDetails.surveyData.HealthyCalves,
                //         colostrum: {
                //           ...prevFarmDetails.surveyData.HealthyCalves.colostrum,
                //           focusAdditional: newValue
                //         }
                //       }
                //     }
                //   }));
                // }}
                onChange={(e) => {
                  if ((e.target.value).length > 500) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    healthyCalves.colostrum.focusAdditional = (e.target.value)
                  farmDetails.surveyData['HealthyCalves'].colostrum.focusAdditional = (e.target.value)
                  setIsDataEdited(true)

                  }

                }}
                onKeyDown={(e) => {
                  const value = e.target.value;
                  const numberOfNewLines = (value.match(/\n/g) || []).length;
                  
                  if (numberOfNewLines >= 9 && e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => {
                  // Get clipboard data
                  const clipboardData = e.clipboardData || window.clipboardData;
                  const pastedData = clipboardData.getData('Text');
              
                  // Count the number of lines in the pasted text
                  const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
              
                  // Check if the number of lines exceeds 10
                  if (numberOfNewLines > 9) {
                    e.preventDefault();
                    // Trim the pasted text to 10 lines and insert it manually
                    const trimmedData = pastedData.split('\n').slice(0, 10).join('\n');
                    const selectionStart = e.target.selectionStart;
                    const before = e.target.value.substring(0, selectionStart);
                    const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                    e.target.value = before + trimmedData + after;
                    // Update state
                    healthyCalves.colostrum.focusAdditional = (e.target.value)
                    farmDetails.surveyData['HealthyCalves'].colostrum.focusAdditional = (e.target.value)
                    setIsDataEdited(true)

                  }
                }}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
 

  
        
 
      <ColostrumActionTable onDataEdit={handleDataEdit} />
        


      <h2>Calf Pathways</h2>

                  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>Calf Pathways Benchmark<sup>*</sup></h3>
        </AccordionSummary>
        <AccordionDetails>
     
          <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].calfPathways.currentYear : ""}
            label="Current Year"
            InputProps={{ style: { fontSize: 25, fontWeight: 'bold' } }}
            InputLabelProps={{ style: { fontSize: 25 } }}
            onChange={(e) => {
              const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                      healthyCalves.calfPathways.currentYear = (e.target.value);
                      setisGraph3(false);
                      farmDetails.surveyData['HealthyCalves'].calfPathways.currentYear = (newValue)
                      setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

            }}/>
        </p>
        <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].replacement1 : ""}
            label="Number of calves reared for replacements"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.replacement1 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].replacement1 = (e.target.value)
              setIsDataEdited(true)

            }}
              />
          </p>

          <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].beef1 : ""}
            label="Number of calves reared for beef"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.beef1 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].beef1 = (e.target.value)
              setIsDataEdited(true)

            }}
              />
          </p>

          <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].export1 : ""}
            label="Number of calves reared for live export"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.export1 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].export1 = (e.target.value)
              setIsDataEdited(true)

            }}
              />
          </p>
          
          <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].rearing1 : ""}
            label="Number calves sold for off-farm rearing (beef or dairy)"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.rearing1 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].rearing1 = (e.target.value)
              setIsDataEdited(true)

            }}/>
          </p>

          <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].euthanised1 : ""}
            label="Number of calves euthanised at birth"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.euthanised1 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].euthanised1 = (e.target.value)
              setIsDataEdited(true)

            }}/>
          </p>

        <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].bobby1 : ""}
            label="Number of calves processed as bobby calves 5-30 days old"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.bobby1 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].bobby1 = (e.target.value)
              setIsDataEdited(true)

            }}/>
          </p>
                         
                  <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].calfPathways.futureYear : ""}
            label="Future Year"
            InputProps={{ style: { fontSize: 25, fontWeight: 'bold' } }}
            InputLabelProps={{ style: { fontSize: 25 } }}
            onChange={(e) => {
              const newValue = e.target.value;
                  if (/^(?!-)(0?\.\d+|\d+(\.\d+)?)$/.test(newValue) || newValue === '') {
                      healthyCalves.calfPathways.futureYear = (e.target.value);
                      setisGraph3(false);
                      farmDetails.surveyData['HealthyCalves'].calfPathways.futureYear = (newValue)
                      setIsDataEdited(true)

                    }
                else {
                    setOpenDialog1(true);

                }

            }}/>
        </p>
          <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].replacement2 : ""}
            label="Number of calves reared for replacements"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.replacement2 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].replacement2 = (e.target.value)
              setIsDataEdited(true)

            }}
              />
          </p>

          <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].beef2 : ""}
            label="Number of calves reared for beef"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.beef2 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].beef2 = (e.target.value)
              setIsDataEdited(true)

            }}
              />
          </p>

          <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].export2 : ""}
            label="Number of calves reared for live export"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.export2 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].export2 = (e.target.value)
              setIsDataEdited(true)

            }}
              />
          </p>
          
          <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].rearing2 : ""}
            label="Number calves sold for off-farm rearing (beef or dairy)"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.rearing2 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].rearing2 = (e.target.value)
              setIsDataEdited(true)

            }}/>
          </p>

                    <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].euthanised2 : ""}
            label="Number of calves euthanised at birth"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.euthanised2 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].euthanised2 = (e.target.value)
              setIsDataEdited(true)

            }}/>
          </p>

          <p>
          <TextField  
            variant="standard"
            required
            fullWidth
            type="number"
            id="outlined-required"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].bobby2 : ""}
            label="Number of calves processed as bobby calves 5-30 days old"
            InputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            onChange={(e) => {
              healthyCalves.bobby2 = (e.target.value);
              setisGraph3(false);
              farmDetails.surveyData['HealthyCalves'].bobby2 = (e.target.value)
              setIsDataEdited(true)

            }}/>
          </p>

        

        { isGraph3  &&
          <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
            <Grid container direction="row"  spacing={1}>
            <div ref={calfPathwaysGraphRef1}>
            <Grid item xs={6}>
              <Item>
             
                <div style={{ height: '100%', width: '80%', marginLeft: '10%'}}>
                  <h2><b>{healthyCalves.calfPathways.currentYear}</b></h2>
                  <PieChart data={data5} />
                </div>
              </Item>
            </Grid>
            </div>
            <div ref={calfPathwaysGraphRef2}>
            <Grid item xs={6}>
              <Item style={{ marginRight: '10%', marginLeft: '60%'}}>
             
                <div style={{ height: '100%', width: '80%'}}>
                 <h2><b>{healthyCalves.calfPathways.futureYear}</b></h2>
                  <PieChart data={data6} />
                </div>
              </Item>
            </Grid>
            </div>
          </Grid>
        </Box>
        }

        <div className="btn-pieChart">
          <Button id = "displayGraph" variant="contained" onClick = {(e) => showGraph3(e)} style={{backgroundColor: "#002D4E"}}>Show Plot</Button>
        </div>
        
        </AccordionDetails>
      </Accordion>


      <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
            Commentary
            {farmDetails?.surveyData?.['GeneralFarm']?.commentary &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
              <h3>Commentary<sup>*</sup></h3>
            </AccordionSummary>

            {/* <AccordionDetails>
              <Typography>
                <TextareaAutosize
                  minRows={3}
                  maxRows={5}
                  placeholder="Commentary"
                  defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].commentary : ""}
                  style={{
                    color: 'black',
                    width: "100%",
                    paddingBottom: '2%',
                    border: "2px solid #000000",
                    fontSize: 20,
                    fontFamily: "Arial",
                    backgroundColor: "#EBF4F9",
                  }}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm

                  //   setFarmDetails(prevFarmDetails => ({
                  //     ...prevFarmDetails,
                  //     surveyData: {
                  //       ...prevFarmDetails.surveyData,
                  //       GeneralFarm: {
                  //         ...prevFarmDetails.surveyData.GeneralFarm,
                  //         commentary: newValue
                  //       }
                  //     }
                  //   }));
                  // }}
                  onChange={(e) => {
                    generalFarm.commentary = (e.target.value)
                    farmDetails.surveyData['GeneralFarm'].commentary = (e.target.value)
                  }}
                />
              </Typography>
            </AccordionDetails> */}

            <AccordionDetails>
              <ReactQuill ref={quillRef2} theme="snow" value={value2} 
              onChange={(content, delta, source, editor) => {
                if (source === 'user') {
                  setValue2(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)

                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
              <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>
            </AccordionDetails>
          </Accordion>

        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3>
          Calf Pathways Questions<sup>*</sup>
          { allCalfQuestionsAnswered() && <CheckCircleIcon style={{ marginLeft: '10px', marginBottom: '-3px', color: 'green', fontSize: '1.5rem' }} /> }
        </h3>
      </AccordionSummary>
        <AccordionDetails>
                  {/* Calf pathways questions */}
        {QArray2.map((Arr2,post2) =>
          divDisplay2 ? (
            <div>
              <List
                sx={{ width: "100%", bgcolor: "#EBF4F9"}}
                component="nav"
                aria-labelledby="nested-list-subheader" >

                <ListItemButton style={{ fontSize: 20, fontWeight: 'bold' }} onClick={() => handleClick2(post2)}>
                  Question {Arr2.number}.{Arr2.question}
                  { 
                    (farmDetails?.surveyData['HealthyCalves']?.calfPathways?.questions[post2]?.notApplicable ||
                    (farmDetails?.surveyData['HealthyCalves']?.calfPathways?.questions[post2]?.practice && farmDetails?.surveyData['HealthyCalves']?.calfPathways?.questions[post2]?.focusCheck)) &&
                    <CheckIcon style={{ marginLeft: '10px', color: 'green' }} />
                  }
                </ListItemButton>



                {postDisplay2[post2] ?
                  <FormControl>
                                                    <div style={{ marginLeft: '2%', marginTop: '15px' }}>
                  <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={farmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].notApplicable}
                            onChange={() => {
                                // Copy the current farmDetails state
                                const updatedFarmDetails = {...farmDetails};
                                // Toggle the notApplicable value for the current question
                                updatedFarmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].notApplicable = !updatedFarmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].notApplicable;
                              
                                if (updatedFarmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].notApplicable) { 
                                    // Reset checkboxes
                                    farmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].subQuestions.forEach((subQ, j) => {
                                        const currentSection = QArray2[post2].section[j];
                                        currentSection.option.key.forEach((key, k) => {
                                            updatedFarmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].subQuestions[j][k] = false;
                                            // Update generalFarm.scoreArray and generalFarm.generalQuestions
                                          
                                            CalfScoresArray = subtractScore(CalfScoresArray, key, currentSection.option.value[k]);
                                            healthyCalves.calfPathways.scoreArray = CalfScoresArray;
                                            healthyCalves.calfPathways.calvesQuestions.delete(key);
                                            setFarmDetails(prevFarmDetails => ({
                                              ...prevFarmDetails,
                                              surveyData: {
                                                  ...prevFarmDetails.surveyData,
                                                  HealthyCalves: {
                                                      ...prevFarmDetails.surveyData.HealthyCalves,
                                                      calfPathways: {
                                                        ...prevFarmDetails.surveyData.HealthyCalves.calfPathways,
                                                        scoreArray: CalfScoresArray
                                                    }
                                                  }
                                              }
                                          }));
                                        });
                                    });
                                    
                                    // Reset practice and focusCheck
                                    updatedFarmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].practice = "";
                                    updatedFarmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].focusCheck = "";
                                    healthyCalves.calfPathways.practices[post2] = "";
                                    healthyCalves.calfPathways.focusArea[post2] = "";
                                }
                                
                                // Update the state
                                setFarmDetails(updatedFarmDetails);
                                setIsDataEdited(true)

                            }}
                            name={`notApplicable_${post2}`}
                        />
                    }
                    label={
                      <Typography variant="body1" style={{  fontStyle: 'italic' }}>
                          Not Applicable for the farm
                      </Typography>
                  }
                />

              </div>
                  {Arr2.section.map((innerArr2,i) =>(                   
                    <div key = {i}>
                      <FormLabel  component = "legend" style = {{color: 'black', marginLeft: '3%', paddingTop: '2%'}}> {innerArr2.statement} </FormLabel>
                        {innerArr2.option.text.map((text,k) => (
                        <FormGroup key = {k}>
                        <FormControlLabel control = {
                            <Checkbox 
                                onChange =  { handleChangeCalf }
                                name = {innerArr2.option.key[k]}
                                value = {innerArr2.option.value[k]}
                                style = {{marginLeft: '3%'}}
                                checked = {farmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].subQuestions[i][k]}
                                data-post={post2}
                                data-subq={i}
                                data-opt={k}
                                disabled={farmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].notApplicable}
                            />}
                            label = {text}
                            style={{width: "50ch",wordWrap: 'break-word'}}
                        />
                    </FormGroup>
                      ))} 
                    </div>
                  ))}

                  <h6 style={{marginLeft: '3%', fontSize: '20px'}}>
                    Suggestion: {GetSuggestion(CalfScoresArray[post2])}
                  </h6>

                  <TextField
                    id="select-practice"
                    select
                    name= {post2}
                    label={<CustomInputLabel label="Select appropriate practice" />}
                    value = {farmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].practice}
                    style = {{marginLeft: '3%', marginTop: '2%' }}
                      sx={{ m: 1, width: '25ch' }}
                    onChange = {updateCalfPractice}
                  
            
                    disabled={farmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].notApplicable}
                   InputProps={{ style: { fontSize: 20} }}
                    InputLabelProps={{ style: { fontSize: 20} }}
                  
                  >
                    {practices.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="select-improvement"
                    select
                    name = {post2}
                    label={<CustomInputLabel label="Is this a focus area you want support with?" />}
                    value = {farmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].focusCheck}
                    style = {{marginLeft: '3%', marginTop: '2%' }}
                      sx={{ m: 1, width: '25ch' }}
                      onChange = {updateCalfFocus}
                     
   
                      disabled={farmDetails.surveyData['HealthyCalves'].calfPathways.questions[post2].notApplicable}
                       InputProps={{ style: { fontSize: 15} }}
                InputLabelProps={{ style: { fontSize: 15 } }}
               
                  >
                    {wantImprovement.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>



                </FormControl>
                : null}
                
              </List>    
              </div>
          ) : null
          )}
         
        </AccordionDetails>
      </Accordion>

                  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>Area of focus<sup>*</sup></h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <h5>Maximum character limit - 550</h5>
                 <Textarea
          maxRows={3}
          maxLength="551"
          placeholder="Focus"
          defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].calfPathways.focus : ""}
          style={{ 
            color: 'black', 
            width: "100%",
            paddingBottom: '2%',
            border: "2px solid #000000",
            fontSize: 20, 
            fontColor: "black", 
            fontFamily: "Arial", 
            backgroundColor:"#EBF4F9",}}
            onChange={(e) => {
               if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    healthyCalves.calfPathways.focus = (e.target.value)
                    farmDetails.surveyData['HealthyCalves'].calfPathways.focus = (e.target.value)
                    setIsDataEdited(true)

                  }

            }}
            onKeyDown={(e) => {
              const value = e.target.value;
              const numberOfNewLines = (value.match(/\n/g) || []).length;
              
              if (numberOfNewLines >= 7 && e.key === "Enter") {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              // Get clipboard data
              const clipboardData = e.clipboardData || window.clipboardData;
              const pastedData = clipboardData.getData('Text');
          
              // Count the number of lines in the pasted text
              const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
          
              // Check if the number of lines exceeds 10
              if (numberOfNewLines > 7) {
                e.preventDefault();
                // Trim the pasted text to 10 lines and insert it manually
                const trimmedData = pastedData.split('\n').slice(0, 8).join('\n');
                const selectionStart = e.target.selectionStart;
                const before = e.target.value.substring(0, selectionStart);
                const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                e.target.value = before + trimmedData + after;
                // Update state
                healthyCalves.calfPathways.focus = (e.target.value)
                farmDetails.surveyData['HealthyCalves'].calfPathways.focus = (e.target.value)
                setIsDataEdited(true)

              }
            }}
        />
       
          </Typography>
        </AccordionDetails>
      </Accordion>

                   <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>Opportunity for follow up/support - (Optional)</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <h5>Maximum character limit - 500</h5>
                      <Textarea
            maxRows={3}
            maxLength="501"
            placeholder="Additional focus"
            defaultValue={farmDetails.surveyData['HealthyCalves'] ? farmDetails.surveyData['HealthyCalves'].calfPathways.focusAdditional : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              marginBottom: "2%",
              backgroundColor:"#EBF4F9",}}
              onChange={(e) => {
                 if ((e.target.value).length > 500) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                     healthyCalves.calfPathways.focusAdditional = (e.target.value)
                      farmDetails.surveyData['HealthyCalves'].calfPathways.focusAdditional = (e.target.value)
                      setIsDataEdited(true)

                  }

              }}
              onKeyDown={(e) => {
                const value = e.target.value;
                const numberOfNewLines = (value.match(/\n/g) || []).length;
                
                if (numberOfNewLines >= 9 && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get clipboard data
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('Text');
            
                // Count the number of lines in the pasted text
                const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
            
                // Check if the number of lines exceeds 10
                if (numberOfNewLines > 9) {
                  e.preventDefault();
                  // Trim the pasted text to 10 lines and insert it manually
                  const trimmedData = pastedData.split('\n').slice(0, 10).join('\n');
                  const selectionStart = e.target.selectionStart;
                  const before = e.target.value.substring(0, selectionStart);
                  const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                  e.target.value = before + trimmedData + after;
                  // Update state
                  healthyCalves.calfPathways.focusAdditional = (e.target.value)
                  farmDetails.surveyData['HealthyCalves'].calfPathways.focusAdditional = (e.target.value)
                  setIsDataEdited(true)

                }
              }}
          />
       
          </Typography>
        </AccordionDetails>
      </Accordion>
       
            <CalfPathwaysActionTable onDataEdit={handleDataEdit}/>


            <div className="back-next-button">
            <Back back = {prev} onClick={() => handleNavigationAttempt(prev)}/>
              <Next 
                next={next} 
                onClick={() => handleNavigationAttempt(next)} 
                disabled={true}
              />
                  <div className="btn-text-left">
                  <Button  variant="contained" href="#contained-buttons"  className="btn-save" style={{ backgroundColor: isDataEdited ? "#002D4E" : "#a8a8a8"}}  sx={{ ml: 1}} onClick={handleSave} disabled={!isDataEdited}>Save</Button>
                {/* <Link to="/ModulePage">
                    <Button  variant="contained" href="#contained-buttons" id = "module" style={{ backgroundColor:"#002D4E"}}  sx={{ ml: 5}}>Module Page</Button>
              </Link> */}
              </div>
              {/* <Button
                  id="next"
                  style={{ backgroundColor:"#002D4E", marginTop:55, marginBottom:55 }}
                  variant="contained"
                  onClick={() => { window.location.href = '/'; }}
              >
                  Home
              </Button> */}
            </div>
             </div>
                 </Grid>
                 </Grid>
          </Box>
      </header>



          {/* Dialog Component */}
          <Dialog
            open={openImageFormatDialog}
            onClose={handleImageFormatDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Type"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Invalid file type. Please select a JPEG or PNG file.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageFormatDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openImageSizeDialog}
            onClose={handleImageSizeDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Size"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              File size exceeds the limit (400 KB).
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageSizeDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Incomplete Questions"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please complete all questions/show plot before proceeding.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={openDialog1} onClose={handleCloseDialog1}>
              <DialogTitle>Error</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Negative numbers are not allowed. Please enter a positive number.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog1} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>

          {/* Save Successful Dialog */}
          <Dialog
            open={openSaveSuccessDialog}
            onClose={handleSaveSuccessDialogClose}
            aria-labelledby="save-success-dialog-title"
            aria-describedby="save-success-dialog-description"
        >
            <DialogTitle id="save-success-dialog-title">{"Save Successful"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="save-success-dialog-description">
                    You have successfully saved the form.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveSuccessDialogClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

                  {/* Save Failed Dialog */}
                  <Dialog
            open={openSaveFailedDialog}
            onClose={handleSaveFailedDialogClose}
            aria-labelledby="save-failed-dialog-title"
            aria-describedby="save-failed-dialog-description"
        >
            <DialogTitle id="save-failed-dialog-title">{"Save Failed"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="save-failed-dialog-description">
                    An error occurred while saving the form. Please try again.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveFailedDialogClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

                          {/* Percent Dialog */}
                  <Dialog
            open={openPercentDialog}
            onClose={handlePercentDialogClose}
            aria-labelledby="save-failed-dialog-title"
            aria-describedby="save-failed-dialog-description"
        >
            <DialogTitle id="save-failed-dialog-title">{"Wrong Values"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="save-failed-dialog-description">
                    Please check that the percentages add upto 100
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePercentDialogClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

        {/* Saving Dialog */}
        <Dialog
        open={openSavingDialog}
        aria-labelledby="saving-dialog-title"
        aria-describedby="saving-dialog-description"
        >
        <DialogTitle id="saving-dialog-title">{"Saving..."}</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
                Please wait while we save your form.
            </DialogContentText>
            {/* Centered spinner with space above */}
            <CircularProgress size={40} style={{ marginTop: '10px' }} />
        </DialogContent>
        </Dialog>
        
        <Dialog
        open={openUnsavedChangesDialog}
        onClose={() => setOpenUnsavedChangesDialog(false)}
        aria-labelledby="unsaved-changes-title"
        aria-describedby="unsaved-changes-description"
      >
        <DialogTitle id="unsaved-changes-title">{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-description">
            You have unsaved changes in the commentary section. Please save before continuing.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
          onClick={() => {
            setOpenUnsavedChangesDialog(false);
            setIsTextEdited(false); // Reset the text edited state
            setIsDataEdited(false)
            navigate(unsavedChangesNavigationPath); // Navigate to the stored path
            setUnsavedChangesNavigationPath(null); // Reset the navigation path state
          }}
          style={{ color: 'red', marginRight: 'auto', marginLeft: '7px' }} // This aligns the button to the left
        >
          Continue without saving
        </Button>
        <div style={{ marginLeft: 'auto' }}> {/* This div groups the 'Cancel' and 'Save' buttons to the right */}
          <Button onClick={() => setOpenUnsavedChangesDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </div>
      </DialogActions>
      </Dialog>

                 {/* Character Dialog */}
        <Dialog open={isCharacterLimitExceeded} onClose={handleCharacterDialogClose}>
      <DialogTitle>Character Limit Exceeded</DialogTitle>
      <DialogContent>
        You have exceeded the character limit. Please shorten your input.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCharacterDialogClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>

    </div>
  )}

   