import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import Commentary from "../../../images/Commentary.jpg"
import {default as styles} from "../Styles"


export default function EffluentCommentary(effluent, farmDetails){
  const decodeHTMLEntities = (text) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  }
  function renderCommentaryWithImages(commentary, images) {
    // Define a regex pattern for the image markers
    const pattern = /effluentCommentary[^\s]+/g;
   
    // Split the commentary into segments based on the pattern
    let segments = [];
    let lastIndex = 0;
    let match;
    while ((match = pattern.exec(commentary)) !== null) {
        // Push the text segment before the image marker
        segments.push({ type: 'text', content: commentary.slice(lastIndex, match.index) });
        
        // Push the image segment
        segments.push({ type: 'image', key: match[0] });  // Change here: use match[0]
        
        lastIndex = match.index + match[0].length;
    }
    
    // Push any remaining text after the last image marker
    if (lastIndex < commentary.length) {
        segments.push({ type: 'text', content: commentary.slice(lastIndex) });
    }
    
    console.log(segments)
    // Render each segment as either text or image
    return segments.map((segment, index) => {
      if (segment.type === 'text') {
        return <Text key={index} style={[styles.commentarySection]}>{decodeHTMLEntities(segment.content)}</Text>;       
      } else {
          const imageData = images[segment.key];
          if (imageData) {
              return (
                <>
                  <div style={[styles.commentaryImageWrapper]}>
                    <Image key={index} src={imageData} style={{ width: "35%", height: "auto", marginBottom: 10 }} />
                    <Text key={`spacer-${index}`} style={{ height: 20, display:"block" }}>&nbsp;</Text>
                  </div>
                </>  
              );
          }
      }
  });
    // return (
    // <Text style={styles.commentary}>
    // {generalFarm.commentary}
    // </Text>
    // )
  }

    return(
      <Page wrap>        
        <div fixed style = {styles.commentaryHeaderImageBox}>
          <Image 
            src = {Commentary}
            style={{position: 'relative',
            display: 'flex',
            paddingBottom: 0,
            marginBottom: 0}} />      
          <div style={{position: 'absolute'}}>
            <Text style={styles.title}>The Commentary</Text>
            <Text style={styles.secondSubtitle}>Effluent Management</Text>  
          </div>
        </div>
        <View fixed style = {{height: 15}}></View>
        <div style={styles.wrapper}>
        {renderCommentaryWithImages(effluent.commentary, effluent.images)}  
        </div> 
        <View fixed style = {{marginTop: "30%"}}></View> 
        <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
      </Page>
    )
} 