import React from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import nitrogenBenchmark from "../../../images/nitrogenBenchmark.jpg"
import nitrogenGraphPlaceholder from "../../../images/nitrogenGraphPlaceholder.png"
import {default as styles} from "../Styles"


export default function NitrogenBenchmark(nitrogen, farmDetails){
  return(
    <Page>
   
      
      <div style = {{height: "18%"}} >
        <Image 
          src = {nitrogenBenchmark}/>
        <div style={{position: 'absolute'}}>
          <Text style={styles.title}>The Benchmark</Text>
          <Text style={styles.secondSubtitle}>Nutrient Management (Nitrogen)</Text>  
        </div>
      </div>

      {/* Placeholder for graph */}
      <div style={{ alignItems: "center", ...((nitrogen.graph === ""  || !nitrogen.graph) ? { marginTop: 50 } : {marginLeft: 30, position:"relative", border:"1px solid #fff" }) }}>
        <div style={{position:"absolute",top:23,left:23, width:1,height:201,borderLeft: "1px solid #d7d7d7"}}></div>
        <Image
          id='nitrogenGraph'
          src={(nitrogen.graph === ""  || !nitrogen.graph) ? nitrogenGraphPlaceholder : nitrogen.graph}
          alt="Nitrogen benchmark plot"
          style={{
            ...((nitrogen.graph === ""  || !nitrogen.graph) ? { width: 400, height: 150, marginRight: "5%", marginLeft:"5%"   } : { width: 530, height: 250, marginRight:0, marginLeft: 15})
          }}
        />
      </div>

       {nitrogen.benchmarkcommentary !== '' && (
                <div style = {{height: "30%", marginLeft:"5%",marginRight:"5%", marginTop: "10%"}}>
        <Text style ={{fontFamily:"Arial", fontSize: 14, color: '#002D4E'}}> 
         {nitrogen.benchmarkcommentary}
        </Text>
      </div>
        )}



    
     {/* <div style = {{height: "30%", marginLeft:"5%",marginRight:"5%", marginTop: "10%"}}>
        <Text style ={{fontFamily:"Arial", fontSize: 14, color: '#002D4E'}}> 
          With an average of {nitrogen.nitrogenFertiliser} kgN/ha applied per year and {nitrogen.importedFeed} 
          kgN/ha per year brought in via supplements the farm has an estimated nitrogen surplus of {nitrogen.surplus} kgN/ha per year.
        </Text>
      </div>*/}

      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
}
