import React from 'react';
import { Page, Text, Image, StyleSheet } from '@react-pdf/renderer';
import Thankyou from "../../images/Thankyou.jpg"


const styles = StyleSheet.create({
    endHeader: {
        height: "25%",
    },
    title: {
        fontSize: 40,
        textAlign: 'left',
        color: '#002D4E',
        marginLeft: 30,
        marginBottom: 80,
        marginTop: 100,
        fontFamily: 'Recoleta',
    },
    subtitle: {
        fontSize: 20,
        textAlign: 'left',
        color: '#002D4E',
        marginLeft: 30,
        marginTop: 450,
        fontFamily: 'RecoletaMedium',
    },
    text: {
        marginTop: 20,
        marginBottom: 8,
        marginLeft: "5%",
        marginRight: "5%",
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Arial',
        color: '#002D4E',
      },
})

export default function pdfEnding() {
    return(
      <Page>
        < div>
          <Image 
            src = {Thankyou}
            style={styles.styles} />
        </div>

        <div style={{position: 'absolute'}}>
            <Text style={styles.title}>Thank you</Text>
        </div>

        <div style={{position: 'absolute'}}>
            <Text style={styles.subtitle}>Disclaimer</Text>

            <Text style={styles.text}>
                Fonterra Australia Pty Ltd and its related bodies corporate (“we”, “us”) do not, and 
                cannot provide the recipient of this report (“you”) with financial or business advice. 
                While every effort has been made to ensure that the content of this report is accurate, 
                it is based on information and assumptions that you, your agents or third parties have 
                provided to us. We have not verified the information or assumptions. Where we have applied 
                our knowledge and understanding of current best practices, we have done so in good faith 
                but do not undertake to correct or keep the information up to date. You are ultimately 
                responsible for the environmental compliance of your farm. We do not give any warranty, 
                either express or implied, as to the accuracy, reliability or completeness of this report. 
                To the maximum extent permissible by law, we exclude all liability in contract or tort 
                (including negligence) or otherwise in relation to the report. We do not claim to replace
                professional advice and we strongly encourage you to seek independent professional advice. 
                Neither you nor a third-party should rely on this report to make an investment, business,
                or operational decision in relation to your farm or practices.
            </Text>
        </div>
      </Page>
    )
}
