import React from "react";
import Button from "@mui/material/Button";

const Back = ({ back, onClick }) => {
    return (
        <div className="btn-text-left">
            <Button
                style={{ backgroundColor: "#002D4E" }}
                id="Back"
                variant="contained"
                onClick={onClick} // Use the passed onClick handler
            >
                Back
            </Button>
        </div>
    );
};

export default Back;
