import React from 'react';
import { Page, Text, StyleSheet, Image } from '@react-pdf/renderer';
import ColostrumCover from "../../../images/ColostrumCover.png"
import {default as styles} from "../Styles"
import healthy_calves_header_icon from "../../../images/healthy_calves_header_icon.png"


const calfPathwayStyle = StyleSheet.create({
    header_text: {
        fontSize: 18,
        color: '#002D4E',
        fontFamily: 'Arial',
        marginTop: 10
    },
    header_number: {
        fontSize: 18,
        color: '#002D4E',
        fontFamily: 'Recoleta',
    }
})


export default function CalfPathwayHeader(healthyCalves, farmDetails){
    return(
        <Page>
            <div style ={{height:"45%"}} >
                <Image 
                    src = {ColostrumCover} />
            </div>
            <div style={{position: 'absolute'}}>
                <Text style={styles.title}>The Headline</Text>
                <Text style={styles.subtitle}>Animal Health and Welfare - Healthy Calves    (Calf Pathways) </Text>
            </div>    

            <div style = {{height: "45%", backgroundColor: "#F7F6FB", alignItems: "center"}}>  
                <Image 
                    src = {healthy_calves_header_icon} 
                    style = {{width: "18%", height: "30%", marginBottom: 20, marginTop: 30}}/>

                <Text style = {calfPathwayStyle.header_text}> Number of calves reared for replacements: 
                    <Text style = {calfPathwayStyle.header_number}> {healthyCalves.replacement1} </Text>
                </Text>
                <Text style = {calfPathwayStyle.header_text}> Number of calves reared for beef:  
                    <Text style = {calfPathwayStyle.header_number}> {healthyCalves.beef1} </Text>
                </Text>
                <Text style = {calfPathwayStyle.header_text}> Number of calves reared for live export:  
                    <Text style = {calfPathwayStyle.header_number}> {healthyCalves.export1} </Text>
                </Text>
                <Text style = {calfPathwayStyle.header_text}> Number of calves sold for off-farm rearing (beef or dairy): 
                    <Text style = {calfPathwayStyle.header_number}> {healthyCalves.rearing1} </Text>
                </Text>
                <Text style = {calfPathwayStyle.header_text}>
                  Number of calves euthanased at birth or processed as bobby
                </Text>
                <Text style = {calfPathwayStyle.header_text}>  calves 5-30 days old: 
                    <Text style = {calfPathwayStyle.header_number}> {healthyCalves.bobby1} </Text>
                </Text>
            </div> 
        
            <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
            <Text style={styles.pageNumber} render={({ pageNumber }) => (
            `${pageNumber}`
            )} fixed />
        </Page>
    )
}