import React from 'react';
import { Page, Text, Image, StyleSheet, View } from '@react-pdf/renderer';
import IrrigationCover from "../../../images/IrrigationCover.jpg"
import IrrigationIcons from "../../../images/IrrigationIcons.png"
import {default as styles} from "../Styles"


const IrrigationStyle = StyleSheet.create({
    boxes:{
      flexDirection: 'row',
      alignItems: "center",
      position: "absolute",
      marginLeft: "5%",
      marginTop: "5%",
    },   
    firstColumn:{
      width: "20%",
      paddingTop: 136,
      font: 'Arial',
      fontSize: 16,
      color:"#FFFFFF",
      textAlign: 'center',
    },
    secondColumn:{
      width: "45%",
      paddingTop: 136,
      font: 'Arial',
      fontSize: 16,
      color:"#FFFFFF",
      textAlign: 'center',
    },
    thirdColumn:{
      width: "34%",
      paddingTop: -30,
      font: 'Arial',
      fontSize: 35,
      color:"#FFFFFF",
      textAlign: 'center',
    },
    moduleIntroduction: {
        marginTop: '4%',
        marginLeft: '10%',
        marginRight: '10%',
        fontSize: 14,
        textAlign: 'justify',
        lineHeight:1.4,
        fontFamily: 'Arial',
        color: '#002D4E',
      },
      boxes1:{
        flexDirection: 'row',
        alignItems: "center",
        position: "absolute",
        marginLeft: "5%",
        marginTop: "5%",       
      },
      blank_box1:{    
        width: "7%",
        height:"25%",
        marginTop: 150,  
        marginLeft:115,   
        zIndex:2,   
        backgroundColor:"#DEEBF7", 
      },
      blank_box2:{  
        width: "7%",
        height:"25%",
        marginTop: 146,  
        marginLeft:140,   
        zIndex:2,     
        backgroundColor:"#DEEBF7"  
      },
})

function getPageNumber(pageNumber, irrigation, setIrrigation){
  if (pageNumber !== irrigation.irrigationPage) {
    setIrrigation(prevDetails => ({
          ...prevDetails,
          irrigationPage: pageNumber
      }));
  }
}

export default function IrrigationHeader(irrigation, farmDetails, setIrrigation){
    const formatwaterApplied = irrigation.waterApplied.toLocaleString('en-AU');
  return(
    <Page>
      <div style ={{height: "27%"}} >
        <Image 
          src = {IrrigationCover} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Headline</Text>
        <Text style={styles.subtitle}>Irrigation Management</Text>
      </div>    
        
      <div style = {{height: "38%", backgroundColor: "#FCF8EC"}}>  
        <div style = {{alignItems: "center"}}>
            <Text style={IrrigationStyle.moduleIntroduction}>
                Water is a precious resource. It is important that all users of water, the dairy industry 
                being a significant one, are responsible for ensuring the effective and efficient use of 
                the available water. 
            </Text>
            <Text style={IrrigationStyle.moduleIntroduction}>
                Efficient irrigation management supplies benefits such as saving energy, increasing 
                pasture yield, and maximizing return on investment. Expenditure invested each year to 
                irrigate pasture and crops include the costs of labour, nutrients, and maintenance of 
                the system, all of which can be significant. 
            </Text>
            <Text style={IrrigationStyle.moduleIntroduction}>
                Efficient irrigation water use will also aid in utilising a farm's water allocation more 
                effectively. It will ensure water run-off from the farm is minimalised, including associated 
                leaching of nutrients and other pollutants which could potentially effect ground and surface 
                water sources, therefore contributing to the protection of the environment long-term.
            </Text>
        </div>  
      </div> 

      <div style = {{height: "29%", backgroundColor: "#DEEBF7", alignItems: "center" }}> 

        <div style={{paddingTop: 0, height: "120%", width: "85%"}}> 
            <Image 
            src = {IrrigationIcons} />           
            <View style={IrrigationStyle.boxes1}>
                <div  style={IrrigationStyle.blank_box1}></div>
                <div  style={IrrigationStyle.blank_box2}></div>
            </View> 
            <View style={IrrigationStyle.boxes}>
                <Text style={IrrigationStyle.firstColumn} >{formatwaterApplied} </Text>
                <Text style={IrrigationStyle.secondColumn} >{irrigation.rainfall} </Text>
                <Text style={IrrigationStyle.thirdColumn} >{irrigation.yourGPWUI} </Text>
            </View> 
        </div>
        
      </div>
        
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
      `${pageNumber}` && getPageNumber(pageNumber, irrigation, setIrrigation)
      )} fixed />
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
      `${pageNumber}`
      )} fixed />
    </Page>
  )
}