import React from 'react';
import { Page, Text, Image, StyleSheet } from '@react-pdf/renderer';
import focus from "../../../images/Focus.jpg"
import { default as styles } from "../Styles"
import { Table, TableHeader, TableFooter } from '../../../components/Utilities'
import { CalculateScores, CalculateFocusArea, IconPicture } from '../../Utilities';


const focusStyle = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#002D4E',
    borderColor: '#000000',
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    height: 15,
    marginLeft: "5%",
    marginRight: "5%",
  },
})

export default function IrrigationTable(irrigation, farmDetails) {
  const borderHeight1 = [33, 33, 40, 33, 33, 33];
  const borderHeight2 = [33, 33, 33, 33, 33];
  const borderHeight3 = [33, 33, 33];
  CalculateScores(irrigation, irrigation.practices);
  CalculateFocusArea(irrigation, irrigation.focusColumn);
  IconPicture(irrigation.practices, irrigation.goodColumn, irrigation.improvementColumn, irrigation.innovativeColumn)

  const data = {
    module: "Irrigation Management",
    total:
      [irrigation.scores.innovative, irrigation.scores.good, irrigation.scores.improvement, irrigation.scores.focusArea],
    items: []
  }

  for (var i = 0; i < irrigation.questions.length; i += 1) {
    data.items.push({
      sr: 1,
      question: irrigation.questions[i] || "",
      innovative: irrigation.innovativeColumn[i] || 0,
      good: irrigation.goodColumn[i] || 0,
      areaOfImprovement: irrigation.improvementColumn[i] || 0,
      focusArea: irrigation.focusColumn[i] || "",
    })
  }

  console.log(data)

  //divide the content array to sections of questions
  const section1 = data.items.slice(0, 7)
  const section2 = data.items.slice(7, 12)
  const section3 = data.items.slice(12, 15)

  return (
    <Page size="A4" style={styles.page}>
      <div >
        <Image
          src={focus}
          style={styles.headerImage} />
      </div>

      <div style={{ position: 'absolute' }}>
        <Text style={styles.title}>The Focus</Text>
        <Text style={styles.secondSubtitle}>Irrigation Management</Text>
        {TableHeader(data)}
        {Table(section1, borderHeight1, 8)}

        {
          irrigation.selectedIrrigationType === "Flood Irrigation" && (
            <>
              <div style={focusStyle.container}>
                <Text style={[styles.headerText, { marginLeft: 10 }]} >Flood Irrigation</Text></div>
              {Table(section2, borderHeight2, 8)}
            </>
          )
        }

        {
          irrigation.selectedIrrigationType === "Spray Irrigation" && (
            <>
              <div style={focusStyle.container}>
                <Text style={[styles.headerText, { marginLeft: 10 }]} >Spray Irrigation</Text></div>
              {Table(section3, borderHeight3, 8)}
            </>
          )
        }
        {TableFooter(data.total, 30)}
      </div>

      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
} 