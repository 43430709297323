import React from 'react';
import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import nitrogenBenchmark from "../../../images/nitrogenBenchmark.jpg"
import {default as styles} from "../Styles"
import Icon from "../../../images/healthy_calves_benchmark_icon.png"
import healthyCalvesCalfPathwaysGraph1Placeholder from "../../../images/healthyCalvesCalfPathwaysGraph1Placeholder.png"
import healthyCalvesCalfPathwaysGraph2Placeholder from "../../../images/healthyCalvesCalfPathwaysGraph2Placeholder.png"

const focusStyle = StyleSheet.create({
    boxes:
    {
        flexDirection: 'row',
        alignContent: "center",        
    },
    first_column:{
        width: "37%",
    },
    second_column:{
        width: "10%",},
    third_column:{
        width: "45%",},
    graph_title:{
        fontFamily: 'Recoleta',
        fontSize: 20,
        color:'#002D4E',
    textAlign: 'center'},
    first_column2:{
        width: "30%",
    },
    second_column2:{
        width: "70%",
        textAlign: "left"},
    color_label:{
        marginLeft: "80%",
        width: "7%",
        height: "7%",      
        marginBottom: "6%"
    },
    text_label:{
        fontFamily: 'Calibri',
        fontSize: 12,
        padding:0,
        color:'#2C4C6D',
    },   
    leftblankBox1:{
        position: 'absolute',
        left:"26%", 
        width:"100%", 
        height:70, 
        backgroundColor:"#fff"
    },
    leftblankBox2:{
        position: 'absolute',
        left:"85%",
        top:70, 
        width:"40%", 
        height:220, 
        backgroundColor:"#fff"
    },
    rightblankBox1:{
        position: 'absolute',
        left:"23%", 
        width:"3%", 
        height:290, 
        backgroundColor:"#fff"
    },
    rightblankBox2:{
        position: 'absolute',
        left:"36%", 
        width:"80%", 
        height:70, 
        backgroundColor:"#fff"
    },
    rightblankBox3:{
        position: 'absolute',
        left:"101%", 
        top:35, 
        width:"10%", 
        height:240, 
        backgroundColor:"#fff"
    }
})


export default function CalfPathwayBenchMark(healthyCalves, farmDetails){
    return(
        <Page>            
            <div style ={{height: "18%"}} >
                <Image 
                    src = {nitrogenBenchmark}/>
                <div style={{position: 'absolute'}}>
                    <Text style={styles.title}>The Benchmark</Text>
                    <Text style={styles.secondSubtitle}>Animal Health and Welfare - Healthy Calves (Calf Pathways)</Text>  
                </div>
            </div>
 
            <div style ={{height: "5%", marginTop: "10%"}}>
                <View style = {focusStyle.boxes}>
                    <div style = {focusStyle.first_column}>
                        <Text style = {focusStyle.graph_title}>{farmDetails.currentYear}</Text> 
                    </div>
                    <div style = {focusStyle.second_column}>
                        <Image 
                            src={Icon}/>
                    </div>
                    <div style = {focusStyle.third_column}>
                        <Text style = {focusStyle.graph_title}>{farmDetails.futureYear}</Text> 
                    </div>
                </View>
            </div>
            <div style ={{height: "50%", marginRight:"5%"}}>
                <View style ={[focusStyle.boxes]}> 
                    <div style = {{width: "145%"}}> 
                        <Image
                            id='calfPathwaysGraph1'
                            src={(healthyCalves.calfPathways.graph1 === ""  || !healthyCalves.calfPathways.graph1) ? healthyCalvesCalfPathwaysGraph1Placeholder : healthyCalves.calfPathways.graph1}
                            alt = "calf Pathways benchmark plot"
                            style={{
                            width: "145%",
                            height: "77%",                                                  

                        }}/>
                        <div style={focusStyle.leftblankBox1}></div>
                        <div style={focusStyle.leftblankBox2}></div>
                    </div>
                    <div style = {{width: "145%"}}>
                        <Image
                            id='calfPathwaysGraph2'
                            src={(healthyCalves.calfPathways.graph2 === ""  || !healthyCalves.calfPathways.graph2) ? healthyCalvesCalfPathwaysGraph2Placeholder : healthyCalves.calfPathways.graph2}
                            alt = "calf Pathways benchmark plot"
                            style={{
                            width: "145%",
                            height: "77%",                
                        }}/>
                        <div style={focusStyle.rightblankBox1}></div>
                        <div style={focusStyle.rightblankBox2}></div>
                        <div style={focusStyle.rightblankBox3}></div>
                    </div>
                </View>
            </div>

            <div style ={{height: "30%", marginTop: "-20%"}}>
                <View style = {{flexDirection: 'row'}}>
                <div style = {focusStyle.first_column2}>
                    <div style = {[focusStyle.color_label, {backgroundColor:"#2C4C6D"}]}> </div>
                    <div style = {[focusStyle.color_label, {backgroundColor:"#66B7E1"}]}> </div>
                    <div style = {[focusStyle.color_label, {backgroundColor:"#ED7D31"}]}> </div>
                    <div style = {[focusStyle.color_label, {backgroundColor:"#5FB33A"}]}> </div>
                      <div style = {[focusStyle.color_label, {backgroundColor:"#FFC000"}]}> </div>
                    <div style = {[focusStyle.color_label, {backgroundColor:"#6864FC"}]}> </div>
                </div>
                <div style = {[focusStyle.second_column2, focusStyle.text_label]}>
                        <Text style = {{marginBottom: 8}}> Number of calves reared for replacements </Text>
                        <Text style = {{marginBottom: 8}}> Number of calves reared for beef </Text>
                        <Text style = {{marginBottom: 8}}> Number of calves reared for live export </Text>
                         <Text style = {{marginBottom: 8}}> Number calves sold for off-farm rearing (beef or dairy) </Text>
                        <Text style = {{marginBottom: 8}}> Number of non-replacement calves euthanised </Text>
                        <Text style = {{marginBottom: 8}}> Number of calves processed as bobby calves 5-30 days old </Text>
                    </div>
                </View>
            </div>

            <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
            <Text style={styles.pageNumber} render={({ pageNumber }) => (
            `${pageNumber}`
            )} fixed />
      </Page>
    )
}
