import React from 'react';
import { Page, Text, Image, StyleSheet } from '@react-pdf/renderer';
import effluentCover from "../../../images/Effluent.jpg"
import {default as styles} from "../Styles"


const EffluentStyle = StyleSheet.create({
  header_image: {
      marginTop:0,
      marginVertical: 0,
      marginHorizontal: 0,
      padding: 0,
      height: "70%"
    },
})

function getPageNumber(pageNumber, effluent, setEffluent){
  if (pageNumber !== effluent.effluentPage) {
    setEffluent(prevDetails => ({
          ...prevDetails,
          effluentPage: pageNumber
      }));
  }
}

export default function EffluentHeader(effluent, farmDetails, setEffluent){
  return(
    <Page style={{backgroundColor: '#FCF8EC'}}>
      <div >
        <Image 
        src = {effluentCover}
        style={EffluentStyle.header_image} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Headline</Text>
        <Text style={styles.subtitle}>Effluent Management</Text>               
        <Text style={[styles.effluentHeaderIntro, {marginTop: 315}]}>
          This module looks into the management of effluent in the farm against industry best 
          practice in effluent management; thereby creating minimal environmental impact.
          Farms can achieve this by:
        </Text>
        <Text style={[styles.effluentHeaderIntro, {marginTop: 10}]}>
          • 	Design and management of your system to suit your site-specific requirements 
          e.g. labour, access to contractors, topography, soils and maintenance ability. 
        </Text>
        <Text style={[styles.effluentHeaderIntro, {marginTop: 10}]}>
          • 	Contain everything from controlled areas such as sheds, yards, feed pads and other 
          areas where stock are held for extended periods of time, and effectively convey it to an 	
          appropriate management system. 
        </Text>
        <Text style={[styles.effluentHeaderIntro, {marginTop: 10}]}>
          • 	Manage your system in a way that the nutrients can be reused to grow pasture or crops.
        </Text>
        <Text style={[styles.effluentHeaderIntro, {marginTop: 10}]}>
          • 	Prevent the pollution of groundwater, surface water, soils; or unacceptable amenity impacts 
          on neighbouring land uses. 
        </Text>
        <Text style={[styles.effluentHeaderIntro, {marginTop: 10}]}>
          • 	Review and modify your system when farm circumstances change e.g. herd expansion, 
          feed pad added, hose wash to flood wash.
        </Text>
        <Text style={[styles.effluentHeaderIntro, {marginTop: 10}]}>
          • 	Manage application rates and withhold periods. Ensuring there's an understanding of 
          maximum applications and required withhold periods.
        </Text>
      </div>

      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
      `${pageNumber}` && getPageNumber(pageNumber, effluent, setEffluent)
      )} fixed />
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
      `${pageNumber}`
      )} fixed />
    </Page>
  )
} 
