import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import React, { Fragment } from 'react';
import {default as styles} from "./pdf/Styles.jsx"
import { AppRunTimeContext } from '../Context/index'; 
import { useContext } from 'react';
import tickIcon from "../images/tickIcon.png"


const borderColor = '#000000';

const UtilitiesStyle = StyleSheet.create({
  headerQuestion:{
    width: "48%",
    borderColor: borderColor,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    height: 48,
    paddingVertical: 10,
  },
  headerInnovative:{
    width: "13%",
    borderColor: borderColor,
    borderRightWidth: 2,
    height: 48,
    paddingVertical: 10
  },
  headerGood:{
    width: "13%",
    borderColor: borderColor,
    borderRightWidth: 2,
    height: 48,
    paddingVertical: 10
  },
  headerAreaOfImprovement:{
    width: "13%",
    borderColor: borderColor,
    borderRightWidth: 2,
    height: 48,
    paddingVertical: 10
  },
  headerFocusArea:{
    width: "14.8%",
    borderColor: borderColor,
    height: 48,
    paddingVertical: 5
  },
  question: {
    width: "48%",
    textAlign: 'justify',
    color: "#002D4E",
    borderColor: borderColor,
    borderLeftColor: borderColor,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    padding: 5,
    display: 'flex',    
    justifyContent:'center',
  },
  innovative: {
    borderColor: borderColor,
    backgroundColor: "#76B82A",
    borderRightWidth: 2,
    padding: 5,
    position: 'relative',
    width: '13%',
    display: 'block',
    alignItems: 'center'
  },
  good: {
    borderColor: borderColor,
    backgroundColor:"#FFC845",
    borderRightWidth: 2,
    padding: 5,
    position: 'relative',
    width: '13%',
    display: 'block',
    alignItems: "center"
  },
  areaOfImprovement: {
    borderColor: borderColor,
    backgroundColor: "#E57200",
    borderRightWidth: 2,
    padding: 5,
    position: 'relative',
    width: '13%',
    display: 'block',
    alignItems: "center"
  },
  focusArea: {
    width: "15%",
    borderColor: borderColor,
    backgroundColor:"#EBF4F9",
    borderRightWidth: 2,
    paddingTop: 5,
    paddingBottom: 5,
    position: 'relative',
    display: 'block',
    alignItems: "center"
  },
  tickIcon: {
    width: 24, // Adjust size as needed
    height: 24, // Adjust size as needed
    borderColor: '#000',
    borderWidth: 1.5,
    borderRadius: 13,
    position: 'relative',
  },
  checkMark: {
    position: 'absolute',
    transform: 'rotate(50deg) translate(-50%, -50%)',
    top:73,
    left: 2,    
    height: 14,
    width: 7,
    borderBottom: '2px solid #000',
    borderRight: '2px solid #000',    
  },
  blankIcon: {
    width: 24, // Adjust size as needed
    height: 24, // Adjust size as needed   
    borderColor: 'transparent', 
    borderWidth: 0,
    borderRadius: 13,
    position: 'relative',
  },
})

var innovative, improvement

// List the selection of practices possible
export const practices = [
    {
      value: 'Innovative Practice',
      label: 'Innovative Practice',
    },
    {
      value: 'Good Practice',
      label: 'Good Practice',
    },
    {
      value: 'Area of Improvement',
      label: 'Area of Improvement',
    },
];

// List the choices of want improvement option
export const wantImprovement = [
    {
      value: 'Yes',
      label: 'Yes',
    },
    {
      value: 'No',
      label: 'No',
    }
];

// Add score to a question in the array
export function addScore(ScoresArray, questionKey, value){
    var questNumber = questionKey.slice(3, 5)
    questNumber = parseInt(questNumber)
    ScoresArray[questNumber-1] += parseInt(value)
    return ScoresArray
}
  
// Deduct score to a question in the array
export function subtractScore(ScoresArray, questionKey, value){
    var questNumber = questionKey.slice(3, 5)
    questNumber = parseInt(questNumber)
    ScoresArray[questNumber-1] -= parseInt(value)
    return ScoresArray
  }
  
// Get practice suggestion based on question score
export function GetSuggestion(value){
    const {innovativeScore, improvementScore} = useContext(AppRunTimeContext)
    innovative = innovativeScore
    improvement = improvementScore
    if (value >= innovative)
      return 'Innovative Practice'
    else if (value < improvement)
      return 'Area of Improvement'
    else
      return 'Good Practice'
}

// Return module name choice for table of content
export function GetModuleName(module, generalFarm, nitrogen, carbon, effluent, healthyCalves, irrigation, biosecurity){

  var string;
  if (module === "/GeneralFarm"){
    string = "General Farm Management ........................................................................ "
    return string.concat(generalFarm.generalPage)
  }
  else if (module === "/Nitrogen"){
    string = "Nutrient Management (Nitrogen) ................................................................. "
    return string.concat(nitrogen.nitrogenPage)
  }
  else if (module === "/Effluent"){
    string = "Effluent Management ................................................................................... "
    return string.concat(effluent.effluentPage)
  }
  else if (module === "/Carbon"){
    string = "Greenhouse Gas Emissions ........................................................................ "
    return string.concat(carbon.carbonPage)
  }
  else if (module === "/HealthyCalves"){
    string = "Animal Health and Welfare (Healthy Calves) ............................................... "
    return string.concat(healthyCalves.healthyCalvesPage)
  }
    else  if (module === "/Irrigation"){ 
    string = "Irrigation Management ................................................................................ " 
    return string.concat(irrigation.irrigationPage)
  }
    else if (module === "/Biosecurity"){ 
    string = "Farm Biosecurity ......................................................................................... " 
    return string.concat(biosecurity.biosecurityPage)
  }

}

export function ListModules(modules, generalFarm, nitrogen, carbon, effluent, healthyCalves, irrigation, biosecurity){
  return(
    modules.map((moduleName,i) => (
      displayModule(GetModuleName(moduleName, generalFarm, nitrogen, carbon, effluent, healthyCalves, irrigation, biosecurity))
    )))
}

// Display module in table of content in report template
export function displayModule(moduleName){
  return (
    <View>
      <Text style={styles.tableOfContents}>
        {moduleName}
      </Text>
     </View>
  )
}

// Creates a grading table in the report for the questions and chosen practices
//borderHeight is set individually for each module as they have different number of questions with different lengths
//The minimum borderHeight should be 35
export function Table(items, borderHeight, fontSize) {
  {console.log(items)}
  return(
    items.map((item,k) => 
        <View style={styles.tableContainer} >
        <Fragment>
          <View  key = {k} style={[styles.row,{height: borderHeight[k]+2}]}>
          <div style={[UtilitiesStyle.question,{height: borderHeight[k]}]}>          
            <Text style = {{display: 'flex', justifyContent:'center',alignItems:'center', fontFamily: 'Arial', fontSize: fontSize, minHeight: k === items.length - 1 ? '24px' : ''}}  >{item.question}</Text>
            </div>
           
            <div style={[UtilitiesStyle.innovative,{height: borderHeight[k], display:'flex', justifyContent:'center', alignItems:'center'}]}>
              {item.innovative === 0 ? (<View style={UtilitiesStyle.blankIcon}></View>) :               
               (<View style={UtilitiesStyle.tickIcon}><View style={UtilitiesStyle.checkMark}></View></View>)}
            </div>
            <div style={[UtilitiesStyle.good,{height: borderHeight[k], display:'flex', justifyContent:'center', alignItems:'center'}]}>
              {item.good === 0 ? (<View style={UtilitiesStyle.blankIcon}></View>) :               
              (<View style={UtilitiesStyle.tickIcon}><View style={UtilitiesStyle.checkMark}></View></View>)}
            </div>
            <div style={[UtilitiesStyle.areaOfImprovement,{height: borderHeight[k], display:'flex', justifyContent:'center', alignItems:'center'}]}>
              {item.areaOfImprovement === 0 ? (<View style={UtilitiesStyle.blankIcon}></View>) :               
              (<View style={UtilitiesStyle.tickIcon}><View style={UtilitiesStyle.checkMark}></View></View>)}
            </div> 
            <div style={[UtilitiesStyle.focusArea, {height: borderHeight[k], display:'flex', justifyContent:'center', alignItems:'center'}]}>
              {item.focusArea === 0 || item.focusArea === "" ? (<View style={UtilitiesStyle.blankIcon}></View>) :               
              (<View style={UtilitiesStyle.tickIcon}><View style={UtilitiesStyle.checkMark}></View></View>)}
            </div>  
          </View> 
        </Fragment>
      </View>
  ))
}

// Create the table header for the practice grading table 
export function TableHeader(array){
  return(
    <View style={[styles.headerContainer, styles.headerText ]} >
      <div style={styles.headerQuestion} >
      <Text >{array.module}</Text>
      </div>
      <div style={UtilitiesStyle.headerInnovative}>
      <Text>Innovative Practice</Text>
      </div>
      <div style={UtilitiesStyle.headerGood}>
      <Text>Good Practice</Text>
      </div>
      <div style={UtilitiesStyle.headerAreaOfImprovement} >
      <Text >Area of </Text>
      <Text> Improvement</Text>        
      </div>
      <div style={UtilitiesStyle.headerFocusArea} >
      <Text >Is this a focus area you want support with?</Text>
      </div> 
    </View> 
)}

//Table footer for the practice grading system. 
//the array is calculated by function CalculateScores
export function TableFooter(array, borderHeight){
  return(
  <View style={[styles.row,styles.footerText, {height: borderHeight+2, display:"flex",justifyContent:"center", alignItems:"center"}]} >
    <Text style={[UtilitiesStyle.question, {height: borderHeight, display:"flex",justifyContent:"center", alignItems:"center"}]} >Total</Text>
    <Text style={[UtilitiesStyle.innovative, {height: borderHeight, display:"flex",justifyContent:"center", alignItems:"center"}]} >{array[0]}</Text>
    <Text style={[UtilitiesStyle.good, {height: borderHeight, display:"flex",justifyContent:"center", alignItems:"center"}]} >{array[1]}</Text>
    <Text style={[UtilitiesStyle.areaOfImprovement, {height: borderHeight, display:"flex",justifyContent:"center", alignItems:"center"}]} >{array[2]}</Text>
    <Text style={[UtilitiesStyle.focusArea, {height: borderHeight, display:"flex",justifyContent:"center", alignItems:"center"}]} >{array[3]}</Text>
  </View> 
)}


// Calculate number of innovative, good and area of improvement practices
export function CalculateScores(module, modPractice){
  const practices = modPractice
  var innov = 0, good = 0, improv = 0

  for (var i = 0; i < practices.length; i += 1){
    if (practices[i] === "Innovative Practice")
      innov += 1
    else if (practices[i] === "Area of Improvement")
      improv += 1
    else if (practices[i] === "Good Practice")
      good += 1
  }
  module.scores.innovative = innov
  module.scores.good = good
  module.scores.improvement = improv
  
}

//Calculate and show ticks for areas that need support
export function CalculateFocusArea(moduleName, focusColumn){
  const focusArea = moduleName.focusArea
  var score = 0
  for (var i = 0; i < focusArea.length; i += 1){
    if (focusArea[i] === "Yes"){
      score += 1
      focusColumn[i] = tickIcon
    }
    //else focusColumn[i] = focusTableColor

  }
  moduleName.scores.focusArea = score
}


// Show ticks for practices
export function IconPicture(practices,goodColumn, improvementColumn, innovativeColumn){
  for (var i = 0; i < practices.length; i += 1){
    if (practices[i] === "Good Practice"){
    goodColumn[i] = tickIcon
  }
    else if (practices[i] === "Area of Improvement"){
    improvementColumn[i] = tickIcon   
  }
    else if (practices[i] === "Innovative Practice"){
    innovativeColumn[i] = tickIcon
    }  
  }
}
