import React  from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import focus from "../../../images/Focus.jpg"
import {default as styles} from "../Styles"
import { Table, TableHeader,TableFooter  } from '../../../components/Utilities'
import { CalculateScores, CalculateFocusArea , IconPicture} from '../../Utilities'; 


export default function BiosecurityTable(biosecurity, farmDetails){
  const borderHeight = [65,55,65,45,45,65,45,45,45];
  CalculateScores(biosecurity, biosecurity.practices);
  CalculateFocusArea(biosecurity, biosecurity.focusColumn);
  IconPicture(biosecurity.practices,biosecurity.goodColumn, biosecurity.improvementColumn, biosecurity.innovativeColumn)
  const data = {
      module: "Biosecurity",
      total:
      [biosecurity.scores.innovative, biosecurity.scores.good, biosecurity.scores.improvement, biosecurity.scores.focusArea],
      items: []}
      for (var i = 0; i < biosecurity.questions.length; i += 1){
        data.items.push({
          sr: 1,
          question: biosecurity.questions[i] || "",
          innovative: biosecurity.innovativeColumn[i] || 0,
          good: biosecurity.goodColumn[i] || 0,
          areaOfImprovement: biosecurity.improvementColumn[i] || 0,
          focusArea: biosecurity.focusColumn[i] || "",
        },)
      }
      
  return(
    <Page size="A4" style={styles.page}>
      <div >
        <Image 
        src = {focus}
        style={styles.headerImage} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Focus</Text>
        <Text style={styles.secondSubtitle}>Farm Biosecurity</Text>  
        {TableHeader(data)}
        {Table(data.items,borderHeight, 9)}
        {TableFooter(data.total, 30)}
      </div>
      
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
} 