import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import Button from "@mui/material/Button";
import Papa from "papaparse";
import { useDropzone } from 'react-dropzone';
import Plot from 'react-plotly.js';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import * as  htmlToImage from 'html-to-image';
import { AppRunTimeContext } from '../Context/index';

//Package for processing CSV file: https://react-papaparse.js.org/?_sm_au_=iVVpp7QSL424v7NV4F1NkKH7sGcLG
//Package for plot: https://plotly.com/javascript/box-plots/


export const BoxplotNitrogen = ({ dataimport = [], onImageGenerated, runCalculations }) => {
  const [parsedCsvData, setParsedCsvData] = useState([]); //set processed data from csv file
  const [plot1, setplot1] =  useState([]); //napply
  const [plot2, setplot2] =  useState([]); //nsur
  const [plot3, setplot3] =  useState([]); //nres
  const [isshow, setisshow] = useState(false); //show plot
  const [triggerImageGeneration, setTriggerImageGeneration] = useState(false);

  const {farmDetails, setFarmDetails, nitrogen, setNitrogen} = useContext(AppRunTimeContext);
  useEffect(() => {
    if (isshow) {
        new Promise(resolve => setTimeout(() => resolve(htmlToImage.toPng(graphRef.current, { width: 980, height: 500 })), 1000))
            .then((dataUrl) => {
                onImageGenerated(dataUrl);  // Send the data URL to the parent component
            })
            .catch((error) => {
                console.error('Error converting to image:', error);
            });
    }
  }, [triggerImageGeneration, isshow]);


  const graphRef = useRef(null);

  const load = function() {
    // First, run the Calculations function
    runCalculations();

    // Toggle the triggerImageGeneration state
    setTriggerImageGeneration(prev => !prev)
    
    // Then fetch and process the CSV
    fetch('./NmoduleTas.csv')
      .then(response => response.text())
      .then(responseText => {
        setisshow(true);
        parseFile(responseText);
      });
};

  //function to  get csv file data 
  const parseFile = file => {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: results => {
          setParsedCsvData(results.data)
        },
    });
  };

  
  useEffect(() => { 
    processData();
    return () => {}
  }, [parsedCsvData])



  //function to process data to separate columns
  function processData() {
    var temp = Object.values(parsedCsvData);
    var nsur = [];
    var nres = [];
    var napply = [];
    
    temp.map((parsedData, index) => (
      nsur.push(parsedData.NSUR),
      nres.push(parsedData.NRES),
      napply.push(parsedData.Napplied)
    ))
    
    setplot1(napply);
    setplot2(nsur);
    setplot3(nres);
  }


  return (
    <><>
    <div>
      <h4>Nitrogen Benchmark Box Plot</h4>
    </div>

    <div className="btn-save">
    <Button variant="contained"  style={{ backgroundColor:"#002D4E"}} onClick={ load }>Calculate</Button>
    </div>

    </>
    {/*Plot */}
    { isshow &&
    <div ref={graphRef}>
    <Box sx={{ mt: 6, flexGrow: 1 }}>
      <Grid container direction="row"  spacing={1}>
        <Grid item xs={-1}>
          <Plot
            data = {[
              {name: '', 
              marker: {color: '#76B82A'}, 
              type: 'box', 
              y: plot1, 
              boxpoints: false},
            ]}
            layout={{
              width: 300, 
              height: 400, 
              title: 'kg N applied per hectare', 
              yaxis: {title: 'kg N/ha'}, 
              color: 'rgba(114,191,68,0.1)',
              hovermode: false,
              annotations: [{x: 0, y: dataimport[0], text: 'Your Value', arrowhead: 7, textposition: 'bottom'}]
            }}
            config={{ displayModeBar: false }}
          />
        </Grid>

        <Grid item xs={-1}>
          <Plot
            data = {[
              {name: '', 
              marker: {color: '#76B82A'}, 
              type: 'box', 
              y: plot2, 
              boxpoints: false},  
            ]}
            layout={{
              width: 300, 
              height: 400, 
              title: 'Nitrogen surplus', 
              yaxis: {title: 'kg N/ha'},
              color: 'rgba(114,191,68,0.1)',
              hovermode: false,
              annotations: [{x: 0, y: dataimport[1], text: 'Your Value', arrowhead: 7, textposition: 'bottom'}]
            }}
            config={{ displayModeBar: false }}
          />
        </Grid>

        <Grid item xs={-1}>
          <Plot
            data = {[
              {name: '', marker: {color: '#76B82A'}, 
              type: 'box', 
              y: plot3, 
              boxpoints: false},
            ]}
            layout={{width: 300, 
              height: 400, 
              title: 'kg N applied per t DM consumed', 
              yaxis: {title: 'kg N/t DM'},
              color: 'rgba(114,191,68,0.1)',
              hovermode: false,
              annotations: [{x: 0, y: dataimport[2], text: 'Your Value', arrowhead: 7, textposition: 'bottom'}]
            }}
            config={{ displayModeBar: false }}
          />
        </Grid>

      </Grid>
    </Box>
    </div>
    }
    </>
  );
} 
  
export default BoxplotNitrogen; 
        

