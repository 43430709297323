import React  from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import focus from "../../../images/Focus.jpg"
import {default as styles} from "../Styles"
import { Table, TableHeader,TableFooter  } from '../../../components/Utilities'
import { CalculateScores, CalculateFocusArea , IconPicture} from '../../Utilities'; 


export default function GfTable(generalFarm, farmDetails){
  const borderHeight = [40,40,40,40,40,40,40,40,40,40,40,40];
  CalculateScores(generalFarm, generalFarm.practices);
  CalculateFocusArea(generalFarm, generalFarm.focusColumn);
  IconPicture(generalFarm.practices,generalFarm.goodColumn, generalFarm.improvementColumn, generalFarm.innovativeColumn)
  const data = {
    module: "General Farm Management",
    total:
    [generalFarm.scores.innovative, generalFarm.scores.good, generalFarm.scores.improvement, generalFarm.scores.focusArea],
    items: []}
    for (var i = 0; i < generalFarm.questions.length; i += 1){
      data.items.push({
        sr: 1,
        question: generalFarm.questions[i] || "",
        innovative: generalFarm.innovativeColumn[i] || 0,
        good: generalFarm.goodColumn[i] || 0,
        areaOfImprovement: generalFarm.improvementColumn[i] || 0,
        focusArea: generalFarm.focusColumn[i] || "",
      },)
    }
  return(
    <Page size="A4" style={styles.page}>
      {console.log(generalFarm)}
      <div >
        <Image 
        src = {focus}
        style={styles.headerImage} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Focus</Text>
        <Text style={styles.secondSubtitle}>General Farm Management</Text>  
        {TableHeader(data)}
        {Table(data.items, borderHeight, 10)}
        {TableFooter(data.total, 30)}
      </div>
      
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
} 