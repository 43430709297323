import React  from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import focus from "../../../images/Focus.jpg"
import {default as styles} from "../Styles"
import { Table, TableHeader,TableFooter  } from '../../../components/Utilities'
import { CalculateScores, CalculateFocusArea , IconPicture} from '../../Utilities'; 


export default function EffluentTable(effluent, farmDetails){
  const borderHeight = [35,35,35,40,35,40,35,45,35,50,45,40,40];
  CalculateScores(effluent, effluent.practices);
  CalculateFocusArea(effluent, effluent.focusColumn);
  IconPicture(effluent.practices,effluent.goodColumn, effluent.improvementColumn, effluent.innovativeColumn)
  const data = {
      module: "Effluent Management",
      total:
      [effluent.scores.innovative, effluent.scores.good, effluent.scores.improvement, effluent.scores.focusArea],
      items: []}
      for (var i = 0; i < effluent.questions.length; i += 1){
        data.items.push({
          sr: 1,
          question: effluent.questions[i] || "",
          innovative: effluent.innovativeColumn[i] || 0,
          good: effluent.goodColumn[i] || 0,
          areaOfImprovement: effluent.improvementColumn[i] || 0,
          focusArea: effluent.focusColumn[i] || "",
        },)
      }
    
  return(
    <Page size="A4" style={styles.page}>
      <div >
        <Image 
        src = {focus}
        style={styles.headerImage} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Focus</Text>
        <Text style={styles.secondSubtitle}>Effluent Management</Text>  
        {TableHeader(data)}
        {Table(data.items,borderHeight,8)}
        {TableFooter(data.total, 30)}
      </div>
      
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
} 