import React from 'react';
import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import nitrogenCover from "../../../images/nitrogen.jpg"
import nitrogenIcons from "../../../images/nitrogenIcons.png"
import {default as styles} from "../Styles"


const NitrogenStyle = StyleSheet.create({
  boxes:{
    flexDirection: 'row',
    alignItems: "center",
    position: "absolute",
    marginLeft: "5%",
    marginTop: "5%",
  },
  firstColumn:{
    width: "20%",
    paddingTop: 90,
    font: 'Arial',
    fontSize: 16,
    color:"#FFFFFF",
    textAlign: 'center',
  },
  secondColumn:{
    width: "30%",
    paddingTop: 90,
    font: 'Arial',
    fontSize: 16,
    color:"#FFFFFF",
    textAlign: 'center',
  },
  thirdColumn:{
    width: "20%",
    paddingTop: 90,
    font: 'Arial',
    fontSize: 16,
    color:"#FFFFFF",
    textAlign: 'center',
  },
  fourthColumn:{
    width: "30%",
    paddingTop: 90,
    font: 'Arial',
    fontSize: 16,
    color:"#FFFFFF",
    textAlign: 'center',
  },
  nitrogen_icons: {
    paddingTop: 0,
    height: "25%",
  },   
})

function getPageNumber(pageNumber, nitrogen, setNitrogen){
  if (pageNumber !== nitrogen.nitrogenPage) {
      setNitrogen(prevDetails => ({
          ...prevDetails,
          nitrogenPage: pageNumber
      }));
  }
}

export default function NitrogenHeader(nitrogen, farmDetails, setNitrogen){

   const formatFertiliser = nitrogen.nitrogenFertiliser.toLocaleString('en-AU');
   console.log(formatFertiliser);
   const formatimportedFeed = nitrogen.importedFeed.toLocaleString('en-AU');
   const formatsurplus = nitrogen.surplus.toLocaleString('en-AU');
   const formatexportedPro= nitrogen.exportedPro.toLocaleString('en-AU');


  return(
    <Page>
      <div style ={{height: "47%"}} >
        <Image 
          src = {nitrogenCover} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>The Headline</Text>
        <Text style={styles.subtitle}>Nutrient Management (Nitrogen)</Text>
      </div>    
        
      <div style = {{height: "18%", backgroundColor: "#FCF8EC"}}>  
        <div style = {{alignItems: "center"}}>
          <Text style={styles.moduleIntroduction2}>
            With an average of {formatFertiliser} kgN/ha applied per year and {formatimportedFeed} 
             kgN/ha per year brought in via supplements the farm has an estimated nitrogen surplus of {formatsurplus} kgN/ha per year.
          </Text>
        </div>  
      </div> 

      <div style={NitrogenStyle.nitrogen_icons}> 
        <Image 
          src = {nitrogenIcons} />
        <View style={NitrogenStyle.boxes}>
          <Text style={NitrogenStyle.firstColumn} >{formatFertiliser} kgN/ha</Text>
          <Text style={NitrogenStyle.secondColumn} >{formatimportedFeed} kgN/ha</Text>
          <Text style={NitrogenStyle.thirdColumn} >{formatexportedPro} kgN/ha</Text>
          <Text style={NitrogenStyle.fourthColumn} >{formatsurplus} kgN/ha</Text>
        </View> 
      </div>
        
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
      `${pageNumber}` && getPageNumber(pageNumber, nitrogen, setNitrogen)
      )} fixed />
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
      `${pageNumber}`
      )} fixed />
    </Page>
  )
}