import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import StepButton from '@mui/material/StepButton';
import StepIcon from '@mui/material/StepIcon';
import Button from '@mui/material/Button';
import useModuleLink from '../hooks/moduleLink';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { AppRunTimeContext } from '../Context/index'; 
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


//logic: fix styling, 
//make font biugger, make rectangles same size increase height vertically





  export default function ProgressBar({ currentStep = -1, checkUnsavedChanges, setOpenUnsavedChangesDialog, setUnsavedChangesNavigationPath }) {
    const { farmDetails} = useContext(AppRunTimeContext);
   const [openDialog, setOpenDialog] = React.useState(false);
   const { modules,activeStep, setActiveStep, steps, setSteps } = useContext(AppRunTimeContext);
   const navigate = useNavigate(); // Use this line to get access to the navigate function

     const moduleLabels = {
          '/GeneralFarm': 'General Farm Management',
          '/Nitrogen': 'Nutrient Management (Nitrogen)',
          '/Effluent': 'Effluent Management',
          '/Carbon': 'Greenhouse Gas Emissions',
          '/HealthyCalves': 'Animal Health and Welfare (Healthy Calves)',
          '/Irrigation': 'Irrigation Management',
          '/Biosecurity': 'Farm Biosecurity'
   };

     // Function to check if all questions are answered for a given module
     const areAllQuestionsAnswered = (moduleName) => {
      const moduleData = farmDetails?.surveyData?.[moduleName];
      
      if (!moduleData) {
          // If the module data is not found, assume not all questions are answered
          return false;
      }
  
      // Check mandatory fields if they exist
      const mandatoryFields = ['commentary', 'focus', 'action', 'riskAssessment'];
      for (let field of mandatoryFields) {
          if (moduleData.hasOwnProperty(field) && !moduleData[field]) {
              // If the field exists but is empty or invalid, return false
              return false;
          }
      }
  
      // Additional handling for the 'HealthyCalves' module
      if (moduleName === 'HealthyCalves') {
          const allColostrumQuestionsAnswered = moduleData.colostrum?.questions?.every((question) => 
              question.notApplicable || (question.practice && question.focusCheck)
          );
  
          const allCalfQuestionsAnswered = moduleData.calfPathways?.questions?.every((question) => 
              question.notApplicable || (question.practice && question.focusCheck)
          );
  
          return allColostrumQuestionsAnswered && allCalfQuestionsAnswered;
      }
  
      // Check if questions array exists and every question is answered
      if (moduleData.questions && !moduleData.questions.every(question => 
          question.notApplicable || (question.practice && question.focusCheck))
      ) {
          return false;
      }
  
      // If all checks pass, return true
      return true;
  };
  
  

     console.log(currentStep);
    
   const handleDialogClose = () => {
        setOpenDialog(false);
    };

   useEffect(() => {
    // Determine if updates are needed
    let updatesNeeded = false;
    for (let i = 0; i < modules.length; i++) {
      const module = modules[i];
      if (!steps.some(step => step.link === module)) {
        updatesNeeded = true;
        break;
      }
    }
  
    // Update steps only if updates are needed
    if (updatesNeeded) {
      const newSteps = [...steps];
  
      for (let i = 0; i < modules.length; i++) {
        const module = modules[i];
        const moduleAlreadyExists = newSteps.some(step => step.link === module);
  
        if (!moduleAlreadyExists) {
          newSteps.push({
            label: moduleLabels[module],
            link: module
          });
        }
      }
  
      setSteps(newSteps);
    }
  }, [modules, moduleLabels]); // Remove steps from dependency array
  

    useEffect(() => {
      if (currentStep > -1) {
        setActiveStep(currentStep);
      }
      if ((currentStep === 2) && (!(farmDetails.supplier_no) && !(farmDetails.farmRegion) && !(farmDetails.farm_owner) && !(farmDetails.plan_owner) && !(farmDetails.financialYear))) {
      
            setOpenDialog(true);
            setActiveStep(1);
            setTimeout(() => {
              window.location.href = '/FarmDetails';
            }, 2000);
            
            }


      
    }, [currentStep]);


  //console.log(steps);


    //console.log(modules);
    //console.log(activeStep);

  const [completed, setCompleted] = React.useState({});
 


  const totalSteps = () => {
    return steps.length;
  };




  const handleStep = (step, link) => () => {
    if (checkUnsavedChanges && checkUnsavedChanges()) {
      // Store the intended navigation path and prompt the dialog
      setUnsavedChangesNavigationPath(link);
      setOpenUnsavedChangesDialog(true);
    } else {
      // No unsaved changes, so update the active step and navigate
      setActiveStep(step);
      navigate(link);
    }
  }

  


  return (




    
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {console.log(steps)}
        {steps.map((step, index) => (
          <Step key={step} completed={completed[index]}
        sx={{
         '& .MuiStepLabel-root .MuiStepLabel-label': {
          fontSize: '16px', // Adjust the font size as needed
       
        },
        '& .MuiStepIcon-root': {
          width: '32px',     // Adjust the width (size) of the circle icon as needed
          height: '32px',    // Adjust the height (size) of the circle icon as needed
          marginRight: '15px' // Increase the gap between label and circle icon
        },
          '& .MuiStepLabel-root .Mui-completed': {
            color: '#002D4E', // circle color (COMPLETED)
          },
          '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
            {
              color: '#002D4E', // Just text label (COMPLETED)
            },
          '& .MuiStepLabel-root .Mui-active': {
            color: '#002D4E', // circle color (ACTIVE)
          },
          '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
            {
              color: '#002D4E', // Just text label (ACTIVE)
            },
          '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
            fill: '#FFFFFF', // circle's number (ACTIVE)
          },
        }}>
            <StepButton color="inherit" onClick={handleStep(index, step.link)}>
              {step.label}
              {areAllQuestionsAnswered(step.link.slice(1)) && <CheckCircleIcon sx={{ color: 'green', ml: 1 }} />}
            </StepButton>
          </Step>
        ))}
      </Stepper>
          
      {openDialog && (
  <Dialog
    open={openDialog}
    onClose={handleDialogClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Incomplete Form"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Please fill in all the required fields and address before proceeding/saving.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDialogClose} color="primary" autoFocus>
        OK
      </Button>
    </DialogActions>
  </Dialog>
)}

    </Box>
   

  );
}




