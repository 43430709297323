import React from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import introductionHeader from "../../images/Introduction.jpg"
import {default as styles} from "./Styles.jsx"


export default function pdfIntro(farmDetails){
  return(
    <Page>
      <div>
        <Image 
        src = {introductionHeader}
        style={styles.headerImage} />
      </div>

      <div style={{position: 'absolute'}}>
        <Text style={styles.title}>Introduction</Text>
        <Text style={styles.text}>
          A sustainable future for our business is core to Fonterra's strategy. We're bringing sustainability to the heart of everything 
          we do - we need to respect and safeguard the wellbeing of our people, our farmers and their animals, and protect and regenerate 
          our environment. Caring for the planet for future generations is a collective responsibility. It's also an increasing expectation 
          of our customers and consumers generally.
        </Text>
        <Text style={styles.text}> 
          In Australia, our contribution is focused on six key areas - farm environment, water, emissions, waste, packaging, and palm oil. 
          We are making good progress on some of these areas and acknowledge there is more work to do.
        </Text>
        <Text style={styles.text}>
          Our 2030 target is to have reduced our CO2 emissions from Australian manufacturing and transport by 30% from a 2018 baseline 
          and we are on track to hit this target. . We have reduced our solid waste to landfill every year for the last three years, 
          and our overall water consumption has also reduced in the same timeframe.
        </Text>
        <Text style={styles.text}>
          But it all starts on farm. We know Australian farmers in many regards are global leaders and respected custodians of the land 
          they farm. Although as consumers seek greater assurances about where and how their food is produced, they want to know that safe 
          and sustainable farming practices are used to deliver the nutritional benefits of the dairy they consume.
        </Text>
        <Text style={styles.text}>
          Fonterra is investing in the development of a number of emission reduction solutions. For example, our trial of red seaweed in 
          Australia as a way to reduce methane emissions from cows is an example of investing in technology and innovation to reduce the 
          environmental impact of livestock in a way that works for farmers and is safe for animals and our consumers.
        </Text>
        <Text style={styles.text}>
          The next step in our commitment to sustainability is the launch of our Farm Environment Plan program. It's intended to recognise 
          existing good practice across a range of topics including general farm management, greenhouse gas emissions and nutrient management,
          and sign-post opportunities to build on this foundation of strong performance.
        </Text>
        <Text style={styles.text}>
          Farm Environment Plans go beyond compliance, with these requirements already outlined in the Supplier Handbook and Dairy Diary. 
          Undertaking a Farm Environmental Plan is about working with you over the short, medium and longer term to lay out a path for 
          continual improvement to address the environmental priorities which you set for your business.
        </Text>
        <Text style={styles.text}>
          The modules have been specifically designed with the Australian operating context in mind and draw on many industry-led 
          initiatives which have been developed by industry bodies such as Dairy Australia.
        </Text>
        <Text style={styles.text}>
          Thank you for agreeing to participate in this first step of the program, as we develop a service offering that will help 
          our farmers make informed decisions that support improved productivity.
        </Text>
        <Text style={styles.text}>
          We're excited about the future of our Australian business, and we're all accountable to help build a sustainable and 
          successful dairy industry. Your partnership with us is vital to making this happen.
        </Text>
        <Text style={styles.text}>
          Regards
        </Text>
        <Text style={styles.signature}>
          René Dedoncker
        </Text>
        <Text style={styles.signature}>
          Managing Director, Fonterra Australia
        </Text>
      </div>

        <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
      
    </Page>
  )
}
