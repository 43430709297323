import * as React from "react";
import { useState, useRef, useMemo } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import useModuleLink from '../hooks/moduleLink';
import Next from "./Next";
import Back from "./Back";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Textarea from '@mui/joy/Textarea';
import axios from 'axios';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AppRunTimeContext } from '../Context/index';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { addScore, subtractScore, GetSuggestion, practices, wantImprovement } from './Utilities.jsx'
import acquireAccessToken from "../accessToken"
import { useMsal } from "@azure/msal-react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ProgressBar from "./ProgressBar";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import pdf_image from '../images/GF.PNG';



// Module questions with corresponding subquestions. 
//Each checkbox(option) are given a unique key with the initial of the module - question no - subquestion no - checkbox no.
//For instance, XX-00-00-00.
//The value for each option is decided based on how good the practices are.
const QArray = [
  { number: 1, //Question no
  question: "Is a farm map available that identifies environmental characteristics on your farm?", //Question content
  section: [
    {id:"1.1",
    statement: "",  //sub question
    option: {
      text: ["An electronic map (live) to be able to overlay yearly data changes on farm", "Farm map - Reparian zones, farm management zones, waterways, etc","Farm map - Paddocks, area, tree plantations","Farm map - Little detail","No farm map"],  //options for checkbox
      value: [9,8, 5, 1, 0],  // assigned value for each option
      key: ["GF-01-1-1","GF-01-1-2", "GF-01-1-3", "GF-01-1-4", "GF-01-1-5"] 
      }},
  ]},
 {
  number: 2, 
  question: "Are practices in place to minimize soil structure problems and erosion on farm?", 
  section: [
    {id:"2.1",
    statement: "",
    option: {
      text: ["Minimised with an innovative approach","Erosion management plan is present","Visible erosion exists and there is no plan to manage it"],
      value: [8, 4, 0],
      key: ["GF-02-1-1","GF-02-1-2", "GF-02-1-3"] 
    }},
]},
{
number: 3, 
question: "Is soil testing conducted at least every 2 years and used in conjunction with a whole-farm nutrient budget?", 
section: [
  {id:"3.1",
  statement: "3.1. Time",
  option: {
    text: ["Within 2 years","More than 2 years","Not at all"],
    value: [3, 1, 0],
    key: ["GF-03-1-1","GF-03-1-2", "GF-03-1-3"] 
  }},
  {id:"3.2",
  statement: "3.2. Samples",
  option: {
    text: ["Taken from all Farm Management Zones or paddocks","Taken from all Three or four representative areas"],
    value: [4, 2],
    key: ["GF-03-2-1","GF-03-2-2"] 
  }},
  {id:"3.3",
  statement: "3.3. Documentation / sampling technique",
  option: {
    text: ["Soil sampling transects are mapped using GPS for future soil testing","Plant sampling is used to fine-tune fertiliser requirements","A soil testing laboratory (i.e. NATA or ASPAC) is used for the analysis required","No documentation"],
    value: [3, 3, 1, 0] ,
    key: ["GF-03-3-1","GF-03-3-2", "GF-03-3-3", "GF-03-3-4"] 
  }},
]},
  {
    number: 4,
    question: "Is fertiliser applied using the (4 Rs) right rate, right place, right time, right source approach?",
    section: [
      {
        id: "4.1",
        statement: "",
        option: {
          text: ["A 4 R nutrient management plan is implemented", "Nutrient budgets are calculated for different Farm Management Zones", "Variable rate fertiliser application is explored", "N is applied as soon after grazing as possible", "Other consideration is given to product source", "No consideration"],
          value: [8, 3, 3, 3, 2, 0],
          key: ["GF-04-1-1", "GF-04-1-2", "GF-04-1-3", "GF-04-1-4", "GF-04-1-5", "GF-04-1-6"]
        }
      },
    ]
  },
  {
    number: 5,
    question: "Do you believe your effluent management practices are current?",
    section: [
      {
        id: "5.1",
        statement: "5.1. Effluent system",
        option: {
          text: ["Multiple systems in place with potential expansion", "System is serviced regularly", "No system maintenance in place"],
          value: [5, 3, 0],
          key: ["GF-05-1-1", "GF-05-1-2", "GF-05-1-3"]
        }
      },
      {
        id: "5.2",
        statement: "5.2. Effluent reuse",
        option: {
          text: ["Sampled and analysed prior to reuse", "Reused as a fertiliser", "Leaves the farm without management"],
          value: [5, 3, 0],
          key: ["GF-05-2-1", "GF-05-2-2", "GF-05-2-3"]
        }
      },
    ]
  },
  {
    number: 6,
    question: "Is biodiversity considered when making any practice change on farm?",
    section: [
      {
        id: "6.1",
        statement: "",
        option: {
          text: ["Biodiversity plan is present in conjuction with appropriate NRM agencies (e.g., Catchment Management Authorities)", "Biodiversity plan is present", "Not present"],
          value: [8, 4, 0],
          key: ["GF-06-1-1", "GF-06-1-2", "GF-06-1-3"]
        }
      },
    ]
  },
  {
    number: 7,
    question: "Is efficient use of energy important to your farm?",
    section: [
      {
        id: "7.1",
        statement: "7.1. Energy monitoring",
        option: {
          text: ["Routinely monitored through a set system", "Energy bill reviewed for further optimisation", "No monitoring"],
          value: [4, 2, 0],
          key: ["GF-07-1-1", "GF-07-1-2", "GF-07-1-3"]
        }
      },
      {
        id: "7.2",
        statement: "7.2. Renewable energy (RE)",
        option: {
          text: ["Utilised", "Investigated and considered", "No knowledge of RE"],
          value: [4, 2, 0],
          key: ["GF-07-2-1", "GF-07-2-2", "GF-07-2-3"]
        }
      },
      {
        id: "7.3",
        statement: "7.3. Energy efficiency",
        option: {
          text: ["Implement energy capture practices", "Considered when purchasing new equipments", "No consideration "],
          value: [4, 2, 0],
          key: ["GF-07-3-1", "GF-07-3-2", "GF-07-3-3"]
        }
      },
    ]
  },
  {
    number: 8,
    question: "Do you believe water use for the dairy shed and stock water is efficient?",
    section: [
      {
        id: "8.1",
        statement: "8.1. Monitoring",
        option: {
          text: ["Water use is monitored daily", "Overall water requirements are calculated", "No monitoring - Water efficiency of equipments are unknown"],
          value: [4, 2, 0],
          key: ["GF-08-1-1", "GF-08-1-2", "GF-08-1-3"]
        }
      },
      {
        id: "8.2",
        statement: "8.2. Recycling",
        option: {
          text: ["Cooling water & effluent streams are recycled for yard cleaning", "Water is reused in multiple applications", "No recycle or reuse"],
          value: [4, 2, 0],
          key: ["GF-08-2-1", "GF-08-2-2", "GF-08-2-3"]
        }
      },
    ]
  },
  {
    number: 9,
    question: "Are controls in place for weeds and pests on farm?",
    section: [
      {
        id: "9.1",
        statement: "9.1. Control application",
        option: {
          text: ["Coordinated weed and pests control is performed with authorities and people in the area", "Permitted weed and pests control conducted individually", "Pest control is carried out without appropriate permits"],
          value: [5, 3, 0],
          key: ["GF-09-1-1", "GF-09-1-2", "GF-09-1-3"]
        }
      },
      {
        id: "9.2",
        statement: "9.2. Control plan",
        option: {
          text: ["Plan is well documented", "Performed without documentation", "No proper plan in place"],
          value: [4, 2, 0],
          key: ["GF-09-2-1", "GF-09-2-2", "GF-09-2-3"]
        }
      },
    ]
  },
  {
    number: 10,
    question: "Are silage wrap and animal carcasses disposed of in an environmentally friendly way?",
    section: [
      {
        id: "10.1",
        statement: "10.1. Silge wrap and other rubbish",
        option: {
          text: ["Collected for recycle", "Disposed in public landfills", "Buried", "burnt or left in paddocks"],
          value: [4, 2, 0, 0],
          key: ["GF-10-1-1", "GF-10-1-2", "GF-10-1-3", "GF-10-1-4"]
        }
      },
      {
        id: "10.2",
        statement: "10.2. Carcasses",
        option: {
          text: ["Cause of death for all stock is determined", "Disposed according to legislation", "Disposed without consideration"],
          value: [4, 2, 0],
          key: ["GF-10-2-1", "GF-10-2-2", "GF-10-2-3"]
        }
      },
    ]
  },
  {
    number: 11,
    question: "Do you have shade and shelter belt plantings on farm?",
    section: [
      {
        id: "11.1",
        statement: "",
        option: {
          text: ["Farm plan is developed in conjuction with NRM agencies", "Buffers are established around individual paddock trees", "Fencing extends beyond the Eucalyptus drip line.", "Revegetation with appropriate native species has occurred", "No revegetation"],
          value: [5, 5, 3, 3, 0],
          key: ["GF-11-1-1", "GF-11-1-2", "GF-11-1-3", "GF-11-1-4", "GF-11-1-5"]
        }
      },
    ]
  },
  {
    number: 12,
    question: "Are stock fenced out of riparian zones?",
    section: [
      {
        id: "12.1",
        statement: "",
        option: {
          text: ["Yes, and stock are provided with alternative routes", "Yes, but stocks are not provided with alternative routes", "Stock can graze through riparian zones"],
          value: [7, 4, 0],
          key: ["GF-12-1-1", "GF-12-1-2", "GF-12-1-3"]
        }
      },
    ]
  },
]

export default function GeneralFarm() {
  const { generalFarm, setGeneralFarm } = useContext(AppRunTimeContext);
  const { farmDetails, setFarmDetails, payload, setPayload, operation, setOperation, highestRevisionNumber, setHighestRevisionNumber } = useContext(AppRunTimeContext);
  // Use the dictionary from generalFarm[images] to replace image filenames
  const formattedValue = (farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].commentary : "").replace(/gfCommentary\S+/g, match => {
    console.log(match)
    const base64 = generalFarm.images[match];
    if (base64) {
      console.log('match')
        return `<img src="${base64}" alt="${match}" />`;
    }
    console.log('no match')
    return match; // If no match found, return the original string
  }).replace(/\n/g, '<br/>');  // Convert newlines to <br/>
  const [value, setValue] = useState(formattedValue);

  const { instance, accounts } = useMsal();

  const { setNextPrev, getNext, getPrev } = useModuleLink();
  const { steps} = useContext(AppRunTimeContext);


  var ScoresArray = generalFarm.scoreArray;
  const [openDialog, setOpenDialog] = useState(false);

  const [openSaveSuccessDialog, setOpenSaveSuccessDialog] = React.useState(false);
  const [openSaveFailedDialog, setOpenSaveFailedDialog] = React.useState(false);
  const [openSavingDialog, setOpenSavingDialog] = React.useState(false);
  const [notApplicableStates, setNotApplicableStates] = useState([]);
  const [isDataEdited, setIsDataEdited] = useState(false);
  const [isTextEdited, setIsTextEdited] = useState(false);
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);
  const [unsavedChangesNavigationPath, setUnsavedChangesNavigationPath] = useState(null);
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] = useState(false);
  const [isWordLimitExceeded, setIsWordLimitExceeded] = useState(false);
  const [openImageFormatDialog, setOpenImageFormatDialog] = React.useState(false);
  const [openImageSizeDialog, setOpenImageSizeDialog] = React.useState(false);
  const stateRef = useRef();

  console.log(isCharacterLimitExceeded);

  useEffect(() => {
    stateRef.current = {
      farmDetails,
      value
    };
  }, [farmDetails, value]);

  const navigate = useNavigate();

  const handleImageFormatDialogClose = () => {    
    setOpenImageFormatDialog(false);   
  };
  
  const handleImageSizeDialogClose = () => {
    setOpenImageSizeDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSaveSuccessDialogClose = () => {
    setOpenSaveSuccessDialog(false);
  };

  const handleSaveFailedDialogClose = () => {
    setOpenSaveFailedDialog(false);
  };
    const handleCharacterDialogClose = () => {
    setIsCharacterLimitExceeded(false);
  };

  const handleWordDialogClose = () => {
    setIsWordLimitExceeded(false);
  };


  const handleNextClick = () => {
    // const questions = farmDetails.surveyData['GeneralFarm'].questions;
    // for (let i = 0; i < questions.length; i++) {
    //   if (!questions[i].practice || !questions[i].focusCheck) {
    //     console.log('open dialog')
    //     setOpenDialog(true);
    //     return;
    //   }
    // }
    // If all conditions are met, navigate to the next page.
    navigate(next);
  };


  //Routing for the application
  setNextPrev("/GeneralFarm");
  let next = getNext();
  let prev = getPrev();
  const stepIndex = steps.findIndex(step => step.link === '/GeneralFarm');

  //State to expand/close the questions
  const [postDisplay, setPostDisplay] = useState(true);
  //State to handle the ticked/unticked checkboxes
  const [state, setState] = React.useState(null);
  const divDisplay = true;

  useEffect(() => {

    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
      window.location.href = '/'; // Redirect to   page
    }


    if (farmDetails.surveyData['GeneralFarm']) {
      // Your code here will run after the component has mounted
      // Loop through the surveyData[1].questions array
      farmDetails.surveyData['GeneralFarm'].questions.forEach((question, index) => {
        // Update the generalFarm.practices array based on the practice in each question
        generalFarm.practices[index] = question.practice;
        generalFarm.focusArea[index] = question.focusCheck
      });
      console.log(generalFarm.practices)
      // console.log(tempModule)
      // console.log(farmDetails)
    }
    else {
      if (!(farmDetails.surveyData['GeneralFarm'])) {


        setFarmDetails(prevDetails => ({
          ...prevDetails,
          surveyData: {
            ...prevDetails.surveyData,
            details: {
              farmOwner: farmDetails.farm_owner,
              planOwner: farmDetails.plan_owner,
              farmAddressLat: farmDetails.farmAddressLat,
              farmAddressLong: farmDetails.farmAddressLong,
              financialYear: farmDetails.financialYear,
              farmAddress: farmDetails.farmAddress
            },
            GeneralFarm: {
              module: "GeneralFarm",
              commentary: "",
              focus: "",
              focusAdditional: "",
              farmMap: "",
              mapTitle: "",
              mapDescription: "",
              mapFocus: "",
              generalPage: 0,
              scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              QuestionMapping: [
                {
                  Q1_1: "Is a farm map available that identifies environmental characteristics on your farm?",
                  Q2_1: "Are practices in place to minimise soil structure problems and erosion on farm?",
                  Q3_1: "Is soil testing conducted at least every 2 years and used in conjunction with a whole-farm nutrient budget?",
                  Q4_1: "Is fertiliser applied using the (4 Rs) right rate, right place, right time, right source approach?",
                  Q5_1: "Do you believe your effluent management practices are current?",
                  Q6_1: "Is biodiversity considered when making any practice change on farm?",
                  Q7_1: "Is efficient use of energy important to your farm?",
                  Q8_1: "Do you believe water use for the dairy shed and stock water is efficient?",
                  Q9_1: "Are controls in place for weeds and pests on farm?",
                  Q10_1: "Are silage wrap and animal carcasses disposed of in an environmentally friendly way?",
                  Q11_1: "Do you have shade and shelter belt plantings on farm?",
                  Q12_1: "Are stock fenced out of riparian zones?"
                }
              ],
              questions: [
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false
                    ],
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                },
                {
                  subQuestions: [
                    [
                      false,
                      false,
                      false
                    ]
                  ],
                  practice: "",
                  focusCheck: "",
                  notApplicable: false
                }
              ]
            }
          }
        }));
      }

    }
  }, []);  // Empty dependency array means this useEffect runs once after initial render

  useEffect(() => {
    // Function to be executed
    const intervalFunction = () => {
      console.log(operation);
      if (operation === "modify") {
        handleSave('autosave');
        console.log('This function runs every 30 seconds');
      }
      // Add your logic here
    };
  
    // Set the interval
    const interval = setInterval(intervalFunction, 120000); 
  
    // Clear interval on component unmount or when operation changes
    return () => clearInterval(interval);
  }, [operation]); // Include operation in the dependency array


  // Check whether the checkboxes are ticked
  const getChecked = (key) => {
    if (generalFarm.generalQuestions.get(key))
      return true;
    else
      return false;
  }

  const handleNavigationAttempt = (navigationPath) => {
    if (isTextEdited) {
      setUnsavedChangesNavigationPath(navigationPath); // Store the intended navigation path
      setOpenUnsavedChangesDialog(true); // If text is edited, show the warning dialog
    } else {
      navigate(navigationPath); // Otherwise, navigate immediately
    }
  };
  
  
  

  //Expand/close each questions individually
  const handleClick = (post) => {
    const objectCopy = {
      ...postDisplay
    };
    objectCopy[post] = !objectCopy[post];
    setPostDisplay(objectCopy);
  };

  //Update the score of each subquestion
  const handleChange = (event) => {

    
    const postIndex = event.target.parentNode.dataset.post;
    const subQIndex = event.target.parentNode.dataset.subq;
    const optIndex = event.target.parentNode.dataset.opt;

    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });

    // {console.log(farmDetails.surveyData[1].questions)}
    { console.log(postIndex) }
    { console.log(subQIndex) }
    { console.log(optIndex) }
    { console.log(event.target.name) }
    farmDetails.surveyData['GeneralFarm'].questions[postIndex].subQuestions[subQIndex][optIndex] = event.target.checked;
    setIsDataEdited(true)
    if (event.target.checked) {
      ScoresArray = addScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue);
      generalFarm.scoreArray = ScoresArray
      generalFarm.generalQuestions.set(event.target.name, true);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            GeneralFarm: {
                ...prevFarmDetails.surveyData.GeneralFarm,
                scoreArray: ScoresArray
            }
        }
    }));
    }
    else {
      ScoresArray = subtractScore(ScoresArray, event.target.name, event.target.attributes.value.nodeValue);
      generalFarm.scoreArray = ScoresArray
      generalFarm.generalQuestions.delete(event.target.name);
      setFarmDetails(prevFarmDetails => ({
        ...prevFarmDetails,
        surveyData: {
            ...prevFarmDetails.surveyData,
            GeneralFarm: {
                ...prevFarmDetails.surveyData.GeneralFarm,
                scoreArray: ScoresArray
            }
        }
    }));
    }
    console.log(ScoresArray)
    //event.target.name - Gets key of checkbox
    //event.target.attributes.value.nodeValue - Gets value of checkbox
  };

  // Update user's choice of practice
  function updatePractice(e) {
    generalFarm.practices[e.target.name] = e.target.value
    // Create a deep copy of the farmDetails object
    const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
    // Update the nested property
    newFarmDetails.surveyData['GeneralFarm'].questions[e.target.name].practice = e.target.value;
    // Update the state
    setFarmDetails(newFarmDetails);
    setIsDataEdited(true)
  }

  // Update whether user would like to focus on that area
  function updateFocus(e) {
    generalFarm.focusArea[e.target.name] = e.target.value
    // Create a deep copy of the farmDetails object
    const newFarmDetails = JSON.parse(JSON.stringify(farmDetails));
    // Update the nested property
    newFarmDetails.surveyData['GeneralFarm'].questions[e.target.name].focusCheck = e.target.value;
    // Update the state
    setFarmDetails(newFarmDetails);
    setIsDataEdited(true)
  }

const isAEDT = (date) => {
  const year = date.getFullYear();
  const startDST = new Date(Date.UTC(year, 9, 1)); // October 1st
  const endDST = new Date(Date.UTC(year, 3, 1)); // April 1st

  // Find the first Sunday in October
  startDST.setUTCDate(1 + (7 - startDST.getUTCDay()) % 7);
  
  // Find the first Sunday in April
  endDST.setUTCDate(1 + (7 - endDST.getUTCDay()) % 7);

    // Find the first Sunday in April
    endDST.setUTCDate(1 + (7 - endDST.getUTCDay()) % 7);

    return (date >= startDST && date < endDST);
  };


  const getCurrentDateTime = () => {
    const nowUTC = new Date(Date.now());

    const offset = isAEDT(nowUTC) ? 12 : 11; // UTC+13 if Daylight Saving Time, otherwise UTC+12
  
    const aet = new Date(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(),
                         nowUTC.getUTCHours() + offset, nowUTC.getUTCMinutes(), nowUTC.getUTCSeconds());
  
    const year = aet.getFullYear();
    const month = String(aet.getMonth() + 1).padStart(2, '0');
    const day = String(aet.getDate()).padStart(2, '0');
    const hour = String(aet.getHours()).padStart(2, '0');
    const minute = String(aet.getMinutes()).padStart(2, '0');
    const second = String(aet.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}${hour}${minute}${second}`;
  };

  // Helper function to prepare payload
  const preparePayload = (prevDetails, specificFields = {}) => {
    const currentState = stateRef.current;
    const { surveyData } = currentState.farmDetails;

    const surveyDataWithoutGraphs = { ...farmDetails.surveyData };

    if (surveyData.Carbon) {
      const { graph: carbonGraph, ...rest } = surveyData.Carbon;
      surveyDataWithoutGraphs.Carbon = rest;
    }


    if (surveyData.Nitrogen) {
      const { graph: nitrogenGraph, ...rest } = surveyData.Nitrogen;
      surveyDataWithoutGraphs.Nitrogen = rest;
    }


    if (surveyData.Irrigation) {
      const { graph: irrigationGraph, ...rest } = surveyData.Irrigation;
      surveyDataWithoutGraphs.Irrigation = rest;
    }

    if (surveyData.HealthyCalves) {
      const { colostrum, calfPathways, ...rest } = surveyData.HealthyCalves;

      // Remove graph1 and graph2 from colostrum
      const { graph1: colostrumGraph1, graph2: colostrumGraph2,graph3: colostrumGraph3, graph4: colostrumGraph4, ...colostrumRest } = colostrum;

      // Remove graph1 and graph2 from calfPathways
      const { graph1: calfPathwaysGraph1, graph2: calfPathwaysGraph2, ...calfPathwaysRest } = calfPathways;

      surveyDataWithoutGraphs.HealthyCalves = {
        ...rest,
        colostrum: colostrumRest,
        calfPathways: calfPathwaysRest
      };

      if (surveyData.GeneralFarm) {
        const { farmMap, ...restGeneralFarm } = surveyData.GeneralFarm;
        surveyDataWithoutGraphs.GeneralFarm = restGeneralFarm;
      }
    }
console.log(surveyDataWithoutGraphs)
    return {
      ...prevDetails,
      dateEdited: getCurrentDateTime(),
      region: farmDetails.farmRegion,
      status: "DRAFT",
      financialYear: farmDetails.financialYear,
      farmId: farmDetails.supplier_no,
      surveyData: surveyDataWithoutGraphs,
      modules: farmDetails.modules,
      ...specificFields
    };
  };

  const CustomInputLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontSize: '15px' }}>{label}</span>
      <span style={{ fontSize: '15px', color: 'red', fontWeight: 'bold', marginLeft: '4px' }}>*</span>
    </div>
  );

  // Helper function to make Axios call
  const makeAxiosCall = async (urlSuffix, payload) => {
    try {
      const token = await acquireAccessToken(instance, accounts[0]);
      localStorage.setItem("accessToken", token);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/${urlSuffix}`, payload, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      // console.log('Response:', response.data);
      console.log(payload);
      return response;
    } catch (error) {
      console.error('An error occurred while performing Axios POST:', error);
      return null;  // Return null if there's an error
    }
    // return true;
  };

  const handleSave = (saveType) => {
    const currentState = stateRef.current;

    const urlSuffix = saveType === "autosave" ? "modifyFarmSurveyData" : "insertFarmSurveyData";
    saveType !== "autosave" && setOpenSavingDialog(true);

    if (operation !== "modify") {
      farmDetails.farmSurveyId = farmDetails.supplier_no + getCurrentDateTime();
    }
    // Check if highestRevisionNumber is defined and is a string of 4 digits
    console.log(highestRevisionNumber)
    let validRevisionNumber = highestRevisionNumber && /^\d{4}$/.test(highestRevisionNumber);
    let newRevisionNumber;
    if (saveType !== 'autosave') {
      if (validRevisionNumber) {
        // Increment the revision number if it's valid
        newRevisionNumber = (parseInt(highestRevisionNumber, 10) + 1).toString().padStart(4, '0');
      } else {
        // Default to '0001' if highestRevisionNumber is undefined or invalid
        newRevisionNumber = '0001';
      }
    }

    // Step 2: Extract images and modify the commentary text
    const regex = /<img src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
    let match;
    const images = [];

    while ((match = regex.exec(currentState.value)) !== null) {
      const imageName = saveType==='autosave' ? `gfCommentary${images.length + 1}_${farmDetails.farmSurveyId}_AUTOSAVE` : `gfCommentary${images.length + 1}_${farmDetails.farmSurveyId}_${newRevisionNumber}`;
      images.push({ base64: match[1], name: imageName });
    }

    console.log(images);

    let modifiedText = currentState.value;
    console.log(modifiedText)
    // Replace base64 values with image names
    for (let i = 0; i < images.length; i++) {
      modifiedText = modifiedText.replace(images[i].base64, images[i].name);
    }

    // Convert <img> tags to just the image name (which we replaced earlier) followed by a newline
    modifiedText = modifiedText.replace(/<img src="([^"]+)"[^>]*>/g, (match, p1) => {
      return p1 + '\n';  // Add a newline character after the image name
    });
    
    // First, handle the <li> tags by converting them to bullet points
    modifiedText = modifiedText.replace(/<li[^>]*>/gi, '• ');
    modifiedText = modifiedText.replace(/<\/li>/gi, '\n');

    // Then, remove the <ul> and </ul> tags as they are no longer necessary
    modifiedText = modifiedText.replace(/<\/?ul[^>]*>/gi, '\n');

    // Convert block-level elements (excluding <ul>, <li> handled above) to newlines
    modifiedText = modifiedText.replace(/<\/?(p|div|h[1-6]|blockquote)[^>]*>/gi, '\n');

    // Convert inline elements to spaces or remove them
    modifiedText = modifiedText.replace(/<\/?(a|span|b|i|em|strong)[^>]*>/gi, ' ');

    // Convert <br> tags to newlines
    modifiedText = modifiedText.replace(/<br[^>]*>/gi, '\n');

    // Remove other HTML tags
    modifiedText = modifiedText.replace(/<\/?[^>]+(>|$)/g, '').trim();

    // Replace multiple newlines with a single newline
    modifiedText = modifiedText.replace(/\n+/g, '\n').trim();

    // Replace multiple spaces with a single space
    modifiedText = modifiedText.replace(/ +/g, ' ').trim();

    console.log(modifiedText);

    // Update the commentary field
    generalFarm.commentary = modifiedText;
    farmDetails.surveyData['GeneralFarm'].commentary = modifiedText;
    console.log(modifiedText)
    console.log(farmDetails.surveyData['GeneralFarm'].commentary)
    // Define a helper function to make the image upload call
    const uploadGraph = async (imageName, graphData) => {      
      if (imageName.startsWith('farmPic_')) {
        if (!farmDetails.hasFarmPicChanged) {
          return true
        }
        else {
          setFarmDetails(prevDetails => ({
            ...prevDetails,
            hasFarmPicChanged: false
          }));
        }
      }
      const imagePayload = {
        "imageName": imageName,
        "imageDetails": graphData
      };
      console.log("Image Payload:", imagePayload);
      return makeAxiosCall("storeImage", imagePayload);
    };

    const imageUploadPromises = images.map(image => {
      return uploadGraph(image.name, image.base64);
    });
    
    console.log(farmDetails.surveyData['GeneralFarm'].commentary)

    setPayload(prevDetails => {
      const specificFields = operation === "modify" ? {
        revisionNumber: saveType==='autosave' ? 'AUTOSAVE' : newRevisionNumber,
        id: saveType==='autosave' ? farmDetails.supplier_no : farmDetails.supplier_no + `_${newRevisionNumber}`,
        editedBy: accounts[0].name
    } : {
        creatorName: accounts[0].name,
        dateCreated: getCurrentDateTime(),
        id: farmDetails.supplier_no + `_${newRevisionNumber}`,
        schemaVersionNum: "001",
        surveyVersionNumber: "1",
        status: "DRAFT",
        farmSurveyId: farmDetails.farmSurveyId,
        revisionNumber: newRevisionNumber,  
        editedBy: accounts[0].name
    };

      const updatedPayload = preparePayload(prevDetails, specificFields);
 

      (async () => {
        console.log(updatedPayload)

        try {
          const response = await makeAxiosCall(urlSuffix, updatedPayload);

          if (response) {
            if (saveType !== 'autosave') { 
              setHighestRevisionNumber(newRevisionNumber);
            }
            if (operation !== "modify") {
              farmDetails.farmSurveyId = updatedPayload.farmSurveyId;
              farmDetails.date_edited = updatedPayload.dateEdited;
              console.log('operation is set to modify')
              setOperation('modify');
            }

            const graphPaths = [
              { path: "HealthyCalves.colostrum.graph1", namePrefix: "healthyCalvesColostrumGraph1_" },
              { path: "HealthyCalves.colostrum.graph2", namePrefix: "healthyCalvesColostrumGraph2_" },
              { path: "HealthyCalves.colostrum.graph3", namePrefix: "healthyCalvesColostrumGraph3_" },
              { path: "HealthyCalves.colostrum.graph4", namePrefix: "healthyCalvesColostrumGraph4_" },
              { path: "HealthyCalves.calfPathways.graph1", namePrefix: "healthyCalvesCalfPathwaysGraph1_" },
              { path: "HealthyCalves.calfPathways.graph2", namePrefix: "healthyCalvesCalfPathwaysGraph2_" },
              { path: "Nitrogen.graph", namePrefix: "nitrogenGraph_" },
              { path: "Carbon.graph", namePrefix: "carbonGraph_" },
              { path: "Irrigation.graph", namePrefix: "irrigationGraph_" },
              { path: "farmPic", namePrefix: "farmPic_" },
              { path: "GeneralFarm.farmMap", namePrefix: "farmMap_" }
            ];

            const graphPromises = graphPaths.map(graph => {
              const parts = graph.path.split('.');
              let graphValue;

              switch (parts.length) {
                case 1:
                  graphValue = farmDetails[parts[0]];
                  break;
                case 2:
                  if (farmDetails.surveyData && farmDetails.surveyData[parts[0]]) {
                    graphValue = farmDetails.surveyData[parts[0]][parts[1]];
                  }
                  break;
                case 3:
                  if (farmDetails.surveyData && farmDetails.surveyData[parts[0]] && farmDetails.surveyData[parts[0]][parts[1]]) {
                    graphValue = farmDetails.surveyData[parts[0]][parts[1]][parts[2]];
                  }
                  break;
                default:
                  break;
              }

              if (graphValue && graphValue !== "") {
                if (saveType === 'autosave') {
                  return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_AUTOSAVE`, graphValue);
                }
                else {
                  return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_${newRevisionNumber}`, graphValue);
                }
              }
              return Promise.resolve(true);
            });

             // Merge imageUploadPromises and graphPromises
             const allUploadPromises = [...imageUploadPromises, ...graphPromises];

             const allUploadResponses = await Promise.all(allUploadPromises);

            if (allUploadResponses.every(res => res)) {
              setOpenSavingDialog(false);
              saveType !== "autosave" && setOpenSaveSuccessDialog(true);
              // After save is successful
              setIsTextEdited(false);
              setIsDataEdited(true)
              setOpenUnsavedChangesDialog(false);
            } else {
              setOpenSavingDialog(false);
              saveType !== "autosave" && setOpenSaveFailedDialog(true);
            }
          } else {
            setOpenSavingDialog(false);
            saveType !== "autosave" && setOpenSaveFailedDialog(true);
          }
        } catch (error) {
          console.error("An error occurred during saving:", error);
          setOpenSavingDialog(false);
          saveType !== "autosave" && setOpenSaveFailedDialog(true);
        }
      })();

      return updatedPayload;
    });
  };


  const allQuestionsAnswered = () => {
    return QArray.every((arr, post) => {
      return farmDetails?.surveyData?.['GeneralFarm']?.questions[post]?.notApplicable ||
        (farmDetails?.surveyData?.['GeneralFarm']?.questions[post]?.practice && farmDetails?.surveyData?.['GeneralFarm']?.questions[post]?.focusCheck);
    });
  }


  const quillRef = useRef(null);  // Reference to the ReactQuill component
  useEffect(() => {
    if (quillRef.current) {
        const quill = quillRef.current.getEditor();

        // This function will be called whenever there's a text change in the editor
        const handleChange = () => {
            const numberOfImages = quill.container.querySelectorAll("img").length;
            
            if (numberOfImages > 5) {
                alert("You can only add up to 5 images.");
                // Revert the change using the history module's undo method
                quill.history.undo();
            } else {
              const contents = quill.getContents();
              let lastImage = null;
              contents.ops.forEach(op => {               
                if (op.insert && op.insert.image) {
                  lastImage = op.insert.image;                  
                }
              });
              if (lastImage) {                
                const imageType = getImageType(lastImage); 
                if (!(imageType === 'image/png' || imageType === 'image/jpeg')) {
                  setOpenImageFormatDialog(true);                  
                  handleDeleteImage();                               
                  return false;              
                }                        
                const sizeInBytes = (lastImage.length * 3) / 4;
                const sizeInKB = sizeInBytes / 1024; 
                const fileSizeInKB = Math.round(sizeInKB);  
                if(fileSizeInKB !== null){ 
                  if (fileSizeInKB > 400) {
                      // File size is within the limit
                      setOpenImageSizeDialog(true);
                      handleDeleteImage();                
                      return false; 
                  }   
                }              
              }              
            }
        };

        // Attach the event listener
        quill.on('text-change', handleChange);

        // Clean up the event listener when the component unmounts
        return () => {
            quill.off('text-change', handleChange);
        };
    }
  }, []);

  const handleDeleteImage = () => {
    const quill = quillRef.current.getEditor();
    const selection = quill.getSelection();
    quill.history.undo();
  };

  const getImageType = (lastImage) => {
    const matches = lastImage.match(/^data:(.*);base64,/);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return null;
  };

  const customImageHandler = () => {
    const editor = quillRef.current.getEditor();
      const selection = editor.getSelection();
      const content = editor.getContents();
      console.log("Custom image handler triggered"); // This should be printed whenever an image is added

      // Count the number of images
      let imageCount = 0;
      content.ops.forEach(op => {
          if (typeof op.insert === 'string' && op.insert.includes('<img')) {
              imageCount++;
          }
      });

      if (imageCount >= 5) {
          alert('You can only insert a maximum of 5 images.');
      } else {        
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/png, image/jpeg');
          input.click();

          input.onchange = () => {            
              const file = input.files[0];              
              const reader = new FileReader();

              reader.onload = () => {
                const range = selection;
                editor.insertEmbed(range.index, 'image', reader.result);
            };

                reader.readAsDataURL(file);
          };
      }
  };
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ list: 'bullet' }],
        ['image']
      ]
    }
  }), [])

  const checkUnsavedChanges = () => {
    return isTextEdited; // where isTextEdited is a state variable that tracks if the text has been edited
  };  
  
  return (
    <div className="generalFarm-page">
      {console.log(farmDetails)}
      {/* {console.log(payload)} */}
      {console.log(generalFarm)}
      {console.log(value)}
      {console.log(operation)}
      {/* {console.log(modules)} */}
      {/* {console.log(next)} */}
                   <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
        <Grid item xs={3}>
         <div className="progressbar">
          <ProgressBar currentStep={stepIndex} checkUnsavedChanges={checkUnsavedChanges} setOpenUnsavedChangesDialog={setOpenUnsavedChangesDialog} setUnsavedChangesNavigationPath={setUnsavedChangesNavigationPath}/>
           </div>
           </Grid>
            <Grid item xs={9}>


      <header className="generalFarm-header">
        <h1 className="generalFarm-h1">General Farm Management</h1>
              
        <div className="module-questions"> 
              <h5>* indicates <b>mandatory fields</b></h5>
        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
            Commentary
            {farmDetails?.surveyData?.['GeneralFarm']?.commentary &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
              <h3>Commentary<sup>*</sup></h3>
            </AccordionSummary>

            {/* <AccordionDetails>
              <Typography>
                <TextareaAutosize
                  minRows={3}
                  maxRows={5}
                  placeholder="Commentary"
                  defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].commentary : ""}
                  style={{
                    color: 'black',
                    width: "100%",
                    paddingBottom: '2%',
                    border: "2px solid #000000",
                    fontSize: 20,
                    fontFamily: "Arial",
                    backgroundColor: "#EBF4F9",
                  }}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm

                  //   setFarmDetails(prevFarmDetails => ({
                  //     ...prevFarmDetails,
                  //     surveyData: {
                  //       ...prevFarmDetails.surveyData,
                  //       GeneralFarm: {
                  //         ...prevFarmDetails.surveyData.GeneralFarm,
                  //         commentary: newValue
                  //       }
                  //     }
                  //   }));
                  // }}
                  onChange={(e) => {
                    generalFarm.commentary = (e.target.value)
                    farmDetails.surveyData['GeneralFarm'].commentary = (e.target.value)
                  }}
                />
              </Typography>
            </AccordionDetails> */}

            <AccordionDetails>
              <ReactQuill ref={quillRef} theme="snow" value={value} 
              onChange={(content, delta, source, editor) => {               
                if (source === 'user') {
                  setValue(content); // existing setValue call
                  setIsTextEdited(true); // set the edited flag to true
                  setIsDataEdited(true)
                  setFarmDetails(prevFarmDetails => ({
                    ...prevFarmDetails,
                    surveyData: {
                      ...prevFarmDetails.surveyData,
                      GeneralFarm: {
                        ...prevFarmDetails.surveyData.GeneralFarm,
                        commentary: content.trim() !== '<p><br></p>' ? content : ''
                      }
                    }
                  }));
                }
              }}
              formats={[
                'list', 'bullet',
                'image'
              ]}
              modules={modules}/>
              <p style={{color:'green',fontSize:'12px', marginTop:'0px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>                
            </AccordionDetails>
          </Accordion>
 
        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3>
          Questions<sup>*</sup> 
          { allQuestionsAnswered() && <CheckCircleIcon style={{ marginLeft: '10px', marginBottom: '-3px', color: 'green', fontSize: '1.5rem' }} /> }
        </h3>
      </AccordionSummary>

        <AccordionDetails>
          <Typography>
                  {/* map through the QArray to display questions and options */}
          {QArray.map((arr, post) =>
            divDisplay ? (
              <div>
                <List
                  sx={{ width: "100%", bgcolor: "#EBF4F9"}}
                  component="nav"
                  aria-labelledby="nested-list-subheader" >

                <ListItemButton style={{ fontSize: 20, fontWeight: 'bold' }} onClick={() => handleClick(post)}>
                  Question {post+1}.{arr.question}
                  { 
                    (farmDetails?.surveyData?.['GeneralFarm']?.questions[post]?.notApplicable ||
                    (farmDetails?.surveyData?.['GeneralFarm']?.questions[post]?.practice && farmDetails?.surveyData?.['GeneralFarm']?.questions[post]?.focusCheck)) &&
                    <CheckIcon style={{ marginLeft: '10px', color: 'green' }} />
                  }
                </ListItemButton>



                  {postDisplay[post] ?
                  
                  <FormControl>
                                   <div style={{ marginLeft: '2%', marginTop: '15px' }}>
                  <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={farmDetails.surveyData['GeneralFarm'].questions[post].notApplicable}
                            onChange={() => {
                                // Copy the current farmDetails state
                                const updatedFarmDetails = {...farmDetails};
                                // Toggle the notApplicable value for the current question
                                updatedFarmDetails.surveyData['GeneralFarm'].questions[post].notApplicable = !updatedFarmDetails.surveyData['GeneralFarm'].questions[post].notApplicable;
                              
                                if (updatedFarmDetails.surveyData['GeneralFarm'].questions[post].notApplicable) { 
                                    // Reset checkboxes
                                    farmDetails.surveyData['GeneralFarm'].questions[post].subQuestions.forEach((subQ, j) => {
                                        const currentSection = QArray[post].section[j];
                                        currentSection.option.key.forEach((key, k) => {
                                            updatedFarmDetails.surveyData['GeneralFarm'].questions[post].subQuestions[j][k] = false;
                                            // Update generalFarm.scoreArray and generalFarm.generalQuestions
                                            ScoresArray = subtractScore(ScoresArray, key, currentSection.option.value[k]);
                                            generalFarm.scoreArray = ScoresArray;
                                            generalFarm.generalQuestions.delete(key);
                                            setFarmDetails(prevFarmDetails => ({
                                              ...prevFarmDetails,
                                              surveyData: {
                                                  ...prevFarmDetails.surveyData,
                                                  GeneralFarm: {
                                                      ...prevFarmDetails.surveyData.GeneralFarm,
                                                      scoreArray: ScoresArray
                                                  }
                                              }
                                          }));
                                        });
                                    });
                                    
                                    // Reset practice and focusCheck
                                    updatedFarmDetails.surveyData['GeneralFarm'].questions[post].practice = "";
                                    updatedFarmDetails.surveyData['GeneralFarm'].questions[post].focusCheck = "";
                                    generalFarm.practices[post] = "";
                                    generalFarm.focusArea[post] = "";
                                }
                                setIsDataEdited(true)
                                // Update the state
                                setFarmDetails(updatedFarmDetails);
                            }}
                            name={`notApplicable_${post}`}
                        />
                    }
                    label={
                      <Typography variant="body1" style={{  fontStyle: 'italic' }}>
                          Not Applicable for the farm
                      </Typography>
                  }
                />

              </div>
                  {arr.section.map((innerArr,j) =>( 
                  
                    <div key = {j}>
                      <FormLabel  component = "legend" style = {{color: 'black', marginLeft: '2%', paddingTop: '2%'}}> {innerArr.statement} </FormLabel>
                      {innerArr.option.text.map((text,k) => (
                        <FormGroup key = {k}>
                      <FormControlLabel control = {
                          <Checkbox 
                              onChange =  { handleChange }
                              name = {innerArr.option.key[k]}
                              value = {innerArr.option.value[k]}
                              style = {{marginLeft: '2%'}}
                              checked = {farmDetails.surveyData['GeneralFarm'].questions[post].subQuestions[j][k]}
                              data-post={post}
                              data-subq={j}
                              data-opt={k}
                              disabled={farmDetails.surveyData['GeneralFarm'].questions[post].notApplicable}
                          />}
                          label = {text}
                            style={{width: "110ch",wordWrap: 'break-word'}}
                      />
                  </FormGroup>
                      ))} 
                    </div>
                  ))}

                  <h6 style={{marginLeft: '2%', fontSize: '20px'}}>
                    Suggestion: {GetSuggestion(ScoresArray[post])}
                  </h6>

                  <TextField
                    id="select-practice"
                    select
                    name = {post}
                    label={<CustomInputLabel label="Select appropriate practice" />}
                    value={farmDetails.surveyData['GeneralFarm'].questions[post].practice} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch'}}
                    onChange = {updatePractice}
                    
            
                    disabled={farmDetails.surveyData['GeneralFarm'].questions[post].notApplicable}
                     InputProps={{ style: { fontSize: 20} }}
                    InputLabelProps={{ 
                        style: { fontSize: 20}}}
                 
                  >
                    {practices.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  
                 
                  <TextField
                    id="select-improvement"
                    select
                    name = {post}
                    label={<CustomInputLabel label="Is this a focus area you want support with?" />}
                    value={farmDetails.surveyData['GeneralFarm'].questions[post].focusCheck} 
                    style = {{marginLeft: '2%', marginTop: '2%' }}
                      sx={{ m: 1, width: '42ch'}}
                    onChange = {updateFocus}
                    
           
                    disabled={farmDetails.surveyData['GeneralFarm'].questions[post].notApplicable}
                     InputProps={{ style: { fontSize: 15} }}
                    InputLabelProps={{ style: { fontSize: 15} }}

                  >
                    {wantImprovement.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

 

                  </FormControl>
                  : null}
                </List>  
               </div>
           ) : null
          )}  
          </Typography>
        </AccordionDetails>
      </Accordion>



       <Accordion>
       <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {/* <h3>
          Area of focus
          {farmDetails?.surveyData?.['GeneralFarm']?.focus &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
        </h3> */}
         <h3>Area of focus<sup>*</sup></h3>
         
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
           <h5>Maximum character limit - 550</h5>
           <Textarea
            maxRows={3}
            maxLength="551"
            placeholder="Focus"
            defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].focus : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   generalFarm.focus = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       GeneralFarm: {
              //         ...prevFarmDetails.surveyData.GeneralFarm,
              //         focus: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
            
                if ((e.target.value).length > 550) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                    generalFarm.focus = (e.target.value);
                    setFarmDetails(prevFarmDetails => ({
                      ...prevFarmDetails,
                      surveyData: {
                          ...prevFarmDetails.surveyData,
                          GeneralFarm: {
                              ...prevFarmDetails.surveyData.GeneralFarm,
                              focus: (e.target.value)
                          }
                      }
                  }));
                    setIsDataEdited(true)
                  }

          }}
          onKeyDown={(e) => {
            const value = e.target.value;
            const numberOfNewLines = (value.match(/\n/g) || []).length;
            
            if (numberOfNewLines >= 7 && e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onPaste={(e) => {
            // Get clipboard data
            const clipboardData = e.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('Text');
        
            // Count the number of lines in the pasted text
            const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
        
            // Check if the number of lines exceeds 10
            if (numberOfNewLines > 7) {
              e.preventDefault();
              // Trim the pasted text to 10 lines and insert it manually
              const trimmedData = pastedData.split('\n').slice(0, 8).join('\n');
              const selectionStart = e.target.selectionStart;
              const before = e.target.value.substring(0, selectionStart);
              const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
              e.target.value = before + trimmedData + after;
              // Update state
              generalFarm.focus = (e.target.value)
              setFarmDetails(prevFarmDetails => ({
                ...prevFarmDetails,
                surveyData: {
                    ...prevFarmDetails.surveyData,
                    GeneralFarm: {
                        ...prevFarmDetails.surveyData.GeneralFarm,
                        focus: (e.target.value)
                    }
                }
            }));
              setIsDataEdited(true)
            }
          }}/>
          </Typography>
        </AccordionDetails>
      </Accordion>

             <Accordion>
             <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <h3>
                Opportunity for follow up/support
                {farmDetails?.surveyData?.['GeneralFarm']?.focusAdditional &&
                  <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
                }
              </h3> */}
               <h3>Opportunity for follow up/support - (Optional)</h3>
              
            </AccordionSummary>

        <AccordionDetails>
          <Typography>
            <h5>Maximum character limit - 500</h5>
           <Textarea
            maxRows={3}
            maxLength="501"
            placeholder="Focus"
            defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].focusAdditional : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20, 
              fontColor: "black", 
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   generalFarm.focusAdditional = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       GeneralFarm: {
              //         ...prevFarmDetails.surveyData.GeneralFarm,
              //         focusAdditional: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
                 if ((e.target.value.length) > 500) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                                    generalFarm.focusAdditional = (e.target.value)
                                    setFarmDetails(prevFarmDetails => ({
                                      ...prevFarmDetails,
                                      surveyData: {
                                          ...prevFarmDetails.surveyData,
                                          GeneralFarm: {
                                              ...prevFarmDetails.surveyData.GeneralFarm,
                                              focusAdditional: (e.target.value)
                                          }
                                      }
                                  }));
                setIsDataEdited(true)
                  }

          }}
          onKeyDown={(e) => {
            const value = e.target.value;
            const numberOfNewLines = (value.match(/\n/g) || []).length;
            
            if (numberOfNewLines >= 9 && e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onPaste={(e) => {
            // Get clipboard data
            const clipboardData = e.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('Text');
        
            // Count the number of lines in the pasted text
            const numberOfNewLines = (pastedData.match(/\n/g) || []).length;
        
            // Check if the number of lines exceeds 10
            if (numberOfNewLines > 9) {
              e.preventDefault();
              // Trim the pasted text to 10 lines and insert it manually
              const trimmedData = pastedData.split('\n').slice(0, 10).join('\n');
              const selectionStart = e.target.selectionStart;
              const before = e.target.value.substring(0, selectionStart);
              const after = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
              e.target.value = before + trimmedData + after;
              // Update state
              generalFarm.focusAdditional = (e.target.value)
              setFarmDetails(prevFarmDetails => ({
                ...prevFarmDetails,
                surveyData: {
                    ...prevFarmDetails.surveyData,
                    GeneralFarm: {
                        ...prevFarmDetails.surveyData.GeneralFarm,
                        focusAdditional: (e.target.value)
                    }
                }
            }));
              setIsDataEdited(true)
            }
          }}/>
          </Typography>
        </AccordionDetails>
      </Accordion>


      <p>
        <h5> Click on box below to insert map of farm identifying environmental aspects<sup>*</sup></h5>
        <input
        type="file"
        id="userFileInput"
        accept="image/*"  // This line restricts to image file types
        onChange={(e) => {          
            const reader = new FileReader();
            let fileType = e.target.files[0];   
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {         
                const base64String = reader.result;

                // Set the states with the base64 representation
                setGeneralFarm(prevGeneralFarm => ({
                    ...prevGeneralFarm,
                    farmMap: base64String
                }));

                setFarmDetails(prevFarmDetails => ({
                    ...prevFarmDetails,
                    surveyData: {
                        ...prevFarmDetails.surveyData,
                        GeneralFarm: {
                            ...prevFarmDetails.surveyData.GeneralFarm,
                            farmMap: base64String
                          }
                        }
                    }));   
                  };    
                const fileExtension = fileType.name.split(".").at(-1);
                const allowedFileTypes = ["jpg", "png"];               

                if (!allowedFileTypes.includes(fileExtension)) {                  
                  setOpenImageFormatDialog(true);
                  console.error('Invalid file type. Please select a JPEG or PNG file.');
                  e.target.value = null;
                  return false;
                } 
                else if (fileType.size > 400 * 1024) {
                  setOpenImageSizeDialog(true);
                  e.target.value = null;
                  console.error('File size exceeds the limit (400 KB).');
                  return false;
                };
            reader.onerror = (error) => {
                console.error('Error converting image to base64:', error);
            };
            setIsDataEdited(true)
        }}
    />
    
      </p>
      <p style={{color:'green',fontSize:'12px', marginTop:'-20px', fontWeight:'500'}}>*Only Jpeg/Png images with a size of 400kb are allowed.</p>                

      <p>
             <h5> Refer to the below image to understand the placement of the below textboxes in the PDF</h5>
            <img  style={{marginLeft: '5%', marginTop: '5%', marginBottom: '5%'}} src={pdf_image} alt="GF Image" />
      </p>

                      <Accordion>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {/* <h3>
            Commentary
            {farmDetails?.surveyData?.['GeneralFarm']?.commentary &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
          <h3>GFM Map Textbox - 1<sup>*</sup> </h3>
         
        </AccordionSummary>

        <AccordionDetails>
          <Typography>
           <h5>Maximum character limit - 69</h5>
                     <Textarea
            minRows={1}
            maxRows={1}
            maxLength="70"
            placeholder="GFM Map Textbox - 1"
            defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].mapTitle : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20,  
              fontFamily: "Arial", 
            
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       GeneralFarm: {
              //         ...prevFarmDetails.surveyData.GeneralFarm,
              //         commentary: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
                if ((e.target.value.length) > 70) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                                    generalFarm.mapTitle = (e.target.value)  
                                                                  

                                    setFarmDetails(prevFarmDetails => ({
                                      ...prevFarmDetails,
                                      surveyData: {
                                          ...prevFarmDetails.surveyData,
                                          GeneralFarm: {
                                              ...prevFarmDetails.surveyData.GeneralFarm,
                                              mapTitle: (e.target.value)
                                          }
                                      }
                                  }));
                setIsDataEdited(true)
                  }

          }}
              />
          </Typography>
        </AccordionDetails>
      </Accordion>



                <Accordion>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {/* <h3>
            Commentary
            {farmDetails?.surveyData?.['GeneralFarm']?.commentary &&
              <CheckCircleIcon style={{ marginLeft: '10px', marginBottom:'-3px', color: 'green', fontSize: '1.5rem' }} />
            }
          </h3> */}
          <h3>GFM Map Textbox - 2<sup>*</sup> </h3>
         
        </AccordionSummary>

        <AccordionDetails>
          <Typography>
           <h5>Maximum character limit - 345</h5>
                     <Textarea
            minRows={3}
            maxRows={5}
            maxLength="346"
            placeholder="GFM Map Textbox - 2"
            defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].mapDescription : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20,  
              fontFamily: "Arial", 
            
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       GeneralFarm: {
              //         ...prevFarmDetails.surveyData.GeneralFarm,
              //         commentary: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
                             
                if ((e.target.value.length) > 345) {
                    setIsCharacterLimitExceeded(true);
                  } else {
                    setIsCharacterLimitExceeded(false);
                                    generalFarm.mapDescription = (e.target.value)
                                    setFarmDetails(prevFarmDetails => ({
                                      ...prevFarmDetails,
                                      surveyData: {
                                          ...prevFarmDetails.surveyData,
                                          GeneralFarm: {
                                              ...prevFarmDetails.surveyData.GeneralFarm,
                                              mapDescription: (e.target.value)
                                          }
                                      }
                                  }));
                setIsDataEdited(true)
                  }

          }}
              />
          </Typography>
        </AccordionDetails>
      </Accordion>

                <Accordion>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >

          <h3>GFM Map Textbox - 3<sup>*</sup> </h3>
       
        </AccordionSummary>

        <AccordionDetails>
          <Typography>
             <h5>Maximum character limit - 50</h5>
              <Textarea
            minRows={3}
            maxRows={5}
            maxLength="51"
            placeholder="GFM Map Textbox - 3"
            defaultValue={farmDetails.surveyData['GeneralFarm'] ? farmDetails.surveyData['GeneralFarm'].mapFocus : ""}
            style={{ 
              color: 'black', 
              width: "100%",
              paddingBottom: '2%',
              border: "2px solid #000000",
              fontSize: 20,  
              fontFamily: "Arial", 
              backgroundColor:"#EBF4F9",}}
              // onChange={(e) => {
              //   const newValue = e.target.value;
              //   generalFarm.commentary = newValue; // Assuming you also need to update generalFarm
                
              //   setFarmDetails(prevFarmDetails => ({
              //     ...prevFarmDetails,
              //     surveyData: {
              //       ...prevFarmDetails.surveyData,
              //       GeneralFarm: {
              //         ...prevFarmDetails.surveyData.GeneralFarm,
              //         commentary: newValue
              //       }
              //     }
              //   }));
              // }}
              onChange={(e) => {
                const wordLimit = 10;
                const inputValue = e.target.value;
                const words = inputValue.split(/\s+/);
                if (words.length > wordLimit) {                
                  setIsWordLimitExceeded(true);
                } 
                else if ((inputValue.length) > 50) {
                  setIsCharacterLimitExceeded(true);
                } else {
                    setIsCharacterLimitExceeded(false);
                    generalFarm.mapFocus = (inputValue)
                    setFarmDetails(prevFarmDetails => ({
                      ...prevFarmDetails,
                      surveyData: {
                          ...prevFarmDetails.surveyData,
                          GeneralFarm: {
                              ...prevFarmDetails.surveyData.GeneralFarm,
                              mapFocus: (e.target.value)
                          }
                      }
                  }));
                  setIsDataEdited(true)
                }

          }}
              />
          </Typography>
        </AccordionDetails>
      </Accordion>


                    

            <div className="back-next-button">
              <Back back = {prev} onClick={() => handleNavigationAttempt(prev)}/>
              <Next 
                next={next} 
                onClick={() => handleNavigationAttempt(next)} 
                disabled={true}
              />
                  <div className="btn-text-left">
                  <Button  variant="contained" href="#contained-buttons"  className="btn-save" style={{ backgroundColor: isDataEdited ? "#002D4E" : "#a8a8a8"}}  sx={{ ml: 1}} onClick={handleSave} disabled={!isDataEdited}>Save</Button>
                {/* <Link to="/ModulePage">
                    <Button  variant="contained" href="#contained-buttons" id = "module" style={{ backgroundColor:"#002D4E"}}  sx={{ ml: 5}}>Module Page</Button>
              </Link> */}
              </div>
 
            </div>

          {/* <div className="back-next-button"> 
            <Back back = {prev}/>
            <Next next = {next} />
            
            <div className="btn-text-left">
            <Button  variant="contained" href="#contained-buttons"  className="btn-save" style={{ backgroundColor:"#002D4E"}}  sx={{ ml: 1}} onClick={handleSave}>Save</Button>
          <Link to="/ModulePage">
              <Button  variant="contained" href="#contained-buttons" id = "module" style={{ backgroundColor:"#002D4E"}}  sx={{ ml: 5}}>Module Page</Button>
         </Link>
         </div>
           </div> */}

        </div>
      </header>
      </Grid>
        </Grid>
          </Box>
              {/* Dialog Component */}
              <Dialog
            open={openImageFormatDialog}
            onClose={handleImageFormatDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Type"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Invalid file type. Please select a JPEG or PNG file.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageFormatDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openImageSizeDialog}
            onClose={handleImageSizeDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Size"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              File size exceeds the limit (400 KB).
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageSizeDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
              <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Incomplete Questions"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please complete all questions before proceeding.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

              {/* Save Successful Dialog */}
              <Dialog
            open={openSaveSuccessDialog}
            onClose={handleSaveSuccessDialogClose}
            aria-labelledby="save-success-dialog-title"
            aria-describedby="save-success-dialog-description"
        >
            <DialogTitle id="save-success-dialog-title">{"Save Successful"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="save-success-dialog-description">
                    You have successfully saved the form.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveSuccessDialogClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

          {/* Save Failed Dialog */}
          <Dialog
        open={openSaveFailedDialog}
        onClose={handleSaveFailedDialogClose}
        aria-labelledby="save-failed-dialog-title"
        aria-describedby="save-failed-dialog-description"
    >
        <DialogTitle id="save-failed-dialog-title">{"Save Failed"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="save-failed-dialog-description">
                An error occurred while saving the form. Please try again.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveFailedDialogClose} color="primary" autoFocus>
                OK
            </Button>
        </DialogActions>
    </Dialog>

        {/* Saving Dialog */}
        <Dialog
        open={openSavingDialog}
        aria-labelledby="saving-dialog-title"
        aria-describedby="saving-dialog-description"
    >
        <DialogTitle id="saving-dialog-title">{"Saving..."}</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
                Please wait while we save your form.
            </DialogContentText>
            {/* Centered spinner with space above */}
            <CircularProgress size={40} style={{ marginTop: '10px' }} />
        </DialogContent>
        </Dialog>
         {/* Character Dialog */}
        <Dialog open={isCharacterLimitExceeded} onClose={handleCharacterDialogClose}>
      <DialogTitle>Character Limit Exceeded</DialogTitle>
      <DialogContent>
        You have exceeded the character limit. Please shorten your input.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCharacterDialogClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={isWordLimitExceeded} onClose={handleWordDialogClose}>
      <DialogTitle>Word Limit Exceeded</DialogTitle>
      <DialogContent>
        You have exceeded the 10 words limit. Please shorten your input.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleWordDialogClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
    

        <Dialog
        open={openUnsavedChangesDialog}
        onClose={() => setOpenUnsavedChangesDialog(false)}
        aria-labelledby="unsaved-changes-title"
        aria-describedby="unsaved-changes-description"
      >
        <DialogTitle id="unsaved-changes-title">{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-description">
            You have unsaved changes in the commentary section. Please save before continuing.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
          onClick={() => {
            setOpenUnsavedChangesDialog(false);
            setIsTextEdited(false); // Reset the text edited state
            navigate(unsavedChangesNavigationPath); // Navigate to the stored path
            setUnsavedChangesNavigationPath(null); // Reset the navigation path state
            setIsDataEdited(false);
          }}
          style={{ color: 'red', marginRight: 'auto', marginLeft: '7px' }} // This aligns the button to the left
        >
          Continue without saving
        </Button>
        <div style={{ marginLeft: 'auto' }}> {/* This div groups the 'Cancel' and 'Save' buttons to the right */}
          <Button onClick={() => setOpenUnsavedChangesDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </div>
      </DialogActions>
      </Dialog>


    </div>
  );
}
