import React, { useState } from 'react';
import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import {default as styles} from "../Styles"
import action from "../../../images/Action.jpg"


const carbonStyles = StyleSheet.create({
  text_action:{
    color: '#002D4E',
    font: 'Arial',
    fontSize: 12,
    paddingHorizontal: 10,
    marginBottom: 10,
    marginHorizontal: "5%",
    lineHeight:1.4
  },
})

export default function CarbonAction(carbon, farmDetails){
  const [imageWidth, setImageWidth] = useState('');
  const decodeHTMLEntities = (text) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  }

  const getImageWidth = (currentWidth) => {
    if (currentWidth > 1500 ) {
      return '75%'
    }
    else if (currentWidth > 1200 ) {
      return '65%'
    }
    else if (currentWidth > 900 ) {
      return '55%'
    }
    else if (currentWidth > 600 ) {
      return '45%'
    }
    else {
      return '45%'
    }
  }

  function renderActionWithImages(action, images) {
    // Define a regex pattern for the image markers
    const pattern = /carbonAction[^\s]+/g;
   
    // Split the commentary into segments based on the pattern
    let segments = [];
    let lastIndex = 0;
    let match;
    while ((match = pattern.exec(action)) !== null) {
         console.log(match);
        // Push the text segment before the image marker
        segments.push({ type: 'text', content: action.slice(lastIndex, match.index) });
        
        // Push the image segment
        segments.push({ type: 'image', key: match[0] });  // Change here: use match[0]
        
        lastIndex = match.index + match[0].length;
    }
    
    // Push any remaining text after the last image marker
    if (lastIndex < action.length) {
        segments.push({ type: 'text', content: action.slice(lastIndex) });
    }
    
    console.log("Action", segments)
    // Render each segment as either text or image
    return segments.map((segment, index) => {
      if (segment.type === 'text') {        
        return <Text key={index} style={{ ...styles.commentaryAction}}>{decodeHTMLEntities(segment.content)}</Text>;       
      } else {
          const imageData = images[segment.key];

          var img = document.createElement("img")
          img.setAttribute("src", imageData)
          setTimeout(function(){           
            const findImageWidth = getImageWidth(img.width)
            setImageWidth(findImageWidth)
          }, 0)
          if (imageData) {
              return (
                <>
                  <div style={[styles.commentaryImageWrapper]}> 
                    <Image key={index} src={imageData} style={{width: `${imageWidth}`, height: "auto", marginBottom: 10 }} />
                    <Text key={`spacer-${index}`} style={{ height: 20, display:"block" }}>&nbsp;</Text>
                  </div>
                </>
              );
          }
      }
  });
    // return (
    // <Text style={styles.commentary}>
    // {generalFarm.commentary}
    // </Text>
    // )
  }
    return(
      <Page>
      {/* header */}
      <div fixed style = {styles.actionHeaderImageBox} >
        <Image 
          src = {action}
          style={{position: 'relative',
          display: 'flex',
          paddingBottom: 0,
          marginBottom: 0}} />
        
        <div style={{position: 'absolute'}}>
          <Text style={styles.title}>The Action</Text>
          <Text style={styles.secondSubtitle}>Greenhouse Gas Emissions</Text>  
        </div>
      </div>
         <View fixed style = {{height: 15}}></View>
         {/* <div style={{border:"1px solid #00cc00", height:"640px"}}> */}
         <div style={[styles.wrapper]}>
           {renderActionWithImages(carbon.action, carbon.images)}   
           </div>
        {/* </div>           */}
        <View fixed style = {{marginTop: "30%"}}></View> 
        
 
          <Text style={styles.farmName} fixed>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (
          `${pageNumber}`
        )} fixed />
      </Page>
    )
}