import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { TextField } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { AppRunTimeContext } from '../Context/index';
import { useContext, useEffect } from 'react';
import axios from 'axios';
import acquireAccessToken from "../accessToken"
import { useMsal } from "@azure/msal-react";
import useModuleLink from '../hooks/moduleLink';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popover from '@mui/material/Popover';

// styling for search button
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

//styling for search icon
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

//styling for search input text field
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));



//creates data columns
function createData(region, farmId, date_create, creator, reviewer, financialYear, status, mapRequired, date_edit) {
  return {
    region,
    farmId,
    date_create,
    creator,
    reviewer,
    financialYear,
    status,
    mapRequired,
    date_edit
  };
}

//creates rows
let rows = [
  createData('East', 'E2986', '04-05-2022', 'Sara', 'Shane', 'COMPLETE', '08-07-2022'),
  createData('North', 'N0439', '01-06-2022', 'Sara', 'Shane', 'COMPLETE', '08-09-2022'),
  createData('South', 'S0289', '08-06-2022', 'Sara', 'Shane', 'COMPLETE', '09-09-2022'),
  createData('South', 'S0441', '02-06-2022', 'Sara', 'Shane', 'DRAFT', '09-10-2022'),
  createData('North', 'N0439', '01-06-2022', 'Ivana', 'Sara', 'DRAFT', '07-12-2022'),
  createData('North', 'N2222', '07-04-2022', 'Ivana', 'Sara', 'DRAFT', '07-12-2022'),
  createData('North', 'N3108', '28-06-2022', 'Ivana', 'Sara', 'DRAFT', '07-12-2022'),
  createData('West', 'W3054', '08-06-2022', 'Sara', 'Ivana', 'COMPLETE', '24-06-2022'),
  createData('East', 'E1014', '25-05-2022', 'Sara', 'Shane', 'COMPLETE', '24-12-2022'),
  createData('East', 'E1014', '25-05-2022', 'Sara', 'Shane', 'COMPLETE', '24-12-2022'),
  createData('East', 'E1014', '25-05-2022', 'Sara', 'Shane', 'COMPLETE', '24-12-2022'),
  createData('East', 'E1014', '25-05-2022', 'Sara', 'Shane', 'COMPLETE', '24-12-2022'),
  createData('East', 'E1014', '25-05-2022', 'Sara', 'Shane', 'COMPLETE', '24-12-2022'),
];

//creates table headers
const headCells = [
  {
    id: 'region',
    numeric: false,
    disablePadding: true,
    label: 'Region',
  },
  {
    id: 'farmId',
    numeric: false,
    disablePadding: false,
    label: 'Farm ID',
  },
  {
    id: 'dateCreated',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
  },

  {
    id: 'creatorName',
    numeric: false,
    disablePadding: false,
    label: 'Created By',
  },
  {
    id: 'reviewerName',
    numeric: false,
    disablePadding: false,
    label: 'Reviewer',
  },
  {
    id: 'financialYear',
    numeric: false,
    disablePadding: false,
    label: 'Financial Year',
  },

  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'mapRequired',
    numeric: false,
    disablePadding: false,
    label: 'Whiteboard Map',
  },
  {
    id: 'dateEdited',
    numeric: false,
    disablePadding: false,
    label: 'Date Modified',
  }
];

//function for sorting descending
function descendingComparator(a, b, orderBy) {
  if (orderBy === 'mapRequired') {
    // Handle boolean comparison
    return (b[orderBy] === a[orderBy]) ? 0 : b[orderBy] ? -1 : 1;
  }
  // Existing logic for other types of comparisons (string, number, etc.)
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


//function for sorting descending
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
//For sorting for MUI Table please see: https://stackblitz.com/run?file=demo.js
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}





//refers to the TableHead, show number of rows selected aand delete icon when row/rows selected
function EnhancedTableHead(props) {
  const {
    onSelectAllClick, order, orderBy, numSelected, rowCount,
    onRequestSort, srows, setSrows, fetchedData, highestRevisionRecords,
    regionFilter, setRegionFilter, statusFilter, setStatusFilter, FYFilter, setFYFilter
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);

  };



  const regionOptions = ['East', 'North', 'West', 'South'];
  const statusOptions = ['DRAFT', 'REVIEW', 'COMPLETE', 'CANCELLED'];
  const currentMonth = new Date().getMonth();
  let currentYear;
  if (currentMonth >= 7) {
    // If the current month is August (7) or later, set the current year
    currentYear = new Date().getFullYear();
  } else {
    // If the current month is earlier than August, subtract 1 from the current year
    currentYear = new Date().getFullYear() - 1;
  }
  const prevYear = currentYear - 1;

  const nextYear = currentYear + 1;
  const twonextYear = currentYear + 2;


  const FYOptions = ["FY - " + prevYear, "FY - " + currentYear, "FY - " + nextYear, "FY - " + twonextYear];

  const [regionFilterOpen, setRegionFilterOpen] = React.useState(false);
  const [statusFilterOpen, setStatusFilterOpen] = React.useState(false);
  const [FYFilterOpen, setFYFilterOpen] = React.useState(false);
  const [anchorElRegion, setAnchorElRegion] = React.useState(null);
  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const [anchorElFY, setAnchorElFY] = React.useState(null);
  //console.log(srows);
  //const originalData = Array.from(srows);
  //console.log(fetchedData);

  //  if (regionFilter.length === 0 && statusFilter.length === 0 && FYFilter.length === 0) {

  //       setSrows(highestRevisionRecords);
  //   }


  //region
  //function to open
  function handleRegionFilterClick(e) {
    setAnchorElRegion(e.currentTarget);
    setRegionFilterOpen(true);

  }

  const handleChangeRegion = (event) => {
    const {
      target: { value },
    } = event;
    const currentIndex = regionFilter.indexOf(value);
    const newSelected = [...regionFilter];

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setRegionFilter(newSelected);
  };

  //function to close filter
  function closeRegionFilter() {
    setAnchorElRegion(null);
    setRegionFilterOpen(false);




    //status

  }

  //function to open
  function handleStatusFilterClick(e) {
    setAnchorElStatus(e.currentTarget);
    setStatusFilterOpen(true);

  }

  //function to process region filter
  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    const currentIndex = statusFilter.indexOf(value);
    const newSelected = [...statusFilter];

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setStatusFilter(newSelected);
  };
  //function to close filter
  function closeStatusFilter() {
    setAnchorElStatus(null);
    setStatusFilterOpen(false);
  }

  //Financial Year
  //function to close filter
  function closeFYFilter() {
    setAnchorElFY(null);
    setFYFilterOpen(false);
  }

  //function to open
  function handleFYFilterClick(e) {
    setAnchorElFY(e.currentTarget);
    setFYFilterOpen(true);

  }

  //function to process region filter
  const handleChangeFY = (event) => {
    const {
      target: { value },
    } = event;
    const currentIndex = FYFilter.indexOf(value);
    const newSelected = [...FYFilter];

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setFYFilter(newSelected);


  };





  //function to close filter
  function closeFYFilter() {
    setAnchorElFY(null);
    setFYFilterOpen(false);

  }


  //returns table head and table cells
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" margin="none"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>

            {headCell.id === 'region' && (
              <Button style={{ fontSize: '16px' }} onClick={(e) => handleRegionFilterClick(e)}>
                <FilterListIcon />
              </Button>
            )}
            {headCell.id === 'region' && regionFilterOpen &&
              <ClickAwayListener onClickAway={closeRegionFilter}>
                <Popover

                  open={regionFilterOpen}
                  anchorEl={anchorElRegion}
                  onClose={closeRegionFilter}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >

                  <FormControl sx={{ m: 1, width: 200 }}>

                    {regionOptions.map((regionOption) => (

                      <MenuItem key={regionOption} value={regionOption}>
                        <Checkbox value={regionOption} checked={regionFilter.indexOf(regionOption) > -1}
                          onChange={handleChangeRegion} />
                        <ListItemText primary={regionOption} />
                      </MenuItem>
                    ))}

                  </FormControl>
                </Popover>
              </ClickAwayListener>

            }

            {headCell.id === 'status' && (
              <Button style={{ fontSize: '16px' }} onClick={(e) => handleStatusFilterClick(e)}>
                <FilterListIcon />
              </Button>
            )}


            {headCell.id === 'status' && statusFilterOpen &&
              <ClickAwayListener onClickAway={closeStatusFilter}>
                <Popover

                  open={statusFilterOpen}
                  anchorEl={anchorElStatus}
                  onClose={closeStatusFilter}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >

                  <FormControl sx={{ m: 1, width: 200 }}>

                    {statusOptions.map((statusOption) => (

                      <MenuItem key={statusOption} value={statusOption}>
                        <Checkbox value={statusOption} checked={statusFilter.indexOf(statusOption) > -1}
                          onChange={handleChangeStatus} />
                        <ListItemText primary={statusOption} />
                      </MenuItem>
                    ))}

                  </FormControl>
                </Popover>
              </ClickAwayListener>

            }

            {headCell.id === 'financialYear' && (
              <Button style={{ fontSize: '16px' }} onClick={(e) => handleFYFilterClick(e)}>
                <FilterListIcon />
              </Button>
            )}


            {headCell.id === 'financialYear' && FYFilterOpen &&
              <ClickAwayListener onClickAway={closeFYFilter}>
                <Popover

                  open={FYFilterOpen}
                  anchorEl={anchorElFY}
                  onClose={closeFYFilter}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >

                  <FormControl sx={{ m: 1, width: 200 }}>

                    {FYOptions.map((FYOption) => (

                      <MenuItem key={FYOption} value={FYOption}>
                        <Checkbox value={FYOption} checked={FYFilter.indexOf(FYOption) > -1}
                          onChange={handleChangeFY} />
                        <ListItemText primary={FYOption} />
                      </MenuItem>
                    ))}

                  </FormControl>
                </Popover>
              </ClickAwayListener>

            }

          </TableCell>

        ))}

      </TableRow>
    </TableHead>
  );
}

//features for table head like sorting, selection and getting row count
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  srows: PropTypes.array.isRequired,
  setSrows: PropTypes.func.isRequired,
  fetchedData: PropTypes.array.isRequired,
  highestRevisionRecords: PropTypes.array.isRequired
};

//features for table head like sorting, selection and getting row count
export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc'); //order in asc/dsc
  const [orderBy, setOrderBy] = React.useState('region'); //order which column
  const [selected, setSelected] = React.useState([]); //which rows selected
  const [srows, setSrows] = React.useState([]); //which rows are shown while searching
  const [searched, setSearched] = React.useState(""); //gets keyword to be searched
  const [page, setPage] = React.useState(0);//gets page number
  const [rowsPerPage, setRowsPerPage] = React.useState(10);//sets rows in page

  const dense = false //ignore as dense feature not implemented
  const { farmDetails, setFarmDetails, operation, setOperation, clearContext, biosecurity, highestRevisionNumber, setHighestRevisionNumber, generalFarm, setGeneralFarm, nitrogen } = useContext(AppRunTimeContext);
  const { setNitrogen, setCarbon, setEffluent, setHealthyCalves, setIrrigation, setBiosecurity } = useContext(AppRunTimeContext);

  const { clearLinks } = useModuleLink();

  const { instance, accounts } = useMsal();
  const [fetchedData, setFetchedData] = React.useState([]);
  const [highestRevisionRecords, setHighestRevisionRecords] = React.useState([]);
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [selectedFarmData, setSelectedFarmData] = React.useState([]);
  const [selectedFarmId, setSelectedFarmId] = React.useState('');
  const [regionFilter, setRegionFilter] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [FYFilter, setFYFilter] = React.useState([]);

  function filterDataRegion(data, selectedRegions) {
    return data.filter((item) => selectedRegions.includes(item.region));
  }

  function filterDataStatus(data, selectedStatus) {
    return data.filter((item) => selectedStatus.includes(item.status));
  }

  function filterDataFY(data, selectedFY) {
    return data.filter((item) => selectedFY.includes(item.financialYear));
  }

  useEffect(() => {
    // Reset states to their default states when the HomePage component mounts
    setFarmDetails({
      supplier_no: "",
      farm_owner: "",
      plan_owner: "",
      farmAddress: "",
      farmRegion: "",
      farmPic: "",
      farmAddressLat: 0,
      farmAddressLong: 0,
      date_edited: "",
      currentYear: "",
      futureYear: "",
      financialYear: "",
      farmSurveyId: "",
      revisionNumber: "",
      surveyData: [],
      modules: [],
      hasFarmPicChanged: true,
      mapRequired: ""
    });
    setGeneralFarm({
      questions: [
        "Is a farm map available that identifies environmental characteristics on your farm?",
        "Are practices in place to minimise soil structure problems and erosion on farm?",
        "Is soil testing conducted at least every 2 years and used in conjunction with a whole-farm nutrient budget?",
        "Is fertiliser applied using the (4 Rs) right rate, right place, right time, right source approach?",
        "Do you believe your effluent management practices are current?",
        "Is biodiversity considered when making practice change on farm?",
        "Is efficient use of energy important to your farm?",
        "Do you believe water use for the dairy shed and stock water is efficient?",
        "Are controls in place for weeds and pests on farm?",
        "Are silage wrap and animal carcasses disposed of in an environmentally friendly way?",
        "Do you have shade and shelter belt plantings on farm?",
        "Are stock fenced out of riparian zones?"
      ],
      scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      practices: ["", "", "", "", "", "", "", "", "", "", "", ""],
      focusArea: ["", "", "", "", "", "", "", "", "", "", "", ""],
      goodColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      improvementColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      innovativeColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      focusColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
      },
      commentary: "•\n\n•\n\n•",
      action: "*Not applicable for General Farm Module*",
      focus: "",
      focusAdditional: "",
      approach: "*Not applicable for General Farm Module*",
      farmMap: "",
      mapTitle: "",
      mapDescription: "",
      mapFocus: "",
      generalQuestions: new Map(),
      generalPage: 0,
      images: []
    });
    setNitrogen({
      questions: [
        "Is your fertiliser spreader calibrated correctly and checked regularly?",
        "Do you assess the current weather and soil conditions before applying Nitrogen fertiliser?",
        "Are other limiting growth factors considered into your Nitrogen fertiliser decision?",
        "Do you assess pasture and feed requirements before applying Nitrogen?",
        "Do you adjust your Nitrogen fertilisation rates based on seasonal conditions?",
        "Is your grazing round adjusted throughout the season to optimise the Nitrogen response?",
        "Do you feed test for CP and adjust your diet accordingly?",
        "Do you adjust your Nitrogen formulation for minimising Nitrogen losses to the environment?",
        "Do you manage and monitor the legume content of your pasture?",
        "Do you grow alternative pasture and forage to limit Nitrogen losses to the environment?",
      ],
      scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      practices: ["", "", "", "", "", "", "", "", "", ""],
      focusArea: ["", "", "", "", "", "", "", "", "", ""],
      goodColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      improvementColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      innovativeColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      focusColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
      },
      nitrogenFertiliser: "",
      purchasedFeed: "",
      milkingCows: "",
      milkingHectares: "",
      milkSolids: "",
      milkingFat: "",
      milkProtein: "",
      homegrownFeed: "",
      importedFeed: "",
      exportedPro: "",

      surplus: "",
      benchmarkcommentary: "",
      commentary: "•\n\n•\n\n•",
      action: "•\n\n•\n\n•",
      focus: "",
      focusAdditional: "",
      approach: "•\n\n•\n\n•",
      nitrogenQuestions: new Map(),
      nitrogenPage: 0,
      graph: "",
      images: []
    });
    setCarbon({
      questions: [
        "As a cow eats more and produces more milk, the energy needed for maintenance is diluted lowering emission intensity (EI). Are your cows achieving >90% of their liveweight in kg of MS?",
        "Increasing reproductive performance will decrease your emissions as there is less DMI requirement for young stock and less methane emissions. Have you been able to improve your reproductive performance?",
        "Direct leaching and nitrous oxide emissions can be minimised by avoiding applying fertiliser to waterlogged soils. Do you have an improved drainage program for your farm?",
        "Balancing the cow’s diets for nitrogen helps minimise nitrogen loss to the environment. Do you use low nitrogen feed or grow low nitrogen forages?",
        "Using effluent as a fertiliser source and minimising build-up of manures helps limit nitrous oxide emissions. Do you have an active effluent management plan?",
        "Sequestering carbon in the soil can help offset GHG emissions. Are you aiming to increase soil carbon levels on your farm through practices such as reduced tillage, use of riparian buffers, deeper rooted species selection, improved grazing etc.?",
        "Planting trees can help ‘offset’ emissions from your farm business without impacting on production. Do you strategically plant trees on farm?",
        "On average energy and fuel account for 16% of the emissions on farm. Do you conduct any energy audits and/or use renewable energy sources?",
        "Research is accelerating the development of feed additives that can mitigate methane. Have you considered adopting low emission feed additives?",
      ],
      scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      practices: ["", "", "", "", "", "", "", "", ""],
      focusArea: ["", "", "", "", "", "", "", "", ""],
      goodColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      improvementColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      innovativeColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      focusColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
      },

      commentary: "•\n\n•\n\n•",
      action: "•\n\n•\n\n•",
      focus: "",
      focusAdditional: "",
      approach: "•\n•\n•",
      netGHG: "",
      vehicleEquivalent: "",
      GHGEmissionsIntensity: "",
      carbonQuestions: new Map(),
      graph: "",
      carbonPage: 0,
      images: []
    });
    setEffluent({
      questions: [
        "Have you calculated the water usage in the dairy and facilities and identified measures to reduce water usage?",
        "Do you have a system to separate solids and debris from the effluent stream?",
        "Is proper management in place to ensure adequate storage of effluent in your ponds during wet months or when application is not suitable?",
        "Is there proper management in place to prevent effluent from polluting groundwater, surface waters, or surrounding land in the case of roads, underpasses, laneways, and bridge crossings?",
        "How do you manage the reuse and application of the effluent to ensure no offsite discharge?",
        "How do you manage the reuse and application of the effluent to gain maximum productivity benefits from the nutrients, organic matter and water?",
        "Is a farm map used to plan irrigation from the effluent system (which incorporates soil nutrient and paddock elevation details)?",
        "How do you ensure to provide a safe work environment for staff ,visitors and service providers  and do you provide adequate training to staff on risks related to Effluent system?",
        "How do you manage effluent to prevent and minimize the potential infection and spread of diseases within the herd?",
        "Do you have a contingency plan to prevent sudden shock loading of effluent in the pond, such as when you need to dispose of milk that is unacceptable for supply or cannot be collected by Fonterra and cannot be stored on-farm for animal feeding?",
        "Have you used a ‘Self Assessment tool’ to proactively identify the risks your business may pose to human health and the environment (General Environmental Duty)?",
        "Does your effluent management plan reflect the existing effluent system? Have changes been made on farm which would require the plan to be altered?",
      ],
      scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      practices: ["", "", "", "", "", "", "", "", "", "", "", ""],
      focusArea: ["", "", "", "", "", "", "", "", "", "", "", ""],
      goodColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      improvementColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      innovativeColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      focusColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
      },
      haApplied: 0,
      commentary: "•\n\n•\n\n•",
      action: "•\n\n•\n\n•",
      focus: "",
      focusAdditional: "",
      approach: "•\n•\n•",
      effluentQuestions: new Map(),
      effluentPage: 0,
      images: []
    });
    setHealthyCalves({
      colostrum: {
        currentYear: "",
        futureYear: "",
        questions: [
          "Are teats washed, disinfected and dried prior to colostrum harvesting? Are calves collected and cows milked soon as possible after calving to manage colostrum quality and mastitis risk?",
          "Is colostrum quality tested, classified and either used immediately or stored appropriately?",
          "Are calves are actively fed an adequate volume of warm supplementary colostrum as soon as possible?",
          "Has everyone on the farm team received training in how to correctly collect, test and store colostrum; and stomach tube a calf?",
          "Do you regularly assess and review the adequacy of our colostrum management program?",
        ],
        scoreArray: [0, 0, 0, 0, 0],
        practices: ["", "", "", "", ""],
        focusArea: ["", "", "", "", ""],
        goodColumn: [0, 0, 0, 0, 0],
        improvementColumn: [0, 0, 0, 0, 0],
        innovativeColumn: [0, 0, 0, 0, 0],
        focusColumn: [0, 0, 0, 0, 0],
        calvesQuestions: new Map(),
        scores: {
          good: 0,
          innovative: 0,
          improvement: 0,
          focusArea: 0,
        },
        commentary: "•\n\n•\n\n•",
        focus: "",
        focusAdditional: "",
        actionTable: [{ id: '1', goals: 'Eg: To ensure whole farm team is harvesting colostrum to the best possible standard.', action: 'Eg: Agree upon standard of care for colostrum collection, develop a clear system and protocols, communicate to whole team and display in dairy', priority_who: '', priority_when: '' },
        { id: '2', goals: 'Eg.: To give our calves the best chance to be the healthiest, most productive calves they possibly can.', action: 'Eg: Conduct colostrum management review each season with team and regularly review performance using calf blood tests', priority_who: '', priority_when: '' },
        { id: '3', goals: 'Eg: Harvest as much high quality colostrum as quickly as we can.', action: 'Eg: Commit to twice daily collection of calves from calving area, and Brix testing colostrum', priority_who: '', priority_when: '' },
        { id: '4', goals: '', action: '', priority_who: '', priority_when: '' },
        { id: '5', goals: '', action: '', priority_who: '', priority_when: '' }],
        graph1: "",
        graph2: "",
        graph3: "",
        graph4: "",
        images: []
      },
      calfPathways: {
        currentYear: "",
        futureYear: "",
        questions: [            
          "Do all calves that you rear receive the same standard of care, whether they are replacement heifers or surplus/non-replacements?",
          "Does everybody in your farm team understand the legal requirements for transporting bobby calves (5-30 days old), and have a clear system for communicating which calves are fit for transport and sale and when they were last fed?",
          "Do you use sexed semen to breed replacement females from the highest genetic merit dams in the herd, determined using a combination of production data and genomic testing?",
          "Are beef sires used for cross breeding selected strategically for target markets?",
          "Is the proportions and numbers of calves reared reviewed annually for profitability?",
      ],
        scoreArray: [0, 0, 0, 0, 0],
        practices: ["", "", "", "", ""],
        focusArea: ["", "", "", "", ""],
        goodColumn: [0, 0, 0, 0, 0],
        improvementColumn: [0, 0, 0, 0, 0],
        innovativeColumn: [0, 0, 0, 0, 0],
        focusColumn: [0, 0, 0, 0, 0],
        calvesQuestions: new Map(),
        scores: {
          good: 0,
          innovative: 0,
          improvement: 0,
          focusArea: 0,
        },
        commentary: "•\n\n•\n\n•",
        focus: "",
        focusAdditional: "",
        actionTable: [{ id: '1', goals: 'Eg: Feel confident that the numbers and proportions of calves we are rearing is right for our system ', action: 'Eg: Calculate the rearing costs and true cost of heifer replacements for our herd, and schedule regular reviews', priority_who: '', priority_when: '' },
        { id: '2', goals: 'Eg: Know that everybody in our farm team understands the legal requirements surrounding bobby calves ', action: 'Eg: Develop an agreement for the team to read and sign. Implement communications, record keeping and signage for bobby calves', priority_who: '', priority_when: '' },
        { id: '3', goals: 'Eg: Our breeding decisions are considered and strategic, and reviewed each season', action: 'Eg: Use the Good Bulls Guide and DataGene website to get the latest information on ABVs when making decisions.', priority_who: '', priority_when: '' },
        { id: '4', goals: '', action: '', priority_who: '', priority_when: '' },
        { id: '5', goals: '', action: '', priority_who: '', priority_when: '' }],
        graph1: "",
        graph2: "",
        images: []
      },
      trainingGrid: [
        { id: '1', training_complete: '', who: '', when: '' },
        { id: '2', training_complete: '', who: '', when: '' },
        { id: '3', training_complete: '', who: '', when: '' },
        { id: '4', training_complete: '', who: '', when: '' },
        { id: '5', training_complete: '', who: '', when: '' },
        { id: '6', training_complete: '', who: '', when: '' },
        { id: '7', training_complete: '', who: '', when: '' },
        { id: '8', training_complete: '', who: '', when: '' },
        { id: '9', training_complete: '', who: '', when: '' },
        { id: '10', training_complete: '', who: '', when: '' }
      ],
      achievingTransfer: "",
      failureTransfer: "",
      less12hours: "",
      more12hours: "",
      noColostrum: "",
      goalachievingTransfer: "",
      goalfailureTransfer: "",
      goalless12hours: "",
      goalmore12hours: "",
      goalnoColostrum: "",
      replacement1: "",
      beef1: "",
      export1: "",
      rearing1: "",
      euthanised1: "",
      bobby1: "",
      replacement2: "",
      beef2: "",
      export2: "",
      rearing2: "",
      euthanised2: "",
      bobby2: "",
      healthyCalvesPage: 0
    });
    setIrrigation({
      questions: [
        "What type of irrigation system/s does your farm have?",
        "What factors do you consider when deciding on irrigation start-up? Are all staff trained in the current irrigation practices on farm?",
        "How are irrigation intervals determined? Readily Available Water (RAW) and evapotranspiration are critical to determination of required irrigation interval.",
        "What automation processes are used during the irrigation application?",
        "Does the irrigation system design allow for efficient use of water and energy?",
        "What monitoring equipment is used to determine efficient use of irrigation?",
        "How are soil related risk factor such as salinity, water logging, erosion and compaction managed?",
        "Precision flood irrigation is most efficient when water is on and off the paddock in 8 hours. Do your paddocks meet this benchmark?",
        "Do you consider the 48 hour weather forecast prior to irrigation?",
        "Do you test and monitor water quality in bores regularly?",
        "Do you utilise the correct shandy rate when irrigating wastewater (effluent), bore water etc.?",
        "Do you have a water market strategy?",
        "Do you calibrate spray irrigators prior to the season starting?",
        "Is there an ongoing maintenance schedule developed for irrigation equipment?",
        "Is variable rate (VRI) irrigation applications used if the tecnology is installed.",
      ],
      scoreArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      practices: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
      focusArea: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
      goodColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      improvementColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      innovativeColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      focusColumn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
      },
      benchmarkcommentary: "",
      commentary: "•\n\n•\n\n•",
      action: "•\n\n•\n\n•",
      focus: "",
      focusAdditional: "",
      approach: "•\n•\n•",
      waterApplied: "",
      rainfall: "",
      yourGPWUI: "",
      pastureGrown: 0,
      irrigationQuestions: new Map(),
      irrigationPage: 0,
      graph: "",
      images: [],
      selectedIrrigationType: ""
    });
    setBiosecurity({
      questions: [
        "UNDERSTANDING: Do you have a documented biosecurity plan for your farm that includes and addresses the key risks for your individual system, and is clearly communicated both verbally and visually to all people entering our property and facilities?",
        "PREVENTING and MANAGING: Do you know disease status of your herd, and existing farm weeds and pests? Do you employ screening and preventative programs to suit your individual farm biosecurity requirements?",
        "CONTROLLING and PROTECTING: Do you understand the biosecurity risks posed by farm inputs, pest species, neighbours, equipment, vehicles, and people, and have appropriate strategies and controls in place to manage these risks?",
        "CONTAINING and SEGREGATING: Are sick and dead animals, effluent, and waste materials isolated for management in a way that prevents spread of disease?",
        "MONITORING: Do you identify stock and record movements appropriately and regularly assess and review the adequacy and efficacy of your biosecurity management plan?",
      ],
      actionTable: [{ id: '1', goals: 'Eg: To ensure farm team are mitigating our biosecurity risks to the best possible standard.', action: 'Eg: Document biosecurity management plan, develop a clear set of decontamination protocols, provide required equipment, and communicate to team and display in dairy.', priority_who: '', priority_when: '' },
      { id: '2', goals: 'Eg.: Manage our biosecurity risks when introducing purchased livestock.', action: ' Eg: Purchase low-risk animals and conduct screening tests prior to purchasing livestock, and segregate and quarantine upon arrival (and plan to purchase animals with enough lead time to allow for this, ie bulls intended for upcoming use). ', priority_who: '', priority_when: '' },
      { id: '3', goals: 'Eg: Explore options for managing sick lactating cows as a separate herd or to milk last. ', action: 'Eg: Create a separate hospital area for sick calves away from healthy animals with little to no risk of cross-contamination.', priority_who: '', priority_when: '' },
      { id: '4', goals: '', action: '', priority_who: '', priority_when: '' },
      { id: '5', goals: '', action: '', priority_who: '', priority_when: '' }],

      scoreArray: [0, 0, 0, 0, 0],
      practices: ["", "", "", "", ""],
      focusArea: ["", "", "", "", ""],
      goodColumn: [0, 0, 0, 0, 0],
      improvementColumn: [0, 0, 0, 0, 0],
      innovativeColumn: [0, 0, 0, 0, 0],
      focusColumn: [0, 0, 0, 0, 0],
      scores: {
        good: 0,
        innovative: 0,
        improvement: 0,
        focusArea: 0,
      },
      commentary: "•\n\n•\n\n•",
      focus: "",
      focusAdditional: "",
      riskAssessment: "",
      managementPlan: "",
      visitorRegister: "",
      propertySignage: "",
      planReview: "",
      biosecurityQuestions: new Map(),
      biosecurityPage: 0,
      images: []
    });
    
  }, []);

  useEffect(() => {
    let baseData = highestRevisionRecords;

    // Apply filters first
    if (regionFilter.length > 0) {
      baseData = filterDataRegion(baseData, regionFilter);
    }
    if (statusFilter.length > 0) {
      baseData = filterDataStatus(baseData, statusFilter);
    }
    if (FYFilter.length > 0) {
      baseData = filterDataFY(baseData, FYFilter);
    }

    // Then search
    if (searched.length > 0) {
      baseData = baseData.filter((row) => {
        const searchString = `${row.region || ''} ${row.farmId || ''} ${formatDate(row.dateCreated) || ''} ${row.creatorName || ''} ${row.reviewerName || ''}${row.financialYear || ''} ${row.status || ''} ${formatDate(row.dateEdited) || ''}`.toLowerCase();
        return searchString.includes(searched.toLowerCase());
      });
    }

    setSrows(baseData);
  }, [highestRevisionRecords, searched, regionFilter, statusFilter, FYFilter]);


  React.useEffect(() => {
    if (clearContext) {
      // Call this function to clear the links
      clearLinks();
      console.log('context cleared')
      clearContext();
      setFarmDetails(prevState => ({ ...prevState, modules: [] }));
      console.log(farmDetails)

    }


    async function fetchData() {

      const token = await acquireAccessToken(instance, accounts[0])
      localStorage.setItem("accessToken", token);
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/api/fep/farm/getAllFarmsSurveyData`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        console.log("Fetched Data:", response.data);
        setFetchedData(response.data);
        console.log(response.data);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // Assuming fetchedData is an array of objects, each containing farmSurveyId and revisionNumber
    const records = getHighestRevisionRecords(fetchedData);
    setHighestRevisionRecords(records)
    setSrows(records); // Now srows will have only the records with highest revisionNumber for each farmSurveyId
    console.log("Processed Rows:", records);
  }, [fetchedData]);

  function getHighestRevisionRecords(data) {
    const recordMap = new Map();

    data.forEach((item) => {
      // Only proceed if farmSurveyId is available
      if (item.farmSurveyId) {
        let existingRecord = recordMap.get(item.farmSurveyId);

        if (existingRecord) {
          // If both current and existing records have revisionNumbers, compare them
          if ('dateEdited' in item && 'dateEdited' in existingRecord) {
            if (item.dateEdited > existingRecord.dateEdited) {
              recordMap.set(item.farmSurveyId, item);
            }
          } else if ('dateEdited' in item && !('dateEdited' in existingRecord)) {
            // If the current item has a revisionNumber but the existing one doesn't, replace it
            recordMap.set(item.farmSurveyId, item);
          }
          // If neither has a revisionNumber or the existing one has a revisionNumber but the current one doesn't,
          // keep the existing record (do nothing)
        } else {
          // If we haven't encountered this farmSurveyId yet, add the current item
          recordMap.set(item.farmSurveyId, item);
        }
      }
    });

    // Extract the records with the highest revisionNumbers (or without revisionNumber if none exist with one)
    return Array.from(recordMap.values());
  }


  //function for deleting rows selected
  const handleDelete = (event) => {
    let result = rows.filter(row => !selected.includes(row));
    setSrows(result);
    setSelected([]);
    if (selected.length === srows.length) {
      setSrows([]);
      setSelected([]);
    }
  }




  //function for searching and showing searched rows
  function requestSearch(searchedVal) {

    setSearched(searchedVal);
  };


  //function for resetting rows after search
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  //sorting columns based on asc or dsc
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    console.log(property);
  };

  //function for selecting all rows 
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = highestRevisionRecords.map((n) => n.name);
      setSelected(newSelected);
      console.log(newSelected);

      return;
    }
    setSelected([]);
  };

  //function for selecting rows and updating number of selected rows
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  //function to move to new page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //function to change rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //function to get the row selected
  const isSelected = (name) => selected.indexOf(name) !== -1;

  //function to get the row selected
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  //function to get table toolbar with table heading, user name, number of rows selected and delete icon
  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },

          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >

        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%', mt: 3 }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%', mt: 3 }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Table of Farm Environment Plans
          </Typography>


        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton onClick={e => handleDelete(e)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={accounts[0].name}>
            <IconButton sx={{ mr: 4 }}>
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }

  const findAndSetHighestRevisionNumber = (farmSurveyId) => {
    // Filter the dataset to find all revisions of the selected farm ID
    const revisions = fetchedData.filter(item => item.farmSurveyId === farmSurveyId);

    // Check if there are any entries with a revision number and exclude 'AUTOSAVE'
    const entriesWithRevision = revisions.filter(item => item.revisionNumber && item.revisionNumber !== "AUTOSAVE");

    let highestRevision;
    if (entriesWithRevision.length > 0) {
      // Find the highest revision number among these revisions
      highestRevision = Math.max(...entriesWithRevision.map(item => parseInt(item.revisionNumber, 10)));
      // Format as a four-digit string
      highestRevision = highestRevision.toString().padStart(4, '0');
    } else {
      // If no entries have a revisionNumber, set to '0001'
      highestRevision = '0001';
    }

    // Update the context with the highest revision number
    console.log(highestRevision)
    setHighestRevisionNumber(highestRevision);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const onModify = (row) => {
    console.log(row)
    console.log(farmDetails)
    console.log(generalFarm)
    console.log(biosecurity)
    setFarmDetails(prevState => ({ ...prevState, farmSurveyId: row.farmSurveyId, revisionNumber: row.revisionNumber }));
    setOperation("modify")
    // Filter data for the selected farmSurveyId
    const relatedData = fetchedData.filter(item => item.farmSurveyId === row.farmSurveyId)
      .map(item => ({
        ...item,
        revisionNumber: item.revisionNumber,
        dateEdited: item.dateEdited // Assuming dateEdited is always available; format it in the RevisionTable
      }));
    // Sort the related data
    relatedData.sort((a, b) => {
      // Check for undefined or empty revisionNumber and sort accordingly
      if ((!a.dateEdited || a.dateEdited.trim() === '') && (!b.dateEdited || b.dateEdited.trim() === '')) {
        return 0; // Both don't have a valid revisionNumber, retain original order
      } else if (!a.dateEdited || a.dateEdited.trim() === '') {
        return 1; // 'a' doesn't have a valid revisionNumber, place it after 'b'
      } else if (!b.dateEdited || b.dateEdited.trim() === '') {
        return -1; // 'b' doesn't have a valid revisionNumber, place 'a' before 'b'
      } else {
        // Both have valid revisionNumbers, sort by numeric value
        return parseInt(b.dateEdited, 10) - parseInt(a.dateEdited, 10);
      }
    });
    console.log(relatedData)
    setSelectedFarmData(relatedData);
    setSelectedFarmId(row.farmId); // Store the farmId of the selected row
    setDialogOpen(true); // Open the dialog
  }

  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const hour = dateString.substring(8, 10);
    const minute = dateString.substring(10, 12);
    const second = dateString.substring(12, 14);

    const dateObject = new Date(year, month - 1, day, hour, minute, second);

    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    const humanReadableDate = dateObject.toLocaleDateString('en-NZ', options);

    return humanReadableDate;
  };

  const handleModify = (row) => {
    console.log(row);
    //alert("234");
    findAndSetHighestRevisionNumber(row.farmSurveyId);
    setFarmDetails(prevState => ({
      ...prevState,
      farmSurveyId: row.farmSurveyId,
      revisionNumber: row.revisionNumber
    }));
    setOperation("modify");
  };


  const RevisionTable = ({ data }) => {
    const dataToShow = data.slice(0, 10);
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Version Number</TableCell>
            <TableCell>Date Edited</TableCell>
            <TableCell>Modified By</TableCell>
            {/* Include other columns as needed */}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToShow.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.revisionNumber || '0001'}</TableCell>
              <TableCell>{formatDate(row.dateEdited)}</TableCell>
              <TableCell>{row.editedBy || 'N/A'}</TableCell> {/* Display the editedBy field */}
              {/* Render other data fields as needed */}
              <TableCell>
                <Tooltip title="Modify this version" placement="top">
                  <Link
                    to="/Review"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      style={{ backgroundColor: "#002D4E" }}
                      onClick={() => handleModify(row)}
                    >
                      Modify
                    </Button>
                  </Link>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    //creates header
    <div className="firstPage">
      <header className="firstPage-header">
        <h1>
          Fonterra Farm Environment Plan
        </h1>
        {/* blue thing which had create button and search*/}
        <AppBar sx={{ width: '80%' }} style={{ backgroundColor: "#002D4E" }} position="sticky" color="primary">
          <Toolbar>

            <Tooltip title="Create a new plan" placement="top">

              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                style={{ backgroundColor: 'transparent' }}
                sx={{
                  mx: 'auto'
                }}
              >
                <div style={{ display: 'grid', alignItems: 'center' }}>
                  <Link
                    to="/FarmDetails"
                    onClick={() => {
                      setOperation("create");  // Setting operation to "create" in AppRunTimeContext
                    }}
                  >
                    <ControlPointIcon sx={{ fontSize: '20px', mr: 1, ml: 1 }} />
                    Create
                  </Link>
                </div>

              </IconButton>

            </Tooltip>

            <Tooltip title="Search based on any value" placement="top">
              <Search>
                <SearchIconWrapper >
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                  value={searched}

                  onChange={(searchVal) => requestSearch(searchVal.target.value)}
                  onCancelSearch={() => cancelSearch()}

                />
              </Search>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: '80%' }}>
          {/* box and paper store whole table */}
          <Paper sx={{ width: '100%' }}>
            {/* where table from enhanced toolbar, ignore dense cannot be deleted as it causes errors*/}
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                sx={{ minWidth: 500, mt: 3 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={highestRevisionRecords.length}
                  srows={srows}
                  setSrows={setSrows}
                  fetchedData={fetchedData}
                  highestRevisionRecords={highestRevisionRecords}
                  regionFilter={regionFilter}
                  setRegionFilter={setRegionFilter}
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                  FYFilter={FYFilter}
                  setFYFilter={setFYFilter}
                />
                {/* From table cells */}
                <TableBody>
                  {stableSort(srows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover

                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                          padding="none"
                          margin="none"
                        >
                          <TableCell align="center" padding="none" margin="none"></TableCell>

                          {/* each table*/}
                          <TableCell align="center" padding="none" margin="none">
                            {row.region}
                          </TableCell>

                          <TableCell align="left">{row.farmId}</TableCell>
                          <TableCell align="left">{formatDate(row.dateCreated)}</TableCell>

                          <TableCell align="left">{row.creatorName}</TableCell>
                          <TableCell align="left">{row.reviewerName}</TableCell>
                          <TableCell align="left">{row.financialYear}</TableCell>
                          <TableCell align="left">{row.status}</TableCell>
                          <TableCell align="left">{row.mapRequired ? <Checkbox
                            disabled={true}
                            style={{ marginLeft: '-10%' }}
                            checked={true}
                          /> : <Checkbox
                            disabled={true}
                            style={{ marginLeft: '-10%' }}
                            checked={false}
                          />}</TableCell>
                          <TableCell align="left">{formatDate(row.dateEdited)}</TableCell>

                          {/* buttons*/}
                          <Tooltip title="Modify the plan" placement="top">
                            {/* <Link to="/Review"> */}
                            <Button onClick={() => onModify(row)} align="left" variant="contained" id="edit" style={{ backgroundColor: "#002D4E" }} sx={{ mr: 1, ml: 1 }}>Modify</Button>
                            {/* </Link> */}
                          </Tooltip>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                    // style={{
                    //   height: (dense ? 33 : 53) * emptyRows,
                    // }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/*Pagination */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={srows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

        </Box>
      </header>

      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="dialog-title">Version History for Farm ID: {selectedFarmId}</DialogTitle>
        <DialogContent>
          <RevisionTable data={selectedFarmData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
