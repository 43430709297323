import React from 'react';
import { Page, Text, StyleSheet, Image } from '@react-pdf/renderer';
import ColostrumCover from "../../../images/ColostrumCover.png"
import {default as styles} from "../Styles"
import healthy_calves_header_icon from "../../../images/healthy_calves_header_icon.png"


const colostrumStyle = StyleSheet.create({
    header_text: {
        fontSize: 18,
        color: '#002D4E',
        fontFamily: 'Arial',
        marginTop: 10
    },
    header_number: {
        fontSize: 18,
        color: '#002D4E',
        fontFamily: 'Recoleta',
    }
})

export default function ColostrumHeader(healthyCalves, farmDetails){
    return(
        <Page>
            <div style ={{height:"45%"}} >
                <Image 
                    src = {ColostrumCover} />
            </div>
            <div style={{position: 'absolute'}}>
                <Text style={styles.title}>The Headline</Text>
                <Text style={styles.secondSubtitle}>Animal Health and Welfare - Healthy Calves (Colostrum) </Text>
            </div>    

            <div style = {{height: "45%", backgroundColor: "#F7F6FB", alignItems: "center"}}>  
                <Image 
                    src = {healthy_calves_header_icon} 
                    style = {{width: "18%", height: "30%", marginBottom: 20, marginTop: 30}}/>

                <Text style = {colostrumStyle.header_text}>Percentage of calves achieving passive transfer: 
                    <Text style = {colostrumStyle.header_number}> {healthyCalves.achievingTransfer}</Text>%
                </Text>
                <Text style = {colostrumStyle.header_text}>Percentage of calves with failure of passive transfer:  
                    <Text style = {colostrumStyle.header_number}> {healthyCalves.failureTransfer}</Text>%
                </Text>
                <Text style = {colostrumStyle.header_text}>Percentage of calves fed colostrum {'<'} 12 hours:  
                    <Text style = {colostrumStyle.header_number}> {healthyCalves.less12hours}</Text>%
                </Text>
                <Text style = {colostrumStyle.header_text}>Percentage of calves fed colostrum {'>'} 12 hours: 
                    <Text style = {colostrumStyle.header_number}> {healthyCalves.more12hours}</Text>%
                </Text>
                <Text style = {colostrumStyle.header_text}>Percentage of calves not fed colostrum at all: 
                    <Text style = {colostrumStyle.header_number}> {healthyCalves.noColostrum}</Text>%
                </Text>
            </div> 
            
            <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
            <Text style={styles.pageNumber} render={({ pageNumber }) => (
            `${pageNumber}`
            )} fixed />
        </Page>

    )
}