import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

// Takes user to the next page
const Next = ({ onClick }) => {
    return (
      <div className="btn-text-right">
        <Button
          style={{ backgroundColor: "#002D4E" }}
          id="next"
          variant="contained"
          onClick={onClick}
        >
          Next
        </Button>
      </div>
    );
  };

export default Next;
