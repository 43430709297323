import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import action from "../../../images/Action.jpg"
import {default as styles} from "../Styles"

export default function NitrogenApproach(nitrogen, farmDetails){
	  return(
    <Page>
      {/* header */}
      <div style = {styles.approachHeaderImageBox} >
        <Image 
          src = {action}
          style={{position: 'relative',
          display: 'flex',
          paddingBottom: 0,
          marginBottom: 0}} />
      
        <div style={{position: 'absolute'}}>
          <Text style={styles.title}>The Action</Text>
          <Text style={styles.secondSubtitle}>Nutrient Management (Nitrogen)</Text>  
        </div>
      </div>

      <View fixed style = {{height: 15}}></View>
      {nitrogen.approach !== '' && (
      <div style = {styles.approach}>        
        <Text style={styles.approach_text}>Approach</Text>
        <Text style = {styles.text_action}>{nitrogen.approach} </Text>
      </div>
      )}
       <View fixed style = {{marginTop: "30%"}}></View> 
      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  )
}